import {
  mdiArrangeSendBackward, mdiCardMultipleOutline,
  mdiChartLineVariant,
  mdiCircleOutline, mdiDraw, mdiEllipseOutline,
  mdiFolder,
  mdiFormatText,
  mdiImage,
  mdiImageOutline, mdiPackageVariant,
  mdiPanorama, mdiPanoramaHorizontal,
  mdiRectangleOutline, mdiShapeOvalPlus, mdiSquareCircle, mdiTriangleOutline, mdiVectorLine
} from "@mdi/js";
import { fabric } from 'fabric';
import { lockOptions } from "../config"


const CanvasObject = {
  FreeTextbox: {
    title: `文字图层`,
    icon: mdiFormatText,
    create: ({text, ...option}) => new fabric.FreeTextbox(text, option),
    options: {
      type: `FreeTextbox`,
      category: `text`, // 用于图层区分
      splitByGrapheme: true,
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      backgroundColor: `rgba(255, 255, 255, 0)`,
      hoverCursor: `pointer`,
      editable: true,
      cloneable: true,
      evented: true,
      // lockUniScaling: false
    },
    listener: (obj, canvas) => {
      obj.on('scaling', () => {
        let newHeight = obj.height * obj.scaleY;
        obj.set({
          width: obj.width * obj.scaleX,
          _width: obj.width * obj.scaleX,
          scaleX: 1,
        });
        obj.initDimensions();
        obj.set({height: newHeight, scaleY: 1, _height: newHeight});
        // canvas.bringToFront(obj);
      });
      obj.on(`changed`, (e) => {
        if (obj.height < obj._height) {
          let newHeight = obj._height;
          obj.set({height: newHeight, _height: newHeight})
        }
      })
      obj.on('mouseup', (e) => {
        obj.selectionText = obj[`text`].slice(obj.selectionStart, obj.selectionEnd)
      });
    }
  },
  textbox: {
    title: `文字`,
    icon: mdiFormatText,
    create: ({text, ...option}) => new fabric.Textbox(text, option),
    options: {
      erasable: false,
      icon: mdiFormatText,
      title: `文字`,
      type: `textbox`,
      category: `textbox`, // 用于图层区分
      splitByGrapheme: true,
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      backgroundColor: `rgba(255, 255, 255, 0)`,
      // hoverCursor: `pointer`,
      editable: true,
      cloneable: true,
      evented: true,
      strokeWidth: 0,
      paintFirst: "stroke",
      // lockUniScaling: false
    },
  },
  line: {
    title: `直线`,
    icon: mdiVectorLine,
    create: (options) => {
      const {coords} = options;
      return new fabric.Line(coords, options)
    },
    options: {
      erasable: false,
      editable: true,
      icon: mdiVectorLine,
      originX: `center`,
      originY: `center`,
      title: `直线`,
      type: `line`,
      category: `line`, // 用于图层区分
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      strokeUniform: true,

      // lockScalingFlip: true,
      // lockScalingX: true,
      // lockScalingY: true,
      // hoverCursor: `pointer`,
    },
  },
  triangle: {
    title: `三角`,
    icon: mdiTriangleOutline,
    create: (option) => new fabric.Triangle(option),
    options: {
      erasable: true,
      icon: mdiTriangleOutline,
      title: `三角`,
      type: `triangle`,
      category: `triangle`, // 用于图层区分
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      backgroundColor: `rgb(255, 255, 255, 0)`,
      // hoverCursor: `pointer`,
    },
  },
  circle: {
    title: `圆形`,
    icon: mdiCircleOutline,
    create: (option) => new fabric.Circle(option),
    options: {
      erasable: true,
      icon: mdiCircleOutline,
      title: `圆形`,
      type: `circle`,
      category: `circle`, // 用于图层区分
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      backgroundColor: `rgb(255, 255, 255, 0)`,
      // hoverCursor: `pointer`,
    },
  },
  ellipse: {
    title: `椭圆`,
    icon: mdiEllipseOutline,
    create: (option) => new fabric.Ellipse(option),
    options: {
      erasable: true,
      icon: mdiEllipseOutline,
      title: `椭圆`,
      type: `ellipse`,
      category: `ellipse`, // 用于图层区分
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      backgroundColor: `rgb(255, 255, 255, 0)`,
      // hoverCursor: `pointer`,
    },
  },
  rect: {
    title: `矩形`,
    icon: mdiRectangleOutline,
    create: (option) => new fabric.Rect(option),
    options: {
      erasable: true,
      icon: mdiRectangleOutline,
      title: `矩形`,
      type: `rect`,
      category: `rect`, // 用于图层区分
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      backgroundColor: `rgb(255, 255, 255, 0)`,
      // hoverCursor: `pointer`,
    },
  },
  image: {
    title: `图片`,
    icon: mdiImageOutline,
    create: ({element = new Image(), ...option}) => new fabric.Image(element, {
      ...option,
      crossOrigin: 'anonymous',
    }),
    options: {
      erasable: true,
      icon: mdiImageOutline,
      title: `图片`,
      type: `image`,
      category: `image`, // 用于图层区分
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      backgroundColor: `rgb(255, 255, 255, 0)`,
      // hoverCursor: `pointer`,
      editable: true,
      cloneable: true,
      evented: true,

      // 等比缩放
      uniSize: true,
    },
  },

  // 非常规
  path: {
    title: `路径`,
    icon: mdiChartLineVariant,
    options: {
      type: `path`,
      category: `path`,
      ...lockOptions
    }
  },
  activeSelection: {
    title: `涂鸦组`,
    icon: mdiDraw,
    selectable: false,
    options: {
      type: `activeSelection`,
      category: `group`,
    }
  },
  segment: {
    title: `背景层`,
    icon: mdiArrangeSendBackward,
    evented: true,
    selectable: true,
    options: {
      erasable: false,
      icon: mdiArrangeSendBackward,
      title: `背景层`,
      type: `image`,
      category: `segment`, // 用于图层区分
      strokeWidth: 0,
      // hoverCursor: `pointer`,
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      // 同时在图层中不可选择
      // excludeFromLayer: true,
      // 这个会在点击快捷键时 忽略当前选中 从而触发模式的切换
      // excludeFromIntersection: true,
      // // 忽略历史记录
      // ignoreTransaction: true,
      // 等比缩放
      uniSize: true,
      ...lockOptions,
    }
  },
  background: {
    title: `背景`,
    icon: mdiPanoramaHorizontal,
    evented: true,
    selectable: false,
    options: {
      erasable: false,
      title: `背景`,
      type: `image`,
      category: `background`, // 用于图层区分
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      // backgroundColor: `rgb(255, 255, 255, 0)`,
      // 同时在图层中不可选择
      excludeFromLayer: true,
      // 这个会在点击快捷键时 忽略当前选中 从而触发模式的切换
      excludeFromIntersection: true,
      // 忽略历史记录
      ignoreTransaction: true,
      hoverCursor: `pointer`,
      // 等比缩放
      uniSize: true,
      ...lockOptions,
    }
  },
  // 画板的配置项
  board: {
    title: `画布`,
    icon: mdiSquareCircle,
    selectable: false,
    evented: false,
    editable: false,
    options: {
      erasable: false,
      type: `rect`,
      category: `board`, // 用于图层区分
      hoverCursor: `default`,

      excludeFromExport: true,
      fill: `rgba(0, 0, 0, 0)`,
      stroke: `#807f80`,
      strokeUniform: true,
      rx: 5,
      ry: 5,
      strokeWidth: 5,
      left: -5,
      top: -5,

      // 不可删除标记
      eternal: true,
      // 这个会在点击快捷键时 忽略当前选中 从而触发模式的切换
      excludeFromIntersection: true,
      // 会从图层中去除（用户不可见 一般用于画板、框选区域、画笔的笔触）
      excludeFromLayer: true,
      // 忽略历史记录
      ignoreTransaction: true,
      // 锁定的参数
      ...lockOptions,
    }
  },
  boardHeader: {
    selectable: false,
    evented: false,
    editable: false,
    options: {
      erasable: false,
      type: `textbox`,
      category: `boardHeader`, // 用于图层区分
      hoverCursor: `default`,

      excludeFromExport: true,
      left: -5,
      top: -36,
      fontSize: 24,
      fill: `#807f80`,

      // 不可删除标记
      eternal: true,
      // 这个会在点击快捷键时 忽略当前选中 从而触发模式的切换
      excludeFromIntersection: true,
      // 会从图层中去除（用户不可见 一般用于画板、框选区域、画笔的笔触）
      excludeFromLayer: true,
      // 忽略历史记录
      ignoreTransaction: true,
      // 锁定的参数
      ...lockOptions,
    }
  },
  collection: {
    title: `多选`,
    icon: mdiCardMultipleOutline,
    options: {
      erasable: true,
      icon: mdiCardMultipleOutline,
      title: `多选`,
      type: `collection`,
      category: `collection`, // 用于图层区分
      borderColor: `rgb(222, 64, 118)`,
      borderDashArray: [3, 3, 3, 3],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 118)`,
      backgroundColor: `rgb(255, 255, 255, 0)`,
      // hoverCursor: `pointer`,
    },
  },
  selector: {
    options: {
      type: `rect`,
      category: `selector`,
      fill: "rgba(222,64,117,0.2)",
      borderColor: `rgb(222, 64, 117)`,
      borderDashArray: [5, 5, 5, 5],
      cornerSize: 10,
      cornerColor: `rgb(222, 64, 117)`,
      hoverCursor: `pointer`,
      strokeWidth: 0,
      // 会从图层中去除（用户不可见 一般用于画板、框选区域、画笔的笔触）
      // 同时在图层中不可选择
      excludeFromLayer: true,
      // 这个会在点击快捷键时 忽略当前选中 从而触发模式的切换
      excludeFromIntersection: true,
      // 忽略历史记录
      ignoreTransaction: true,
    },
  },
  patch: {
    create: (option) => new fabric.Rect(option),
    options: {
      type: `rect`,
      fill: `rgba(0, 0, 0, 1)`,
      strokeWidth: 0,
      stroke: null,
      ignoreTransaction: true,
    }
  },
  cursor: {
    draw: {
      options: {
        erasable: false,
        type: `circle`,
        category: `cursor`,
        radius: 15,
        fill: "rgb(222,64,117)",
        strokeDashArray: [5, 1],
        stroke: 'black',
        // opacity: 0.5,
        originX: "center",
        originY: "center",
        editable: false,
        freeDrawingCursor: `default`,
        hoverCursor: `default`,
        ...lockOptions,
        // 同时在图层中不可选择
        excludeFromLayer: true,
        // 这个会在点击快捷键时 忽略当前选中 从而触发模式的切换
        excludeFromIntersection: true,
        // 忽略历史记录
        ignoreTransaction: true,
      },
    },
    patch: {
      options: {
        erasable: false,
        type: `circle`,
        category: `cursor`,
        radius: 15,
        fill: "rgba(106, 117, 202, 0.5)",
        strokeDashArray: [5, 1],
        stroke: 'rgb(106, 117, 202)',
        // opacity: 0.5,
        originX: "center",
        originY: "center",
        editable: false,
        freeDrawingCursor: `default`,
        hoverCursor: `default`,
        ...lockOptions,
        // 同时在图层中不可选择
        excludeFromLayer: true,
        // 这个会在点击快捷键时 忽略当前选中 从而触发模式的切换
        excludeFromIntersection: true,
        // 忽略历史记录
        ignoreTransaction: true,
      },
    }
  }
};

export default CanvasObject;
