import { mdiEyedropperVariant, mdiFormatSize, mdiSquare, mdiSquareOutline, mdiWaterOutline } from "@mdi/js";
import Mcon from "@mdi/react";
import { Button, InputNumber, Divider, Select, Slider } from "antd";
import React from "react";
import { SketchPicker } from "react-color";
import { defaultFontSize } from "../../config";
import { HexToRGBA, RGBToHex } from "../../utils";

const keyName = "tool.tools.patch";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;

export default function ToolPatch({editing, handler, brush}) {
  const {width, color} = brush || {};
  const freeDrawingBrush = handler?.canvas?.freeDrawingBrush;

  const compHandler = {
    enable: () => {
      handler.onState({outsideDisabled: false})
    },
    disabled: () => {
      handler.onState({outsideDisabled: true})
    },
  }

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <div onMouseEnter={compHandler.disabled}
             onMouseLeave={compHandler.enable}>
          {/*文本大小*/}
          <div>
            <div className="label"><Mcon size={iconSize} path={mdiFormatSize} /> 画笔宽度</div>
            <Button.Group>
              <InputNumber value={width}
                           style={{width: 100, borderRadius: `0`, marginLeft: -1}}
                           step={1}
                           onChange={val => handler.drawHandler.set(`width`, val)}
              />
              <Select className={`in-group-selector`}
                      dropdownMatchSelectWidth={false}
                      onChange={val => handler.drawHandler.set(`width`, val)}
                      value={width}>
                {
                  defaultFontSize.map((f, fi) => <Select.Option key={f} value={f}>{f}</Select.Option>)
                }
              </Select>
            </Button.Group>
            <Slider min={1} max={255} value={width} onChange={val => handler.drawHandler.set(`width`, val)} />
          </div>

          <Divider />

          <Button block type="primary" onClick={e => handler.interactionHandler.selection()}>退出涂抹</Button>
        </div>
      </div>
    </div>
  )
}