import { getOS } from "../../../../utils";
const shortMainKeyName = getOS() === `MacOS` ? `metaKey` : `ctrlKey`;


class ShortcutHandler {
  constructor(handler) {
    this.handler = handler;
    this.keyEvent = handler.keyEvent;
  }

  /**
   * Whether keydown Escape
   *
   * @param {KeyboardEvent} e
   * @returns
   */
  isEscape = (e) => {
    return e.keyCode === 27 && this.keyEvent.esc;
  }

  /**
   * Whether keydown Delete
   *
   * @param {KeyboardEvent} e
   * @returns
   */
  isDelete = (e) => {
    return (e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 127) && this.keyEvent.del;
  }

  /**
   * Whether keydown Arrow
   *
   * @param {KeyboardEvent} e
   * @returns
   */
  isArrow = (e) => {
    return e.code.includes('Arrow') && this.keyEvent.move;
  }

  /**
   * Whether keydown Ctrl + Z
   *
   * @param {KeyboardEvent} e
   * @returns
   */
  isMetaZ = (e) => {
    return e.metaKey && e.keyCode === 90 && this.keyEvent.transaction;
  }

  /**
   * Whether keydown Ctrl + Z
   *
   * @param {KeyboardEvent} e
   * @returns
   */
  isMetaShiftZ = (e) => {
    return e.metaKey && e.shiftKey && e.keyCode === 90 && this.keyEvent.transaction;
  }

  /**
   * Whether keydown Ctrl + Z
   *
   * @param {KeyboardEvent} e
   * @returns
   */
  isCtrlZ = (e) => {
    return e.ctrlKey && e.keyCode === 90 && this.keyEvent.transaction;
  }

  /**
   * Whether keydown Ctrl + Z
   *
   * @param {KeyboardEvent} e
   * @returns
   */
  isCtrlShiftZ = (e) => {
    return e.ctrlKey && e.shiftKey && e.keyCode === 90 && this.keyEvent.transaction;
  }

  isCtrlC = (e) => {
    return e.ctrlKey && e.keyCode === 67 && this.keyEvent.copy;
  }

  isCtrlV = (e) => {
    return e.ctrlKey && e.keyCode === 86 && this.keyEvent.paste;
  }

  isCtrlX = (e) => {
    return e.ctrlKey && e.keyCode === 88 && this.keyEvent.cut;
  }

  isCtrlS = (e) => {
    return (e.ctrlKey || e.metaKey) && e.keyCode === 83 && this.keyEvent.save;
  }

  isT = (e) => {
    return e.keyCode === 84 && this.keyEvent.txt;
  }

  isB = (e) => {
    return e.keyCode === 66 && this.keyEvent.draw;
  }

  isC = (e) => {
    return e.keyCode === 67 && this.keyEvent.ocr;
  }

  isX = (e) => {
    return e.keyCode === 88 && this.keyEvent.cut;
  }

  isV = (e) => {
    return e.keyCode === 86 && this.keyEvent.txt;
  }

  isJ = (e) => {
    return e.keyCode === 74 && this.keyEvent.patch;
  }

  isSpace = (e) => {
    return e.keyCode === 32 && this.keyEvent.space;
  }

  isO = (e) => {
    return (e.keyCode === 79 && !e[shortMainKeyName]);
  }

  isR = (e) => {
    return (e.keyCode === 82 && !e[shortMainKeyName]);
  }
}

export default ShortcutHandler