import { mdiFormatLetterCaseUpper } from "@mdi/js";
import Mcon from "@mdi/react";
import { Tooltip, Button } from "antd";
import { PopoverGuideWidget } from "./index";
import React from "react";
import { toolbarConfig } from "../../";

const compKey = `tool.pdf.widget.btn.guide`;
const compKlass = compKey.replace(/\./g, '-');

export default function buttonGuideComp({active, type, onPress, guideStatus, ...other}) {
  let p = type.split(".")[0], c = type.split(".")[1];
  const {title, icon, description, guide} = toolbarConfig[p][c];
  return (
    <span className={`cp-${compKlass}`} {...other}>
      {
        !!guideStatus ?
          <Tooltip mouseEnterDelay={0.8}
                   overlayClassName={`cp-${compKlass}-popover`}
                   title={title}
                   placement={`bottom`}
          >
            <Button onClick={onPress} type={active ? `primary` : `default`} icon={<Mcon size={0.75} path={icon} />}>

            </Button>
          </Tooltip>
          :
          <Button onClick={onPress} type={active ? `primary` : `default`} icon={<Mcon size={0.75} path={icon} />}>

          </Button>
      }
    </span>
    // <span className={`cp-${compKlass}`} {...other}>
    //   {
    //     !!guideStatus ?
    //       <Popover mouseEnterDelay={0.8}
    //                overlayClassName={`cp-${compKlass}-popover`}
    //                content={<PopoverGuideWidget src={guide.video} title={title} description={description} />}
    //       >
    //         <Button onClick={onPress} type={active ? `primary` : `default`} icon={<Mcon size={0.75} path={icon} />}>
    //
    //         </Button>
    //       </Popover>
    //       :
    //       <Button onClick={onPress} type={active ? `primary` : `default`} icon={<Mcon size={0.75} path={icon} />}>
    //
    //       </Button>
    //   }
    // </span>

  )
}