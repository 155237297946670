import React, { useState } from "react"
import { Button, Row, Col, Space, Select, InputNumber, Tooltip } from "antd";
import {
  mdiAlignHorizontalCenter,
  mdiAlignHorizontalDistribute,
  mdiAlignHorizontalLeft,
  mdiAlignHorizontalRight,
  mdiAlignVerticalBottom,
  mdiAlignVerticalCenter,
  mdiAlignVerticalDistribute,
  mdiAlignVerticalTop,
  mdiChevronDown,
  mdiChevronUp,
  mdiHexagon,
  mdiHexagonOutline,
  mdiLastpass,
} from "@mdi/js";
import Mcon from "@mdi/react";
import { ButtonGuideWidget } from "../../../widgets";
import { capitalize, lowerCase, upperCase } from "lodash";
import { isValidArr } from "../../../../../../../utils";

const keyName = "tool.new.textbox.style";
const klassName = keyName.replace(/\./g, '-');
const video_guide_open = true;
const iconSize = 0.75;
const iconColor = `#333`;

export default function TextboxColor({handler, editing, fontsZH, fontsEN, fontsAvailable}) {

  const {
    fontSize,
    fontFamily,
    fontWeight,
    underline,
    linethrough,
    textAlign,
    fill,
    lineHeight,
    fontStyle,
    splitByGrapheme,
    backgroundColor,
    charSpacing
  } = editing || {};

  let isBold = fontWeight === `bold`;
  let isItalic = fontStyle === `italic`;
  let isUnderline = underline;
  let isLinethrough = linethrough;
  let alignment = textAlign;
  let isSplitByGrapheme = splitByGrapheme;

  const charSpacingPercent = (charSpacing / 1000).toFixed(1);

  const [more, setMore] = useState(false)

  return (
    <div className={`cp-${klassName}`}>
      <Space direction={`vertical`} className="inner">
        <Row gutter={3}>
          <Col span={24}>
            <Select style={{width: `100%`}}
                    onChange={val => handler.sets(`fontFamily`, val)}
                    value={fontFamily || `SimHei`}>
              <Select.OptGroup label={`中文字体`}>
                {
                  fontsZH.map((font, fi) => {
                    // if (!fontsAvailable.includes(font.name)) return null;
                    return <Select.Option key={font.title} value={font.name}><span
                      style={{fontFamily: font.name}}>{font.title}</span></Select.Option>
                  })
                }
              </Select.OptGroup>
              <Select.OptGroup label={`英文字体`}>
                {
                  fontsEN.map((font, fi) => {
                    // if (!fontsAvailable.includes(font.name)) return null;
                    return <Select.Option key={font.title} value={font.name}><span
                      style={{fontFamily: font.name}}>{font.title}</span></Select.Option>
                  })
                }
              </Select.OptGroup>
            </Select>
          </Col>

        </Row>

        <Row gutter={3}>
          <Col span={8}>
            <InputNumber value={fontSize}
                         style={{width: `100%`}}
                         min={1}
                         max={999}
                         step={5}
                         onChange={val => handler.sets(`fontSize`, val)}
            />
            <span className="text">字号</span>
          </Col>

          <Col span={8}>
            <InputNumber value={charSpacingPercent}
                         style={{width: `100%`}}
                         min={-10}
                         max={10}
                         step={0.1}
                         onChange={val => handler.sets(`charSpacing`, val * 1000)}
            />
            <span className="text">字间距</span>
          </Col>

          <Col span={8}>
            <InputNumber value={lineHeight}
                         style={{width: `100%`}}
                         min={1}
                         max={999}
                         step={1}
                         onChange={val => handler.sets(`lineHeight`, val)}
            />
            <span className="text">行距</span>
          </Col>
        </Row>


        <Row justify="space-between">
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={isBold}
                               type={`font.bold`}
                               onPress={e => handler.sets(`fontWeight`, isBold ? `normal` : `bold`)}
            />
          </Col>

          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={isItalic}
                               type={`font.italic`}
                               onPress={e => handler.sets(`fontStyle`, isItalic ? `normal` : `italic`)}
            />
          </Col>
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={isUnderline}
                               type={`font.underline`}
                               onPress={e => handler.sets(`underline`, !isUnderline)}
            />
          </Col>
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={isLinethrough}
                               type={`font.linethrough`}
                               onPress={e => handler.sets(`linethrough`, !isLinethrough)}
            />
          </Col>


          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.capitalize`}
                               onPress={e => {
                                 let val = capitalize(editing.text);
                                 handler.sets(`text`, val)
                               }}
            />
          </Col>
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.upperCase`}
                               onPress={e => {
                                 let val = upperCase(editing.text);
                                 handler.sets(`text`, val)
                               }}
            />
          </Col>
        </Row>


        {/*<Row justify="space-between">*/}
        {/*  <Col>*/}
        {/*    <Button icon={<Mcon color={iconColor} size={iconSize} path={mdiAlignHorizontalLeft}/>}*/}
        {/*            onClick={e => handler.sets(`left`, 0)}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <Button icon={<Mcon color={iconColor} size={iconSize} path={mdiAlignHorizontalCenter}/>}*/}
        {/*            onClick={e => {*/}
        {/*              let value = (handler.canvas.lastWidth - editing.width * editing.scaleX || 1) / 2;*/}
        {/*              handler.sets(`left`, value)*/}
        {/*            }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <Button icon={<Mcon color={iconColor} size={iconSize} path={mdiAlignHorizontalRight}/>}*/}
        {/*            onClick={e => {*/}
        {/*              let value = handler.canvas.lastWidth - editing.width * editing.scaleX || 1;*/}
        {/*              handler.sets(`left`, value)*/}
        {/*            }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <Button icon={<Mcon color={iconColor} size={iconSize} path={mdiAlignVerticalTop}/>}*/}
        {/*            onClick={e => handler.sets(`top`, 0)}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <Button icon={<Mcon color={iconColor} size={iconSize} path={mdiAlignVerticalCenter}/>}*/}
        {/*            onClick={e => {*/}
        {/*              let value = (handler.canvas.lastHeight - editing.height * editing.scaleY || 1) / 2;*/}
        {/*              handler.sets(`top`, value)*/}
        {/*            }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col>*/}
        {/*    <Button icon={<Mcon color={iconColor} size={iconSize} path={mdiAlignVerticalBottom}/>}*/}
        {/*            onClick={e => {*/}
        {/*              let value = handler.canvas.lastHeight - editing.height * editing.scaleY || 1;*/}
        {/*              handler.sets(`top`, value)*/}
        {/*            }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}

        <Row justify="space-between">
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={alignment === `left`}
                               type={`alignment.left`}
                               onPress={e => handler.sets(`textAlign`, `left`)}
            />
          </Col>
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={alignment === `center`}
                               type={`alignment.center`}
                               onPress={e => handler.sets(`textAlign`, `center`)}
            />
          </Col>
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={alignment === `right`}
                               type={`alignment.right`}
                               onPress={e => handler.sets(`textAlign`, `right`)}
            />
          </Col>
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={alignment === `justify`}
                               type={`alignment.justify`}
                               onPress={e => handler.sets(`textAlign`, `justify`)}
            />
          </Col>
          <Col>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.lowerCase`}
                               onPress={e => {
                                 let val = lowerCase(editing.text);
                                 handler.sets(`text`, val)
                               }}
            />
          </Col>
          <Col>
            <Button icon={<Mcon size={0.75} path={more ? mdiChevronUp : mdiChevronDown}/>} shape="square"
                    onClick={e => setMore(!more)}/>
          </Col>
        </Row>

        {
          more &&
          <Row justify="space-between">
            <Col>
              <Tooltip title={`截断单词`} mouseEnterDelay={0.8} placement={`bottom`}>
                <Button shape={`square`}
                        icon={<Mcon size={0.75} path={mdiLastpass}/>}
                        type={isSplitByGrapheme ? `primary` : `default`}
                        onClick={e => {
                          handler.sets(`splitByGrapheme`, !editing.splitByGrapheme);
                        }}/>
              </Tooltip>


            </Col>

            <Col>
              <ButtonGuideWidget guideStatus={video_guide_open}
                                 type={`str.dbc`}
                                 onPress={e => handler.contentHandler.toCDB(`DBC`)}
              />
            </Col>

            <Col>
              <ButtonGuideWidget guideStatus={video_guide_open}
                                 type={`str.cdb`}
                                 onPress={e => handler.contentHandler.toCDB(`CDB`)}
              />
            </Col>

            <Col>
              <ButtonGuideWidget guideStatus={video_guide_open}
                                 type={`str.superScript`}
                                 onPress={e => handler.contentHandler.superScript()}
              />
            </Col>

            <Col>
              <ButtonGuideWidget guideStatus={video_guide_open}
                                 type={`str.subScript`}
                                 onPress={e => handler.contentHandler.subScript()}
              />
            </Col>

            <Col>
              <ButtonGuideWidget guideStatus={video_guide_open}
                                 type={`str.removeScript`}
                                 onPress={e => handler.contentHandler.removeScript()}
              />
            </Col>
          </Row>
        }
      </Space>
    </div>
  )
}