import { mdiTranslate } from "@mdi/js";
import Mcon from "@mdi/react";
import { Space, Switch, Divider, Select, Form } from "antd";
import React, { Fragment, useEffect } from "react";
import useState from "react-usestateref"
import Languages from "../../assets/languages_map.json";


const compKey = `tool.pdf.tool.ocr.config`
const compKlass = compKey.replace(/\./g, '-');

export default function TextInput({handler, editing, tarLang, autoTranslate}) {
  const languages = Object.values(Languages);

  const _onChange = (e, key) => {
    handler.sets(key, e.target.value, editing)
  }

  const compHandler = {
    enable: () => {
      handler.onState({outsideDisabled: false})
    },
    disabled: () => {
      handler.onState({outsideDisabled: true})
    },
  }


  return (
    <div className={`cp-${compKlass}`}>
      <div className="inner"
           onMouseEnter={compHandler.disabled}
           onMouseLeave={compHandler.enable}
      >
        <Space>
          <div className="label">选择语言</div>
          <Select
            showSearch
            value={tarLang || editing[`to`]}
            className="lang-select"
            placeholder="选择语言"
            optionFilterProp="children"
            onChange={val => handler.translateHandler.changeLanguage(val)}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              languages.map(lang => {
                return (
                  <Select.Option key={lang[`code`]} value={lang[`code`]}>{lang[`name_cn`]}</Select.Option>
                )
              })
            }
          </Select>
        </Space>

        <Divider />

        <Space>
          <div className="label">自动翻译</div>
          <Switch
            checkedChildren="开" unCheckedChildren="关"
            checked={autoTranslate}
            onChange={e => handler.dbHandler.set(`func`, `auto_translate`, !autoTranslate)} />
        </Space>
      </div>
    </div>
  )
}