import {
  mdiCircleDouble,
  mdiEyedropperVariant,
  mdiFormatSize, mdiHexagon, mdiHexagonOutline,
  mdiSquare,
  mdiSquareOutline, mdiWaterOpacity,
  mdiWaterOutline
} from "@mdi/js";
import Mcon from "@mdi/react";
import { Button, InputNumber, Divider, Select, Slider, Popover, Row, Col } from "antd";
import React from "react";
import { defaultFontSize } from "../../../../config";
import { SketchPicker } from "react-color";
import { hexToRgb, HexToRGBA, RGBToHex } from "../../../../utils";
import { ToolPanelListItemNewComp } from "../../index";


const keyName = "tool.new.image.fill";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;

export default function ToolFill({fill, handler, brush}) {

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagon,
            iconColor: fill,
            title: `当前`
          }}
          active={false}
        >
          <label onChange={e => {
            const {r, g, b} = hexToRgb(e.target.value);
            handler.fillHandler.set(`rgb(${r}, ${g}, ${b})`)
          }}
          >
            <input defaultValue={fill} type="color"
                   style={{visibility: `hidden`, position: `absolute`}}/>
          </label>
        </ToolPanelListItemNewComp>

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagonOutline,
            title: `白色`,
            click: e => handler.fillHandler.set(colorWhite)
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagon,
            title: `黑色`,
            click: e => handler.fillHandler.set(colorBlack)
          }}
          handler={handler}
          active={false}
        />
      </div>
    </div>
  )
}