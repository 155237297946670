// name是fontfamily用 title是用户看的

const system = {
  // 安全字体
  "Arial": {
    name: `Arial`,
    title: `Arial`, nation: `en`
  },
  "Courier New": {
    name: `Courier New`,
    title: `Courier New`, nation: `en`
  },
  "Georgia": {
    name: `Georgia`,
    title: `Georgia`, nation: `en`
  },
  "Times New Roman": {
    name: `Times New Roman`,
    title: `Times New Roman`, nation: `en`
  },
  "Trebuchet MS": {
    name: `Trebuchet MS`,
    title: `Trebuchet MS`, nation: `en`
  },
  "Verdana": {
    name: `Verdana`,
    title: `Verdana`, nation: `en`
  },

  // 本机可能有的字体
  "STXihei": {
    name: `STXihei`,
    title: `华文细体`, nation: `zh-CN`
  },
  "Microsoft YaHei": {
    name: `Microsoft YaHei`,
    title: `微软雅黑`, nation: `zh-CN`
  },
  "PingFang SC": {
    name: `PingFang SC`,
    title: `苹方简体`, nation: `zh-CN`
  },
  "SimSun": {
    name: "SimSun",
    title: "中易宋体",
    nation: `zh-CN`
  },
  "SimHei": {
    name: "SimHei",
    title: "中易黑体",
    nation: `zh-CN`
  },
  "FangSong": {
    name: "FangSong",
    title: "仿宋",
    nation: `zh-CN`
  },
  "KaiTi": {
    name: "KaiTi",
    title: "楷体",
    nation: `zh-CN`
  },
  "LiSu": {
    name: "LiSu",
    title: "隶书",
    nation: `zh-CN`
  },
  "STKaiti": {
    name: "STKaiti",
    title: "华文楷体",
    nation: `zh-CN`
  },
  "STSong": {
    name: "STSong",
    title: "华文宋体",
    nation: `zh-CN`
  },
  "STZhongsong": {
    name: "STZhongsong",
    title: "华文中宋",
    nation: `zh-CN`
  },
  "STFangsong": {
    name: "STFangsong",
    title: "华文仿宋",
    nation: `zh-CN`
  },
}

const free = {
  "Hallmarke Condensed Black": {
    title: "Hallmarke Condensed Black",
    name: "Hallmarke Condensed Black",
    nation: "en"
  },
  "Nexa Bold": {
    title: "Nexa Bold",
    name: "Nexa Bold",
    nation: "en"
  },
  "Aqum Classic": {
    title: "Aqum Classic",
    name: "Aqum Classic",
    nation: "en"
  },
  "Aqum Small Caps": {
    title: "Aqum Small Caps",
    name: "Aqum Small Caps",
    nation: "en"
  },
  "HolySans Normal": {
    title: "HolySans Normal",
    name: "HolySans Normal",
    nation: "en"
  },
  "HolySans Bold": {
    title: "HolySans Bold",
    name: "HolySans Bold",
    nation: "en"
  },
  "HolySans Light": {
    title: "HolySans Light",
    name: "HolySans Light",
    nation: "en"
  },
  "PlayfairDisplay Regular": {
    title: "PlayfairDisplay Regular",
    name: "PlayfairDisplay Regular",
    nation: "en"
  },
  "PlayfairDisplay Black": {
    title: "PlayfairDisplay Black",
    name: "PlayfairDisplay Black",
    nation: "en"
  },
  "Poly Italic": {
    title: "Poly Italic",
    name: "Poly Italic",
    nation: "en"
  },
  "Poly Regular": {
    title: "Poly Regular",
    name: "Poly Regular",
    nation: "en"
  },
  "Versa Versa": {
    title: "Versa Versa",
    name: "Versa Versa",
    nation: "en"
  },

  // 可商用中文字体
  "FangZhengFangSongJianTi": {
    name: "方正仿宋简体",
    title: "方正仿宋简体",
    nation: `zh-CN`
  },
  "FangZhengFangSongFanTi": {
    name: "方正仿宋繁体",
    title: "方正仿宋繁体",
    nation: `zh-CN`
  },
  "HuXiaoBoSaoBaoTi": {
    name: "胡晓波骚包体",
    title: "胡晓波骚包体",
    nation: `zh-CN`
  },
  "YaoRuanBiShouXieTi": {
    name: "沐瑶软笔手写体",
    title: "沐瑶软笔手写体",
    nation: `zh-CN`
  },
  "PangMenZhengDaoQingSongTi": {
    name: "庞门正道轻松体",
    title: "庞门正道轻松体",
    nation: `zh-CN`
  },
  "QianTuXiaoTuTi": {
    name: "千图小兔体",
    title: "千图小兔体",
    nation: `zh-CN`
  },
  "SuCaiJiShiKangKangTi": {
    name: "素材集市康康体",
    title: "素材集市康康体",
    nation: `zh-CN`
  },
  "TaiWanMingTi": {
    name: "台湾明体",
    title: "台湾明体",
    nation: `zh-CN`
  },
  "WenQuanDengKuanZhengHei": {
    name: "文泉等宽正黑",
    title: "文泉等宽正黑",
    nation: `zh-CN`
  },
  "WenQuanZhengHei": {
    name: "文泉正黑",
    title: "文泉正黑",
    nation: `zh-CN`
  },
  "ZhanKuKuaiLeTi": {
    name: "站酷快乐体",
    title: "站酷快乐体",
    nation: `zh-CN`
  },
  "ZhanKuKuHeiTi": {
    name: "站酷酷黑",
    title: "站酷酷黑",
    nation: `zh-CN`
  },
  "ZhanKuWenYiTi": {
    name: "站酷文艺体",
    title: "站酷文艺体",
    nation: `zh-CN`
  },
  "ZhanKuXiaoLOGOTi": {
    name: "站酷小薇LOGO体",
    title: "站酷小薇LOGO体",
    nation: `zh-CN`
  },
  "YuanLiuMingTi": {
    name: "源流明朝体",
    title: "源流明朝体",
    nation: `zh-CN`
  },
  "YuanYunMingTi": {
    name: "源祥明体",
    title: "源祥明体",
    nation: `zh-CN`
  },
}
// {name: `PingFangSC-Ultralight`, title: `苹方-简 极细体`, nation: `zh-CN`},
// {name: `PingFangSC-Thin`, title: `苹方-简 纤细体`, nation: `zh-CN`},
// {name: `PingFangSC-Light`, title: `苹方-简 细体`, nation: `zh-CN`},
// {name: `PingFangSC-Regular`, title: `苹方-简 常规体`, nation: `zh-CN`},
// {name: `PingFangSC-Medium`, title: `苹方-简 中黑体`, nation: `zh-CN`},
// {name: `PingFangSC-Semibold`, title: `苹方-简 中粗体`, nation: `zh-CN`},
// {name: `夏日甜心`, title: `夏日甜心`, nation: `zh-CN`},
// {name: `马卡龙`, title: `马卡龙`, nation: `zh-CN`},

const defaultFont = `STXihei`;

export * from "./font_20211014"

export { system, free, defaultFont }