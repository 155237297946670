import React, { useState } from "react";
import { Tooltip, Tabs, Button, Space, Form, Upload, message, Image } from "antd"
import { fabric } from "fabric"
import { ALLOW_IMAGE_FORMAT_LIST, ALLOW_IMAGE_SIZE } from "../../../../../../../config"
import { testFile, readMediaMetaOfFile, readMineType, checkURLHeader, isBase64 } from "../../../../../../../utils"
import PicWgt from "../../../tools/pic.holder";

const keyName = "tool.new.shape.pattern";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;

export default function ShapePattern({handler, editing}) {
  const [loading, setLoading] = useState(false);
  const [pitching, setPitching] = useState(false);
  const [type, setType] = useState(`add`);
  const [processing, setProcessing] = useState(false);
  const [bg, setBg] = useState(undefined);

  // https://img.miaotranslation.com/web/home/%E6%96%87%E5%AD%97%E5%A4%84%E7%90%86-2.png
  const MEDIA_FUNC = {
    bg: {
      update: v => setBg(v),
      config: {
        image: {
          format: ALLOW_IMAGE_FORMAT_LIST,
          size: ALLOW_IMAGE_SIZE,
        }
      }
    }
  }

  const uploadOptions = {
    accept: "image/*",
    listType: "picture",
    showUploadList: false,
  }

  const compHandler = {
    /**
     * @description 提交
     * @return {Promise<void>}
     * @private
     */
    submit: async () => {
      if (!bg) {
        message.warn(`请选择一张图片`)
        return;
      }
      // 检测图片
      // 看是不是网络图片
      if (typeof bg === `string`) { // 不是dataURL
        const {size = 0, type = ``} = await checkURLHeader(bg) || {};

        if (!ALLOW_IMAGE_FORMAT_LIST.includes(type)) {
          message.warn(`格式错误! 允许的格式为：${ALLOW_IMAGE_FORMAT_LIST.join(` `)}`)
          return
        }

        if (ALLOW_IMAGE_SIZE < +size) {
          message.warn(`体积太大! 体积应小于：${(ALLOW_IMAGE_SIZE / 1024 / 1024).toFixed(0)}MB`)
          return
        }
      }
      // console.log(`bg`, bg)

      handler.patternHandler.open(bg.src);

      // handler.sets(`fill`, pattern)

      setBg(undefined)
    },
    /**
     *
     * @param file
     * @param func
     * @return {boolean}
     */
    beforeUpload: (file, func) => {
      (async () => {
        setProcessing(true);
        // 移除预设
        // 对应配置的key
        const fileType = readMineType(file.type);
        if (!fileType) return setProcessing(false);
        // 检测file
        let resolve = testFile(file, func.config[fileType]);
        if (!resolve) return setProcessing(false);
        // 格式化file
        // 生成dataURL显示图片
        const {width, height, duration, src} = await readMediaMetaOfFile(file, fileType);
        // 生成分辨率
        Object.assign(file, {
          src,
          dimension: [width, height],
          duration
        })
        func.update(file);
        setProcessing(false);
      })()
      return false
    },
    normFile: (value, prevValue, allValues) => {
      if (typeof value === `object`) {
        if (value.hasOwnProperty(`file`)) return value[`file`];
      }
      return value;
    },
    fileRemove: (e, key) => {
      if (e) e.stopPropagation();
      // 更新state
      MEDIA_FUNC[key].update(undefined);
      // 更新form
    }
  }

  return (
    <div className={`cp-${klassName}`}>
      <Space className="inner" direction={`vertical`} style={{width: `100%`}}>
        <>
          <Upload {...Object.assign(
            uploadOptions,
            {
              beforeUpload: file => compHandler.beforeUpload(file, MEDIA_FUNC[`bg`])
            }
          )}>
            <div className="avatar-upload-btn">
              {
                bg ?
                  <PicWgt data={bg} onRemove={e => compHandler.fileRemove(e, `bg`)}/>
                  :
                  <Button block>点我选择{type === `replace` ? `新背景` : `图片`}</Button>
              }
            </div>
          </Upload>

        </>

        <Button.Group>
          <Button loading={loading} onClick={compHandler.submit} block type={`primary`}>插入</Button>

          <Tooltip title={`修改图形后可以点击此按钮填满整个形状`}>
            <Button loading={loading} onClick={handler.patternHandler.reload} block>填充</Button>
          </Tooltip>
        </Button.Group>
      </Space>
    </div>
  )
}