import React from "react";
import {
  mdiCloseCircleOutline,
  mdiHandBackRightOutline, mdiImageEditOutline, mdiImageOutline, mdiNewBox,
} from "@mdi/js";
import { ToolPanelListItemNewComp, BrowserDetectionComp } from "../../../index"
import { getOS } from "../../../../../../../utils";

const keyName = "tool.new.operation";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;

export default function ToolOperation({handler, visibleEls, interactionMode}) {

  let isGrab = interactionMode === `grab`;
  const shortKeyName = getOS() === `MacOS` ? `⌥` : `Alt`;

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">

        <div className="part left">
          <ToolPanelListItemNewComp active={!visibleEls}
                                    handler={handler}
                                    item={{
                                      title: visibleEls ? `查看原图` : `关闭原图`,
                                      icon: visibleEls ? mdiImageOutline : mdiCloseCircleOutline,
                                      key: `view`,
                                      click: e => handler.hideAll(!visibleEls)
                                    }}
          />
          <ToolPanelListItemNewComp active={!visibleEls}
                                    handler={handler}
                                    item={{
                                      title: `新建`,
                                      icon: mdiNewBox,
                                      key: `new`,
                                      click: e => window.open(`/`, `_blank`)
                                    }}
          />
        </div>

        <div className="part right">
          <ToolPanelListItemNewComp handler={handler}
                                    active={interactionMode === `grab`}
                                    item={{
                                      title: `移动图片`,
                                      icon: isGrab ? mdiCloseCircleOutline : mdiHandBackRightOutline,
                                      key: `grab`,
                                      shortcut: `Space`,
                                      click: e => {
                                        if (e) e.stopPropagation();
                                        isGrab ?
                                          handler.interactionHandler.selection()
                                          :
                                          handler.interactionHandler.grab()
                                      }
                                    }}
          />
        </div>

        <div className="part bottom">
          <BrowserDetectionComp />
        </div>
      </div>
    </div>
  )
}