import { List } from "antd";

import React from "react";
import { LayerListItemNewComp } from "../index";

const keyName = "tool.new.layer.list";
const klassName = keyName.replace(/\./g, '-');

export default function LayerList({editing, handler, layers, visible, compHandler}) {
  // console.log(`layers`, layers)
  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <List dataSource={layers}
              size={`small`}
              rowKey={`id`}
              className="layer-list"
              renderItem={item => (
                <LayerListItemNewComp handler={handler}
                           visible={visible}
                           editing={editing} item={item}
                           compHandler={compHandler}/>
              )}
        />
      </div>
    </div>
  )
}