import { Popover, Button, Switch } from "antd";
import { PopoverGuideWidget } from "./index";
import React from "react";
import { toolbarConfig } from "../../";

const compKey = `tool.pdf.widget.btn.guide`;
const compKlass = compKey.replace(/\./g, '-');

export default function buttonGuideComp({active, type, onPress, guideStatus, ...others}) {
  let p = type.split(".")[0], c = type.split(".")[1];
  const {title, icon, description, guide} = toolbarConfig[p][c];
  return (
    <span className={`cp-${compKlass}`} {...others}>
      {
        !!guideStatus ?
          <Popover mouseEnterDelay={0.8}
                   overlayClassName={`cp-${compKlass}-popover`}
                   content={<PopoverGuideWidget src={guide.video} title={title} description={description} />}
          >
            <Switch
              checkedChildren="开" unCheckedChildren="关"
              checked={active}
              onChange={onPress} />
          </Popover>
          :
          <Switch
            checkedChildren="开" unCheckedChildren="关"
            checked={active}
            onChange={onPress} />
      }
    </span>

  )
}