export default {
  200: "请求已成功",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）",
  204: "删除数据成功。",

  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "无权访问内容，客户端需要进行身份验证",
  403: "客户端无权访问内容",
  404: "发出的请求针对的是不存在的记录",
  406: "服务器在执行服务器驱动的内容协商后，未能找到符合用户代理给出条件的任何内容",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "该请求格式正确，但由于语义错误而无法遵循。",

  500: "服务器发生未知错误！",
  502: "网关错误",
  503: "服务不可用，服务器暂时过载或维护",
  504: "网关超时",
};