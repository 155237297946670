import { fabric } from "fabric";
import React from "react";

const keyName = "tool.preview";
const klassName = keyName.replace(/\./g, '-');

export default function Preview({handler}) {

  const src = handler.canvas.toDataURL();

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <img className="img-fluid" src={src} alt="" />
      </div>
    </div>
  );
}
