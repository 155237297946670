import React from "react";
import Mcon from "@mdi/react"
import {
  mdiPlus,
  mdiMinus,
} from "@mdi/js";
import PerfectScrollbar from "react-perfect-scrollbar"
import { ToolPanelListItemNewComp } from "../../../index"
import { headerToolConfig } from "../../../../config"

const keyName = "tool.new.panel.list";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;


export default function Header({handler, zoomRatio = 1, interactionMode}) {


  return (
    <PerfectScrollbar className={`cp-${klassName}`} >
      {
        headerToolConfig({handler}).map((item, i) => {
          return <ToolPanelListItemNewComp item={item} handler={handler} active={interactionMode === item.key}
                                           key={i} disabled={item?.key === `brush` && !handler?.canvas?.getActiveObject()}/>
        })
      }
      {/*<div className="cp-tool-new-item-group ffNB">*/}
      {/*  <div className="cp-tool-new-panel-item" onClick={e => handler.zoomHandler.zoomOut()}>*/}
      {/*    <div className="inner">*/}

      {/*      <div className="tool-header">*/}
      {/*        <Mcon path={mdiMinus} size={iconSize}/>*/}
      {/*      </div>*/}

      {/*      <div className="tool-content">*/}
      {/*        <span className="text">缩小</span>*/}
      {/*      </div>*/}

      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="zoom-ratio">*/}
      {/*    {(zoomRatio * 100).toFixed(0)} <small>%</small>*/}
      {/*  </div>*/}

      {/*  <div className="cp-tool-new-panel-item" onClick={e => handler.zoomHandler.zoomIn()}>*/}
      {/*    <div className="inner">*/}
      {/*      <div className="tool-header">*/}
      {/*        <Mcon path={mdiPlus} size={iconSize}/>*/}
      {/*      </div>*/}
      {/*      <div className="tool-content">*/}
      {/*        <span className="text">放大</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </PerfectScrollbar>

  );
}
