import React, { useState } from "react";
import classnames from "classnames";
import Mcon from "@mdi/react";
import { mdiDeleteVariant, mdiGreasePencil, mdiCheck, mdiSelectionOff } from "@mdi/js";
import { Space, Button } from "antd";

const compKey = `tool.widget.context`
const compKlass = compKey.replace(/\./g, '-');

export default function widgetContextComp({ handler, target, loading, event, editing }) {

  if (!target) return null;

  const compHandler = {
    enable: () => {
      handler.onState({ outsideDisabled: false })
    },
    disabled: () => {
      handler.onState({ outsideDisabled: true })
    },
  }

  const { interactionMode } = handler;
  return (
    <div className={classnames(`cp-${compKlass}`)}
    >
      <Space
        onMouseEnter={compHandler.disabled}
        onMouseLeave={compHandler.enable}
      >
        {
          target.category === `segment` && <>
            <Button type={`primary`} loading={loading} onClick={e => {
              handler.interactionHandler.selection();
              handler.patchHandler.toggle({ e: event, target })
            }
            }>编辑关联文字</Button>
            <Button title="" icon={<Mcon path={mdiGreasePencil} size={0.75} />} onClick={e => handler.interactionHandler.patch()} />
            {/* <Button icon={<Mcon path={mdiDeleteVariant} size={0.75} />} danger type={`primary`} onClick={e => handler.remove()} /> */}
          </>
        }
        {
          target.category === `image` && <>
            <Button icon={<Mcon path={mdiDeleteVariant} size={0.75} />} danger type={`primary`} onClick={e => handler.remove()} />
          </>
        }
        {
          target.category === `text` && <>
            {
              target?.isEditing &&
              <Button icon={<Mcon path={mdiCheck} size={0.75} />} type={`primary`} onClick={e => {
                target.exitEditing();
                handler.canvas.requestRenderAll();
              }} />
            }
            {/* <Button title="取消选择" icon={<Mcon path={mdiSelectionOff} size={0.75} />} onClick={e => {
              handler.canvas.discardActiveObject();
              handler.canvas.requestRenderAll();
              handler.onBlur()
            }} /> */}
            {/* <Button icon={<Mcon path={mdiDeleteVariant} size={0.75} />} danger type={`primary`} onClick={e => handler.remove()} /> */}
          </>
        }
      </Space>
    </div>
  )
}