import React from "react"
import { Popover } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiCircleOutline,
  mdiHexagon,
  mdiHexagonOutline, mdiRectangleOutline, mdiTriangleOutline,
} from "@mdi/js";

const keyName = "tool.new.shape.list";
const klassName = keyName.replace(/\./g, '-');

export default function ShapeList({handler, fill}) {
  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <ToolPanelListItemNewComp
          item={{
            icon: mdiRectangleOutline,
            title: `矩形`,
            click: e => handler.shapeHandler.add(`rect`)
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiCircleOutline,
            title: `圆形`,
            click: e => handler.shapeHandler.add(`circle`)
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiTriangleOutline,
            title: `三角形`,
            click: e => handler.shapeHandler.add(`triangle`)
          }}
          handler={handler}
          active={false}
        />
      </div>
    </div>
  )
}