import { Button, Popconfirm, Image } from "antd";
import React from "react";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons"
import { srcUrl } from "../../../../../utils";

export default function PicComp({data, style, onRemove, onView}) {
  if (!data) return null;
  return (
    <div className="img-wrapper" style={style} onClick={e => e.stopPropagation()}>

      <Image className="img-fluid" style={{maxWidth: 300}} src={srcUrl(data.src)} alt=""/>

      <div className="operation">
        <Button onClick={onRemove} className="remove" icon={<DeleteOutlined/>} type="primary" shape="circle"/>
      </div>
    </div>
  )
}