import { message } from "antd";
import { fabric } from "fabric";
import CanvasObject from "./CanvasObjectCreate";
import REQUEST from "../../../../utils/requewt"
import { API } from "../../../../config"

class PatchHandler {
  constructor(handler) {
    this.handler = handler
  }

  start(opt) {
    let aObj = this.handler.canvas.getActiveObject();

    if (!aObj && !opt.target) {
      this.lastPosX = opt.pointer.x;
      this.lastPosY = opt.pointer.y;
      this.handler.isCorpping = true;

    }
  }

  pen() {
    const {brush} = this.handler;

    const {width} = brush || {};

    let color = CanvasObject.cursor.patch.options.fill;
    const cursor = new fabric.Circle(Object.assign({}, CanvasObject.cursor.patch.options, {
      left: this.handler.canvas.backgroundImage.width / 2,
      top: this.handler.canvas.backgroundImage.height / 2,
      radius: width / 2, color,
    }))

    this.handler.canvas.add(cursor);

    return cursor
  }

  // 这是确认后ocr
  patching = async (path) => {

    const segment = this.handler.getObjects().find(e => path.intersectsWithObject(e) && e?.category === `segment`);
    // console.log(`香蕉的片段`, segment)
    if (!segment) return;

    // 设置宽
    const w = (segment.width),
      h = (segment.height),
      l = (segment.left),
      t = (segment.top)
    ;

    let patchKlass = CanvasObject.patch.create(Object.assign({}, CanvasObject.patch.options, {
      left: l,
      top: t,
      width: w,
      height: h
    }))

    let sel = new fabric.ActiveSelection([patchKlass, path]);

    let finalLeft, finalTop;
    const {left: pathLeft, top: pathTop} = path.group || {};

    finalLeft = pathLeft > segment.left ? 0 : segment.left - pathLeft;
    finalTop = pathTop > segment.top ? 0 : segment.top - pathTop;

    // console.log(`final`, finalLeft, finalTop);
    // console.log(`finalTop`, finalTop);
    // console.log(`path`, path.group.left, path.group.top)
    // console.log(`obj`, segment.left, segment.top)
    // console.log(segment.width, segment.height);
    // console.log(w, h);

    let selDataURL = sel.toDataURL({
      format: `jpeg`,
      quality: 1,
      left: finalLeft,
      top: finalTop,
      width: w,
      height: h
    });

    let image = new Image();
    image.width = w;
    image.height = h;
    image.src = selDataURL;
    let maskKlass = await new fabric.Image(image, {
      backgroundColor: `rgb(0, 0, 0)`,
      src: selDataURL,
      width: w, height: h
    })

    let maskDataURL = maskKlass.toDataURL({
      format: `jpeg`,
      quality: 1,
    });

    let segmentDataURL = segment.toDataURL()


    let {bgDataURL} = await this.onPatch(segmentDataURL, maskDataURL)
    // console.log(`bg`, bgDataURL)
    segment.setSrc(bgDataURL);
    // this.handler.canvas.setActiveObject(segment);
    // this.handler.sets(`src`, maskDataURL, segment);
    this.handler.canvas.requestRenderAll();

    const {onPatched} = this.handler;
    if (onPatched) onPatched(segment)
  }

  /**
   * ocr text from the input base64
   * @param segURL
   * @param maskURL
   * @return {Promise<{transTxt: (*|null), bgDataURL: (*|null), text: (*|null)}>}
   */
  async onPatch(segURL, maskURL) {
    const {iid, auto_translate, tar_lang} = this.handler;
    let options = {
      method: `POST`,
      headers: {"Content-Type": "multipart/form-data"},
      body: {
        // img_id: iid,
        mask_base64: maskURL,
        img_base64: segURL
      }
    }
    let response = await REQUEST(API.trial.image.patch, options);
    // console.log(`ocrPic.response`, response);
    if (!response || response[`error_code`] !== 0 || response[`msg`] !== `ok`) {
      message.warning(response?.info || `涂抹失败！请重试`);
      return null
    }
    return {
      bgDataURL: response.data?.[`inpaint_base64`] || null,
    };
  }

  /**
   * 清除选择框
   */
  end() {
    const selector = this.getSelector();
    if (selector) this.handler.remove(selector);
    this.handler.canvas.isDrawingMode = false;
    this.handler.canvas.requestRenderAll();
  }

  /**
   * 获取选择框对象
   * @return {T}
   */
  getSelector = () => this.handler.canvas.getObjects().find(e => e.category === `selector`);

  toggle = opt => {
    const {onSelect} = this.handler;
    const {e, target} = opt;

    const ID = target.rel;
    if (!ID) return;
    const Klass = this.handler.findById(ID);
    if (!Klass) return;
    this.handler.bringForward(Klass);
    onSelect({e, target: Klass})
  }

}

export default PatchHandler