import { message } from "antd";
import { fabric } from "fabric";
import CanvasObject from "./CanvasObjectCreate";
import REQUEST from "../../../../utils/requewt"
import { API } from "../../../../config"

class PatchHandler {
  constructor(handler) {
    this.handler = handler
  }

  set = (color) => {
    const {onFilled, canvas} = this.handler;
    const ao = this.handler.canvas.getActiveObject();
    if (!ao) return;
    const {width: w, height: h, left: l, top: t} = ao;
    const rectKlass = new fabric.Rect({width: w, height: h, fill: color});
    const rectURL = rectKlass.toDataURL({width: w, height: h});
    ao.set(`dirty`, true);
    ao.setSrc(rectURL, () => canvas.requestRenderAll());
    if (onFilled) onFilled()
  }

}

export default PatchHandler