import React, { useEffect } from "react";
import { Layout, Button, Divider, Tooltip, Space, Select, Popconfirm } from "antd";
import Languages from "../../../../assets/languages_map.json";


const keyName = "tool.new.page.translate";
const klassName = keyName.replace(/\./g, '-');

export default function PageTranslate(props) {
  const {editing, handler, interactionMode, tarLang, brush, loading, autoTranslate, fonts, undo, redo} = props;

  const languages = Object.values(Languages);

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <Space direction={`vertical`} style={{width: `100%`}}>
          <h4>将原图上的文字自动翻译为目标语言，并完成涂抹。</h4>
          <Select
            style={{width: `100%`}}
            showSearch
            value={tarLang}
            className="lang-select"
            placeholder="选择语言"
            optionFilterProp="children"
            onChange={val => handler.translateHandler.changeLanguage(val)}
            filterOption={(input, option) => {
              return option.props.children.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 || option.props.key.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }}
          >
            {languages.map(lang => {
              return (<Select.Option key={lang[`code`]} value={lang[`code`]}>{lang[`name_cn`]}</Select.Option>)
            })}
          </Select>

          <Button onClick={handler.pageTranslate} loading={loading} block type={`primary`}>执行</Button>
        </Space>

      </div>
    </div>
  )
}