import React from "react";

export const PAGE_SCALE = 1.5;
export const PAGE_SCALE_HD = 1.5;
export const PDF_VIEWPORT_WIDTH = 2560;
export const PAGE_SCALE_BALANCE = PAGE_SCALE / PAGE_SCALE_HD;

export const canvasConfig = {
  canvasState: [],
  currentStateIndex: -1,
  undoStatus: false,
  redoStatus: false,
  undoFinishedStatus: 1,
  redoFinishedStatus: 1,
};

// 进入文字框编辑模式会锁定的属性
export const textboxEnterEditingOptions = {
  hasControls: false,
  lockMovementX: true,
  lockMovementY: true,
}

// 锁定项（默认锁定）
export const lockOptions = {
  editable: false,
  evented: false,
  selectable: false,
  hasControls: false,

  lock: true,
  lockMovementX: true,
  lockMovementY: true,
  lockRotation: true,
  lockScalingFlip: true,
  lockScalingX: true,
  lockScalingY: true,
  lockSkewingY: true,
  lockSkewingX: true,
}

export const propertiesToInclude = [
  'id',
  `width`,
  `height`,
  `title`,
  `origin`,
  `rel`,
  `icon`,
  `src`,
  `type`,
  `file`,
  `category`,
  `excludeFromIntersection`,
  `excludeFromLayer`,
  `ignoreTransaction`,
  `isEditing`,
  `borderColor`,
  `borderDashArray`,
  `cornerSize`,
  `cornerColor`,
  `erasable`,
  ...Object.keys(lockOptions)
]

export const defaultCanvasOption = {
  preserveObjectStacking: true,
  width: 300,
  height: 150,
  selection: true,
  backgroundColor: "#f5f5f5",
  editable: true,
  minZoom: 10,
  maxZoom: 200,
  zoomEnabled: true,

  mementoConfig: canvasConfig,

  // 涂抹图片的配置
  inpaintObjectOption: {
    type: `image`,
    editable: false,
    selectable: false,
    hoverCursor: `none`
  }
};

export const defaultKeyboardEvent = {
  move: true,
  all: true,
  copy: true,
  paste: true,
  esc: true,
  del: true,
  save: true,
  clipboard: false,
  transaction: true,
  zoom: true,
  cut: true,
  ocr: true,
  draw: true,
  txt: true,
  patch: true,
  space: true,
};

//
export const defaultConfig = {
  version: `2.4.6`,             // 当前版本
  release: `20220211sa323sqr`,             // 当前版本
  setting: {
    theme: `default`,           // 暗色模式或者正常
    video_guide_open: true,     // 工具内的视频引导
    welcome_guide_open: true,   // 工具内的开屏引导
    tool_mode_guide_open: true,      // 工具模式的引导
  },
  ui: {
    collapse: false,            // 左侧目录的开关
    collapse_right: false,      // 右侧术语的开关
    textbox_default_active_key: [`text`, `style`, `color`], // 右侧文字操作的默认打开项
    image_default_active_key: [`fill`, `edit`, `size`, `scale`, `background`, `paints`, `operation`], // 右侧图片操作的默认打开项
    shape_default_active_key: [`backgroundColor`, `color`], // 右侧图形操作的默认打开项
    collection_default_active_key: [`alignment`, `distribution`], // 右侧图形操作的默认打开项
  },
  func: {
    src_lang: `cn`,             // 源语言
    tar_lang: `en`,             // 翻译的语言
    auto_translate: true,               // 框选后自动翻译
    segment_remove_with_txt: true,      // 删除文字的同时 删除片段
  },
  format: {
    fontFamily: `Arial`,        // 默认字体
    fontSize: 30,               // 字号
    lineHeight: 1.16,           // 行高
    padding: 0,                 // 内间距
    fill: `rgb(0, 0, 0)`,        // 颜色
  },
  line: {
    stroke: `rgb(200, 200, 200)`,
    strokeWidth: 2,
  },
  brush: {
    width: 16,
    color: `rgb(0, 0, 0)`
  }
};

export const defaultFontSize = [
  5, 5.5, 6.5, 7.5, 8, 9, 10, 10.5, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72
]

export const defaultLineHeight = [
  1, 1.16, 1.5, 2, 3, 4
]

export const defaultPadding = [
  0, 2, 5, 8, 10, 20, 30, 40, 50
]

// canvas 对象的默认参数
export const defaultTextboxOptions = {
  type: `FreeTextbox`,
  category: `text`, // 用于图层区分
  splitByGrapheme: true,
  borderColor: `rgb(47, 69, 127)`,
  borderDashArray: [3, 3, 3, 3],
  cornerSize: 5,
  cornerColor: `rgb(47, 69, 127)`,
  hoverCursor: `pointer`,
  editable: true,
  cloneable: true,
  evented: true,
  // textBackgroundColor: `rgb(255, 0, 0)`,
  // touchCornerSize: 50
  lockUniScaling: false
}


export const defaultGradientOptions = {
  type: 'linear',
  gradientUnits: 'pixels', // or 'percentage'
  colorStops: [
    {offset: 0, color: '#ff0000'},
    {offset: 1, color: '#0000ff'},
  ]
}

// 可以复制粘贴的参数
export const formatOptions = [
  `fontSize`,
  `fontFamily`,
  `fontStyle`,
  `fontWeight`,
  `overline`,
  `textAlign`,
  `lineHeight`,
  `underline`,
  `linethrough`,
  `backgroundColor`,
  `padding`,
  `fill`,
  `scaleX`,
  `scaleY`
];

// 引起形变的参数
export const triggerOptions = [
  `fontSize`,
  `fontFamily`,
  `fontStyle`,
  `fontWeight`,
  `overline`,
  `textAlign`,
  `lineHeight`,
  // `underline`,
  // `linethrough`,
  `text`,
];

// 继承的参数
export const inheritOptions = [
  `fontSize`,
  `fontFamily`,
  `fontWeight`,
  `fontStyle`,
  `linethrough`,
  `underline`,
  `lineHeight`,
  `padding`,
  `fill`,
  `stroke`,
  `strokeWidth`,
]

// 上传时需要携带的参数
export const uploadOptions = [
  `text`,
  `textLines`,
  `origin`,
  `originLines`,
  `left`,
  `top`,
  `width`,
  `_width`,
  `height`,
  `_height`,
  `coords`,
  `angle`,
  `flipX`,
  `flipY`,
  `id`,
  `indent`,
  `splitByGrapheme`,
];

// 需要处理缩放的参数
export const scaleOptions = [
  `fontSize`,
  `left`,
  `top`,
  `width`,
  `height`,
  `bottom_left`,
  `bottom_top`,
  `padding`,
]

export const defaultPageSize = 30;

export { free as fontFree, system as fontSystem, defaultFont, font_202102 } from "./fonts"

export { default as steps } from "./guide"

export { default as toolbarConfig } from "./toolbar"

export { default as languageMap } from "./languageMap"

export { default as headerToolConfig } from "./header.tools"
