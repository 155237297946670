import React from "react";
import classnames from "classnames";
import Mcon from "@mdi/react";
import { mdiDeleteVariant, mdiContentCopy, mdiClose, mdiOcr, mdiImageFilterCenterFocusStrong, mdiCheck } from "@mdi/js";
import { Space, Button } from "antd";

const compKey = `tool.selector.context`
const compKlass = compKey.replace(/\./g, '-');

export default function contextComp({handler, target, loading}) {
  if (!target) return null;

  const {interactionMode} = handler;
  return (
    <div className={classnames(`cp-${compKlass}`)}
    >
      <Space>
        {
          interactionMode === `ocr` && <>
            <Button danger type="primary" onClick={e => handler.ocrHandler.clear()} icon={<Mcon path={mdiClose} size={0.75}/>}/>
            <Button icon={<Mcon path={mdiOcr} size={0.75}/>} type={`primary`} loading={loading}
                    onClick={e => handler.ocrHandler.ocr(target)}>识别文本</Button>
          </>
        }
        {
          interactionMode === `cut` && <>
            <Button danger type="primary" onClick={e => handler.ocrHandler.clear()} icon={<Mcon path={mdiClose} size={0.75}/>}/>
            <Button icon={<Mcon path={mdiImageFilterCenterFocusStrong} size={0.75}/>} type={`primary`} loading={loading}
                    onClick={e => handler.cutHandler.cut(target)}>抠图</Button>
          </>
        }
        {
          interactionMode === `default` && <>
            <Button type="primary" onClick={e => handler.segmentHandler.resize.confirm(e)} icon={<Mcon path={mdiCheck} size={0.75}/>}/>
            <Button danger type="primary" onClick={e => handler.segmentHandler.resize.cancel(e)} icon={<Mcon path={mdiClose} size={0.75}/>}/>
          </>
        }
      </Space>
    </div>
  )
}