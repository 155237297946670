import React, { useState } from "react";
import { Tabs, Input, Button, Space, Form, Upload, message } from "antd"
import { mdiMicrosoftInternetExplorer, mdiDesktopClassic } from "@mdi/js"
import { ALLOW_IMAGE_FORMAT_LIST, ALLOW_IMAGE_SIZE } from "../../../../../config"
import { testFile, readMediaMetaOfFile, readMineType, checkURLHeader, isBase64 } from "../../../../../utils"
import Mcon from "@mdi/react"
import PicWgt from "./pic.holder"

const keyName = "tool.tools.pop.file.input";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;

export default function PopFileInput({handler}) {

  const [loading, setLoading] = useState(false);
  const [pitching, setPitching] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [bg, setBg] = useState(``);
  // https://img.miaotranslation.com/web/home/%E6%96%87%E5%AD%97%E5%A4%84%E7%90%86-2.png
  const MEDIA_FUNC = {
    bg: {
      update: v => setBg(v),
      config: {
        image: {
          format: ALLOW_IMAGE_FORMAT_LIST,
          size: ALLOW_IMAGE_SIZE,
        }
      }
    }
  }

  const uploadOptions = {
    accept: "image/*",
    listType: "picture",
    showUploadList: false,
  }

  const compHandler = {
    /**
     * @description 提交
     * @return {Promise<void>}
     * @private
     */
    submit: async () => {
      if (!bg) {
        message.warn(`请输入图片网址或从本地上传`)
        return;
      }
      // 检测图片
      // 看是不是网络图片
      if (typeof bg === `string`) { // 不是dataURL
        const {size = 0, type = ``} = await checkURLHeader(bg) || {};

        if (!ALLOW_IMAGE_FORMAT_LIST.includes(type)) {
          message.warn(`格式错误! 允许的格式为：${ALLOW_IMAGE_FORMAT_LIST.join(` `)}`)
          return
        }

        if (ALLOW_IMAGE_SIZE < +size) {
          message.warn(`体积太大! 体积应小于：${(ALLOW_IMAGE_SIZE / 1024 / 1024).toFixed(0)}MB`)
          return
        }
      }
      handler.add.image(bg);
      setLoading(true);
    },
    /**
     *
     * @param file
     * @param func
     * @return {boolean}
     */
    beforeUpload: (file, func) => {
      (async () => {
        setProcessing(true);
        // 移除预设
        // 对应配置的key
        const fileType = readMineType(file.type);
        if (!fileType) return setProcessing(false);
        // 检测file
        let resolve = testFile(file, func.config[fileType]);
        if (!resolve) return setProcessing(false);
        // 格式化file
        // 生成dataURL显示图片
        const {width, height, duration, src} = await readMediaMetaOfFile(file, fileType);
        // 生成分辨率
        Object.assign(file, {
          src,
          dimension: [width, height],
          duration
        })
        func.update(file);
        setProcessing(false);
      })()
      return false
    },
    normFile: (value, prevValue, allValues) => {
      if (typeof value === `object`) {
        if (value.hasOwnProperty(`file`)) return value[`file`];
      }
      return value;
    },
    fileRemove: (e, key) => {
      if (e) e.stopPropagation();
      // 更新state
      MEDIA_FUNC[key].update(undefined);
      // 更新form
    }
  }

  return (
    <div className={`cp-${klassName}`}>
      <Space className="inner" direction={`vertical`}>
        <Tabs>
          <Tabs.TabPane key={`local`}
                        tab={
                          <div className="tab-title">
                            <Mcon path={mdiDesktopClassic} size={iconSize} />
                            <span className="ml-2">选择本地</span>
                          </div>
                        }
          >
            <Upload {...Object.assign(
              uploadOptions,
              {
                beforeUpload: file => compHandler.beforeUpload(file, MEDIA_FUNC[`bg`])
              }
            )}>
              <div className="avatar-upload-btn">
                {
                  bg ?
                    <PicWgt data={bg} onRemove={e => compHandler.fileRemove(e, `bg`)} />
                    :
                    <Button>选择</Button>
                }
              </div>
            </Upload>
          </Tabs.TabPane>
          <Tabs.TabPane key="url"
                        tab={
                          <div className="tab-title">
                            <Mcon path={mdiMicrosoftInternetExplorer} size={iconSize} />
                            <span className="ml-2">输入图片网址</span>
                          </div>
                        }

          >
            <Input defaultValue={bg}
                   allowClear
                   placeholder={`请粘贴一个线上图片的地址`}
                   onChange={e => {
                     setBg(e?.target?.value)
                   }}
            />
          </Tabs.TabPane>

        </Tabs>
        <Button loading={loading} onClick={compHandler.submit} block type={`primary`}>确定</Button>
        <div className="mt-1 text-center">添加新的背景会替换掉之前的背景</div>
      </Space>
    </div>
  )
}