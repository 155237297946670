import { SERVER_BASE } from "../config"
import { message } from "antd"
import { trim } from "lodash";

/**
 * 数组是否有效
 * @param {Array} arr
 * @param {Number} length
 * @return {boolean}
 */
export const isValidArr = (arr, length = 0) => {
  return arr && Array.isArray(arr) && arr.length > length
}

export const isValid = (val) => {
  return val !== `` && val !== null && val !== undefined
}

// 图片过滤
export const isBase64 = (str) => {
  if (str === '' || str.trim() === '') return false;
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

// 图片过滤
export const srcUrl = (url) => {
  if (!url) return null;
  let hasHttp = url.startsWith(`http`) || url.startsWith(`data:`);
  if (hasHttp) return url;
  else return SERVER_BASE + url;
}


/**
 * @description 简化file文件的type 返回大类
 * @param type {String} 类似于 image/*
 */
export const readMineType = type => {
  let mineType = null;
  try {
    // 把大类出来
    mineType = type.split(`/`)[0]
  } catch (e) {

  }
  return mineType
}

export const testFile = (file, {format, size, dimension, count}) => {
  if (!file || !file.type) {
    message.warn(`没有文件进入或传入了非法的文件！`)
    return false
  }

  // console.log(`format`, format);
  // console.log(`size`, size);
  // console.log(`dimension`, dimension);
  // console.log(`count`, count);

  if (isValidArr(format)) {
    const result = format.includes(file.type);
    if (!result) {
      message.warn(`格式错误! 允许的格式为：${format.join(` `)}`)
      return result
    }
  }

  if (size) {
    const result = file.size < size;
    if (!result) {
      message.warn(`体积太大! 体积应小于：${(size / 1024 / 1024).toFixed(0)}MB`)
      return result
    }
  }
  return true
}

/**
 * @description 从file文件生成dataURL
 * @param file
 * @return {Promise<String>}
 */
export const geDataURL = (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(file);
  });
}

export const readMediaMetaOfFile = async (file) => {
  const {size, type} = file;

  let mineType = readMineType(type);
  if (!mineType) return null;
  // 生成url
  let url = await geDataURL(file);

  let width = 0, height = 0, duration = 0;
  let resolve;

  // 依据不同的类型 获取对应的meta信息
  if ([`video`, `audio`].includes(mineType)) {
    resolve = await readAVDimensionOfURL(url, mineType);
  } else if ([`image`].includes(mineType)) {
    resolve = await readImageDimensionOfURL(url);
  }

  const {w = 0, h = 0, d = 0} = resolve || {};
  width = w;
  height = h;
  duration = d;
  return {width, height, duration, src: url}
}

/**
 @description 从URL里读取图片的分辨率
 @param {String} url Url of the video to get dimensions from.
 @return {Promise} Promise which returns the dimensions of the video in 'width' and 'height' properties.
 */
export const readImageDimensionOfURL = (url) => {
  return new Promise(resolve => {
    const img = document.createElement('img');
    img.addEventListener("load", function () {
      // retrieve dimensions
      const h = this.height || 0;
      const w = this.width || 0;
      resolve({w, h});
    }, false);
    img.src = url;
  });
}

/**
 @description 从URL里读取 视频 音频 的分辨率或时长
 @param {String|Object} url Url of the video to get dimensions from.
 @param {String} type 类型
 @return {Promise} Promise which returns the dimensions of the video in 'width' and 'height' properties.
 */
export const readAVDimensionOfURL = (url, type) => {
  return new Promise(resolve => {
    const element = document.createElement(type);
    element.addEventListener("loadedmetadata", function () {
      // retrieve dimensions
      const h = this.videoHeight;
      const w = this.videoWidth;
      const d = this.duration;
      resolve({h, w, d});
    }, false);
    element.src = url;
  });
}

/**
 * @description 从URL里读取资源的type
 * @param url
 * @return {Promise<unknown>}
 */
export const checkURLHeader = (url) => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('HEAD', url);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          resolve({type: xhr.getResponseHeader("Content-Type"), size: xhr.getResponseHeader("Content-Length")})
        } else {
          resolve(null)
          // Oh no! There has been an error with the request!
        }
      }
    };
    xhr.send();
  })

}

export const getOS = () => {
  if (typeof window == `undefined`) return null;
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

export function filterSpace(str) {
  return str.replace(/\s/g, '');
}

export function getBrowser(userAgent) {
  if (!userAgent) return `?`
  let ua = userAgent.toLocaleLowerCase();
  let Browser = null, browserVersion;
  if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
    Browser = "IE";
    browserVersion =
      ua.match(/msie ([\d.]+)/) != null
        ? ua.match(/msie ([\d.]+)/)[1]
        : ua.match(/rv:([\d.]+)/)[1];
    return "IE";
  } else if (ua.match(/firefox/) != null) {
    Browser = "火狐";
    return "FF";
  } else if (ua.match(/ubrowser/) != null) {
    Browser = "UC";
    return "UC";
  } else if (ua.match(/opera/) != null) {
    Browser = "欧朋";
    return "OP";
  } else if (ua.match(/bidubrowser/) != null) {
    Browser = "百度";
    return "baidu";
  } else if (ua.match(/metasr/) != null) {
    Browser = "搜狗";
    return "SG";
  } else if (
    ua.match(/tencenttraveler/) != null ||
    ua.match(/qqbrowse/) != null
  ) {
    Browser = "QQ";
    return "QQ";
  } else if (ua.match(/maxthon/) != null) {
    Browser = "遨游";
    return "AY";
  } else if (ua.match(/chrome/) != null) {
    let is360 = _mime("type", "application/vnd.chromium.remoting-viewer");

    function _mime(option, value) {
      let mimeTypes = navigator && navigator.mimeTypes;
      for (let mt in mimeTypes) {
        if (mimeTypes[mt][option] == value) {
          return true;
        }
      }
      return false;
    }

    if (is360) {
      Browser = "360";
      return "360";
    } else {
      return "Chrome";
    }
  } else if (ua.match(/safari/) != null) {
    Browser = "Safari";
  }

  return Browser
}

/**
 * @description 过滤提交表单的数据
 * @param obj
 * @return {null|*}
 */
export const filter = (obj) => {
  if (!obj || JSON.stringify(obj) === `{}`) return null;
  for (let k in obj) {
    if (typeof obj[k] === `boolean`) obj[k] = Number(obj[k]);
    else if (typeof obj[k] === `string`) obj[k] = trim(obj[k]);
    if (!isValid(obj[k])) delete obj[k]
  }
  return obj
}

export const calcAngle = (originX, originY, targetX, targetY) => {
  let dx = originX - targetX;
  let dy = originY - targetY;

  // var theta = Math.atan2(dy, dx);  // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = west
  // theta *= 180 / Math.PI;          // [0, 180] then [-180, 0]; clockwise; 0° = west
  // if (theta < 0) theta += 360;     // [0, 360]; clockwise; 0° = west

  // var theta = Math.atan2(-dy, dx); // [0, Ⲡ] then [-Ⲡ, 0]; anticlockwise; 0° = west
  // theta *= 180 / Math.PI;          // [0, 180] then [-180, 0]; anticlockwise; 0° = west
  // if (theta < 0) theta += 360;     // [0, 360]; anticlockwise; 0° = west

  // var theta = Math.atan2(dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; anticlockwise; 0° = east
  // theta *= 180 / Math.PI;          // [0, 180] then [-180, 0]; anticlockwise; 0° = east
  // if (theta < 0) theta += 360;     // [0, 360]; anticlockwise; 0° = east

  let theta = Math.atan2(-dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east
  theta *= 180 / Math.PI;           // [0, 180] then [-180, 0]; clockwise; 0° = east
  if (theta < 0) theta += 360;      // [0, 360]; clockwise; 0° = east

  return theta;
}