import { fabric } from "fabric"

class GradientHandler {
  constructor(handler) {
    this.handler = handler;
  }

  open = () => {
    let ao = this.handler.canvas.getActiveObject();
    if (!ao) return;
    console.log(ao)
    let options = Object.assign({}, {
      type: 'linear',
      gradientUnits: 'pixels', // or 'percentage'
      colorStops: [
        {offset: 0, color: '#ff0000'},
        {offset: 1, color: '#0000ff'},
      ],
      coords: {x1: 0, y1: ao.height / 2, x2: ao.width, y2: ao.height / 2},
      angle: 0
    });
    ao.gradientOriginColor = typeof ao.fill === `string` ? ao.fill : `rgb(0, 0, 0)`;
    const gradient = new fabric.Gradient(options);
    this.handler.sets(`fill`, gradient);
  }

  close = (silent) => {
    let ao = this.handler.canvas.getActiveObject();
    if (!ao) return;
    if (!silent) this.handler.sets(`fill`, ao.gradientOriginColor);
  }

  /**
   *
   * @param key
   * @param val 十六进制的颜色 或者 [linear, radial]
   */
  change = (key, val) => {
    const {onGradientModified} = this.handler;
    let ao = {...this.handler.canvas.getActiveObject()};
    if (!ao) return;
    let options;
    if ([`startColor`, `endColor`].includes(key)) { // 颜色改变
      let pos = 0;
      if (key === `endColor`) pos = ao.fill.colorStops.length - 1;
      ao.fill.colorStops[pos].color = val;
      options = {...ao.fill}

      // console.log(`颜色改变 ${ao.text} - ${JSON.stringify(ao.gradientOptions)}`)
    } else if ([`angle`].includes(key)) {
      const {width: w, height: h} = ao;
      let coords = {x1: 0, y1: 0, x2: ao.width, y2: 0};
      if (val === 0) coords = {x1: 0, y1: h / 2, x2: ao.width, y2: h / 2}
      else if (val === 45) coords = {x1: w / 2 - h / 2, y1: 0, x2: w / 2 + h / 2, y2: h};
      else if (val === 90) coords = {x1: w / 2, y1: 0, x2: w / 2, y2: h};
      else if (val === 135) coords = {x1: w - h / 2, y1: 0, x2: w / 2 - h / 2, y2: h};
      else if (val === 180) coords = {x1: w, y1: h / 2, x2: 0, y2: h / 2};

      else if (val === 225) coords = {x1: w / 2 + h / 2, y1: h, x2: w / 2 - h / 2, y2: 0};
      else if (val === 270) coords = {x1: w / 2, y1: h, x2: w / 2, y2: 0};
      else if (val === 315) coords = {x1: w / 2 - h / 2, y1: h, x2: w - h / 2, y2: 0};
      else if (val === 360) coords = {x1: 0, y1: h / 2, x2: w, y2: h / 2};

      options = Object.assign({}, ao.fill, {
        coords,
        angle: val
      })

    }
    let gradient = new fabric.Gradient(options);
    this.handler.sets(`fill`, gradient);
    if (onGradientModified) onGradientModified(key, val);
    ao = gradient = null;
  }

}

export default GradientHandler