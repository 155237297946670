import {
  mdiCalendarMultiselect, mdiCardMultipleOutline, mdiDeleteVariant,
  mdiPalette,
} from "@mdi/js";
import Mcon from "@mdi/react";
import {
  Divider,
  Collapse,
  List,
  Avatar
} from "antd";
import { capitalize, forEach, lowerCase, upperCase, debounce } from "lodash";
import React, { useEffect, useRef } from "react";

import CollectionAlignmentComp from "./alignment";
import CollectionDistributionComp from "./distribution";
import { ToolPanelListItemNewComp, ToolProgressNewComp } from "../../index";

const keyName = "tool.new.collection";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 1;

export default function ToolCollection({editing, handler, targetLanguage, interactionMode, defaultActiveKey}) {


  return (
    <div className={`cp-${klassName}`}>
      {/*<Divider><Mcon size={iconSize} path={mdiCardMultipleOutline}/> 多选编辑</Divider>*/}

      <div className={`cp-tool-new-progress`}>
        <div className="inner">
          <ToolPanelListItemNewComp item={{
            icon: mdiDeleteVariant,
            title: `删除图层`,
            click: e => handler.remove()
          }} handler={handler} active={false}/>
        </div>
      </div>

      <div className="inner">

        <Collapse expandIconPosition="right"
                  defaultActiveKey={defaultActiveKey}
                  onChange={v => {
                    handler.dbHandler.set(`ui`, `collection_default_active_key`, v)
                  }}
                  ghost
        >

          <Collapse.Panel
            key="distribution"
            header={
              <List.Item.Meta
                title={`图层分布`}
                // avatar={
                //   <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                //           icon={<Mcon color={`#000`} path={mdiPalette}/>}/>
                // }
              />
            }
          >
            <CollectionDistributionComp handler={handler} editing={editing}/>
          </Collapse.Panel>

          <Collapse.Panel
            key="alignment"
            header={
              <List.Item.Meta
                title={`图层对齐`}
                // avatar={
                //   <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                //           icon={<Mcon color={`#000`} path={mdiPalette}/>}/>
                // }
              />
            }
          >
            <CollectionAlignmentComp handler={handler} editing={editing}/>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  )
}