import React from "react"
import { Popover } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiHexagon,
  mdiHexagonOutline,
} from "@mdi/js";
import { SketchPicker } from "react-color";
import { hexToRgb } from "../../../../utils";

const keyName = "tool.new.textbox.color";
const klassName = keyName.replace(/\./g, '-');
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`;

export default function TextboxColor({handler, editing}) {

  const {fill: color} = editing;

  let fill = typeof color !== `string` && `#000`

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagon,
            iconColor: fill,
            title: `选择颜色`
          }}
          active={false}
        >
          <label onChange={e => {
            const {r, g, b} = hexToRgb(e.target.value);
            handler.sets(`fill`, `rgb(${r}, ${g}, ${b})`)
          }}
          >
            <input defaultValue={fill} type="color"
                   style={{visibility: `hidden`, position: `absolute`}}/>
          </label>
        </ToolPanelListItemNewComp>

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagonOutline,
            title: `白色`,
            click: e => handler.sets(`fill`, colorWhite)
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagon,
            title: `黑色`,
            click: e => handler.sets(`fill`, colorBlack)
          }}
          handler={handler}
          active={false}
        />
      </div>
    </div>
  )
}