import { Tag, Input, Button, Select, Form, message, Space } from "antd";
import React, { useEffect, useState } from "react";
import Languages from "../../../../assets/languages_map.json";
import { debounce } from "lodash";
import { isValidArr } from "../../../../../../../utils";

const compKey = "tool.panel.textbox.input";
const compKlass = compKey.replace(/\./g, '-');

export default function ToolTextInputNewComp({handler, editing, tarLang}) {
  const languages = Object.values(Languages);

  const [form] = Form.useForm();
  const [origin, setOrigin] = useState(editing.origin || ``);
  const [text, setText] = useState(editing.text);
  const [words, setWords] = useState([]);
  const [unClick, setClick] = useState(true);

  useEffect(() => {
    const {origin, text} = editing;
    form.setFieldsValue({text, origin: origin || text});
    setOrigin(origin || text);
    setText(text);
    setClick(true);
  }, [editing.text])

  useEffect(() => {
    setOrigin(origin)
  }, [origin])

  useEffect(() => {
    setText(text)
  }, [text])

  const _onChange = (e, key) => {
    // handler.sets(key, e.target.value, editing)
  }

  const useMap = {
    origin: {
      action: v => setOrigin(v)
    },
    text: {
      action: v => setText(v)
    }
  }

  const compHandler = {
    translate: async (str) => {
      if (!str) return message.warning(`没有文字`)
      let result = await handler.translateHandler.translateText(str);
      if (!result) return message.warning(`翻译失败`);
      _setStr(`text`, result);
    },
    change: debounce((e, key) => {
      _setStr(key, e.target.value)
    }, 500),
    detect: async (str) => {
      if (!str) return message.warning(`没有文字`);
      let resp = await handler.translateHandler.detectText(str);
      if (!resp) return message.warning(`检测失败`);
      const {shield_result: result, shield_words: words} = resp || {};
      setWords(words);
      // _setStr(`text`, result);
      setClick(false);
    },
  }

  const _setStr = (key, str) => {
    useMap[key].action(str);
    setClick(true);
    handler.sets(key, str);
    form.setFieldsValue({[key]: str});
    delete editing.shields;
  }

  return (
    <div className={`cp-${compKlass}`}>
      <div className="inner">
        <Form form={form}
              layout="vertical"
              name="basic"
              initialValues={{
                origin: origin,
                text: text
              }}
        >
          <Form.Item name={`origin`} label={`原文`}>
            <Input.TextArea
              onChange={e => compHandler.change(e, `origin`)}
              autoSize={{minRows: 3, maxRows: 6}}
            />
          </Form.Item>

          <Button.Group>
            <Select
              showSearch
              value={tarLang || editing[`to`]}
              className="lang-select"
              placeholder="选择语言"
              optionFilterProp="children"
              onChange={val => handler.translateHandler.changeLanguage(val)}
              filterOption={(input, option) => {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            >
              {
                languages.map(lang => {
                  return (
                    <Select.Option key={lang[`code`]} value={lang[`code`]}>{lang[`name_cn`]}</Select.Option>
                  )
                })
              }
            </Select>
            <Button type={`primary`} onClick={e => compHandler.translate(origin)}>翻译</Button>
          </Button.Group>

          <Form.Item name={`text`} label={`展示文字`}>
            <Input.TextArea
              onChange={e => compHandler.change(e, `text`)}
              autoSize={{minRows: 3, maxRows: 6}}
            />
          </Form.Item>

          <h3>敏感词</h3>
          <Space direction={`vertical`}>
            <div>
              {
                unClick ? `未检测` : isValidArr(words) ? words.map((w, i) => <Tag color="orange" key={i}>{w}</Tag>) :
                  <Tag color={`green`}>无敏感词</Tag>
              }
            </div>
            <Button onClick={e => compHandler.detect(text)}>检查敏感词汇</Button>
          </Space>


        </Form>
      </div>
    </div>
  )
}