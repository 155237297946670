import React from "react"
import { Space } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiDeleteVariant, mdiEraser,
  mdiEraserVariant, mdiFormatColorFill,
  mdiFormatText, mdiFountainPen, mdiGreasePencil,
  mdiImageOutline,
  mdiPanoramaHorizontalOutline
} from "@mdi/js";

const keyName = "tool.new.image.paints";
const klassName = keyName.replace(/\./g, '-');

export default function ToolImagePaints({handler, editing, interactionMode}) {

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <ToolPanelListItemNewComp item={{
          icon: mdiEraser,
          iconSize: 2,
          activeIcon: mdiEraser,
          title: `橡皮擦`,
          click: e => handler.interactionHandler.eraser()
        }} handler={handler} active={interactionMode === `eraser`}/>

      </div>
    </div>
  )
}