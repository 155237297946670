import { Typography, Input, Button, Select, Form } from "antd";
import React, { useEffect, useRef } from "react";
import useState from "react-usestateref"
import Languages from "../../../../assets/languages_map.json";
import { debounce } from "lodash";

const compKey = "tool.panel.textbox.detect";
const compKlass = compKey.replace(/\./g, '-');

export default function ToolTextInputNewComp({handler, editing, tarLang}) {
  const languages = Object.values(Languages);

  const [form] = Form.useForm();
  const [origin, setOrigin] = useState(editing.origin || editing.text);
  const [text, setText] = useState(editing.text);

  useEffect(() => {
    form.setFieldsValue({
      text: editing.text,
      origin: editing.origin || editing.text,
    })
  }, [editing.text])

  const _onChange = (e, key) => {
    // handler.sets(key, e.target.value, editing)
  }

  const useMap = {
    origin: {
      action: v => setOrigin(v)
    },
    text: {
      action: v => setText(v)
    }
  }

  const compHandler = {
    translate: async () => {
      let resolveText = await handler.translateHandler.translateText(origin);
      handler.sets(`text`, resolveText, editing)
    },
    change: debounce((e, key) => {
      useMap[key].action(e.target.value);
      handler.sets(key, e.target.value, editing);
    }, 500)
  }

  return (
    <div className={`cp-${compKlass}`}>
      <div className="inner">
        单词检测
      </div>
    </div>
  )
}