import { message } from "antd";
import { API } from "../../../../config";
import REQUEST from "../../../../utils/requewt";
import { isSplitByGrapheme } from "../utils"

class TranslateHandler {
  constructor(handler) {
    this.handler = handler;
  }

  changeLanguage = language => {
    this.handler.dbHandler.set(`func`, `tar_lang`, language);
    this.handler.tar_lang = language;
  }

  /**
   * 翻译textbox
   * @param target
   * @return {Promise<any>}
   */
  async transTextBox(target) {
    const {onTranslated, onState} = this.handler
    let ao = target || this.handler.canvas.getActiveObject();
    if (!ao) return message.error(`未选中！`);
    if (ao.type === `activeSelection`) return message.error(`暂不支持批量翻译！未来开放`);
    if (!ao.text || ao.text === ``) return message.error(`空内容！`);
    onState({translating: true});
    let resolveText = await this.translateText(ao.text);
    if (!resolveText) {
      onState({translating: false});
      return;
    }
    let splitByGrapheme = isSplitByGrapheme(resolveText);
    let text = resolveText.replace(/\r\n|\n|\r/g, ' ');

    this.handler.setByObject(ao, `splitByGrapheme`, splitByGrapheme);
    this.handler.setByObject(ao, `origin`, ao.text);
    this.handler.setByObject(ao, `text`, text);
    this.handler.setByObject(ao, `dirty`, true);
    onState({translating: false});
    this.handler.canvas.requestRenderAll();
    if (onTranslated) onTranslated(ao);
    return ao
  }

  translateText = async (text, language) => {
    const tar_lang = language || this.handler.tar_lang;
    if (!text) return null
    let options = {
      method: `POST`,
      headers: {"Content-Type": "multipart/form-data"},
      body: {
        text,
        tar_lang
      }
    }
    let response = await REQUEST(API.trial.text.translate, options);
    if (response?.[`error_code`] !== 0 || response?.[`msg`] !== `ok`) return null
    return response?.data?.[`trans_res`] || null;
  }

  detectText = async (text) => {
    if (!text) return null;
    let options = {
      method: `POST`,
      headers: {"Content-Type": "multipart/form-data"},
      body: {
        text,
      }
    }
    let response = await REQUEST(API.trial.text.check, options);
    if (response?.[`error_code`] !== 0 || response?.[`msg`] !== `ok`) return null
    return response?.data || null;
  }

}

export default TranslateHandler