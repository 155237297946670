import React from "react";
import {
  mdiBrushVariant, mdiCircleOutline,
  mdiContentSave,
  mdiContentSaveOutline, mdiEllipseOutline, mdiFormatText,
  mdiImageFilterCenterFocusStrong, mdiImageFilterCenterFocusStrongOutline,
  mdiImagePlus,
  mdiOcr, mdiPlusBoxOutline, mdiRectangleOutline, mdiRedo,
  mdiRocketLaunchOutline, mdiUndo, mdiVectorLine
} from "@mdi/js";
import { getOS } from "../../../../utils"
import { message, Button } from "antd";
import { ToolFileSelectNewComp } from "../comp";

const shortKeyName = getOS() === `MacOS` ? `⌥` : `Alt`;
const shortMainKeyName = getOS() === `MacOS` ? `⌘` : `Ctrl`;

export default function ({handler}) {

  const hasBg = !!handler?.canvas?.bg;

  return [
    {
      icon: mdiContentSaveOutline,
      title: `保存图片`,
      key: `save`,
      click: e => {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        if (e) e.stopPropagation();
        setTimeout(() => handler.exportPic(), 300)
      }
    },
    // {
    //   icon: mdiPlusBoxOutline,
    //   title: `添加`,
    //   key: `add`,
    //   click: e => {
    //     if (e) e.stopPropagation();
    //     if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
    //   },
    //   popover: hasBg ? {
    //     trigger: [`click`],
    //     content: <ToolFileSelectNewComp handler={handler}/>
    //   } : null
    // },
    {
      icon: mdiRocketLaunchOutline,
      title: `一键整图翻译`,
      key: `trans`,
      click: e => {
        if (e) e.stopPropagation();
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        handler.visibleToggle(`visible`, true)
      }
    },
    {
      icon: mdiImageFilterCenterFocusStrongOutline,
      title: `整图抠图`,
      key: `trans`,
      click: e => {
        if (e) e.stopPropagation();
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        handler.visibleToggle(`visible_cut`, true)
      }
    },
    {
      icon: mdiImagePlus,
      title: `插入图片`,
      key: `image`,
      click: e => {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        if (e) e.stopPropagation();
      },
      popover: hasBg ? {
        trigger: [`click`],
        content: <ToolFileSelectNewComp handler={handler}/>
      } : null
    },
    {
      icon: mdiOcr,
      title: `文本翻译`,
      key: `ocr`,
      shortcut: `C`,
      click: e => {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        if (e) e.stopPropagation();
        handler.interactionHandler.ocr();
      }
    },
    {
      icon: mdiImageFilterCenterFocusStrong,
      title: `抠图`,
      key: `cut`,
      shortcut: `X`,
      click: e => {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        if (e) e.stopPropagation();
        handler.interactionHandler.cut();
      }
    },
    {
      icon: mdiFormatText,
      title: `添加文字`,
      key: `text`,
      shortcut: `T`,
      click: e => {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        if (e) e.stopPropagation();
        handler.interactionHandler.text();
      }
    },
    {
      icon: mdiRectangleOutline,
      title: `添加矩形`,
      key: `rect`,
      shortcut: `R`,
      click: e => {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        if (e) e.stopPropagation();
        handler.shapeHandler.add(`rect`)
      }
    },
    {
      icon: mdiCircleOutline,
      title: `添加圆形`,
      key: `circle`,
      shortcut: `O`,
      click: e => {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        if (e) e.stopPropagation();
        handler.shapeHandler.add(`circle`)
      }
    },
    // {
    //   icon: mdiEllipseOutline,
    //   title: `添加椭圆`,
    //   key: `ellipse`,
    //   shortcut: `E`,
    //   click: e => {
    //     if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
    //     if (e) e.stopPropagation();
    //     handler.shapeHandler.add(`ellipse`)
    //   }
    // },
    {
      icon: mdiVectorLine,
      title: `添加线条`,
      key: `line`,
      shortcut: `L`,
      click: e => {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        if (e) e.stopPropagation();
        handler.interactionHandler.line();
      }
    },
    {
      icon: mdiBrushVariant,
      title: `复制样式`,
      key: `brush`,
      click: () => handler.interactionHandler.brush()
    },
  ]
}