import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { defaultConfig as config } from "../config";

class DatabaseHandler {
  constructor(handler) {
    this.handler = handler;
  }

  // 每次更新参数改下日期后缀
  keyName = `BS_PREF_CONFIG_20220304  `;

  // 基本配置
  defaultConfig = config;

  /**
   * 初始化本地信息
   * @param {String} id
   * @return {*}
   */
  initial(id) {
    const adapter = new LocalStorage(this.keyName)
    let db = low(adapter);
    db.defaults({config: this.defaultConfig}).write();
    db.defaults({projects: []}).write();

    let collection = db.get(`projects`).value();
    let index = collection.findIndex(el => el.id === id);
    if (index < 0) db.get(`projects`).push({id, current: 1, current_fragment: 1, scale: 1}).write();
    this.handler.db = db;
    return db
  }

  /**
   * 加载本地信息
   * @param id
   */
  load(id) {
    const {db, onInitConfig} = this.handler;
    let {ui, func, setting, brush, format} = db.get(`config`).value();
    let project = db.get('projects').find({id}).value();
    // console.log(ui, func, setting, project);
    let {current, current_fragment} = project;
    this.handler.current = current;
    this.handler.current_fragment = current_fragment;
    if (onInitConfig) onInitConfig({ui, func, project, setting, brush, format})
  }

  continue() {
    const {current} = this.handler;

  }

  /**
   * 设置本地信息 这里是处理func和ui
   * @param collection
   * @param key
   * @param value
   */
  set(collection, key, value) {
    const {db, onDBChange} = this.handler;
    db.set(`config.${collection}.${key}`, value).write();
    if (onDBChange) onDBChange(collection, key, value)
  }


  /**
   * 读取本地信息
   * @param collection
   * @param [key]
   * @return {*}
   */
  read(collection, key) {
    const {db} = this.handler;
    if (key) return db.get(`config.${collection}.${key}`).value();
    return db.get(`config.${collection}`).value();
  }

  find(collection, id) {
    const {db} = this.handler;
    return db.get(collection).find({id}).value();
  }

  update(collection, id, source) {
    const {db} = this.handler;
    db.get(collection)
      .find({id})
      .assign(source)
      .write();
    return db.get(collection).find({id}).value();
  }
}

export default DatabaseHandler