import {
  mdiDeleteVariant, mdiEye, mdiEyeOff,
  mdiHelpCircleOutline, mdiLock
} from "@mdi/js";
import React, { useState, useRef, useEffect } from "react";
import { Button, List, Input, Tooltip } from "antd";
import classnames from "classnames"
import Mcon from "@mdi/react"
import CanvasObject from "../../../../handlers/CanvasObjectCreate"

const keyName = "tool.new.layer.list.item";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 2;

export default function Layer({item, handler, compHandler, editing, visible}) {
  const inputEl = useRef(null);

  // const [locked, setLocked] = useState(item.lock);
  // useEffect(() => {
  //   setLocked(item.lock)
  // }, [item.lock])

  if (!item) return null;
  const isShow = visible === item.id;
  const isVisible = item.visible;
  const isLock = !!item.lock;

  const {type, category} = item;
  const isActive = editing?.id === item.id;
  let icon = CanvasObject[type]?.icon || mdiHelpCircleOutline,
    title = CanvasObject[type]?.title || `未知图层`;

  if ([`background`, `segment`].includes(category)) {
    icon = CanvasObject[category].icon;
    title = CanvasObject[category].title;
  } else if (category === `textbox`) {
    title = item.text
  }

  if (item.display) title = item.display;

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <List.Item onClick={e => compHandler.select(item)}
                   className={classnames({active: isActive})}
        >

          <List.Item.Meta
            onDoubleClick={e => {
              compHandler.title.visible(item.id)
              setTimeout(() => inputEl.current.focus(), 100)
            }}
            avatar={<Button onClick={e => {
              e.stopPropagation();
              handler.toggleVisible(item)
            }} type={`link`} icon={<Mcon path={isVisible ? mdiEye : mdiEyeOff} size={0.75}/>} />}
            title={isShow ? <Input size={`small`} defaultValue={item.display}
                                   ref={inputEl}
                                   placeholder={`重命名`}
                                   allowClear
                                   onBlur={e => {
                                     compHandler.title.change(e, item)
                                     compHandler.title.visible(null)
                                   }}
                                   onPressEnter={e => {
                                     compHandler.title.change(e, item)
                                     compHandler.title.visible(null)
                                   }}/> : title}
          />
          <div className="underlay">
            <Mcon className={`underlay-icon`} path={icon} size={iconSize}/>
          </div>
          <div className="part part-right">
            {
              item.lock ?
                <Tooltip placement={`right`} title={`已锁定`}>
                  <Button type={`link`} onClick={e => {
                    e.stopPropagation();
                    handler.toggleLock(item)
                  }} icon={<Mcon path={mdiLock} size={0.75}/>}/>
                </Tooltip>
                :
                <Tooltip placement={`right`} title={`删除`}>
                  <Button type={`link`} onClick={e => {
                    e.stopPropagation();
                    handler.remove(item);
                  }} icon={<Mcon path={mdiDeleteVariant} size={0.75}/>}/>
                </Tooltip>
            }
          </div>
        </List.Item>
      </div>
    </div>
  );
}
