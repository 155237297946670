import React from "react"
import { Space } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import { mdiDeleteVariant, mdiEraserVariant, mdiFormatText, mdiPanoramaHorizontalOutline } from "@mdi/js";
import CanvasObject from "../../../../handlers/CanvasObjectCreate";

const keyName = "tool.new.progress";
const klassName = keyName.replace(/\./g, '-');

export default function ToolProgress({handler, editing, current}) {

  if (!editing?.id) return null;

  const isSegment = editing.category === `segment`;

  if (isSegment && !editing.rel) return null;

  let dom = ``;
  const aoObj = CanvasObject[editing.category];
  const relativeObj = handler.getObjects().find(e => e.id === editing.rel);
  const relObj = relativeObj?.id && CanvasObject[relativeObj.category];

  if (isSegment) {
    // 如果是segment 查询关联的对象
    dom = (
      <>
        {
          relObj &&
          <ToolPanelListItemNewComp item={{
            icon: relObj?.icon,
            title: `${relObj?.title}编辑`,
            activeIcon: relObj?.icon,
            click: e => handler.progressModify()
          }} handler={handler} active={false}/>

        }

        <ToolPanelListItemNewComp item={{
          icon: aoObj?.icon,
          title: `优化${aoObj?.title}`,
          activeIcon: aoObj?.icon,
          click: e => handler.progressModify()
        }} handler={handler} active={true}/>
      </>
    )
  } else {
    const hasSegment = editing.rel && handler.getObjects().find(e => e.id === editing.rel);

    dom = (
      <>
        <ToolPanelListItemNewComp item={{
          icon: aoObj?.icon,
          title: `${aoObj?.title}编辑`,
          activeIcon: aoObj?.icon,
          click: e => handler.progressModify()
        }} handler={handler} active={true}/>

        {
          hasSegment &&
          <ToolPanelListItemNewComp item={{
            icon: relObj?.icon,
            title: `优化${relObj?.title}`,
            activeIcon: relObj?.icon,
            click: e => handler.progressModify()
          }} handler={handler} active={false}/>
        }

      </>
    )
  }


  // 如果不是segment 那就是第一位 需要鉴别是否有segment


  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        {
          dom
        }


        <ToolPanelListItemNewComp item={{
          icon: mdiDeleteVariant,
          title: `删除图层`,
          click: e => handler.remove()
        }} handler={handler} active={false}/>
      </div>
    </div>
  )
}