import React, { useState } from "react";
import { Tabs, Input, Button, Space, Form, Upload, message, Select } from "antd"
import { mdiMicrosoftInternetExplorer, mdiDesktopClassic, mdiImagePlus } from "@mdi/js"
import { ALLOW_IMAGE_FORMAT_LIST, ALLOW_IMAGE_SIZE } from "../../../../../../../config"
import { testFile, readMediaMetaOfFile, readMineType, checkURLHeader, isBase64 } from "../../../../../../../utils"
import Mcon from "@mdi/react"
import PicWgt from "../../../../comp/tools/pic.holder"
import Languages from "../../../../assets/languages_map.json";

const keyName = "tool.new.pop.file.input";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;
const {Dragger} = Upload;

export default function PopFileInput({handler, targetLanguage}) {
  const languages = Object.values(Languages);

  const [loading, setLoading] = useState(false);
  const [pitching, setPitching] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [bg, setBg] = useState(undefined);
  // https://img.miaotranslation.com/web/home/%E6%96%87%E5%AD%97%E5%A4%84%E7%90%86-2.png
  // https://img.miaotranslation.com/test/poster-test.jpg
  // https://img.miaotranslation.com/test/Macbook-title.jpg
  // https://img.miaotranslation.com/%20web/tmp/%E6%95%8F%E6%84%9F%E8%AF%8D%E6%B5%8B%E8%AF%95.jpg

  const MEDIA_FUNC = {
    bg: {
      update: v => setBg(v),
      config: {
        image: {
          format: ALLOW_IMAGE_FORMAT_LIST,
          size: ALLOW_IMAGE_SIZE,
        }
      }
    }
  }

  const uploadOptions = {
    accept: "image/*",
    listType: "picture",
    showUploadList: false,
  }

  const compHandler = {
    /**
     * @description 提交
     * @return {Promise<void>}
     * @private
     */
    submit: async () => {
      if (!bg) {
        message.warn(`请点击插入图片上传或输入网络图片地址`)
        return;
      }
      // 检测图片
      // 看是不是网络图片
      if (typeof bg === `string`) { // 不是dataURL
        const {size = 0, type = ``} = await checkURLHeader(bg) || {};

        if (!ALLOW_IMAGE_FORMAT_LIST.includes(type)) {
          message.warn(`格式错误! 允许的格式为：${ALLOW_IMAGE_FORMAT_LIST.join(` `)}`)
          return
        }

        if (ALLOW_IMAGE_SIZE < +size) {
          message.warn(`体积太大! 体积应小于：${(ALLOW_IMAGE_SIZE / 1024 / 1024).toFixed(0)}MB`)
          return
        }
      }
      handler.add.image(bg);
      setLoading(true);
    },
    /**
     *
     * @param file
     * @param func
     * @return {boolean}
     */
    beforeUpload: (file, func) => {
      (async () => {
        setProcessing(true);
        // 移除预设
        // 对应配置的key
        const fileType = readMineType(file.type);
        if (!fileType) return setProcessing(false);
        // 检测file
        let resolve = testFile(file, func.config[fileType]);
        if (!resolve) return setProcessing(false);
        // 格式化file
        // 生成dataURL显示图片
        const {width, height, duration, src} = await readMediaMetaOfFile(file, fileType);
        // 生成分辨率
        Object.assign(file, {
          src,
          dimension: [width, height],
          duration
        })
        func.update(file);
        setProcessing(false);
      })()
      return false
    },
    normFile: (value, prevValue, allValues) => {
      if (typeof value === `object`) {
        if (value.hasOwnProperty(`file`)) return value[`file`];
      }
      return value;
    },
    fileRemove: (e, key) => {
      if (e) e.stopPropagation();
      // 更新state
      MEDIA_FUNC[key].update(undefined);
      // 更新form
    }
  }

  return (
    <div className={`cp-${klassName}`}>
      <Space className="inner" direction={`vertical`}>
        <Dragger {...Object.assign(
          uploadOptions,
          {
            beforeUpload: file => compHandler.beforeUpload(file, MEDIA_FUNC[`bg`])
          }
        )}>
          <div className="upload-area">
            {
              bg ?
                <PicWgt data={bg} onRemove={e => compHandler.fileRemove(e, `bg`)}/>
                :
                <div className="content">
                  <Button icon={<Mcon path={mdiImagePlus}/>} type={`link`}/>
                  <h3>插入图片</h3>
                  <span>点击上传 或 拖入上传区域</span>
                </div>
            }
          </div>
        </Dragger>

        <Select
          style={{width: `100%`}}
          showSearch
          value={targetLanguage}
          className="lang-select"
          placeholder="选择语言"
          optionFilterProp="children"
          onChange={val => handler.changeLanguage(val)}
          filterOption={(input, option) => {
            return option.props.children.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 || option.props.key.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }}
        >
          {languages.map(lang => {
            return (<Select.Option key={lang[`code`]} value={lang[`code`]}>{lang[`name_cn`]}</Select.Option>)
          })}
        </Select>

        <Input defaultValue={bg}
               allowClear
               placeholder={`请粘贴一个线上图片的地址`}
               onChange={e => {
                 setBg(e?.target?.value)
               }}
        />
        <Button loading={loading} onClick={compHandler.submit} block type={`primary`}>上传</Button>
        {/*<div className="mt-1 text-center">添加新的背景会替换掉之前的背景</div>*/}
      </Space>
    </div>
  )
}