import { fabric } from "fabric";

class PatternHandler {
  constructor(handler) {
    this.handler = handler;
  }

  open = (dataURL) => {
    const ao = this.handler.canvas.getActiveObject();
    if (!ao?.id) return;
    ao.pattern = dataURL

    this._set(dataURL, ao.getScaledWidth())
  }

  reload = () => {
    const ao = this.handler.canvas.getActiveObject();
    if (!ao?.id) return;

    this._set(ao.pattern, ao.getScaledWidth())
  }

  _set = (url, width, repeat = `no-repeat`) => {
    if (!url || !width) return;
    const {onPatterned} = this.handler;

    fabric.Image.fromURL(url, img => {
      img.scaleToWidth(width)

      const patternKlass = new fabric.Pattern({
        source: img.toDataURL(),
        repeat,
      });

      const obj = this.handler.sets(`fill`, patternKlass);
      if (onPatterned) onPatterned(obj)
    })
  }
}

export default PatternHandler