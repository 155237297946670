import { formatOptions } from "../config";
import CanvasObject from "./CanvasObjectCreate";
import { v4 } from "uuid";

export default class BrushHandler {
  constructor(handler) {
    this.handler = handler
  }

  add = (type) => {
    if (![`circle`, `triangle`, `rect`, `ellipse`].includes(type)) return;
    const {backgroundImage} = this.handler.canvas;

    let size = 100;
    let customOptions = {}
    if ([`triangle`, `rect`].includes(type)) {
      Object.assign(customOptions, {
        width: size,
        height: size
      })
    } else if ([`circle`].includes(type)) {
      Object.assign(customOptions, {
        radius: size / 2,
      })
    } else if ([`ellipse`].includes(type)) {
      Object.assign(customOptions, {
        rx: size,
        ry: size / 2,
      })
    }
    const left = (backgroundImage.width - size) / 2;
    const top = (backgroundImage.height - size) / 2;
    let options = {
      id: v4(),
      left,
      top,
      fill: `rgb(63, 81, 181)`,
      ...customOptions,
      ...CanvasObject[type].options
    }
    const Klass = this.handler.add(options);
    this.handler.canvas.setActiveObject(Klass);
    this.handler.canvas.requestRenderAll();
  }
}