import { mdiEyedropperVariant, mdiFormatSize, mdiSquare, mdiSquareOutline, mdiWaterOutline } from "@mdi/js";
import Mcon from "@mdi/react";
import { Button, InputNumber, Popover, Select, Slider } from "antd";
import React from "react";
import { SketchPicker } from "react-color";
import { defaultFontSize } from "../../config";
import { HexToRGBA, RGBToHex } from "../../utils";

const keyName = "tool.tools.draw";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;

export default function ToolDraw({editing, handler, brush}) {
  const {width, color} = brush || {};
  const freeDrawingBrush = handler?.canvas?.freeDrawingBrush;

  const compHandler = {
    enable: () => {
      handler.onState({outsideDisabled: false})
    },
    disabled: () => {
      handler.onState({outsideDisabled: true})
    },
  }

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <div onMouseEnter={compHandler.disabled}
             onMouseLeave={compHandler.enable}>
          {/*文本大小*/}
          <div>
            <div className="label"><Mcon size={iconSize} path={mdiFormatSize} /> 画笔宽度</div>
            <Button.Group>
              <InputNumber value={width}
                           style={{width: 100, borderRadius: `0`, marginLeft: -1}}
                           step={1}
                           onChange={val => handler.drawHandler.set(`width`, val)}
              />
              <Select className={`in-group-selector`}
                      dropdownMatchSelectWidth={false}
                      onChange={val => handler.drawHandler.set(`width`, val)}
                      value={width}>
                {
                  defaultFontSize.map((f, fi) => <Select.Option key={f} value={f}>{f}</Select.Option>)
                }
              </Select>
            </Button.Group>
            <Slider min={1} max={255} value={width} onChange={val => handler.drawHandler.set(`width`, val)} />
          </div>

          <div>
            <div className="label"><Mcon size={iconSize} path={mdiWaterOutline} /> 画笔颜色</div>
            <Button.Group>
              <Popover
                trigger="click"
                content={<SketchPicker
                  color={color}
                  onChangeComplete={color => {
                    let rgba = Object.values(color.rgb).slice(0, 4);
                    handler.drawHandler.set(`color`, `rgb(${rgba})`)
                  }}
                />}>
                <Button title={`当前颜色 ${color}`}
                  // onClick={e => setVisible(true)}
                >
                  <Mcon size={iconSize} path={mdiSquare}
                        color={color && typeof color !== `object` && color || `#000`} />
                </Button>
              </Popover>
              <Button title={`白色`}
                      onClick={e => handler.drawHandler.set(`color`, `rgb(255, 255, 255)`)}>
                <Mcon size={iconSize} path={mdiSquareOutline} color={`rgb(0, 0, 0)`} />
              </Button>
              <Button title={`黑色`}
                      onClick={e => handler.drawHandler.set(`color`, `rgb(0, 0, 0)`)}>
                <Mcon size={iconSize} path={mdiSquare} color={`rgb(0, 0, 0)`} />
              </Button>
              <label className="ant-btn" title={`拾色器`} onChange={e => {
                const {r, g, b, a} = HexToRGBA(e.target.value);
                handler.drawHandler.set(`color`, `rgb(${r}, ${g}, ${b})`)
              }}>
                <input defaultValue={color && typeof color !== `object` && RGBToHex(color)}
                       type="color"
                       style={{visibility: `hidden`, position: `absolute`}} />
                <Mcon size={iconSize} path={mdiEyedropperVariant} color={`rgba(0, 0, 0, 1)`} />
              </label>
            </Button.Group>
          </div>
        </div>
      </div>
    </div>
  )
}