import React from "react"
import { Popover } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiHexagon,
  mdiHexagonOutline, mdiCheckerboard
} from "@mdi/js";
import { SketchPicker } from "react-color";

const keyName = "tool.new.textbox.background";
const klassName = keyName.replace(/\./g, '-');
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;

export default function TextboxColor({handler, backgroundColor}) {
  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <Popover
          trigger="click"
          className={`cp-tool-new-panel-item`}
          content={<SketchPicker
            color={backgroundColor}
            onChangeComplete={color => {
              let rgb = Object.values(color.rgb).slice(0, 3);
              handler.sets(`backgroundColor`, `rgb(${rgb})`)
            }}
          />}
        >
          <ToolPanelListItemNewComp
            item={{
              icon: mdiHexagon,
              iconColor: backgroundColor,
              title: `当前`
            }}
            active={false}
          />
        </Popover>

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagonOutline,
            title: `白色`,
            click: e => handler.sets(`backgroundColor`, colorWhite)
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagon,
            title: `黑色`,
            click: e => handler.sets(`backgroundColor`, colorBlack)
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiCheckerboard,
            title: `透明`,
            click: e => handler.sets(`backgroundColor`, colorTransparent)
          }}
          handler={handler}
          active={false}
        />
      </div>
    </div>
  )
}