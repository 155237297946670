import CanvasObject from "./CanvasObjectCreate";
import { fabric } from "fabric";

class DrawHandler {
  constructor(handler) {
    this.handler = handler
  }

  start = () => {

  }

  pen = () => {
    const {onModeChange, brush} = this.handler;

    const {width, color} = brush || {};

    let {r, g, b} = this.subRGB2Obj(color);
    // console.log(r, g, b)

    const cursor = new fabric.Circle(Object.assign({}, CanvasObject.cursor.draw.options, {
      left: this.handler.canvas.backgroundImage.width / 2,
      top: this.handler.canvas.backgroundImage.height / 2,
      radius: width / 2, fill: color,
      stroke: `rgb(${255 - r},${255 - g},${255 - b})`
    }))

    this.handler.canvas.add(cursor);

    return cursor
  }

  end = () => {
    let cursor = this.getCursor();
    if (cursor) this.handler.canvas.remove(cursor);
    this.handler.canvas.isDrawingMode = false;
    this.handler.canvas.requestRenderAll();
  }

  set = (key, val) => {
    const {onBrushChange} = this.handler;

    // 更新canvas
    const {cursorKey, cursorVal} = this.fltBrushKey2CursorKey(key, val);

    this.handler.canvas.freeDrawingBrush[key] = val;
    // 更新cursor
    let cursor = this.getCursor();
    cursor.set(cursorKey, cursorVal);
    if (cursorKey === `fill`) {
      let {r, g, b} = this.subRGB2Obj(val);
      cursor.set(`stroke`, `rgb(${255 - r},${255 - g},${255 - b})`);
    }
    this.handler.canvas.bringToFront(cursor);
    this.handler.canvas.requestRenderAll();
    // 更新Dom
    if (onBrushChange) onBrushChange(key, val);

    // this.handler.canvas.freeDrawingCursor = freeDrawingCursor;
  }

  fltBrushKey2CursorKey = (key, val) => {
    let newKey = key, newVal = val;
    switch (key) {
      case `width`:
        newKey = `radius`;
        newVal = val / 2
        break
      case `color`:
        newKey = `fill`
        break
    }

    return {cursorKey: newKey, cursorVal: newVal}
  }

  subRGB2Obj = (rgb) => {
    // console.log(`rgb`, rgb)

    let obj = {
      r: 255, g: 255, b: 255
    }
    if (!rgb || typeof rgb !== "string") return obj;
    try {
      let str = rgb.replace(/^\s+|\s+|\r|\n$/g, '').substring(4, rgb.length - 1).replace(/^\s+|\s+|\r|\n$/g, '');
      // console.log(`str`, str)
      let arr = str.split(",");
      // console.log(`arr`, arr)
      obj = {
        r: +arr[0],
        g: +arr[1],
        b: +arr[2],
      }
    } catch (e) {

    }

    return obj
  }

  fltCursorKey2BrushKey = (key, val) => {
    let newKey = key, newVal = val;
    switch (key) {
      case `radius`:
        newKey = `width`;
        newVal = val * 2
        break
      case `fill`:
        newKey = `color`
        break
    }

    return {brushKey: newKey, brushVal: newVal}
  }

  getCursor = () => this.handler.canvas.getObjects().find(e => e.category === `cursor`);
}

export default DrawHandler