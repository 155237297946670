import React from "react";
import { Space } from "antd"

const keyName = "tool.new.disabled";
const klassName = keyName.replace(/\./g, '-');

export default function () {
  return (
    <div className={`cp-${klassName} text-center`}>
      <Space className="inner" direction={`vertical`} size="large">
        <img className="img-fluid mt-5" style={{height: 200, margin: `0 auto`, display: `block`}}
             src="/images/rebuilding.svg" alt="" />
        <div>为了给您带来更优质的用户体验</div>
        <h1>电商工具 回炉重造 敬请期待</h1>
      </Space>
    </div>
  )
}