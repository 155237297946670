import {
  mdiCardTextOutline,
  mdiFormatColorFill, mdiFormatFont,
  mdiFormatTextbox,
  mdiFormatTextVariantOutline,
  mdiGradientHorizontal,
  mdiPalette,
  mdiTextShadow
} from "@mdi/js";
import Mcon from "@mdi/react";
import {
  Divider,
  Collapse,
  List,
  Avatar
} from "antd";
import { capitalize, forEach, lowerCase, upperCase, debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import useState from "react-usestateref";
import {
  fontFree,
  fontSystem,
  languageMap
} from "../../../../config";
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  ToolTextboxBackgroundNewComp,
  ToolTextboxColorNewComp,
  ToolTextboxShadowNewComp,
  ToolTextboxStyleNewComp,
  ToolTextInputNewComp,
  ToolTextboxGradientNewComp,
  ToolProgressNewComp, ToolTextboxBorderNewComp
} from "../../index";

const keyName = "tool.new.textbox";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 1;
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`;
const defaultOpenCollapse = [`text`, `style`, `color`];

export default function ToolTextbox(props) {
  const {
    editing,
    handler,
    targetLanguage,
    interactionMode,
    fonts,
    defaultActiveKey = []
  } = props;

  const {
    fill,
    backgroundColor,
  } = editing || {};

  const [visible, setVisible] = useState(false);
  const [fontsAvailable, setFontsAvailable] = useState([]);
  const [fontsAll, setFontsAll] = useState([]);
  let container, monoWidth, sansWidth, serifWidth;

  useEffect(() => {
    let _fonts = Object.values(fontSystem).map(f => f.name);
    if (window.availableFonts) {
      setFontsAvailable(window.availableFonts)
    } else {
      container = document.createElement("span");
      container.innerHTML = Array(100).join("wi");
      container.style.cssText = [
        "position:absolute",
        "width:auto",
        "font-size:128px",
        "left:-99999px",
      ].join(" !important;");

      initialWidths();

      let detectedFonts = [];

      forEach(_fonts, (font) => {
        if (isFontAvailable(font)) {
          detectedFonts.push(font);
        }
      });

      detectedFonts = [...detectedFonts];

      window.availableFonts = detectedFonts;

      setFontsAvailable(window.availableFonts);

      let _fontsAvailable = fontsAvailable.map(e => ({name: e}))

      setFontsAll([..._fontsAvailable, ...fonts])
    }
  }, [])

  const fonts_en = [
    ...Object.values(fontSystem).filter(el => el.nation === `en`).sort(),
    ...Object.values(fontFree).filter(el => el.nation === `en`).sort()
  ];
  const fonts_zh = [
    ...Object.values(fontSystem).filter(el => el.nation === `zh-CN`).sort(),
    ...Object.values(fontFree).filter(el => el.nation === `zh-CN`).sort()
  ]

  const getWidth = (font) => {
    container.style.fontFamily = font;

    document.body.appendChild(container);
    const width = container.clientWidth;
    document.body.removeChild(container);

    return width;
  };

  const initialWidths = () => {
    monoWidth = getWidth("monospace");
    serifWidth = getWidth("serif");
    sansWidth = getWidth("sans-serif");
  };

  const isFontAvailable = (font) => {
    return (
      monoWidth !== getWidth(font + ",monospace") ||
      sansWidth !== getWidth(font + ",sans-serif") ||
      serifWidth !== getWidth(font + ",serif")
    );
  };

  return (
    <div className={`cp-${klassName}`}>
      {/*<Divider><Mcon size={iconSize} path={mdiCardTextOutline}/> 文本编辑</Divider>*/}

      <ToolProgressNewComp handler={handler} editing={editing}/>

      <PerfectScrollbar className="inner">

        <Collapse expandIconPosition="right"
                  defaultActiveKey={defaultActiveKey}
                  onChange={v => {
                    handler.dbHandler.set(`ui`, `textbox_default_active_key`, v)
                  }}
                  ghost
        >

          {/*<Collapse.Panel*/}
          {/*  key="text"*/}
          {/*  header={*/}
          {/*    <List.Item.Meta*/}
          {/*      title={`内容`}*/}
          {/*      avatar={*/}
          {/*        <Avatar size={`small`} style={{backgroundColor: `#fff`}}*/}
          {/*                icon={<Mcon color={`#000`} path={mdiFormatTextbox}/>}/>*/}
          {/*      }*/}
          {/*    />*/}
          {/*  }*/}
          {/*>*/}
          {/*  <ToolTextInputNewComp editing={editing} handler={handler} tarLang={targetLanguage}/>*/}
          {/*</Collapse.Panel>*/}

          <Collapse.Panel
            key="style"
            header={
              <List.Item.Meta
                title={`样式`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiFormatFont}/>}/>
                }
              />
            }
          >
            <ToolTextboxStyleNewComp handler={handler} editing={editing} fontsAvailable={fontsAvailable}
                                     fontsEN={fonts_en} fontsZH={fonts_zh}/>
          </Collapse.Panel>

          <Collapse.Panel
            key="color"
            header={
              <List.Item.Meta
                title={`颜色`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiPalette}/>}/>
                }
              />
            }
          >
            <ToolTextboxColorNewComp handler={handler} editing={editing} />
          </Collapse.Panel>

          <Collapse.Panel
            key="gradient"
            header={
              <List.Item.Meta
                title={`渐变色`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiGradientHorizontal}/>}/>
                }
              />
            }
          >
            <ToolTextboxGradientNewComp handler={handler} editing={editing}/>
          </Collapse.Panel>

          <Collapse.Panel
            key="backgroundColor"
            header={
              <List.Item.Meta
                title={`背景色`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiFormatColorFill}/>}/>
                }
              />
            }
          >
            <ToolTextboxBackgroundNewComp handler={handler} backgroundColor={backgroundColor}/>
          </Collapse.Panel>

          <Collapse.Panel
            key="shadow"
            header={
              <List.Item.Meta
                title={`阴影`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiTextShadow}/>}/>
                }
              />
            }
          >
            <ToolTextboxShadowNewComp handler={handler} editing={editing}/>
          </Collapse.Panel>

          <Collapse.Panel
            key="border"
            header={
              <List.Item.Meta
                title={`描边`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiFormatTextVariantOutline}/>}/>
                }
              />
            }
          >
            <ToolTextboxBorderNewComp handler={handler} editing={editing}/>
          </Collapse.Panel>
        </Collapse>

      </PerfectScrollbar>
    </div>
  )
}