import React, { useEffect } from "react";
import { Layout, Button, Divider, Tooltip } from "antd";
import { getOS } from "../../../../../utils";
import { ToolImageComp, ToolDrawComp, ToolTextboxComp, ToolOcrComp, ToolPatchComp } from "../../comp";
import Mcon from "@mdi/react";
import { mdiUndo, mdiRedo } from "@mdi/js";

const shortKeyName = getOS() === `MacOS` ? `⌘` : `Ctrl`;

const keyName = "tool.rside";
const klassName = keyName.replace(/\./g, '-');

export default function Rside(props) {
  const {editing, handler, interactionMode, targetLanguage, brush, translating, autoTranslate, fonts, undo, redo} = props;
  const isCropping = [`ocr`, `cut`].includes(interactionMode) || false;

  const {width, color} = brush || {};
  const freeDrawingBrush = handler?.canvas?.freeDrawingBrush;
  // const {width: brushWidth = 5, color: brushFill = `rgba(0, 0, 0, 1)`} = freeDrawingBrush || {}

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <Layout.Sider theme={`light`}>
          <Button.Group onMouseEnter={() => handler?.interactionHandler.selection()}>
            <Tooltip title={`撤销 快捷键 ${shortKeyName} + Z`}>
              <Button
                title={`撤销`}
                disabled={isCropping || !handler?.transactionHandler.undos.length}
                onClick={e => handler?.transactionHandler.undo()}>
                <Mcon size={0.75} path={mdiUndo} />
              </Button>
            </Tooltip>
            <Tooltip title={`重做 快捷键 ${shortKeyName} + Shift + Z`}>
              <Button
                disabled={isCropping || !handler?.transactionHandler.redos.length}
                title={`重做`} onClick={e => handler?.transactionHandler.redo()}>
                <Mcon size={0.75} path={mdiRedo} />
              </Button>
            </Tooltip>
          </Button.Group>

          <Divider />

          {
            interactionMode === `ocr` &&
            <ToolOcrComp tarLang={targetLanguage} handler={handler} editing={editing} autoTranslate={autoTranslate} />
          }

          {/*画图模式 画笔对象*/}
          {
            interactionMode === `draw` && freeDrawingBrush &&
            <ToolDrawComp handler={handler} editing={editing} brush={brush} />
          }

          {
            interactionMode === `patch` && freeDrawingBrush &&
            <ToolPatchComp handler={handler} editing={editing} brush={brush} />
          }

          {/*背景对象*/}
          {
            editing && [`image`].includes(editing.type) &&
            <ToolImageComp editing={editing} handler={handler} />
          }

          {/*文字框*/}
          {
            editing && [`FreeTextbox`, `textbox`].includes(editing.type) &&
            <ToolTextboxComp editing={editing}
                             fonts={fonts}
                             translating={translating}
                             handler={handler}
                             targetLanguage={targetLanguage}
                             interactionMode={interactionMode}
            />
          }
        </Layout.Sider>
      </div>
    </div>
  )
}