import React from "react";
import { Layout, Spin, Modal, message } from "antd";
import { debounce, capitalize } from "lodash"
import { fabric } from "fabric";
import Cookies from "universal-cookie";

import {
  AsideNewComp,
  HeaderNewComp,
  RsideNewComp,
  ContextWidgetNewComp,
  MainNewComp,
  ContextComp,
  SelectorContextComp,
  PageTranslateComp,
  PanelTextboxComp,
  ElementRangerComp,
  GuideToolWidget,
  steps, PAGE_SCALE_BALANCE, PageCutComp,
} from "../components/pages/tool";
import { defaultConfig, defaultKeyboardEvent } from "../components/pages/tool";
import Joyride, { STATUS } from "react-joyride";

import CanvasObject from "../components/pages/tool/handlers/CanvasObjectCreate";
import { isValidArr } from "../utils";

const {Content} = Layout;

export default class Tool extends React.Component {

  handler = null;

  state = {
    initialized: false,

    id: 1025,
    project: null,
    error: null,
    viewport: null,
    zoomRatio: 1,

    ocring: false,
    saving: false,
    translating: false,

    editing: null,
    mode: `default`,
    outsideDisabled: false,
    visible: false,

    fonts: [],

    isEditing: false,

    undo: false,
    redo: false,

    welcome_guide_open: false,

    loading: false,

    layers: [],

    // 所有元素可视
    visibleEls: true,

    // 抠图中
    cutting: false,
    visible_cut: false,

  }

  componentWillMount() {
    this._fabric_textbox_property_set();

    const cookies = new Cookies();

    cookies.set(`token`, this.props.token, {
      path: '/',
      maxAge: 1 * 24 * 60 * 60,
      sameSite: `strict`,
    });
  }

  componentDidMount() {
    (async () => {
      const {id} = this.state;
      this.setState({fetching: true, tip: `初始化项目中...`});
      // 初始化本地信息
      await this.canvasRef?.handler?.dbHandler?.initial(id);
      // 加载本地信息
      await this.canvasRef?.handler?.dbHandler?.load(id);
      // 加载字体
    })()

  }

  pageHandler = {
    onState: state => this._update(state),
    onInitConfig: async data => {
      console.log(`onInitConfig.data`, data)
      let {ui, func, project, setting, brush, format} = data;
      // 当前本地格式没有拿出来
      let {
        textbox_default_active_key,
        image_default_active_key,
        shape_default_active_key,
        collection_default_active_key
      } = ui;
      let {src_lang, tar_lang, auto_translate, segment_remove_with_txt} = func;
      let {current} = project;
      let {fontFamily} = format;
      this._update({
        textbox_default_active_key,
        image_default_active_key,
        shape_default_active_key,
        collection_default_active_key,
        src_lang,
        tar_lang,
        auto_translate,
        segment_remove_with_txt,
        current,
        zoomRatio: project.scale || 1,
        brush,
        ...setting
      });
      await this.canvasRef?.handler?.fontsLoader([fontFamily])

    },
    onDBChange: (collection, key, value) => {
      if (collection === `func` && Object.keys(defaultConfig.func).includes(key)) {
        this.canvasRef.handler[key] = value;
      }
      this.setState({[key]: value});
    },
    visibleToggle: (key, visible) => {
      this.setState({[key]: visible});
    }
  }

  canvasHandlers = {
    onModeChange: (interactionMode) => {
      if (!interactionMode) return;
      const {editing} = this.state;
      this._update({interactionMode});
      if (interactionMode === `grab`) {
        if (editing?.category === `textbox`) {
          editing.exitEditing();
          this.canvasRef.handler.canvas.discardActiveObject();
          this.changeEditing(null);
        }
      } else if ([
        `text`, `ocr`, `cut`,
        `line`,
        // `draw`,
        // `patch`
      ].includes(interactionMode)) this.changeEditing(null);
      // 更新对应的引导
    },
    onRemove: target => {
      console.log(`onRemove`, target);
      // 如果是删除的文字框 那就同时删除背景
      const {segment_remove_with_txt} = this.state;
      if ([`textbox`, `segment`].includes(target.category) && segment_remove_with_txt) {
        let segment = this.canvasRef.handler.getObjects().find(el => el.rel === target.id);
        if (segment && segment.id) this.canvasRef.handler.canvas.remove(segment);
      }
      this.canvasHandlers.onBlur();
      if (!target) return;
      this.setState({changed: true});
      this.canvasRef.handler.transactionHandler.save('removed');
    },
    onAdd: target => {
      // console.log(`%c onAdd ${target.category} `, 'color: #ff0; font-weight: 800;');
      if (!target?.category) return;
      // 处理背景
      if (target.category === `background`) target.sendToBack();
      // 添加图层
      this.setState({changed: true});
      this.canvasRef.canvas.requestRenderAll();
    },
    onZoom: zoom => {
      this.setState({zoomRatio: zoom});
      // 更新缩放
      this.canvasRef.canvas.requestRenderAll();
    },
    onOcr: obj => {
    },
    onPatching: async path => {
      // 处理涂抹中
      await this.canvasRef.handler.patchHandler.patching(path);
      // 移除路径
      await this.canvasRef.handler.canvas.remove(path);
    },
    onErased: async path => {
      // console.log(`path`, path)
      // 移除路径
      await this.canvasRef.handler.canvas.remove(path);
      this.canvasRef.handler.transactionHandler.save('erase');
    },
    onPatched: async segment => {
      // console.log(`onPatched.segment`, segment)
      this.canvasRef.handler.transactionHandler.save('patch');
    },
    onDrawing: path => {

    },
    onResized: path => {
      this.canvasRef.handler.transactionHandler.save('resize');
    },
    onDrew: path => {
      console.log(`onDrew`, path)
      const {editing} = this.state;
      // 合并segment与path
      if (editing?.category !== `segment`) {
        this.canvasRef.handler.canvas.remove(path);
        return
      }

      fabric.util.loadImage(this.canvasRef.handler.canvas.bg, async imgElement => {

        let temp_sel = new fabric.ActiveSelection([editing, path]);
        let l = temp_sel.left, t = temp_sel.top, w = temp_sel.width,
          h = temp_sel.height - 1,
          sx = temp_sel.scaleX || 1,
          sy = temp_sel.scaleY || 1;

        // console.log(`targetIndex`, targetIndex);

        // if (w % 2 !== 0) w += 1;
        // if (h % 2 !== 0) h += 1;
        // console.log(w, h, l, t)

        let holderOptions = {
          width: w * sx,
          height: h * sy,
          left: l,
          top: t,
          type: `rect`,
        }

        let holderKlass = await new fabric.Rect(holderOptions)
        holderKlass.fill = new fabric.Pattern({
          source: imgElement,
          repeat: 'no-repeat',
          offsetX: -l,
          offsetY: -t,
        });

        const sel = new fabric.ActiveSelection([holderKlass, temp_sel]);

        const targetIndex = this.canvasRef.handler.getObjects().findIndex(e => e.id === editing.id);
        // console.log(`targetIndex`, targetIndex);


        // console.log(`devicePixelRatio`, window.devicePixelRatio)
        let selDataURL = sel.toDataURL({
          top: 0,
          left: 0,
          width: sel.width,
          height: sel.height,
          format: `png`,
          quality: 1,
        });

        let image = new Image();
        image.width = sel.width;
        image.height = sel.height;
        image.src = selDataURL;

        let newSegment = new fabric.Image(image, {
          ...CanvasObject.segment.options,
          rel: editing.rel,
          id: editing.id,
          height: sel.height,
          width: sel.width,
          left: sel.left,
          top: sel.top,
          evented: true,
          selectable: true
        })

        this.canvasRef.handler.canvas.remove(path);
        this.canvasRef.handler.canvas.remove(editing);
        this.canvasRef.handler.canvas.insertAt(newSegment, targetIndex, false);

        this.canvasRef.handler.canvas.setActiveObject(newSegment);
        this.canvasRef.handler.transactionHandler.save('add')
      })


      // console.log(this.canvasRef.handler.objects)
      // console.log(this.canvasRef.handler.canvas.getObjects())
      // this.canvasRef.handler.canvas._objects = this.canvasRef.handler.canvas.getObjects().filter(el => el.type && el.category)
      // this.canvasRef.handler.canvas.renderAll();
    },
    onCut: obj => {
      // console.log(`onCut.obj`, obj)
      this.canvasRef.handler.selectorContextmenuHandler.hide();
      // setTimeout(() => this.canvasRef.handler.sets(`backgroundColor`, `rgb(255, 255, 255, 0)`, obj), 800)
    },
    onPageCut: obj => {
      if (!obj) message.error(`整图抠图失败`);
      this.canvasRef.handler.selectorContextmenuHandler.hide();
      this.setState({cutting: false, visible_cut: false});
      // setTimeout(() => this.canvasRef.handler.sets(`backgroundColor`, `rgb(255, 255, 255, 0)`, obj), 800)
    },
    onExport: async url => {
      // this.appHandler.resolve(url);
      await this.canvasRef?.handler.onState({saving: false})
    },
    // 只处理选中的情况 取消选中的情况交给blur去处理
    onSelect: opt => {
      const {target, e} = opt || {};
      const {interactionMode} = this.state;
      // console.log(`onSelect`, interactionMode, target);
      if (!target) return;

      // 类别判定
      // 处理多选择 标记category
      if (isValidArr(target._objects, 1)) {
        target.category = `collection`;
      }

      // 直接选中背景层的情况下 隐藏关联的文字层
      // 20220210 由于要做组操作 就将背景层改为无法直接选中 只能通过图层及文字层选中 所以这里的代码可以注销
      if (target.category === `segment` && target.rel) {
        const relObj = this.canvasRef.handler.getObjects().find(e => e.id === target.rel);
        if (relObj) relObj.set(`visible`, false);
      }

      // 展示元素组件
      this.canvasRef.handler.widgetContextmenuHandler.show(e, target);
      // 模式判定
      // 激活 这个是保证在下面模式下 依然可见的激活对象
      if ([`patch`, `draw`, `eraser`].includes(interactionMode)) this.canvasRef.handler.canvas.setActiveObject(target);
      else if (interactionMode === `brush`) this.canvasRef.handler.brushHandler.paste(target);

      // 更新state的editing
      this.changeEditing(target);
      // if (![`patch`].includes(interactionMode)) this.changeEditing(target)
    },
    onModified: debounce((target, triggerFunc) => {
      // console.log(`onModified`, target, triggerFunc)
      // this.canvasRef.handler.elementRangerHandler.hide();
      // this.canvasRef.handler.widgetContextmenuHandler.show({}, target);
      this.canvasRef.handler.transactionHandler.save(`modified`);
      this.setState({changed: true});
      this.changeEditing(target);
    }, 0),
    onBlur: (enter) => {
      console.log(`onBlur`, enter);
      this.canvasRef.handler.interactionHandler.selection();
      this.canvasRef.handler.elementRangerHandler.hide();
      this.canvasRef.handler.widgetContextmenuHandler.hide();
      this.canvasRef.handler.selectorContextmenuHandler.hide();
      // 这里延迟是因为修改文字会有延迟 在点击escape的情况下 会再次触发onModified 赋值editing
      this.changeEditing(null);
      // setTimeout(() => this.changeEditing(null), 150);
    },
    onContext: (ref, event, target) => {
      return <ContextComp event={event} target={target} canvas={this.canvasRef?.canvas}
                          handler={this.canvasRef?.handler} />
    },
    onSelectorContext: (ref, event, target) => {
      return <SelectorContextComp loading={this.state.ocring}
                                  event={event} target={target} canvas={this.canvasRef?.canvas}
                                  handler={this.canvasRef?.handler} />
    },
    onWidgetContext: (ref, event, target) => {
      const {editing} = this.state;
      return <ContextWidgetNewComp loading={this.state.ocring}
                                   editing={editing}
                                   event={event} target={target} canvas={this.canvasRef?.canvas}
                                   handler={this.canvasRef?.handler} />
    },
    onElementRanger: (ref, event, target) => {
      return <ElementRangerComp loading={this.state.ocring}
                                event={event} target={target} canvas={this.canvasRef?.canvas}
                                handler={this.canvasRef?.handler} />
    },
    onTransaction: (transaction, lastActiveObject) => {
      if (!lastActiveObject) return;
      const ao = this.canvasRef.handler.getObjects().find(e => e.id === lastActiveObject.id);
      if (!ao) return;
      this.canvasRef.handler.canvas.setActiveObject(ao);
      this.canvasRef.handler.canvas.requestRenderAll();
      // 处理自由画笔模式下 撤销后 画笔消失的问题
      const {interactionMode} = this.state;
      if ([`draw`, `patch`, `eraser`].includes(interactionMode)) {
        this.canvasRef.handler[`${interactionMode}Handler`].pen();
      }
    },
    onBrushChange: async (key, val) => {
      let {brush} = this.state;
      Object.assign(brush, {[key]: val})
      this._update({brush});
      await this.canvasRef?.handler?.dbHandler?.set(`brush`, key, val);
    },
    onTranslated: ao => {
      // console.log(`onTranslated`, ao)
    },
    onLocked: async (ao) => {
      // console.log(`onLocked`, ao)
      this.setState({changed: true})
      this.canvasRef.handler.canvas.requestRenderAll();
    },
    onFilled: async (ao) => {
      this.canvasRef.handler.transactionHandler.save('fill');
    },
    onHideAll: (visible) => {
      this.setState({visibleEls: visible})
    },
    onPageTranslated: ao => {
      this.setState({translating: false, visible: false});
      message.success(`成功！`)
      // console.log(`onTranslated`, ao)
    },
    onPatterned: obj => {
      // console.log(`onPatterned`, obj);
      setTimeout(() => {
        obj.setCoords();
        obj.set(`dirty`, true);
        this.canvasRef.handler.canvas.renderAll()
      }, 0)
    },
    onClipped: obj => {
      // console.log(`onPatterned`, obj);
    }
  };

  changeEditing = editing => this.setState({editing});

  /**
   * @description 同时更新handler和state
   * @param state
   * @private
   */
  _update = (state) => {
    if (!state) return;
    this.setState(state,
      // () => console.log(`state`, this.state)
    );
    for (let k in state) {
      if (state.hasOwnProperty(k) && this.canvasRef) this.canvasRef.handler[k] = state[k];
    }
    // console.log(`handler`, this.canvasRef?.handler)
  }

  _guideCallback = (data) => {
    const {action, index, type, status} = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      this.canvasRef.handler.changeOpenGuide(`welcome_guide_open`, false)
    }
  }

  render() {
    const {id, editing, interactionMode, outsideDisabled, zoomRatio, ocring, visibleEls} = this.state;

    const {
      onModeChange,
      onAdd,
      onBlur,
      onContext,
      onSelectorContext,
      onElementRanger,
      onWidgetContext,
      onModified,
      onRemove,
      onSelect,
      onZoom,
      onTransaction,
      onDrew,
      onErased,
      onFilled,
      onPatching,
      onPatched,
      onBrushChange,
      onExport,
      onTranslated,
      onLocked,
      onCut,
      onPageTranslated,
      onPageCut,
      onHideAll,
      onResized,
      onPatterned
    } = this.canvasHandlers;
    const {onState, onInitConfig, onDBChange} = this.pageHandler;

    return (
      <Layout style={{minHeight: "100vh", overflow: `hidden`}}>

        <Joyride run={this.state.welcome_guide_open}
                 styles={{
                   options: {
                     width: 500,
                     zIndex: 99999,
                   }
                 }}
          // disableOverlay
                 locale={{
                   back: `上一个`,
                   close: `关闭`,
                   last: `最后`,
                   next: `下一个`,
                   skip: `跳过`
                 }}
                 showProgress
                 disableOverlayClose
                 continuous={true}
                 callback={this._guideCallback}
                 steps={steps({handler: this.canvasRef?.handler})}
        />

        <Spin style={{zIndex: ocring ? 9999 : -1}} id={`rd-spin`} />

        <HeaderNewComp handler={this.canvasRef?.handler} interactionMode={interactionMode} zoomRatio={zoomRatio} />

        <Layout style={{zIndex: 100, position: `relative`}}>
          <AsideNewComp interactionMode={interactionMode}
                        handler={this.canvasRef?.handler}
                        editing={editing}
                        loading={this.state.loading}
                        targetLanguage={this.state.tar_lang}
                        pageHandler={this.pageHandler}
                        appHandler={this.appHandler}
          />

          <Content>
            {
              this.state.tool_mode_guide_open &&
              <GuideToolWidget interactionMode={interactionMode} handler={this.canvasRef?.handler} />
            }
            <MainNewComp ref={c => this.canvasRef = c}
                         editable
                         id={id}
                         targetURL={this.state.imageUrl}
                         outsideDisabled={outsideDisabled}
                         onModeChange={onModeChange}
                         onAdd={onAdd}
                         onDrew={onDrew}
                         onErased={onErased}
                         onBlur={onBlur}
                         onContext={onContext}
                         onSelectorContext={onSelectorContext}
                         onWidgetContext={onWidgetContext}
                         onElementRanger={onElementRanger}
                         onModified={onModified}
                         onFilled={onFilled}
                         onRemove={onRemove}
                         onSelect={onSelect}
                         onZoom={onZoom}
                         onTransaction={onTransaction}
                         onState={onState}
                         onPatched={onPatched}
                         onPatching={onPatching}
                         onInitConfig={onInitConfig}
                         onBrushChange={onBrushChange}
                         onExport={onExport}
                         onDBChange={onDBChange}
                         onTranslated={onTranslated}
                         onPageTranslated={onPageTranslated}
                         onPageCut={onPageCut}
                         onLocked={onLocked}
                         onCut={onCut}
                         onResized={onResized}
                         onHideAll={onHideAll}
                         onPatterned={onPatterned}
                         keyEvent={Object.assign({}, defaultKeyboardEvent)}
                         zoomRatio={zoomRatio}
                         visibleEls={visibleEls}
                         interactionMode={interactionMode}
                         targetLanguage={this.state.tar_lang}
            />

          </Content>

          <RsideNewComp fonts={this.state.fonts}
                        handler={this.canvasRef?.handler}
                        editing={editing}
                        targetLanguage={this.state.tar_lang}
                        interactionMode={interactionMode}
                        brush={this.state.brush}
                        autoTranslate={this.state.auto_translate}
                        translating={this.state.translating}
                        redo={this.state.redo}
                        undo={this.state.undo}
                        textboxDefaultActiveKey={this.state.textbox_default_active_key}
                        imageDefaultActiveKey={this.state.image_default_active_key}
                        shapeDefaultActiveKey={this.state.shape_default_active_key}
                        collectionDefaultActiveKey={this.state.collection_default_active_key}
          />
          <div id="ranger" />
        </Layout>

        <Modal title="一键整图翻译"
               visible={this.state.visible}
               onOk={() => this.pageHandler.visibleToggle(`visible`, false)}
               onCancel={() => this.pageHandler.visibleToggle(`visible`, false)}
               destroyOnClose
               footer={false}
        >
          <PageTranslateComp loading={this.state.translating} tarLang={this.state.tar_lang}
                             handler={this.canvasRef?.handler} />
        </Modal>

        <Modal title="整图抠图"
               visible={this.state.visible_cut}
               onOk={() => this.pageHandler.visibleToggle(`visible_cut`, false)}
               onCancel={() => this.pageHandler.visibleToggle(`visible_cut`, false)}
               destroyOnClose
               footer={false}
        >
          <PageCutComp loading={this.state.cutting}
                       handler={this.canvasRef?.handler} />
        </Modal>

        <PanelTextboxComp targetLanguage={this.state.tar_lang}
                          handler={this.canvasRef?.handler}
                          editing={editing}
        />
      </Layout>
    )
  }

  _fabric_textbox_property_set = () => {

    window.onbeforeunload = function () {
      return "If you reload this page, your previous action will be repeated";
    }

    fabric.Canvas.prototype.getAbsoluteCoords = function (object) {
      const zoom = this.getZoom();
      if (!object) return {
        left: 0, top: 0
      }
      return {
        left: (object.left * zoom + this._offset.left),
        top: (object.top * zoom + this._offset.top),
        width: object.scaleX * object.width * zoom,
        height: object.scaleY * object.height * zoom
      };
    }

    // fabric.Textbox.prototype.set({
    //   _getNonTransformedDimensions() { // Object dimensions
    //     return new fabric.Point(this.width, this.height).scalarAdd(this.padding);
    //   },
    //   _calculateCurrentDimensions() { // Controls dimensions
    //     return fabric.util.transformPoint(this._getTransformedDimensions(), this.getViewportTransform(), true);
    //   }
    // });

    // fabric.Object.NUM_FRACTION_DIGITS = 2;

    fabric.FreeTextbox = fabric.util.createClass(fabric.Textbox, {
      type: 'FreeTextbox',
      _dimensionAffectingProps: fabric.IText.prototype._dimensionAffectingProps.slice(0),
    });

    // fabric.FreeTextbox.async = true;

    fabric.FreeTextbox.fromObject = function (option, callback) {
      let text = option.text || ``;
      let custom = new fabric.FreeTextbox(text, option);
      callback && callback(custom);
      return custom;
    };

    fabric.disableStyleCopyPaste = true;
  }

}