import { fabric } from 'fabric';

export default function calc (width, height, text) {
    let currentSize;
    let currentTextJson;
    let currentHeight;
    let currentWidth;
    let minSize = 10;
    let maxSize = 200;
    let rectArea = width * height;
    let options = {
        width,height,
    }

    while (maxSize - minSize > 1) {
      currentSize = parseInt(minSize + (maxSize - minSize) / 2);

      options.fontSize = currentSize
      let textBox = new fabric.Textbox(text, options);
      const currentArea = textBox.getScaledWidth() * textBox.getScaledHeight();
      if (currentArea > rectArea) {
        maxSize = currentSize;
        options.fontSize = minSize;
        textBox = new fabric.Textbox(text, options);
        currentHeight = textBox.height;
        currentWidth = textBox.width;
        currentTextJson = textBox.toJSON();
      }else if (currentArea === rectArea){
        currentHeight = textBox.height;
        currentWidth = textBox.width;
        currentTextJson = textBox.toJSON();
        minSize = currentSize;
        break
      }else{
        currentHeight = textBox.height;
        currentWidth = textBox.width;
        currentTextJson = textBox.toJSON();
        minSize = currentSize;
      }
    }
  
    currentSize = minSize;
  
    // 如果文字区域宽度超过排版区域宽度，则 重新定位fontSize，使currentWidth刚好不大于排版区域宽度
    if (currentWidth > width) {
      options.fontSize = currentSize;
      let textBox = new fabric.Textbox(text, options);
      // 最大循环次数，防止死循环
      let maxLoopNum = 100;
  
      // 从大到小重新定位 fontSize
      while (textBox.width > width && maxLoopNum > 0) {
        currentSize--;
        maxLoopNum--;
        options.fontSize = currentSize;
        textBox = new fabric.Textbox(text, options);
      }
      // 若字号 <= 0，返回之前计算得到的数据
      if (currentSize > 0) {
        currentWidth = textBox.width;
        currentHeight = textBox.height;
        currentTextJson = textBox.toJSON();
      } else {
        currentSize = minSize;
      }
  
    }

    return currentSize
}