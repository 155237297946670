import React from "react";
import { Layout, Button, Tooltip, Tag, Divider, Slider } from "antd";
import Mcon from "@mdi/react"
import {
  mdiPlus,
  mdiMinus, mdiUndo, mdiRedo,
} from "@mdi/js";

import { getOS } from "../../../../../../utils"
import { BrandComp, ToolPanelListItemNewComp, ToolPanelListNewComp } from "../../index"
import { headerToolConfig } from "../../../config"

const keyName = "tool.new.header";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;


export default function Header({handler, zoomRatio = 1, interactionMode}) {

  const isCropping = [`ocr`, `cut`].includes(interactionMode) || false;

  const compHandler = {
    enable: () => handler?.onState({outsideDisabled: false}),
    disabled: () => handler?.onState({outsideDisabled: true}),
  }

  const shortMainKeyName = getOS() === `MacOS` ? `⌘` : `Ctrl`;

  return (
    <Layout.Header theme={`light`} className={`cp-${klassName}`}
      onMouseEnter={compHandler?.disabled}
      onMouseLeave={compHandler?.enable}
    >
      <div className="inner">

        <div className="brand-area">
          <BrandComp/>
        </div>

        <div className="tool-area">
          <div className="inner">
            <ToolPanelListNewComp handler={handler} interactionMode={interactionMode} zoomRatio={zoomRatio} />
          </div>
        </div>

        <div className="transaction-area">
          <div className="inner">
            <ToolPanelListItemNewComp disabled={isCropping || !handler?.transactionHandler.undos.length} item={{
              icon: mdiUndo,
              title: `回退`,
              key: `undo`,
              shortcut: `${shortMainKeyName} + Z`,
              click: () => {
                handler.canvas.discardActiveObject();
                handler.onBlur();
                handler.transactionHandler.undo()
              }
            }}/>

            <ToolPanelListItemNewComp disabled={isCropping || !handler?.transactionHandler.redos.length} item={{
              icon: mdiRedo,
              title: `重做`,
              key: `redo`,
              shortcut: `${shortMainKeyName} + Shift + Z`,
              click: () => {
                handler.canvas.discardActiveObject();
                handler.onBlur();
                handler.transactionHandler.redo()
              }
            }} />
          </div>
        </div>
      </div>
    </Layout.Header>
  );
}
