import React from "react"
import { Popover } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiHexagon,
  mdiHexagonOutline, mdiCheckerboard, mdiSync, mdiBackspace, mdiUndo
} from "@mdi/js";
import { SketchPicker } from "react-color";
import { hexToRgb } from "../../../../utils";

const keyName = "tool.new.image.operation";
const klassName = keyName.replace(/\./g, '-');
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;

export default function ImageBackground({handler, backgroundColor}) {
  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">

        <ToolPanelListItemNewComp
          item={{
            icon: mdiSync,
            title: `重置`,
            click: e => handler.segmentHandler.reset()
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiUndo,
            title: `回退`,
            click: e => handler.transactionHandler.undo()
          }}
          handler={handler}
          active={false}
        />

      </div>
    </div>
  )
}