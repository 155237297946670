import React from "react";

const keyName = "tool.brand";
const klassName = keyName.replace(/\./g, '-');

export default function Brand(props) {
  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <img className="brand-logo" src="https://img-stage.oss-cn-shanghai.aliyuncs.com/imgs/logo_picpaw.svg" alt="" />
        <div className="brand-text">
          <img className="float-left mb-1" height={20} src="/images/picpaw_txt_logo_cn.svg" alt="" />
          <span>Trial <small>v2.4.9</small></span>
        </div>
      </div>
    </div>
  );
}
