import { v4 } from "uuid";
import { fabric } from "fabric"
import { map, sortBy, maxBy } from "lodash";
import { defaultTextboxOptions, formatOptions, uploadOptions } from "../config"
import { ToCDB, ToDBC } from "../utils"

class ContentHandler {
  constructor(handler) {
    this.handler = handler;
  }

  isChinese(str) {
    return escape(str).indexOf("%u") > -1;
  }

  /**
   * @description 合并文字
   */
  merge = (target) => {
    let activeObject = target || this.handler.canvas.getActiveObject(); // 多个Klass
    if (activeObject && activeObject.type === `activeSelection`) {
      let activeSelection = activeObject.getObjects();
      let width = maxBy(activeSelection, `width`)[`width`]
      let length = activeSelection.length;
      let originLines = [];
      let origin = ``;
      let textLines = map(sortBy(activeSelection, [`top`, `left`]), `text`);
      let text = textLines.join("");
      let firstObject = activeSelection[0];
      let outHeight = activeObject.height;
      let inSingleHeight = firstObject.fontSize * firstObject.lineHeight;
      let inHeight = inSingleHeight * length;

      let lineHeight = activeObject.lineHeight || ((outHeight - inHeight) / (length - 1) + inSingleHeight) / inSingleHeight;

      let format = {};
      for (let k of formatOptions) {
        format[k] = firstObject[k]
      }
      for (let k of uploadOptions) {
        format[k] = firstObject[k]
      }
      let options = {
        ...defaultTextboxOptions,
        ...format,

        top: activeObject.top,
        left: activeObject.left,
        lineHeight,
        width,
        text,
        textLines,
        origin,
        originLines,
        id: v4(),
      }
      let newObject = this.handler.add(options);
      for (let obj of activeSelection) {
        this.handler.remove(obj)
      }
      this.handler.canvas.bringToFront(newObject);
      this.handler.canvas.setActiveObject(newObject);
      this.handler.canvas.requestRenderAll();
    }
  }

  // 拆分
  split = () => {
    let activeObject = this.handler.canvas.getActiveObject();
    if (activeObject && activeObject.type !== `activeSelection`) {
      if (!activeObject.textLines || activeObject.textLines.length < 1) return;
      let format = {};
      for (let k of formatOptions) {
        format[k] = activeObject[k]
      }
      for (let k of uploadOptions) {
        format[k] = activeObject[k]
      }
      let length = activeObject.textLines.length;
      let baseTop = activeObject.top;
      let height = activeObject.fontSize * activeObject.lineHeight;
      let space = (activeObject.height - height * length) / (length - 1);
      let lineHeight = activeObject.lineHeight;
      let addObjects = [];

      activeObject.textLines.forEach((t, ti) => {
        let originLines = activeObject.originLines && activeObject.originLines[ti];
        let origin = activeObject.originLines && activeObject.originLines[ti];
        let textLines = [t]
        let text = t;

        let options = {
          ...defaultTextboxOptions,
          ...format,

          top: Math.floor(baseTop + (height + space) * ti),
          left: activeObject.left,
          lineHeight,
          text,
          textLines,
          origin,
          originLines,
          id: v4(),
        }
        let newObject = this.handler.add(options);
        addObjects.push(newObject);
      });
      this.handler.remove(activeObject);
      let sel = new fabric.ActiveSelection(addObjects, {canvas: this.handler.canvas});
      this.handler.canvas.setActiveObject(sel);
      this.handler.canvas.requestRenderAll();
    }
  }

  // 去除空格
  clear = () => {
    let activeObject = this.handler.canvas.getActiveObject();
    if (!activeObject || activeObject.type === `activeSelection`) return;
    let text = activeObject.text;
    if (!text || text.length === 0) return;

    let _text = ``;
    for (let i = 0, l = text.length; i < l; i++) {
      if (i === 0 || i === l) _text += text[i];
      else {
        if (this.isChinese(text[i - 1]) && this.isChinese(text[i + 1])) {
          if (text[i] !== ` `) _text += text[i];
        } else {
          _text += text[i];
        }
      }
    }

    this.handler.setByObject(activeObject, `text`, _text);
  }

  // 全角转半角
  toCDB = (dir) => {
    let activeObject = this.handler.canvas.getActiveObject();
    if (!activeObject || activeObject.type === `activeSelection`) return;
    let text = activeObject.text;
    if (!text || text.length === 0) return;
    let str = text;
    if (dir === `CDB`) str = ToCDB(text);
    else str = ToDBC(text);

    this.handler.setByObject(activeObject, `text`, str);

  }

  superScript() {
    let activeObject = this.handler.canvas.getActiveObject();
    if (!activeObject || activeObject.type === `activeSelection`) return;
    activeObject.setSuperscript();
    this.handler.canvas.requestRenderAll();
  }

  subScript() {
    let activeObject = this.handler.canvas.getActiveObject();
    if (!activeObject || activeObject.type === `activeSelection`) return;
    activeObject.setSubscript();
    this.handler.canvas.requestRenderAll();
  }

  removeScript() {
    let activeObject = this.handler.canvas.getActiveObject();
    if (!activeObject || activeObject.type === `activeSelection`) return;
    activeObject.setSelectionStyles({
      fontSize: undefined,
      deltaY: undefined,
    });
    this.handler.canvas.requestRenderAll();
  }
}

export default ContentHandler