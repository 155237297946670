export const isSplitByGrapheme = async (str) => {
  let text = str.replace(/\r\n|\n|\r/g, ' ');
  let cp = 0, ep = 0;
  for (let i = 0, l = text.length; i < l; i++) {
    if (isChinese(text[i])) cp++;
    else ep++
  }
  return cp > ep
}

export const isChinese = (str) => {
  return escape(str).indexOf("%u") > -1
}

export const invertHex = (hex) => {
  return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
}

export const RGBToHex = (rgb) => {
  if (!rgb) return null;
  var regexp = /[0-9]{0,3}/g;
  var re = rgb.match(regexp);//利用正则表达式去掉多余的部分，将rgb中的数字提取
  var hexColor = "#";
  var hex = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F"];
  for (var i = 0; i < re.length; i++) {
    var r = null, c = re[i], l = c;
    var hexAr = [];
    while (c > 16) {
      r = c % 16;
      c = (c / 16) >> 0;
      hexAr.push(hex[r]);
    }
    hexAr.push(hex[c]);
    if (l < 16 && l != "") {
      hexAr.push(0);
    }
    hexColor += hexAr.reverse().join("");
  }
  //alert(hexColor)
  return hexColor;
};

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
};

export const HexToRGBA = (hex, alpha) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
    a: alpha || 1
  } : null;
};

// 全角转半角
export const ToCDB = (str) => {
  let tmp = "";
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) == 12288) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
      continue;
    }
    if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
    } else {
      tmp += String.fromCharCode(str.charCodeAt(i));
    }
  }
  return tmp
}

// 半角转全角
export const ToDBC = (str) => {
  let tmp = "";
  for (let i = 0; i < str.length; i++) {

    if (str.charCodeAt(i) == 32) { // 处理空格
      // tmp = tmp + String.fromCharCode(12288);
      tmp += String.fromCharCode(str.charCodeAt(i));
    } else if (str.charCodeAt(i) < 127) {
      if (
        (str.charCodeAt(i) >= 48 && str.charCodeAt(i) <= 57) ||
        (str.charCodeAt(i) >= 65 && str.charCodeAt(i) <= 90) ||
        (str.charCodeAt(i) >= 97 && str.charCodeAt(i) <= 122)
      ) {
        tmp += String.fromCharCode(str.charCodeAt(i));
      } else
        tmp = tmp + String.fromCharCode(str.charCodeAt(i) + 65248);
    } else {
      tmp += String.fromCharCode(str.charCodeAt(i));
    }
  }
  return tmp;
}

/**
 * 网络图片转base64
 * @param url
 * @return {Promise<unknown>}
 */
export const imageURL2Base64 = (url) => {
  if (!url) return;
  return new Promise(resolve => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
      let reader = new FileReader();
      reader.onload = function (event) {
        let res = event.target.result;
        resolve(res);
      }
      let file = this.response;
      reader.readAsDataURL(file)
    };
    xhr.send()
  })
}