import { message } from "antd";
import { fabric } from "fabric";
import { v4 } from "uuid";
import { defaultTextboxOptions, lockOptions, PAGE_SCALE_BALANCE } from "../config";
import { isSplitByGrapheme } from "../utils";
import CanvasObject from "./CanvasObjectCreate";
import REQUEST from "../../../../utils/requewt"
import { API } from "../../../../config"
import calc from "../../../../utils/calc.fontSize";

class OcrHandler {
  constructor(handler) {
    this.handler = handler
  }

  select = {
    start: opt => {
      const ao = this.handler.canvas.getActiveObject();
      if (ao) return;

      this.lastPosX = opt.pointer.x;
      this.lastPosY = opt.pointer.y;
      this.handler.isCorpping = true;
    },

    end: opt => {
      const zoom = this.handler.canvas.getZoom();
      const {viewportTransform} = this.handler.canvas;
      this.thisPosX = opt.pointer.x;
      this.thisPosY = opt.pointer.y;

      let l = 0, t = 0, w = 0, h = 0;
      // 如果结束的点 在 开始的点 左上方 即 thisPos < lastPos
      if (this.thisPosX <= this.lastPosX) l = Math.round((this.thisPosX - viewportTransform[4]) / zoom);
      else l = Math.round((this.lastPosX - viewportTransform[4]) / zoom);
      if (this.thisPosY <= this.lastPosY) t = Math.round((this.thisPosY - viewportTransform[5]) / zoom);
      else t = Math.round((this.lastPosY - viewportTransform[5]) / zoom);
      w = Math.round(Math.abs(this.thisPosX - this.lastPosX) / zoom);
      h = Math.round(Math.abs(this.thisPosY - this.lastPosY) / zoom);

      const selector = this.getSelector();
      if (selector) this.handler.canvas.remove(selector);

      // 不足选区 取消选择
      if (w < 10 || h < 10) return this.handler.interactionHandler.selection();
      // if (w < 10 || h < 10) return;

      // 防止出现白边
      if (w % 2 !== 0) w += 1;
      if (h % 2 !== 0) h += 1;

      this.handler.isCorpping = false;

      let holderOptions = {
        width: w,
        height: h,
        left: l,
        top: t,
        ignoreTransaction: true,
        ...CanvasObject.selector.options
      }

      const holderKlass = new fabric.Rect(holderOptions);

      this.handler.canvas.add(holderKlass);
      holderKlass.setCoords();
      this.handler.canvas.setActiveObject(holderKlass);
      this.handler.canvas.bringToFront(holderKlass);

      this.handler.selectorContextmenuHandler.show(opt.e, holderKlass);
    }
  }

  start(opt) {
    let aObj = this.handler.canvas.getActiveObject();

    if (!aObj && !opt.target) {
      this.lastPosX = opt.pointer.x;
      this.lastPosY = opt.pointer.y;
      this.handler.isCorpping = true;

    }
  }

  confirmEnd(opt) {
    let zoom = this.handler.canvas.getZoom();
    const {viewportTransform} = this.handler.canvas;
    this.thisPosX = opt.pointer.x;
    this.thisPosY = opt.pointer.y;

    let l = 0, t = 0, w = 0, h = 0;
    // 如果结束的点 在 开始的点 左上方 即 thisPos < lastPos
    if (this.thisPosX <= this.lastPosX) l = Math.round((this.thisPosX - viewportTransform[4]) / zoom);
    else l = Math.round((this.lastPosX - viewportTransform[4]) / zoom);
    if (this.thisPosY <= this.lastPosY) t = Math.round((this.thisPosY - viewportTransform[5]) / zoom);
    else t = Math.round((this.lastPosY - viewportTransform[5]) / zoom);
    w = Math.round(Math.abs(this.thisPosX - this.lastPosX) / zoom);
    h = Math.round(Math.abs(this.thisPosY - this.lastPosY) / zoom);

    const selector = this.getSelector();
    if (selector) this.handler.canvas.remove(selector);

    if (w < 10 || h < 10) return this.handler.interactionHandler.selection();
    // if (w < 10 || h < 10) return;

    if (w % 2 !== 0) w += 1;
    if (h % 2 !== 0) h += 1;

    this.handler.isCorpping = false;

    let holderOptions = {
      width: w,
      height: h,
      left: l,
      top: t,
      ignoreTransaction: true,
      ...CanvasObject.selector.options
    }

    const holderKlass = new fabric.Rect(holderOptions);

    this.handler.canvas.add(holderKlass);
    holderKlass.setCoords();
    this.handler.canvas.setActiveObject(holderKlass);
    this.handler.canvas.bringToFront(holderKlass);

    this.handler.selectorContextmenuHandler.show(opt.e, holderKlass);
  }

  // 这是确认后ocr
  ocr() {
    const target = this.handler.canvas.getActiveObject();
    if (!target) return this.clear();
    const {onOcr} = this.handler;
    fabric.util.loadImage(this.handler.canvas.bg, async (imgElement) => {
      const {left: l, top: t, width: w, height: h, scaleX: sx, scaleY: sy} = target;
      let holderOptions = {
        width: w * sx,
        height: h * sy,
        left: l,
        top: t,
        ...CanvasObject.selector.options,
        ...lockOptions
      }

      const fillOptions = {
        source: imgElement,
        repeat: 'no-repeat',
        offsetX: -l,
        offsetY: -t,
        // 重要 不然会出现白色边
        left: 0,
        top: 0,
        ignoreTransaction: true,
      }
      // let holderKlass = new fabric.Rect(holderOptions);
      const holderKlass = new fabric.Rect(holderOptions);
      holderKlass.fill = new fabric.Pattern(fillOptions);

      this.handler.canvas.add(holderKlass);
      this.handler.canvas.setActiveObject(holderKlass);
      holderKlass.setCoords();
      // console.log(`holder`, holderKlass);
      // console.log(`fill`, fillKlass, fillKlass.width, fillKlass.height);
      // this.handler.canvas.sendBackwards(holderKlass);
      // this.handler.bringToFront(holderKlass);
      // this.handler.canvas.setActiveObject(holderKlass);
      // return
      // 开始OCR
      // return console.log(holderKlass.toDataURL({format: `jpeg`, quality: 1}));
      this.handler.onState({ocring: true});
      const holderDataURL = holderKlass.toDataURL({format: `jpeg`, quality: 1});
      // console.log(holderDataURL);
      let {text: resText, shieldTxt, shields, transTxt, bgDataURL, layout} = await this.ocrPic(holderDataURL) || {};

      const {width = 0, height = 0, left = 0, top = 0} = layout || {};

      // console.log(`fontSize`, fontSize);
      this.handler.onState({ocring: false});
      if (!resText) {
        message.warning(`未能识别到文字`)
        this.handler.canvas.remove(holderKlass);
        return
      }
      // console.log(bgDataURL);
      const fontSize = resText ? calc(width, height, resText) : 30;

      let textKlass, segmentKlass;
      try {
        let splitByGrapheme = await isSplitByGrapheme(transTxt || resText);
        let resultTxt = resText.replace(/\r\n|\n|\r/g, ' ');
        let inheritOptions = this.handler.dbHandler.read(`format`);

        const txtID = v4(), segID = v4();

        // 建立涂抹层
        segmentKlass = this.handler.add(Object.assign({},
          CanvasObject.segment.options,
          {
            width: w * sx,
            height: h * sy,
            left: l,
            top: t,
            src: bgDataURL,
            rel: txtID,
            id: segID,
            evented: true,
            // selectable: true
          }
        ));

        // console.log(`segmentKlass`, segmentKlass.selectable);

        // 建立文字层
        textKlass = this.handler.add(Object.assign({},
          // CanvasObject.FreeTextbox.options,
          CanvasObject.textbox.options,
          {
            width: w * sx,
            height: h * sy,
            left: l + left,
            top: t + top,
            text: transTxt || resultTxt, // 处理FreeTextbox
            origin: resultTxt,
            shields,
            id: txtID,
            rel: segID,
            ...inheritOptions,
            fontSize: fontSize, // TODO 需要计算
            splitByGrapheme,
          }
        ));
        // 防止框选失效
        if (transTxt) {
          // this.handler.setByObject(textKlass, `text`, transTxt);
          // setTimeout(() => textKlass.text = transTxt, 100);
          textKlass.origin = resultTxt;
        } else {
          // setTimeout(() => textKlass.text = resultTxt, 100);
          // this.handler.setByObject(textKlass, `text`, resultTxt);
        }

      } catch (e) {
        console.log(`e`, e)
      }

      textKlass.setControlVisible(`mt`, false);
      textKlass.setControlVisible(`mb`, false);
      this.handler.canvas.remove(holderKlass);
      this.handler.canvas.bringToFront(textKlass);
      this.handler.canvas.sendToBack(segmentKlass);
      this.handler.canvas.setActiveObject(textKlass);
      let klass = textKlass;
      // 如果需要翻译
      // if (auto_translate) klass = await eventer.translateEventer.transTextBox(textKlass)
      if (onOcr) onOcr(klass);
    });

  }

  end(opt) {
    const {onOcr} = this.handler;

    let zoom = this.handler.canvas.getZoom();

    let l = (this.lastPosX - this.handler.canvas.viewportTransform[4]) / zoom,
      t = (this.lastPosY - this.handler.canvas.viewportTransform[5]) / zoom,
      w = Math.abs(opt.pointer.x - this.lastPosX) / zoom,
      h = Math.abs(opt.pointer.y - this.lastPosY) / zoom
    ;
    if (w < 10 || h < 10) return this.handler.interactionHandler.selection();
    // if (w < 10 || h < 10) return;

    this.handler.isCorpping = false;

    fabric.util.loadImage(this.handler.canvas.bg, async (imgElement) => {
      let scaleLeft = l / PAGE_SCALE_BALANCE;
      let scaleTop = (t + 1) / PAGE_SCALE_BALANCE;
      let holderOptions = {
        width: w / PAGE_SCALE_BALANCE,
        height: h / PAGE_SCALE_BALANCE,
        left: l,
        top: t,
        type: `rect`,
        ignoreTransaction: true,
      }
      // let holderKlass = new fabric.Rect(holderOptions);
      let holderKlass = this.handler.add(holderOptions);
      holderKlass.fill = new fabric.Pattern({
        source: imgElement,
        repeat: 'no-repeat',
        offsetX: -scaleLeft,
        offsetY: -scaleTop,
        ignoreTransaction: true,
      });
      this.handler.canvas.sendBackwards(holderKlass);
      // this.handler.canvas.setActiveObject(holderKlass);
      // return
      // 开始OCR
      // return console.log(holderKlass.toDataURL({format: `jpeg`, quality: 1}));
      this.handler.onState({ocring: true});
      let {
        text: resText,
        transTxt, bgDataURL
      } = await this.ocrPic(holderKlass.toDataURL({format: `jpeg`, quality: 1}));
      this.handler.onState({ocring: false});
      if (!resText) {
        this.handler.canvas.remove(holderKlass);
        return
      }
      let splitByGrapheme = isSplitByGrapheme(resText);
      let text = resText.replace(/\r\n|\n|\r/g, ' ');
      let inheritOptions = this.handler.dbHandler.read(`format`)

      let textKlass = this.handler.add(Object.assign({},
        CanvasObject.FreeTextbox.options,
        {
          width: w, _width: w,
          height: h, _height: h,
          left: l,
          top: t,
          text: ``, // 处理FreeTextbox
          id: v4(),
          ...inheritOptions,
          splitByGrapheme,
          backgroundColor: `rgba(255, 255, 255, 1)`
        }
      ));
      // 防止框选失效
      if (transTxt) {
        this.handler.setByObject(textKlass, `text`, transTxt);
        textKlass.origin = text;
      } else this.handler.setByObject(textKlass, `text`, text);

      this.handler.canvas.remove(holderKlass);
      this.handler.canvas.bringToFront(textKlass);
      this.handler.canvas.setActiveObject(textKlass);
      let klass = textKlass;
      // 如果需要翻译
      // if (auto_translate) klass = await eventer.translateEventer.transTextBox(textKlass)
      if (onOcr) onOcr(klass);
    });
  }

  /**
   * ocr text from the input base64
   * @param dataURL
   * @return {Promise<{transTxt: (*|null), bgDataURL: (*|null), text: (*|null)}>}
   */
  async ocrPic(dataURL) {
    const {iid, auto_translate, tar_lang} = this.handler;
    let options = {
      method: `POST`,
      headers: {"Content-Type": "multipart/form-data"},
      body: {
        tar_lang: tar_lang,
        auto_trans: auto_translate,
        img_base64: dataURL
      }
    }
    let response = await REQUEST(API.trial.image.paint, options);
    // console.log(`ocrPic.response`, response);
    if (!response || response[`error_code`] !== 0 || response[`msg`] !== `ok`) {
      message.warning(response?.info || `OCR失败！请重试`);
      return null
    }
    return {
      layout: response.data.layout,
      text: response.data?.text || null,
      transTxt: response.data?.trans_res || null,
      bgDataURL: response.data?.inpaint_base64 || null,
      shieldTxt: response.data?.shield_result || null,
      shields: response.data?.shield_words || null,
    };
  }

  /**
   * 清除选择框
   */
  clear() {
    const {selectorContextmenuHandler} = this.handler;
    const selector = this.getSelector();
    if (selector) this.handler.canvas.remove(selector);
    if (selectorContextmenuHandler) selectorContextmenuHandler.hide();
    this.handler.canvas.requestRenderAll();
    this.handler.isCorpping = false;
  }

  /**
   * 获取选择框对象
   * @return {T}
   */
  getSelector = () => this.handler.canvas.getObjects().find(e => e.category === `selector`);

}

export default OcrHandler