import { fabric } from "fabric";
import { debounce } from "lodash";
import { v4 } from "uuid";
import CanvasObject from "./CanvasObjectCreate";
import { isValidArr } from "../../../../utils";
import { message } from "antd";

class EventHandler {
  constructor(handler) {
    this.handler = handler;
    this.attachEventListener();
  }

  /**
   * @description Attch document event
   */
  attachEventListener = () => {
    if (this.handler.editable) {
      this.handler.canvas.on({
        "text:changed": debounce(e => this.textchanged(e), 300),
        "object:modified": this.modified,
        "object:added": this.added,
        "object:moved": this.moved,
        "object:moving": this.moving,
        "object:scaling": debounce(e => this.scaling(e), 300),
        "object:rotating": this.rotating,
        "mouse:wheel": this.mousewheel,
        "mouse:down": this.mousedown,
        "mouse:move": this.mousemove,
        "mouse:up": this.mouseup,
        "mouse:over": this.mouseover,
        "mouse:out": this.mouseout,
        "path:created": this.path,
        "selection:cleared": this._selection.cleared,
        "selection:created": this._selection.created,
        "selection:updated": this._selection.updated,
      });
    } else {
      this.handler.canvas.on({
        "mouse:down": this.mousedown,
        "mouse:move": this.mousemove,
        "mouse:up": this.mouseup,
        "mouse:wheel": this.mousewheel,
        "path:created": this.path
      });
    }
    document.addEventListener('keydown', this.keydown, false);
    document.addEventListener('keyup', this.keyup, false);
    document.addEventListener('mousedown', this.onmousedown, false);
    this.handler.canvas.wrapperEl.addEventListener('contextmenu', this.contextmenu, false);
  };

  /**
   * @description Detach document event
   */
  detachEventListener = () => {
    if (this.handler.editable) {
      this.handler.canvas.off({
        "text:changed": this.textchanged,
        "object:modified": this.modified,
        "object:added": this.added,
        "object:moved": this.moved,
        "object:scaling": this.scaling,
        "object:rotating": this.rotating,
        "mouse:wheel": this.mousewheel,
        'mouse:down': this.mousedown,
        "mouse:move": this.mousemove,
        "mouse:up": this.mouseup,
        "mouse:over": this.mouseover,
        "mouse:out": this.mouseout,
        "selection:cleared": this._selection.cleared,
        "selection:created": this._selection.created,
        "selection:updated": this._selection.updated,
      });
    } else {
      this.handler.canvas.off({
        'mouse:down': this.mousedown,
        'mouse:move': this.mousemove,
        'mouse:over': this.mouseover,
        'mouse:out': this.mouseout,
        'mouse:up': this.mouseup,
        "mouse:wheel": this.mousewheel,
        "path:created": this.path
      });
      this.handler.getObjects().forEach(object => {
        object.off("mousedown", this.handler.eventHandler.object.mousedown);
      });
    }
    document.removeEventListener('keydown', this.keydown);
    document.removeEventListener('keyup', this.keyup);
    document.removeEventListener('mousedown', this.onmousedown);
    this.handler.canvas.wrapperEl.removeEventListener('contextmenu', this.contextmenu);
  };

  /**
   * 画布选中事件
   * @description 鼠标点下的一瞬间 先触发selection 再触发mousedown selection的操作可以优先于mouse
   * @type {{created: function(*): void, cleared: function(*): void, updated: function(*): void}}
   * @private
   */
  _selection = {
    cleared: opt => {
      const {onSelect, interactionMode} = this.handler;
      // console.log(`cleared`, opt)
      const {target, deselected} = opt;

      const lastSegment = isValidArr(deselected) && deselected.find(e => [`segment`, `image`].includes(e.category));

      if ([`patch`, `draw`, `eraser`].includes(interactionMode)) {
        // console.log(`涂抹及绘画模式`)

        // console.log(`lastSegment`, lastSegment)
        opt.target = lastSegment;
      } else {
        // console.log(`其他取消选择的情况`)
        if (lastSegment) {
          const relObj = this.handler.getObjects().find(e => e.id === lastSegment.rel);
          if (relObj && !relObj.visible) relObj.set(`visible`, true);
          // 查找关联的图层是否为可见
        }
      }
      if (onSelect && opt?.target) onSelect(opt);
    },
    created: opt => {
      const {onSelect, interactionMode} = this.handler;
      // console.log(`created`, opt)
      if (onSelect && opt?.target) onSelect(opt);
    },
    updated: opt => {
      const {onSelect, interactionMode} = this.handler;
      // console.log(`updated`, opt)
      if (onSelect && opt?.target) onSelect(opt);
    },
  }

  /**
   * @description Selection event on canvas
   * @param {FabricEvent} opt
   */
  selection = opt => {
    const {onSelect, interactionMode} = this.handler;
    // console.log(`selection`, opt);
    // console.log(`activeSelection`, activeSelection);
    const {target, deselected} = opt;

    // 格式刷模式
    if (interactionMode === `brush`) this.handler.brushHandler.paste(target);
    else if ([`patch`, `draw`, `eraser`].includes(interactionMode)) {
      // console.log(`lastSegment`, lastSegment)
      opt[`target`] = isValidArr(deselected) && [`image`, `segment`].includes(deselected[0]?.category) && deselected[0];
    }
    if (onSelect && opt?.[`target`]) onSelect(opt);
  };

  /**
   * @description Individual object event
   * @memberof EventHandler
   */
  object = {
    /**
     * @description Mouse down on object
     * @param {FabricEvent} opt
     */
    mousedown: opt => {
      const {target} = opt;
      if (target && target.link && target.link.enabled) {
        const {onLink} = this.handler;
        if (onLink) {
          onLink(this.handler.canvas, target);
        }
      }
    },
    /**
     * @description Mouse double click on object
     * @param {FabricEvent} opt
     */
    mousedblclick: opt => {
      const {target} = opt;
      if (target) {
        const {onDblClick} = this.handler;
        if (onDblClick) {
          onDblClick(this.handler.canvas, target);
        }
      }
    }
  };

  added = (e) => {
    // console.log(`added.e`, e)
    if (e.target.id && !e.target.ignoreTransaction && !this.handler.transactionHandler.active) {
      if (!this.handler.interactionMode === `draw` || e?.target?.category !== `segment`)
        this.handler.transactionHandler.save('add')
    }
    // if (!e.target.ignoreTransaction) this.handler.transactionHandler.updateCanvasState(this.handler.canvas, canvasConfig);
  }

  textchanged = (e) => {
    // console.log(`textchanged.e`, e)
    this.handler.widgetContextmenuHandler.show(e, e.target)
    // if (!e.target.ignoreTransaction) this.handler.transactionHandler.updateCanvasState(this.handler.canvas, canvasConfig);

    // if (e.target.id && !e.target.ignoreTransaction && !this.handler.transactionHandler.active) {
    //   console.log(`isEditing`, e.target.isEditing)
    //   this.handler.transactionHandler.save('textchanged', null, e.target);
    // }

    const {onModified} = this.handler;
    if (onModified) onModified(e.target);
  }

  moved = (e) => {
    // console.log(`moved.e`, e)
    // if (e.target.id && !e.target.ignoreTransaction && !this.handler.transactionHandler.active) {
    //   this.handler.transactionHandler.save('moved');
    // }
  }

  moving = (e) => {
    // if (e?.target?.category === `line`) {
    //   const {startPoint, endPoint, left, top, width, height} = e.target;
    //
    //   startPoint.set({left: left, top: top});
    //   endPoint.set({left: left + width, top: top + height})
    // }
    // else if (e?.target?.category === `point`) {
    //   const {line, left, top, radius, height, uid} = e.target;
    //   const {startPoint, endPoint} = line;
    //   if (startPoint.uid === uid) line.set({x1: left + radius, y1: top + radius})
    //   else line.set({x2: left + radius, y2: top + radius})
    // }
    // this.handler.elementRangerHandler.hide();
    // this.handler.canvas.requestRenderAll();
  }

  scaling = (e) => {
    const {target} = e;

    if (![`rect`, `triangle`, `circle`, `ellipse`, `textbox`].includes(target?.category)) return;

    if (!target?.id) return;
    let options = {
      scaleX: 1,
      scaleY: 1
    }

    if ([`rect`, `triangle`].includes(target?.category)) {
      Object.assign(options, {
        width: target.width * target.scaleX,
        height: target.height * target.scaleY,
      })
    } else if ([`circle`].includes(target?.category)) {
      Object.assign(options, {
        radius: target.radius * target.scaleX,
      })
    } else if ([`ellipse`].includes(target?.category)) {
      Object.assign(options, {
        rx: target.rx * target.scaleX,
        ry: target.ry * target.scaleY,
      })
    } else if ([`textbox`].includes(target?.category)) {
      let fontSize = Math.round(target.fontSize * target.scaleX);
      Object.assign(options, {
        width: target.width * target.scaleX,
        fontSize
      })

      this.handler.dbHandler.set(`format`, `fontSize`, fontSize)
    }

    target.set(options);
  }

  rotating = e => {

  }

  /**
   * @description Modified object
   * @param {Object} e
   * @returns
   */
  modified = e => {
    // console.log(`modified.e`, e)
    // const {onModified} = this.handler;
    // if (onModified) onModified(target, `eventHandler.object.modified`);
    // 处理文字框失交时 修改movement锁定的区别
    if (e?.target?.id && !e?.target?.ignoreTransaction && !this.handler.transactionHandler.active) {
      // this.handler.transactionHandler.save('modified');
    }

    const {onModified, canvas} = this.handler;
    if (onModified) onModified(e.target, `modified`);

  };

  /**
   * @description Zoom at mouse wheel
   * @param {FabricEvent<WheelEvent>} opt
   * @returns
   */
  mousewheel = (opt) => {
    const event = opt;
    const {e, pointer} = event;
    const {
      widthArea = 0,
      heightArea = 0,
      widthScaleBg = 0,
      heightScaleBg = 0
    } = this.handler.getAreaAndBackgroundSize() || {};
    this.handler.elementRangerHandler.hide();
    this.handler.widgetContextmenuHandler.hide();
    if (this.handler.interactionMode === `grab`) {
      let delta = e.deltaY, point;
      let zoomRatio = this.handler.canvas.getZoom();
      if (delta > 0) zoomRatio -= 0.05;
      else zoomRatio += 0.05;

      // 限制zoomRatio
      zoomRatio = this.handler.zoomHandler.restrictZoom(zoomRatio);

      if (widthScaleBg <= widthArea && heightScaleBg <= heightArea) {
        const center = this.handler.canvas.getCenter();
        point = new fabric.Point(center.left, center.left)
      } else point = new fabric.Point(pointer.x, pointer.y);
      this.handler.zoomHandler.zoomToPoint(point, zoomRatio);
      e.preventDefault();
      e.stopPropagation();
    } else {
      // 如果文档区域小于画布区域 就不做滚动
      if (widthScaleBg <= widthArea && heightScaleBg <= heightArea) return;
      // 处理滚动


      // return;
      let zoom = this.handler.canvas.getZoom();

      let {lastHeight, lastWidth} = this.handler.canvas;
      let canvasHeight = this.handler.canvas.height;
      // let delta = event.deltaY;
      let deltaY = e.deltaY;
      let deltaX = e.deltaX;
      // console.log(`deltaX`, deltaX, lastWidth * zoom, this.handler.canvas.viewportTransform[4]);
      if (!this || !canvasHeight || !lastHeight) return;

      if (
        // this.handler.canvas.viewportTransform[4] - deltaX > (lastWidth * zoom * .9) ||
        // this.handler.canvas.viewportTransform[4] - deltaX < -(lastWidth * zoom * .9) ||
        canvasHeight - this.handler.canvas.viewportTransform[5] + deltaY < (lastHeight * zoom * .1) ||
        this.handler.canvas.viewportTransform[5] - deltaY < -(lastHeight * zoom * .9)
      ) return;
      // this.handler.canvas.viewportTransform[5] -= delta;
      // if (lastHeight * zoom < canvasHeight) return;
      let x = this.handler.canvas.viewportTransform[4];
      let y = this.handler.canvas.viewportTransform[5] - deltaY;
      this.handler.canvas.setViewportTransform([zoom, 0, 0, zoom, x, y]);
      this.handler.canvas.requestRenderAll();
      // Copy
      e.preventDefault();
      e.stopPropagation();

      const {onScroll} = this.handler;
      if (onScroll) {
        onScroll(y);
      }
    }
  };


  /**
   * release the mouse
   * @param opt
   */
  mousedown = opt => {
    const {x, y} = opt.absolutePointer;
    const {interactionMode, onBlur} = this.handler;
    const ao = this.handler.canvas.getActiveObject();
    // console.log(`mousedown`, opt)
    // console.log(`mousedown.ao`, ao)

    // 抓取
    if (interactionMode === 'grab') {
      this.panning = true;
      return;
    }
    // ocr模式
    else if (interactionMode === `ocr`) this.handler.ocrHandler.select.start(opt);
    // 抠图
    else if (interactionMode === `cut`) this.handler.cutHandler.select.start(opt);
    // 文字模式
    else if (interactionMode === `text`) {
      this.handler.textHandler.create(opt)
      // this.handler.textHandler.start(opt);
    } else if (interactionMode === `line`) {
      this.handler.lineHandler.start(opt);
    }
    // 其他情况
    else {
      // console.log(`我在其他`)
    }

  };

  /**
   * on mouse moving
   * @param opt
   */
  mousemove = opt => {
    const event = opt;
    const {interactionMode, canvas} = this.handler;
    let {x, y} = opt.absolutePointer;
    const ao = canvas.getActiveObject();

    // console.log(this.handler.getObjects(e => e.category === `background`));

    if (interactionMode === 'grab' && this.panning) {
      this.handler.interactionHandler.moving(event.e);
      this.handler.canvas.requestRenderAll();
      // if (onBlur) onBlur();
    } else if (interactionMode === `line` && this.handler.isCorpping) {
      this.handler.lineHandler.resize(ao, x, y)
    }
    // 如果是自由绘画 需要更新cursor的位置
    else if (['draw', `patch`, `eraser`].includes(interactionMode)) {
      let cursor = this.handler.canvas
        .getObjects()
        .find(el => el.category === `cursor`);
      if (!cursor) return;

      // console.log(`pointer`, this.handler.canvas.getLocalPointer(opt));
      cursor.set(`left`, x)
      cursor.set(`top`, y)

      canvas.bringToFront(cursor);
      // this.handler.bringToFront(cursor);
      this.handler.canvas.requestRenderAll();
      this.handler.lastX = x;
      this.handler.lastY = y;
    } else {
      // 默认模式
      if (opt?.target?.id === ao?.id) {
        this.handler.widgetContextmenuHandler.show(opt.e, opt.target)
      }
    }


    // get the color array for the pixel under the mouse
  };

  mouseover = debounce(opt => {
    const {target} = opt;
    const ao = this.handler.canvas.getActiveObject();
    // console.log(`mouseover`, target);
    // console.log(`mouseover.ao`, ao);
    if (
      target?.id
      && target?.id !== ao?.id
      && target?.category !== `path`
    ) {
      // this.handler.elementRangerHandler.show(opt.e, opt.target)
      // console.log(`target`, target);
    }
  }, 100)

  mouseout = debounce(opt => {
    const {target} = opt;
    const ao = this.handler.canvas.getActiveObject();
    // console.log(`mouseout`, target);
    if (
      target?.id
      && target?.id !== ao?.id
      && target?.category !== `path`
    ) {
      // this.handler.elementRangerHandler.hide()
    }
  }, 10)

  /**
   * release the mouse
   * @param opt
   */
  mouseup = opt => {
    const {interactionMode, onBlur} = this.handler;
    const ao = this.handler.canvas.getActiveObject();
    // console.log(`isCorpping`, this.handler.isCorpping);
    const {target, e} = opt;
    if (interactionMode === 'grab') {
      this.panning = false;
    }
    // ocr模式
    else if (interactionMode === `ocr`) {
      if (this.handler.isCorpping) {
        this.handler.ocrHandler.select.end(opt)
        // this.handler.ocrHandler.end(opt)
      } else {
        this.handler.selectorContextmenuHandler.show(opt.e, target);
        this.handler.canvas.requestRenderAll();
      }
      // this.handler.ocrHandler.end(opt)
    }
    // 抠图模式
    else if (interactionMode === `cut`) {
      if (this.handler.isCorpping) {
        this.handler.cutHandler.select.end(opt)
      } else {
        this.handler.selectorContextmenuHandler.show(opt.e, target);
        this.handler.canvas.requestRenderAll();
      }
      // this.handler.ocrHandler.end(opt)
    }
    // 文字模式
    else if (interactionMode === `text` && this.handler.isCorpping) {
      this.handler.textHandler.end(opt)
    }
    // 线模式
    else if (interactionMode === `line` && this.handler.isCorpping) {
      this.handler.lineHandler.end(opt)
    }
    // 格式刷模式 多次操作
    else if (interactionMode === `brush` && this.handler.isCopping && !opt.target) {
      this.handler.brushHandler.end(opt)
    }
    // 画笔模式
    else if (interactionMode === `draw`) {
      // console.log(`mouseup.draw`, opt)
      // console.log(`mouseup.ao`, this.handler.canvas.getActiveObject())

    }
    // 其他
    else {
      if (this.handler.isCorpping) {
        this.handler.selectorContextmenuHandler.show(opt.e, target);
      }
      if (!opt?.target && !ao) {
        // console.log(`没有激活项`, ao)
        if (onBlur) onBlur(`mousedown`);
      }
      // console.log(`其他`)
    }
  };

  isChinese(str) {
    return escape(str).indexOf("%u") > -1;
  }

  /**
   * on free drawing path created
   * @param {Object} path
   */
  path = event => {
    // console.log(`path`, event)
    // console.log(`path.ao`, ao)

    let path = event.path;
    if (!path) return;
    const {interactionMode, onDrew, onErased, onPatching} = this.handler;
    // 画笔模式 将所有的path整合到一个组内
    if (interactionMode === `draw`) {
      Object.assign(path, {id: v4()}, CanvasObject[path.type].options);

      if (onDrew) onDrew(path);
    }
    // 补丁模式
    else if (interactionMode === `patch`) {
      Object.assign(path, {fill: `rgba(255, 255, 255, 1)`, stroke: `rgba(255, 255, 255, 1)`});

      if (onPatching) onPatching(path);
      // 这里处理补丁的绘画路径
    }
    // 橡皮擦
    else if (interactionMode === `eraser`) {

      if (onErased) onErased(path);
      // 这里处理补丁的绘画路径
    }
    // if (onAdd) onAdd(path);
  };

  /**
   * @description Context menu on canvas
   * @param {MouseEvent} e
   */
  contextmenu = (e) => {
    e.preventDefault();
    const {editable, onContext} = this.handler;
    if (editable && onContext) {
      const target = this.handler.canvas.findTarget(e, false);
      if (target && target.type !== 'activeSelection') {
        this.handler.select(target);
      }
      this.handler.contextmenuHandler.show(e, target);
    }
  }

  keydown = (e) => {
    const {keyEvent, editable, interactionMode, canvas, onBlur} = this.handler;
    if (!Object.keys(keyEvent).length) return;
    const hasBg = !!this.handler?.canvas?.bg;

    // console.log(`e`, e.keyCode)
    if (editable) {
      // 方向键
      if (this.handler.shortcutHandler.isArrow(e)) {
        this.arrowmoving(e);
      } else if (e.altKey) {
        // this.handler.interactionHandler.grab();
      }
      // Escape键
      else if (this.handler.shortcutHandler.isEscape(e)) {
        e.preventDefault();
        // console.log(`Escape键`)
        // 退出模式
        if ([`ocr`, `text`, `brush`, `draw`, `cut`, `patch`, `eraser`].includes(interactionMode)) {
          // console.log(`退出模式`)
          this.handler.interactionHandler.selection();
        }
        // 取消选择
        else if (interactionMode === `default`) {
          let ao = canvas.getActiveObject();
          // console.log(`取消选择`, ao)
          if (ao) {
            if (ao.isEditing) ao.exitEditing();
            canvas.discardActiveObject();
            canvas.requestRenderAll();
          }
          if (onBlur) onBlur(`keydown`)
        } else {
          console.log(`其他`)

        }
      }
      // 重做
      else if (this.handler.shortcutHandler.isMetaShiftZ(e)) {
        e.preventDefault();
        this.handler.transactionHandler.redo(e);
      }
      // 撤销
      else if (this.handler.shortcutHandler.isMetaZ(e)) {
        e.preventDefault();
        this.handler.transactionHandler.undo(e);
      }
      // 重做
      else if (this.handler.shortcutHandler.isCtrlShiftZ(e)) {
        e.preventDefault();
        this.handler.transactionHandler.redo(e);
      }
      // 撤销
      else if (this.handler.shortcutHandler.isCtrlZ(e)) {
        e.preventDefault();
        this.handler.transactionHandler.undo(e);
      }

      // 保存
      else if (this.handler.shortcutHandler.isCtrlS(e)) {
        e.preventDefault();
      }
      // OCR模式
      else if (this.handler.shortcutHandler.isC(e)) {
        const ao = this.handler.canvas.getActiveObject();
        if (!ao || ao.excludeFromIntersection) {
          e.preventDefault();
          this.handler.interactionHandler.ocr()
        }
      }
      // 抠图模式
      else if (this.handler.shortcutHandler.isX(e)) {
        const ao = this.handler.canvas.getActiveObject();
        if (!ao || ao.excludeFromIntersection) {
          e.preventDefault();
          this.handler.interactionHandler.cut()
        }
      }
        // // 画笔模式
        // else if (this.handler.shortcutHandler.isB(e)) {
        //   const ao = this.handler.canvas.getActiveObject();
        //   if (!ao || ao.excludeFromIntersection) {
        //     e.preventDefault();
        //     this.handler.interactionHandler.draw()
        //   }
        // }
      // 文字模式
      else if (this.handler.shortcutHandler.isT(e)) {
        const ao = this.handler.canvas.getActiveObject();
        if (!ao || ao.excludeFromIntersection) {
          e.preventDefault();
          this.handler.interactionHandler.text()
        }
      }
      // 空格
      else if (this.handler.shortcutHandler.isSpace(e)) {
        const ao = this.handler.canvas.getActiveObject();
        if (interactionMode !== `default` || ao) return;
        this.handler.interactionHandler.grab();
      }
      // O
      else if (this.handler.shortcutHandler.isO(e)) {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        const ao = this.handler.canvas.getActiveObject();
        if (interactionMode !== `default` || ao) return;
        this.handler.shapeHandler.add(`circle`);
      }
      // R
      else if (this.handler.shortcutHandler.isR(e)) {
        if (!hasBg) return message.warn(`尚未创建背景图片 请点击下方「插入图片」按钮上传图片`);
        const ao = this.handler.canvas.getActiveObject();
        if (interactionMode !== `default` || ao) return;
        this.handler.shapeHandler.add(`rect`);
      }
      // 删除键
      // else if (this.handler.shortcutHandler.isDelete(e)) {
      //   const ao = this.handler.canvas.getActiveObject();
      //   console.log(`ao`, ao?.isEditing);
      //   if (ao && !ao.isEditing) {
      //     this.handler.remove();
      //   }
      // }
      // // 涂抹
      // else if (this.handler.shortcutHandler.isJ(e)) {
      //   const ao = this.handler.canvas.getActiveObject();
      //   if (!ao || ao.excludeFromIntersection) {
      //     e.preventDefault();
      //     this.handler.interactionHandler.patch()
      //   }
      // }
      // else if (this.handler.shortcutHandler.isCtrlC(e)) {
      //   e.preventDefault();
      //   this.handler.copy();
      // }
      // else if (this.handler.shortcutHandler.isCtrlV(e) && !clipboard) {
      //   e.preventDefault();
      //   this.handler.paste();
      // }
      // else if (this.handler.shortcutHandler.isCtrlX(e)) {
      //   e.preventDefault();
      //   this.handler.cut();
      // }
    }
  };

  /**
   * Key up event on canvas
   *
   * @param {KeyboardEvent} _e
   */
  keyup = (_e) => {
    const {keyEvent, editable, interactionMode, canvas} = this.handler;
    if ([`grab`].includes(interactionMode)) this.handler.interactionHandler.selection();
  };

  /**
   * @description Moing object at keyboard arrow key down event
   * @param {KeyboardEvent} e
   * @returns
   */
  arrowmoving = (e) => {
    const activeObject = this.handler.canvas.getActiveObject();
    if (!activeObject) return false;
    if (e.keyCode === 38) {
      activeObject.set('top', activeObject.top - 2);
      activeObject.setCoords();
      this.handler.canvas.renderAll();
      return true;
    } else if (e.keyCode === 40) {
      activeObject.set('top', activeObject.top + 2);
      activeObject.setCoords();
      this.handler.canvas.renderAll();
      return true;
    } else if (e.keyCode === 37) {
      activeObject.set('left', activeObject.left - 2);
      activeObject.setCoords();
      this.handler.canvas.renderAll();
      return true;
    } else if (e.keyCode === 39) {
      activeObject.set('left', activeObject.left + 2);
      activeObject.setCoords();
      this.handler.canvas.renderAll();
      return true;
    }
    if (this.handler.onModified) {
      this.handler.onModified(activeObject, `eventHandler.keydown.arrowmoving`);
    }
    return true;
  };

  undo = (e) => {

  }

  onmousedown = (_e) => {
    this.handler.contextmenuHandler.hide();
  }

  /**
   * Called resize event on canvas
   *
   * @param {number} nextWidth
   * @param {number} nextHeight
   * @returns
   */
  resize = (nextWidth, nextHeight) => {
    this.handler.canvas.setWidth(nextWidth).setHeight(nextHeight);
    // this.handler.canvas.setBackgroundColor(
    //   this.handler.canvasOption.backgroundColor,
    //   this.handler.canvas.renderAll.bind(this.handler.canvas),
    // );
    // this.handler.workarea.width = nextWidth;
    // this.handler.workarea.height = nextHeight;
  };


}

export default EventHandler;
