// export var SERVER_BASE = `http://122.51.107.144:5050`
export const SERVER_BASE = `https://etool-api.miaotranslation.com`
export const SERVER_TRAIL_BASE = `https://etool-api.miaotranslation.com`

export const ALLOW_IMAGE_SIZE = 5 * 1024 * 1024;
export const ALLOW_IMAGE_FORMAT_LIST = ["image/jpeg", "image/png", "image/jpg"];

export { default as CODE_MESSAGE } from "./settings/code.error"

export const API = {
  trial: {
    text: {
      translate: `${SERVER_TRAIL_BASE}/trial/text/trans`,

      check: `${SERVER_TRAIL_BASE}/trial/text/check`,
    },

    image: {
      translate: `${SERVER_TRAIL_BASE}/trial/image/translate`,

      ocr: `${SERVER_TRAIL_BASE}/trial/image/ocr`,

      cut: `${SERVER_TRAIL_BASE}/trial/image/segment`,

      paint: `${SERVER_TRAIL_BASE}/trial/image/one_key_ocr`,

      patch: `${SERVER_TRAIL_BASE}/trial/image/inpaint`
    }
  },

  font: {
    entire: `${SERVER_BASE}/font`
  },

  text: {
    translate: `${SERVER_BASE}/text/trans`
  },

  image: {
    translate: `${SERVER_BASE}/image/translate`,

    ocr: `${SERVER_BASE}/image/ocr`,

    cut: `${SERVER_BASE}/image/segment`,

    paint: `${SERVER_BASE}/image/one_key_ocr`,

    patch: `${SERVER_BASE}/image/inpaint`
  }
}