import {
  mdiCircleDouble,
} from "@mdi/js";
import Mcon from "@mdi/react";
import { Button, InputNumber, Divider, Select, Slider, Row, Col } from "antd";
import React from "react";


const keyName = "tool.new.image.eraser";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;

export default function ToolEraser({editing, handler, brush}) {
  const {width, color} = brush || {};

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <div>
          {/*橡皮宽度*/}
          <div>
            <div className="label"><Mcon size={iconSize} path={mdiCircleDouble}/> 橡皮宽度</div>
            <Row>
              <Col span={14}>
                <Slider min={1} max={200} value={width} onChange={val => handler.drawHandler.set(`width`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={width}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={1}
                             onChange={val => handler.drawHandler.set(`width`, val)}
                />
              </Col>
            </Row>
          </div>

          <Divider />

          <Button block type="primary" onClick={e => handler.interactionHandler.selection()}>退出橡皮擦</Button>
        </div>
      </div>
    </div>
  )
}