import { fabric } from "fabric";
import { sortBy, sumBy } from "lodash";

class AlignmentHandler {
  constructor(handler) {
    this.handler = handler;
  }

  vertical = {
    distribute: () => {
      let key = `top`, attr = `height`;
      const activeObject = this.handler.canvas.getActiveObject();
      const selectionHeight = activeObject.height;
      const activeObjects = sortBy(activeObject._objects, e => e[key]);
      // 计算区域的总高
      const objectsHeight = sumBy(activeObjects, e => e[attr]);

      const selectionCounts = activeObjects.length;
      const gap = (selectionHeight - objectsHeight) / (selectionCounts - 1);
      const start = -(activeObject.height / 2);

      if (activeObject && activeObject.category === 'collection') {
        activeObjects.forEach((obj, i) => {
          // 之前对象的高度和
          let sumAttr = sumBy(activeObjects.slice(0, i), e => e[attr]);
          let sumGap = gap * i;
          let val = sumAttr + sumGap + start
          obj.set({[key]: val});
          obj.setCoords();
          this.handler.canvas.renderAll();
        });
      }
    },
    /**
     * Align top at collection
     */
    top: () => {
      const activeObject = this.handler.canvas.getActiveObject();
      if (activeObject && activeObject.category === 'collection') {
        const activeSelection = activeObject
        const activeObjectTop = -(activeObject.height / 2);
        activeSelection.forEachObject(obj => {
          obj.set({
            top: activeObjectTop,
          });
          obj.setCoords();
          this.handler.canvas.renderAll();
        });
      }
    },
    /**
     * Align middle at collection
     */
    middle: () => {
      const activeObject = this.handler.canvas.getActiveObject();
      if (activeObject && activeObject.category === 'collection') {
        const activeSelection = activeObject
        activeSelection.forEachObject(obj => {
          obj.set({
            top: 0 - ((obj.height * obj.scaleY) / 2),
          });
          obj.setCoords();
          this.handler.canvas.renderAll();
        });
      }
    },
    /**
     * Align right at collection
     */
    bottom: () => {
      const activeObject = this.handler.canvas.getActiveObject();
      if (activeObject && activeObject.category === 'collection') {
        const activeSelection = activeObject
        const activeObjectBottom = (activeObject.height / 2);
        activeSelection.forEachObject(obj => {
          obj.set({
            top: activeObjectBottom - (obj.height * obj.scaleY),
          });
          obj.setCoords();
          this.handler.canvas.renderAll();
        });
      }
    }
  }

  horizontal = {
    distribute: () => {
      let key = `left`, attr = `width`;
      const activeObject = this.handler.canvas.getActiveObject();
      const selectionHeight = activeObject.height;
      const activeObjects = sortBy(activeObject._objects, e => e[key]);
      // 计算区域的总高
      const objectsHeight = sumBy(activeObjects, e => e[attr]);

      const selectionCounts = activeObjects.length;
      const gap = (selectionHeight - objectsHeight) / (selectionCounts - 1);
      const start = -(activeObject.height / 2);

      if (activeObject && activeObject.category === 'collection') {
        activeObjects.forEach((obj, i) => {
          // 之前对象的高度和
          let sumAttr = sumBy(activeObjects.slice(0, i), e => e[attr]);
          let sumGap = gap * i;
          let val = sumAttr + sumGap + start
          obj.set({[key]: val});
          obj.setCoords();
          this.handler.canvas.renderAll();
        });
      }
    },
    /**
     * Align left at collection
     */
    left: () => {
      const activeObject = this.handler.canvas.getActiveObject();
      if (activeObject && activeObject.category === 'collection') {
        const activeSelection = activeObject
        const activeObjectLeft = -(activeObject.width / 2);
        activeSelection.forEachObject(obj => {
          obj.set({
            left: activeObjectLeft,
          });
          obj.setCoords();
          this.handler.canvas.renderAll();
        });
      }
    },

    /**
     * Align center at collection
     */
    center: () => {
      const activeObject = this.handler.canvas.getActiveObject();
      if (activeObject && activeObject.category === 'collection') {
        const activeSelection = activeObject
        activeSelection.forEachObject(obj => {
          obj.set({
            left: 0 - ((obj.width * obj.scaleX) / 2),
          });
          obj.setCoords();
          this.handler.canvas.renderAll();
        });
      }
    },

    /**
     * Align right at collection
     */
    right: () => {
      const activeObject = this.handler.canvas.getActiveObject();
      if (activeObject && activeObject.category === 'collection') {
        const activeSelection = activeObject
        const activeObjectLeft = (activeObject.width / 2);
        activeSelection.forEachObject(obj => {
          obj.set({
            left: activeObjectLeft - (obj.width * obj.scaleX),
          });
          obj.setCoords();
          this.handler.canvas.renderAll();
        });
      }
    }
  }


}

export default AlignmentHandler;
