import { textboxEnterEditingOptions } from "../config";
import { throttle } from "lodash";
import { fabric } from "fabric";

class TransactionHandler {
  constructor(handler) {
    this.handler = handler;
    this.lastActiveObject = undefined;
    if (this.handler.editable) {
      this.initialize();
    }
  }

  initialize = () => {
    this.redos = [];
    this.undos = [];
    this.state = [];
    this.active = false;
  };

  save = (type, canvasJSON, target) => {
    console.log(`%c save ${type} `, 'color: #f00; font-weight: 800;', this.handler.canvas.getActiveObject());
    this.lastActiveObject = this.handler.canvas.getActiveObject();
    if (!this.handler.keyEvent.transaction) {
      return;
    }
    try {
      if (this.state) {
        const json = JSON.stringify(this.state);
        this.redos = [];
        this.undos.push({
          type,
          json,
        });
      }
      const {objects} = canvasJSON || this.handler.canvas.toJSON(this.handler.propertiesToInclude);
      this.state = objects.filter(obj => {
        if (obj.ignoreTransaction || !obj.id) return false;

        // if (obj.isEditing) {
        //   for (const lockOptionsKey in textboxEnterEditingOptions) {
        //     if (obj.hasOwnProperty(lockOptionsKey)) obj[lockOptionsKey] = !target[lockOptionsKey]
        //   }
        // }
        return true
      });
    } catch (error) {
      console.error(error);
    }
  };

  undo = throttle(() => {
    // this.handler.interactionHandler.selection();
    const undo = this.undos.pop();
    if (!undo) {
      return;
    }
    this.redos.push({
      type: 'redo',
      json: JSON.stringify(this.state),
    });
    this.replay(undo);
  }, 100);

  redo = throttle(() => {
    // this.handler.interactionHandler.selection();
    const redo = this.redos.pop();
    if (!redo) {
      return;
    }
    this.undos.push({
      type: 'undo',
      json: JSON.stringify(this.state),
    });
    this.replay(redo);
  }, 100);

  replay = (transaction) => {
    console.log(`replay`, transaction)
    const {onTransaction} = this.handler;
    const objects = JSON.parse(transaction.json);

    this.state = objects;
    this.active = true;
    this.handler.canvas.renderOnAddRemove = false;
    this.handler.clear();
    this.handler.canvas.discardActiveObject();
    fabric.util.enlivenObjects(
      objects,
      (enlivenObjects) => {
        enlivenObjects.forEach(klass => {
          // console.log(`klass`, klass)
          const targetIndex = this.handler.canvas._objects.length;
          this.handler.canvas.insertAt(klass, targetIndex, false);
        });
        this.handler.canvas.renderOnAddRemove = true;
        this.active = false;
        this.handler.canvas.renderAll();
        this.handler.objects = this.handler.getObjects();
        if (onTransaction) onTransaction(transaction, this.lastActiveObject);
      },
      null,
    );
  };
}

export default TransactionHandler