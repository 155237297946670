import { Button, Divider } from "antd";
import Mcon from "@mdi/react";
import {
  mdiArrowCollapseDown,
  mdiArrowCollapseUp,
  mdiArrowDown,
  mdiArrowUp,
  mdiAxisArrow,
  mdiAxisArrowLock
} from "@mdi/js";
import React from "react";
import classnames from "classnames";

const keyName = "tool.new.layer.operation";
const klassName = keyName.replace(/\./g, '-');

export default function LayerOperation({editing, handler}){
  const isInvalidEditing = !editing || [`cursor`].includes(editing.category);

  return (
    <div className={`cp-${klassName}`}>

      <Divider style={{marginBottom: 0}}>图层</Divider>

      <div className="ope-list">
        <div className={classnames(`ope-item`, {disabled: isInvalidEditing})}>
          <Button type="link" disabled={isInvalidEditing} onClick={e => handler.bringForward()} icon={<Mcon path={mdiArrowUp} size={0.75}/>}/>
          <span className="text">上移</span>
        </div>
        <div className={classnames(`ope-item`, {disabled: isInvalidEditing})}>
          <Button type="link" disabled={isInvalidEditing} onClick={e => handler.bringToFront()}
                  icon={<Mcon path={mdiArrowCollapseUp} size={0.75}/>}/>
          <span className="text">置顶</span>

        </div>
        <div className={classnames(`ope-item`, {disabled: isInvalidEditing})}>
          <Button type="link" disabled={isInvalidEditing} onClick={e => handler.sendBackwards()}
                  icon={<Mcon path={mdiArrowDown} size={0.75}/>}/>
          <span className="text">下移</span>
        </div>
        <div className={classnames(`ope-item`, {disabled: isInvalidEditing})} onClick={e => handler.sendToBack()}>
          <Button type="link" disabled={isInvalidEditing}
                  icon={<Mcon path={mdiArrowCollapseDown} size={0.75}/>}/>
          <span className="text">置底</span>
        </div>

        <div className={classnames(`ope-item`, {disabled: isInvalidEditing})} onClick={e => handler.toggleLock(editing)}>
          <Button disabled={isInvalidEditing}
                  type={editing?.lock ? `primary` : `link`}
                  icon={<Mcon path={editing?.lock ? mdiAxisArrowLock : mdiAxisArrow} size={0.75}/>}/>
          <span className="text">锁定</span>
        </div>

      </div>
    </div>
  )
}