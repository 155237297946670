import { message } from "antd";
import { fabric } from "fabric";
import { v4 } from "uuid";
import { defaultTextboxOptions, lockOptions, PAGE_SCALE_BALANCE } from "../config";
import { isSplitByGrapheme } from "../utils";
import CanvasObject from "./CanvasObjectCreate";
import REQUEST from "../../../../utils/requewt"
import { API } from "../../../../config"

// 新建偏移的距离
const offsetVertical = 20, offsetHorizontal = 20;

class OcrHandler {
  constructor(handler) {
    this.handler = handler
  }

  select = {
    start: opt => {
      const ao = this.handler.canvas.getActiveObject();
      if (ao) return;

      this.lastPosX = opt.pointer.x;
      this.lastPosY = opt.pointer.y;
      this.handler.isCorpping = true;
    },
    end: opt => {
      const zoom = this.handler.canvas.getZoom();
      const {viewportTransform} = this.handler.canvas;
      this.thisPosX = opt.pointer.x;
      this.thisPosY = opt.pointer.y;

      let l = 0, t = 0, w = 0, h = 0;
      // 如果结束的点 在 开始的点 左上方 即 thisPos < lastPos
      if (this.thisPosX <= this.lastPosX) l = Math.round((this.thisPosX - viewportTransform[4]) / zoom);
      else l = Math.round((this.lastPosX - viewportTransform[4]) / zoom);
      if (this.thisPosY <= this.lastPosY) t = Math.round((this.thisPosY - viewportTransform[5]) / zoom);
      else t = Math.round((this.lastPosY - viewportTransform[5]) / zoom);
      w = Math.round(Math.abs(this.thisPosX - this.lastPosX) / zoom);
      h = Math.round(Math.abs(this.thisPosY - this.lastPosY) / zoom);

      const selector = this.getSelector();
      if (selector) this.handler.canvas.remove(selector);

      // 不足选区 取消选择
      if (w < 10 || h < 10) return this.handler.interactionHandler.selection();
      // if (w < 10 || h < 10) return;

      // 防止出现白边
      if (w % 2 !== 0) w += 1;
      if (h % 2 !== 0) h += 1;

      this.handler.isCorpping = false;

      let holderOptions = {
        width: w,
        height: h,
        left: l,
        top: t,
        ignoreTransaction: true,
        ...CanvasObject.selector.options
      }

      const holderKlass = new fabric.Rect(holderOptions);

      this.handler.canvas.add(holderKlass);
      holderKlass.setCoords();
      this.handler.canvas.setActiveObject(holderKlass);
      this.handler.canvas.bringToFront(holderKlass);

      this.handler.selectorContextmenuHandler.show(opt.e, holderKlass);
    }
  }

  start(opt) {
    let aObj = this.handler.canvas.getActiveObject();

    if (!aObj && !opt.target) {
      this.lastPosX = opt.pointer.x;
      this.lastPosY = opt.pointer.y;
      this.handler.isCorpping = true;

    }
  }

  confirmEnd(opt) {
    let zoom = this.handler.canvas.getZoom();

    const selector = this.getSelector();
    if (selector) {
      this.handler.canvas.remove(selector)
    }

    let l = Math.round((this.lastPosX - this.handler.canvas.viewportTransform[4]) / zoom),
      t = Math.round((this.lastPosY - this.handler.canvas.viewportTransform[5]) / zoom),
      w = Math.round(Math.abs(opt.pointer.x - this.lastPosX) / zoom),
      h = Math.round(Math.abs(opt.pointer.y - this.lastPosY) / zoom)
    ;
    if (w < 10 || h < 10) return this.handler.interactionHandler.selection();
    // if (w < 10 || h < 10) return;
    if (w % 2 !== 0) w += 1;
    if (h % 2 !== 0) h += 1;

    this.handler.isCorpping = false;

    let holderOptions = {
      width: w,
      height: h,
      left: l,
      top: t,
      ignoreTransaction: true,
      ...CanvasObject.selector.options
    }

    let holderKlass = new fabric.Rect(holderOptions);
    holderKlass.setCoords();
    this.handler.canvas.add(holderKlass);
    this.handler.canvas.setActiveObject(holderKlass);
    this.handler.bringToFront(holderKlass);

    this.handler.selectorContextmenuHandler.show(opt.e, holderKlass);
  }

  // 点击后
  cut() {
    const target = this.handler.canvas.getActiveObject();
    if (!target) return this.clear();
    const {onCut} = this.handler;

    fabric.util.loadImage(this.handler.canvas.bg, async (imgElement) => {
      const {left: l, top: t, width: w, height: h, scaleX: sx, scaleY: sy} = target;
      let holderOptions = {
        // 重要 不然会出现白色边
        width: w * sx,
        height: h * sy,
        left: l,
        top: t,
        ...CanvasObject.selector.options,
        ...lockOptions
      }

      const fillOptions = {
        source: imgElement,
        repeat: 'no-repeat',
        offsetX: -l,
        offsetY: -t,
        // 重要 不然会出现白色边
        left: 0,
        top: 0,
        ignoreTransaction: true,
      }
      // let holderKlass = new fabric.Rect(holderOptions);
      const holderKlass = new fabric.Rect(holderOptions);
      holderKlass.fill = new fabric.Pattern(fillOptions);

      this.handler.canvas.add(holderKlass);
      this.handler.canvas.setActiveObject(holderKlass);
      holderKlass.setCoords();
      // this.handler.bringToFront(holderKlass);
      // this.handler.canvas.setActiveObject(holderKlass);
      // return
      // 开始OCR
      // return console.log(holderKlass.toDataURL({format: `jpeg`, quality: 1}));
      this.handler.onState({ocring: true});
      let {resultURL, segmentURL} = await this.cutPic(holderKlass.toDataURL({format: `jpeg`, quality: 1})) || {};

      this.handler.onState({ocring: false});
      if (!resultURL) {
        this.handler.canvas.remove(holderKlass);
        return
      }

      const imgID = v4(), segID = v4();

      // 建立涂抹层
      let segmentKlass = this.handler.add(Object.assign({},
        CanvasObject.segment.options,
        {
          width: w,
          height: h,
          left: l,
          top: t,
          src: segmentURL,
          rel: imgID,
          id: segID,
          evented: true,
          // selectable: true
        }
      ));

      let imageKlass = this.handler.add(Object.assign({},
        CanvasObject.image.options,
        {
          width: w,
          height: h,
          left: l + offsetVertical,
          top: t + offsetHorizontal,
          id: imgID,
          rel: segID,
          src: resultURL,
        }
      ));

      this.handler.canvas.remove(holderKlass);
      this.handler.canvas.sendToBack(imageKlass);
      this.handler.canvas.sendToBack(segmentKlass);
      this.handler.canvas.setActiveObject(imageKlass);

      // 如果需要翻译
      // if (auto_translate) klass = await eventer.translateEventer.transTextBox(textKlass)
      if (onCut) onCut(imageKlass);
    });

  }

  /**
   * ocr text from the input base64
   * @param dataURL
   * @return {Promise<void>}
   */
  async cutPic(dataURL) {
    let options = {
      method: `POST`,
      headers: {"Content-Type": "multipart/form-data"},
      body: {
        img_base64: dataURL
      }
    }
    // let response = await REQUEST(API.image.cut, options);
    let response = await REQUEST(API.trial.image.cut, options);
    console.log(`cutPic.response`, response);
    if (!response || response[`error_code`] !== 0 || response[`msg`] !== `ok`) {
      message.warning(response?.info || `抠图失败！请重试`);
      return null
    }
    return {
      resultURL: response.data?.img_base64,
      segmentURL: response.data?.inpaint_base64,
    };
  }

  /**
   * 清除选择框
   */
  clear() {
    const selector = this.getSelector();
    if (selector) {
      this.handler.canvas.remove(selector);
      this.handler.selectorContextmenuHandler.hide();
    }
    this.handler.canvas.requestRenderAll();
    this.handler.isCorpping = false;
  }

  /**
   * 获取选择框对象
   * @return {T}
   */
  getSelector = () => this.handler.canvas.getObjects().find(e => e.category === `selector`);

}

export default OcrHandler