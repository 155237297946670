import React, { useEffect, useState } from "react";
import { Layout, Button, Radio, Tooltip, Space, Select, Form } from "antd";
import { getOS, filter } from "../../../../../../../utils";
import Languages from "../../../../assets/languages_map.json";
import { fabric } from "fabric";

const shortKeyName = getOS() === `MacOS` ? `⌘` : `Ctrl`;

const keyName = "tool.new.page.translate";
const klassName = keyName.replace(/\./g, '-');

export default function PageTranslate(props) {
  const {editing, handler, interactionMode, tarLang, brush, loading, autoTranslate, fonts, undo, redo} = props;
  const {canvas} = handler || {};
  const {lastWidth: w = 0, lastHeight: h = 0} = canvas || {};
  const languages = Object.values(Languages);
  const [white, setWhite] = useState(undefined);
  const [black, setBlack] = useState(undefined);

  useEffect(() => {
    console.log(`生成背景色`);
    const whiteURL = new fabric.Rect({width: w, height: h, fill: `#fff`}).toDataURL({width: w, height: h});
    const blackURL = new fabric.Rect({width: w, height: h, fill: `#000`}).toDataURL({width: w, height: h});
    setWhite(whiteURL);
    setBlack(blackURL);
  }, [])

  const onFinish = async (values) => {
    values = filter(values);
    await handler.pageCut(values)
  }

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <h4>整图范围抠出商品（<span>*抠图不会去除文字</span>）</h4>
        <Form
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            src: ``
          }}
        >
          <Form.Item
            label="填充背景颜色"
            name="src"
          >
            <Radio.Group name="backgroundColor" optionType="button" buttonStyle="solid">
              <Radio.Button value={``}>无填充</Radio.Button>
              <Radio.Button value={white}>白色</Radio.Button>
              <Radio.Button value={black}>黑色</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Button htmlType={`submit`} loading={loading} block type={`primary`}>执行</Button>
        </Form>

      </div>
    </div>
  )
}