import React, { useState } from "react";
import classnames from "classnames";
import Mcon from "@mdi/react";
import {
  mdiDeleteVariant,
  mdiCheck,
  mdiResize
} from "@mdi/js";
import { Space, Button, Divider, Tooltip } from "antd";
import {PanelTextboxComp} from "../../panel"

const compKey = `tool.new.widget.context`
const compKlass = compKey.replace(/\./g, '-');

export default function WidgetContextComp({handler, target, loading, event, editing}) {

  if (!target) return null;

  const compHandler = {
    enable: () => {
      handler.onState({outsideDisabled: false})
    },
    disabled: () => {
      handler.onState({outsideDisabled: true})
    },
  }

  let relObj = handler.getObjects().find(e => e.id === target.rel);

  const {interactionMode} = handler;
  return (
    <div className={classnames(`cp-${compKlass}`)}>
      <Space
        className="inner"
        onMouseEnter={compHandler.disabled}
        onMouseLeave={compHandler.enable}
      >
        {/*文字层*/}
        {
          [`textbox`].includes(target.category)  && <>
            <Space style={{width: `100%`}} align={`start`}>
              {/*<PanelTextboxComp editing={editing} handler={handler} />*/}
              {
                target?.isEditing &&
                <Button icon={<Mcon path={mdiCheck} size={0.75}/>} type={`primary`} onClick={e => {
                  target.exitEditing();
                  handler.widgetContextmenuHandler.show({}, target);
                }}/>
              }

              {
                relObj && relObj.id &&
                <>
                  <Button type={`primary`} icon={<Mcon path={relObj?.icon} size={0.75}/>}
                          onClick={e => handler.progressModify()}>
                    优化{relObj?.title}
                  </Button>
                </>
              }

            </Space>
          </>
        }

        {/*图片*/}
        {
          [`image`, `segment`].includes(target.category) &&
          <>
            <Button icon={<Mcon path={mdiDeleteVariant} size={0.75}/>} danger type={`primary`}
                    onClick={e => handler.remove()}/>

            {
              [`segment`].includes(target.category) &&
              <Button type={`primary`} icon={<Mcon path={mdiResize} size={0.75}/>}
                      onClick={e => handler.segmentHandler.resize.start(e)}>
                重新选区
              </Button>
            }

            {
              relObj && relObj.id &&
              <>
                <Button type={`primary`} icon={<Mcon path={relObj?.icon} size={0.75}/>}
                        onClick={e => handler.progressModify()}>
                  优化{relObj?.title}
                </Button>
              </>
            }
          </>
        }

        {/*形状*/}
        {
          [`circle`, `triangle`, `rect`].includes(target.category) &&
          <>
            <Button icon={<Mcon path={mdiDeleteVariant} size={0.75}/>} danger type={`primary`}
                    onClick={e => handler.remove()}/>
          </>
        }
      </Space>
    </div>
  )
}