import {
  mdiArrowCollapseDown,
  mdiArrowCollapseUp, mdiArrowDown,
  mdiArrowUp,
  mdiAxisArrow, mdiAxisArrowLock,
} from "@mdi/js";
import React, { useEffect } from "react";
import { Button, List, Divider, Space, Tooltip, Popconfirm, Input } from "antd";
import { reverse } from "lodash"
import Mcon from "@mdi/react"
import useState from "react-usestateref"
import LayerItem from "./layer.item"

const keyName = "tool.layer";
const klassName = keyName.replace(/\./g, '-');

export default function Layer({data, handler, editing}) {

  const isInvalidEditing = !editing || [`cursor`].includes(editing.category);

  const [layers, setLayers] = useState([]);
  const [visible, setVisible] = useState(undefined);

  useEffect(() => {
    setLayers(reverse(data))
  }, [data])


  const compHandler = {
    select: item => {
      if (item.excludeFromIntersection) return;
      handler.select(item)
    },
    title: {
      change: (e, item) => {
        item.display = e.target.value;
      },
      visible: v => {
        setVisible(v)
      }
    },
  }

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <div className="layer-operation">

          <Divider style={{margin: `5px 0`}}>图层</Divider>
          <Button.Group>
            <Tooltip title={`上移一层`}>
              <Button disabled={isInvalidEditing} onClick={e => handler.bringForward()}
                      icon={<Mcon path={mdiArrowUp} size={0.75}/>}/>
            </Tooltip>
            <Tooltip title={`置于顶层`}>
              <Button disabled={isInvalidEditing} onClick={e => handler.bringToFront()}
                      icon={<Mcon path={mdiArrowCollapseUp} size={0.75}/>}/>
            </Tooltip>
            <Tooltip title={`下移一层`}>
              <Button disabled={isInvalidEditing} onClick={e => handler.sendBackwards()}
                      icon={<Mcon path={mdiArrowDown} size={0.75}/>}/>
            </Tooltip>
            <Tooltip title={`置于底层`}>
              <Button disabled={isInvalidEditing} onClick={e => handler.sendToBack()}
                      icon={<Mcon path={mdiArrowCollapseDown} size={0.75}/>}/>
            </Tooltip>
            <Divider type={`vertical`}/>

            {/*<Tooltip placement={`right`} title={`删除所有图层`}>*/}
            {/*  <Popconfirm disabled={!isValidArr(handler?.getObjects())} title="确定删除所有图层么？" onConfirm={e => handler.clear()}>*/}
            {/*    <Button disabled={!isValidArr(handler?.getObjects())}*/}
            {/*            icon={<Mcon path={mdiDeleteAlertOutline} size={0.75} />} />*/}
            {/*  </Popconfirm>*/}
            {/*</Tooltip>          */}

            <Tooltip placement={`right`} title={editing?.lock ? `位置解锁` : `位置锁定`}>
              <Button onClick={e => handler.toggleLock(editing)}
                      disabled={isInvalidEditing}
                      type={editing?.lock ? `primary` : `default`}
                      icon={<Mcon path={editing?.lock ? mdiAxisArrowLock : mdiAxisArrow} size={0.75}/>}/>
            </Tooltip>

          </Button.Group>
        </div>

        <List dataSource={layers}
              size={`small`}
              rowKey={`id`}
              className="layer-list"
              renderItem={item => <LayerItem handler={handler}
                                             visible={visible}
                                             editing={editing} item={item}
                                             compHandler={compHandler}/>}
        />
      </div>
    </div>
  );
}
