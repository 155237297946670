import { v4 } from "uuid";
import { fabric } from "fabric";
import CanvasObject from "./CanvasObjectCreate";
import { angle, calcAngle } from "../../../../utils";

class LineHandler {
  constructor(handler) {
    this.handler = handler;

    this.line = null
  }

  async start(opt) {
    let aObj = this.handler.canvas.getActiveObject();

    const {x, y} = opt.absolutePointer;
    this.handler.isCorpping = true;
    const lineKlass = await this.make(x, y);
    this.line = lineKlass;
    lineKlass.setControlVisible(`tr`, false);
    lineKlass.setControlVisible(`tl`, false);
    lineKlass.setControlVisible(`bl`, false);
    lineKlass.setControlVisible(`br`, false);
    lineKlass.setControlVisible(`mt`, false);
    lineKlass.setControlVisible(`mb`, false);
    // 添加开始点
    // let startKlass = await this._makePoint(lineKlass.x1, lineKlass.y1, `start`);
    // lineKlass.startPoint = startKlass;
    // startKlass.line = lineKlass;

    this.handler.canvas.add(lineKlass);
    this.handler.canvas.setActiveObject(lineKlass);
    this.handler.canvas.requestRenderAll();

    // if (!aObj && !opt.target) {
    //   this.lastPosX = opt.pointer.x;
    //   this.lastPosY = opt.pointer.y;
    //   this.handler.isCorpping = true;
    // }
  }

  async end(opt) {
    const {onAdd} = this.handler;

    const {x1, y1, x2, y2} = this.line;

    const dx = x2 - x1, dy = y2 - y1;
    const dz = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
    const cx = x1 + dx / 2, cy = y1 + dy / 2;
    const sx = cx - dz / 2, ex = cx + dz / 2;
    const sy = cy, ey = sy;

    let angle = calcAngle(x1, y1, x2, y2);

    this.line.set({
      x1: sx,
      y1: sy,
      x2: ex,
      y2: ey,
      angle
    })

    // 计算线条的角度

    this.handler.isCorpping = false
    this.handler.interactionHandler.selection();

    // 添加结束点
    // let endKlass = await this._makePoint(this.line.x2, this.line.y2, `end`);
    // endKlass.line = this.line;
    // this.handler.canvas.add(endKlass);
    // this.line.endPoint = endKlass;

    if (onAdd) onAdd(this.line);
    this.line = null;
  }

  make(x, y) {
    return new Promise(resolve => {
      let inheritOptions = this.handler.dbHandler.read(`line`);
      //Inside the Promise, draw the actual line from (x,y) to (x2,y2)
      resolve(new fabric.Line([x, y], {
        ...CanvasObject.line.options,
        stroke: 'rgb(63, 81, 181)',
        strokeWidth: 5,
        id: v4(),
        ...inheritOptions,
      }));
    });
  }

  resize(obj, x, y) {
    obj.set({
      x2: x,
      y2: y
    }).setCoords();

    this.handler.canvas.requestRenderAll();

    return new Promise(resolve => {
      resolve(obj);
    });
  }

  _makePoint(x, y, pos) {
    return new Promise(resolve => {
      let options = {
        radius: 6,
        left: x,
        top: y,
        editable: true,
        selectable: true,
        category: `point`,
        fill: `red`,
        uid: v4(),
      }
      if (pos === `start`) Object.assign(options, {originX: `left`, originY: `top`});
      else Object.assign(options, {originX: `left`, originY: `top`});
      //Inside the Promise, draw the actual line from (x,y) to (x2,y2)
      resolve(new fabric.Circle(options));
    });
  }
}

export default LineHandler