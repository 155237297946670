import { Input, Tooltip } from 'antd';
import React from 'react';

export default class NumericInput extends React.Component {
  onChange = e => {
    const {value} = e.target;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    let {value, onBlur, onChange} = this.props;
    value = value.toString();
    let valueTemp = value.toString();
    if (value && value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(Number(valueTemp.replace(/0*(\d+)/, '$1')));
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const {value} = this.props;
    return (
      <Input
        {...this.props}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholder="Input a number"
        maxLength={25}
      />
    );
  }
}