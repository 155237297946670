import {Typography, Input, Button, Select, Form} from "antd";
import React, {useEffect, useRef} from "react";
import useState from "react-usestateref"
import Languages from "../../assets/languages_map.json";

const {Paragraph} = Typography

const compKey = `tool.pdf.tool.text.input`
const compKlass = compKey.replace(/\./g, '-');

export default function TextInput({handler, editing, tarLang}) {
  const languages = Object.values(Languages);

  const [form] = Form.useForm();
  const [ellipsis, setEllipsis] = useState(true);
  const textareaEl = useRef(null);
  const langEl = useRef(null);

  useEffect(() => {
    form.setFieldsValue({text: editing.text})
  }, [editing.text])

  let origin = editing?.origin || editing?.text || ``;

  const _onChange = (e, key) => {
    handler.sets(key, e.target.value, editing)
  }

  return (
    <div className={`cp-${compKlass}`}>
      <div className="inner">
        <span className="label">翻译</span>
        <Button.Group>
          <Select
            ref={langEl}
            // onPressEnter={e => {
            //   if (editing?.isEditing) editing.exitEditing();
            //   langEl.current.blur();
            // }}
            onClick={e => {
              e.preventDefault();
              editing.enterEditing()
              editing.setSelectionStart(editing.text.length)
              editing.setSelectionEnd(editing.text.length)
              setTimeout(() => {
                langEl.current.focus();
              }, 0)
            }}
            showSearch
            value={tarLang || editing[`to`]}
            className="lang-select"
            placeholder="选择语言"
            optionFilterProp="children"
            onChange={val => handler.translateHandler.changeLanguage(val)}
            filterOption={(input, option) => {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          >
            {
              languages.map(lang => {
                return (
                  <Select.Option key={lang[`code`]} value={lang[`code`]}>{lang[`name_cn`]}</Select.Option>
                )
              })
            }
          </Select>
          <Button type={`primary`} onClick={e => handler.translateHandler.transTextBox()}>翻译</Button>
        </Button.Group>

        <div className="label">原文 : <span className="float-right">
                    <small><a className="ml-2"
                              onClick={e => setEllipsis(!ellipsis)}>{ellipsis ? `显示` : `隐藏`}</a></small>
        </span></div>
        <Paragraph className="origin-text" ellipsis={ellipsis}>
          {origin}
        </Paragraph>

        <div className="label">展示文字</div>

        <Form form={form}
              name="basic"
              initialValues={{
                text: editing?.text
              }}
        >
          <Form.Item name={`text`} style={{marginBottom: 10}}>
            <Input.TextArea
              ref={textareaEl}
              onChange={e => _onChange(e, `text`)}
              autoSize={{minRows: 3, maxRows: 6}}
              onClick={e => {
                e.preventDefault();
                editing.enterEditing()
                editing.setSelectionStart(editing.text.length)
                editing.setSelectionEnd(editing.text.length)
                setTimeout(() => {
                  textareaEl.current.focus();
                }, 100)
              }}
            />
          </Form.Item>

        </Form>
      </div>
    </div>
  )
}