import React from "react"
import { Button, Popover, Space, Switch, Tooltip } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiEyedropperVariant, mdiFormatRotate90,
  mdiHexagon,
  mdiHexagonOutline, mdiSquare,
} from "@mdi/js";
import { SketchPicker } from "react-color";
import Mcon from "@mdi/react";

const keyName = "tool.new.textbox.gradient";
const klassName = keyName.replace(/\./g, '-');
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;
const iconSize = .75;

export default function TextboxGradient({handler, editing}) {

  const isGradient = typeof editing?.fill === `object`;
  const gradientStartColor = isGradient ? editing?.fill?.colorStops[0].color : `#f00`;
  const gradientEndColor = isGradient ? editing?.fill?.colorStops[1].color : `#00f`;
  const gradientAngle = isGradient ? editing?.fill?.angle : 0;

  // console.log(`gradientStartColor`, gradientStartColor);
  // console.log(`gradientEndColor`, gradientEndColor);
  return (
    <div className={`cp-${klassName}`}>
      <div>
        <small className="mr-1"><b>开关</b></small>
        <Switch
          style={{margin: `5px 0 8px`}}
          checked={isGradient} onChange={e => {
          if (e) handler.gradientHandler.open();
          else handler.gradientHandler.close();
        }}/>
      </div>
      <div className="inner">

        {
          isGradient &&
          <>

            <ToolPanelListItemNewComp
              item={{
                icon: mdiHexagon,
                iconColor: gradientStartColor,
                title: `开始颜色`
              }}
              active={false}
            >
              <label onChange={e => handler.gradientHandler.change(`startColor`, e.target.value)}
              >
                <input defaultValue={gradientStartColor} type="color"
                       style={{visibility: `hidden`, position: `absolute`}}/>
              </label>
            </ToolPanelListItemNewComp>


            <ToolPanelListItemNewComp
              item={{
                icon: mdiHexagon,
                iconColor: gradientEndColor,
                title: `结束颜色`
              }}
              active={false}
            >
              <label onChange={e => handler.gradientHandler.change(`endColor`, e.target.value)}
              >
                <input defaultValue={gradientEndColor} type="color"
                       style={{visibility: `hidden`, position: `absolute`}}/>
              </label>
            </ToolPanelListItemNewComp>

            <ToolPanelListItemNewComp
              item={{
                icon: mdiFormatRotate90,
                title: `角度 ${gradientAngle}°`,
                click: e => {
                  let angle = gradientAngle + 45;
                  if (gradientAngle >= 315) angle = 0;
                  handler.gradientHandler.change(`angle`, angle)
                }
              }}
              active={false}
            />

          </>
        }
      </div>
    </div>
  )
}