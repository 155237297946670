import React from "react"
import { Popover } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiHexagon,
  mdiHexagonOutline, mdiCheckerboard
} from "@mdi/js";
import { SketchPicker } from "react-color";
import { hexToRgb } from "../../../../utils";

const keyName = "tool.new.image.background";
const klassName = keyName.replace(/\./g, '-');
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;

export default function ImageBackground({handler, backgroundColor}) {
  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagon,
            iconColor: backgroundColor,
            title: `当前`
          }}
          active={false}
        >
          <label onChange={e => {
            const {r, g, b} = hexToRgb(e.target.value);
            handler.sets(`backgroundColor`, `rgb(${r}, ${g}, ${b})`)
          }}
          >
            <input defaultValue={backgroundColor} type="color"
                   style={{visibility: `hidden`, position: `absolute`}}/>
          </label>
        </ToolPanelListItemNewComp>

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagonOutline,
            title: `白色`,
            click: e => handler.sets(`backgroundColor`, colorWhite)
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiHexagon,
            title: `黑色`,
            click: e => handler.sets(`backgroundColor`, colorBlack)
          }}
          handler={handler}
          active={false}
        />

        <ToolPanelListItemNewComp
          item={{
            icon: mdiCheckerboard,
            title: `透明`,
            click: e => handler.sets(`backgroundColor`, colorTransparent)
          }}
          handler={handler}
          active={false}
        />
      </div>
    </div>
  )
}