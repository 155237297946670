import { fabric } from "fabric";
import { v4 } from "uuid";
import CanvasObject from "./CanvasObjectCreate";

class ClipPathHandler {
  constructor(handler) {
    this.handler = handler;
  }

  open = (type) => {
    const ao = this.handler.canvas.getActiveObject();
    if (!ao?.id) return;

    const w = ao.getScaledWidth(), h = ao.getScaledHeight();

    let size = w < h ? w : h;
    let customOptions = {}
    if ([`triangle`, `rect`].includes(type)) {
      Object.assign(customOptions, {
        width: Math.round(size),
        height: Math.round(size)
      })
    } else if ([`circle`].includes(type)) {
      Object.assign(customOptions, {
        radius: Math.round(size / 2),
      })
    } else if ([`ellipse`].includes(type)) {
      Object.assign(customOptions, {
        rx: Math.round(w / 2),
        ry: Math.round(h / 2),
      })
    }
    let options = {
      left: -w / 2,
      top: -h / 2,
      ...customOptions,
    }

    let klass = CanvasObject[type].create(options);
    this._set(klass)
  }

  _set = (klass) => {
    if (!klass) return;
    this.handler.sets(`clipPath`, klass);
  }

  change = (k, v) => {
    const ao = this.handler.canvas.getActiveObject();
    if (!ao?.id || !ao?.clipPath) return;
    ao.clipPath.set(k, v);
    // ao.set(`dirty`, true);
    this.handler.sets(`dirty`, true);
    // this.handler.canvas.requestRenderAll();
  }
}

export default ClipPathHandler