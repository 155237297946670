import { fabric } from "fabric";
import React from "react";
import { Spin } from "antd";
import { v4 } from "uuid";
import OutsideClickHandler from "react-outside-click-handler";
import ResizeObserver from 'resize-observer-polyfill';
import { isValidArr } from "../../../../../utils";
import { defaultCanvasOption } from "../../config";
import { Handler } from "../../handlers";
import CanvasObject from "../../handlers/CanvasObjectCreate";
import { imageURL2Base64 } from "../../utils";
import { readImageDimensionOfURL } from "../../../../../utils";

const keyName = "tool.main";
const klassName = keyName.replace(/\./g, '-');


export default class Canvas extends React.Component {

  container = React.createRef();

  state = {
    loaded: false
  }

  componentDidMount() {
    (async () => {
      const {
        iid, editable, id, canvasOption, width = 0, height = 0, responsive, targetURL, ...other
      } = this.props;
      const mergedCanvasOption = Object.assign({}, defaultCanvasOption, {
        width, height, selection: editable
      });
      this.canvas = new fabric.Canvas(`canvas_${id}`, defaultCanvasOption);
      this.canvas.setBackgroundColor(defaultCanvasOption.backgroundColor, this.canvas.renderAll.bind(this.canvas));
      this.canvas.renderAll();

      this.handler = new Handler({
        id,
        iid,
        width,
        height,
        editable,
        canvas: this.canvas,
        container: this.container.current,
        canvasOption: mergedCanvasOption,
        mode: `default`,
        ...other,
      });
      this.createObserver();

    })()

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.width !== prevProps.width || this.props.height !== prevProps.height) {
      this.handler.eventHandler.resize(this.props.width, this.props.height);
    }
  }

  componentWillUnmount() {
    this.destroyObserver();
    this.handler.destroy();
  }

  createObserver = () => {
    this.resizeObserver = new ResizeObserver((entries = []) => {
      const {width = 0, height = 0} = (entries[0] && entries[0].contentRect) || {};
      this.handler.eventHandler.resize(width, height);
      if (!this.state.loaded) {
        this.handleLoad();
      }
    });
    this.resizeObserver.observe(this.container.current);
  };

  destroyObserver = () => {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  };

  handleLoad = () => {
    this.setState({loaded: true});
  };

  render() {
    const {id, outsideDisabled, interactionMode} = this.props;

    return (
      <div className={`cp-${klassName}`} ref={this.container}>
        <div className="inner">

          {
            !this.handler || (!isValidArr(this.handler?.canvas?._objects)) &&
            <div className="holder">
              <img className="img" src="https://img-stage.oss-cn-shanghai.aliyuncs.com/imgs/tool-holder.png" alt="" />
              <div className="txt">
                <h1>空工作台</h1>
                <span>请点击左侧「插入图片」按钮添加内容进行操作</span>
              </div>
            </div>
          }

          <OutsideClickHandler
            disabled={outsideDisabled}
            onOutsideClick={() => {
              if (!this.handler) return;
              if (![`default`].includes(interactionMode)) setTimeout(() => {
                this.handler.interactionHandler.selection();
              }, 0);
            }}
          >
            <div id="canvas-wrapper">
              <canvas id={`canvas_${id}`} />
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    );
  }
}
