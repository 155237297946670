import { v4 } from "uuid";
import CanvasObject from "./CanvasObjectCreate";

class TextHandler {
  constructor(handler) {
    this.handler = handler
  }

  create(opt){
    let zoom = this.handler.canvas.getZoom();

    let l = (opt.pointer.x - this.handler.canvas.viewportTransform[4]) / zoom,
      t = (opt.pointer.y - this.handler.canvas.viewportTransform[5]) / zoom,
      w = Math.abs(opt.pointer.x - this.lastPosX) / zoom,
      h = Math.abs(opt.pointer.y - this.lastPosY) / zoom
    ;

    let inheritOptions = this.handler.dbHandler.read(`format`);

    const {fontSize} = inheritOptions;
    let width = fontSize * 5 + 10;

    let options = {
      ...CanvasObject.textbox.options,
      left: l,
      top: t,
      width,
      height: 30,
      text: `请输入文字` || `Lorem ipsum dolor sit amet`,
      origin: `请输入文字` || `Lorem ipsum dolor sit amet`,
      id: v4(),
      ...inheritOptions,
    }
    let textKlass = this.handler.add(options);
    textKlass.setControlVisible(`mt`, false);
    textKlass.setControlVisible(`mb`, false);
    this.handler.canvas.setActiveObject(textKlass);
    this.handler.canvas.bringToFront(textKlass);
    this.handler.interactionHandler.selection();
    textKlass.enterEditing().selectAll();
  }

  start(opt) {
    let aObj = this.handler.canvas.getActiveObject();

    if (!aObj && !opt.target) {
      this.lastPosX = opt.pointer.x;
      this.lastPosY = opt.pointer.y;
      this.handler.isCorpping = true;
    }
  }

  end(opt) {
    const {onAdd} = this.handler;

    let zoom = this.handler.canvas.getZoom();

    let l = (this.lastPosX - this.handler.canvas.viewportTransform[4]) / zoom,
      t = (this.lastPosY - this.handler.canvas.viewportTransform[5]) / zoom,
      w = Math.abs(opt.pointer.x - this.lastPosX) / zoom,
      h = Math.abs(opt.pointer.y - this.lastPosY) / zoom
    ;
    if (w < 10 || h < 10) return this.handler.interactionHandler.selection();
    this.handler.isCorpping = false;

    let inheritOptions = this.handler.dbHandler.read(`format`)
    let options = {
      ...CanvasObject.textbox.options,
      left: l,
      top: t,
      width: w,
      // _width: w,
      // _height: h,
      text: `请输入文字` || `Lorem ipsum dolor sit amet`,
      // backgroundColor: `rgba(255, 255, 255, 0)`,
      // text: ``,
      id: v4(),
      ...inheritOptions,
    }
    let textKlass = this.handler.add(options);
    textKlass.setControlVisible(`mt`, false);
    textKlass.setControlVisible(`mb`, false);
    this.handler.setByObject(textKlass, `width`, w);
    this.handler.setByObject(textKlass, `height`, h);
    this.handler.canvas.setActiveObject(textKlass);
    this.handler.canvas.bringToFront(textKlass);
    this.handler.interactionHandler.selection();
    textKlass.enterEditing().selectAll();
    // target.selectionStart(0);
    // target.selectionEnd(24);
  }
}

export default TextHandler