import React from "react"
import { Row, Col, Slider, InputNumber, Checkbox, Divider, Switch, Space } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiCircleDouble, mdiColorHelper,
  mdiHexagon,
  mdiHexagonOutline, mdiWaterOutline,
} from "@mdi/js";
import { SketchPicker } from "react-color";
import { hexToRgb } from "../../../../utils";
import Mcon from "@mdi/react";

const keyName = "tool.new.textbox.border";
const klassName = keyName.replace(/\./g, '-');
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`;

export default function TextboxShadow({handler, editing}) {

  const {stroke = `rgba(0, 0, 0, 1)`, strokeWidth = 0, strokeUniform} = editing || {};
  const isShadow = typeof editing?.shadow === `object` && editing?.shadow !== null;
  const {blur, color} = editing.shadow || {};

  return (
    <div className={`cp-${klassName}`}>
      {/*<div>*/}
      {/*  <small className="mr-1"><b>开关</b></small>*/}
      {/*  <Switch*/}
      {/*    style={{margin: `5px 0 8px`}}*/}
      {/*    checked={isShadow} onChange={e => {*/}
      {/*    if (e) handler.shadowHandler.open();*/}
      {/*    else handler.shadowHandler.close();*/}
      {/*  }}/>*/}
      {/*</div>*/}

      <div className="inner">

        {
          <Space direction={`vertical`} style={{width: `100%`}}>
            {/*<div className="label"><Mcon size={.75} path={mdiCircleDouble}/> 阴影大小</div>*/}
            <Row>
              <Col span={14}>
                <Slider min={1} max={100} value={blur || 0} onChange={val => handler.shadowHandler.change(`blur`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={blur || 0}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={1}
                             onChange={val => handler.shadowHandler.change(`blur`, val)}
                />
              </Col>
            </Row>

            {/*<Divider/>*/}

            {/*<div className="label"><Mcon size={.75} path={mdiWaterOutline}/> 阴影颜色</div>*/}
            <Row>
              <Col span={8}>
                <ToolPanelListItemNewComp
                  item={{
                    icon: mdiHexagon,
                    iconColor: color,
                    title: `当前`
                  }}
                  active={false}
                >
                  <label onChange={e => {
                    const {r, g, b} = hexToRgb(e.target.value);
                    handler.shadowHandler.change(`color`, `rgb(${r}, ${g}, ${b})`)
                  }}
                  >
                    <input defaultValue={stroke} type="color"
                           style={{visibility: `hidden`, position: `absolute`}}/>
                  </label>
                </ToolPanelListItemNewComp>
              </Col>

              <Col span={8}>
                <ToolPanelListItemNewComp
                  item={{
                    icon: mdiHexagonOutline,
                    title: `白色`,
                    click: e => handler.shadowHandler.change(`color`, colorWhite)
                  }}
                  handler={handler}
                  active={false}
                />
              </Col>

              <Col span={8}>
                <ToolPanelListItemNewComp
                  item={{
                    icon: mdiHexagon,
                    title: `黑色`,
                    click: e => handler.shadowHandler.change(`color`, colorBlack)
                  }}
                  handler={handler}
                  active={false}
                />
              </Col>
            </Row>

            {/*<Row>*/}
            {/*  <Col>*/}
            {/*    <Checkbox checked={strokeUniform} onChange={e => {*/}
            {/*      editing.strokeUniform = e.target.checked*/}
            {/*    }}>缩放</Checkbox>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </Space>
        }

      </div>
    </div>
  )
}