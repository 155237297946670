import React from "react"
import { Row, Col, Slider, InputNumber, Space, Divider } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiCircleDouble, mdiColorHelper,
  mdiHexagon,
  mdiHexagonOutline, mdiWaterOutline,
} from "@mdi/js";
import { SketchPicker } from "react-color";
import { hexToRgb } from "../../../../utils";
import Mcon from "@mdi/react";

const keyName = "tool.new.textbox.border";
const klassName = keyName.replace(/\./g, '-');
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`;

export default function TextboxColor({handler, editing}) {

  const {stroke = `rgba(0, 0, 0, 1)`, strokeWidth = 0, strokeUniform} = editing || {}

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <Space direction={`vertical`} style={{width: `100%`}}>

        {/*<div className="label"><Mcon size={.75} path={mdiCircleDouble}/> 描边宽度</div>*/}
        <Row>
          <Col span={14}>
            <Slider min={1} max={100} value={strokeWidth} onChange={val => handler.sets(`strokeWidth`, val)}/>
          </Col>
          <Col span={10}>
            <InputNumber value={strokeWidth}
                         style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                         step={1}
                         onChange={val => handler.sets(`strokeWidth`, val)}
            />
          </Col>
        </Row>

        {/*<Divider />*/}

        {/*<div className="label"><Mcon size={.75} path={mdiWaterOutline}/> 描边颜色</div>*/}
        <Row>
          <Col span={8}>
            <ToolPanelListItemNewComp
              item={{
                icon: mdiHexagon,
                iconColor: stroke,
                title: `当前`
              }}
              active={false}
            >
              <label onChange={e => {
                const {r, g, b} = hexToRgb(e.target.value);
                handler.sets(`stroke`, `rgb(${r}, ${g}, ${b})`)
              }}
              >
                <input defaultValue={stroke} type="color"
                       style={{visibility: `hidden`, position: `absolute`}}/>
              </label>
            </ToolPanelListItemNewComp>
          </Col>

          <Col span={8}>
            <ToolPanelListItemNewComp
              item={{
                icon: mdiHexagonOutline,
                title: `白色`,
                click: e => handler.sets(`stroke`, colorWhite)
              }}
              handler={handler}
              active={false}
            />
          </Col>

          <Col span={8}>
            <ToolPanelListItemNewComp
              item={{
                icon: mdiHexagon,
                title: `黑色`,
                click: e => handler.sets(`stroke`, colorBlack)
              }}
              handler={handler}
              active={false}
            />
          </Col>
        </Row>
        </Space>
        {/*<Row>*/}
        {/*  <Col>*/}
        {/*    <Checkbox checked={strokeUniform} onChange={e => {*/}
        {/*      editing.strokeUniform = e.target.checked*/}
        {/*    }}>缩放</Checkbox>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </div>
    </div>
  )
}