import { fabric } from "fabric"

class GradientHandler {
  constructor(handler) {
    this.handler = handler;
  }

  open = () => {
    let ao = this.handler.canvas.getActiveObject();
    console.log(ao)
    if (!ao) return;
    let options = Object.assign({}, {
      color: 'black',
      blur: 5
    });
    const shadow = new fabric.Shadow(options);
    this.handler.sets(`shadow`, shadow);
  }

  close = (silent) => {
    this.handler.sets(`shadow`, null);
  }

  /**
   *
   * @param key
   * @param val 十六进制的颜色 或者 [linear, radial]
   */
  change = (key, val) => {
    const {onGradientModified} = this.handler;
    let ao = {...this.handler.canvas.getActiveObject()};
    if (!ao) return;
    let options = {
      ...ao.shadow,
      [key]: val
    };

    let shadow = new fabric.Shadow(options);
    this.handler.sets(`shadow`, shadow);
    ao = shadow = null;
  }

}

export default GradientHandler