import React, {useEffect} from "react";
import {Layout} from "antd";

import {BrandComp, ToolsPanel, LayerComp} from "../index"

const keyName = "tool.aside";
const klassName = keyName.replace(/\./g, '-');

export default function Aside({handler, pageHandler, appHandler, interactionMode, editing, targetLanguage, loading}) {
  // 图层数据
  const layers = handler?.getObjects() || [];

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <Layout.Sider theme={`light`}>
          <BrandComp/>

          <ToolsPanel appHandler={appHandler} pageHandler={pageHandler} handler={handler}
                      loading={loading}
                      interactionMode={interactionMode} targetLanguage={targetLanguage} />

          <LayerComp data={layers} handler={handler} editing={editing}/>
        </Layout.Sider>
      </div>
    </div>
  );
}
