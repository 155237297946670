import { fabric } from "fabric";
import "fabric/src/mixins/eraser_brush.mixin"
import initAligningGuidelines from "../../../../../../utils/aligning_guidelines"
import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ResizeObserver from 'resize-observer-polyfill';
import { isValidArr } from "../../../../../../utils";
import { defaultCanvasOption } from "../../../config";
import { Handler } from "../../../handlers";
import CanvasObject from "../../../handlers/CanvasObjectCreate";
import { imageURL2Base64 } from "../../../utils";
import { readImageDimensionOfURL } from "../../../../../../utils";
import { ZoomVerticalNewComp, ToolOperationNewComp, ToolFileInputNewComp, DisabledComp } from "../index";

const keyName = "tool.new.main";
const klassName = keyName.replace(/\./g, '-');


export default class Canvas extends React.Component {

  container = React.createRef();

  state = {
    loaded: false,
    minZoom: 10,
    maxZoom: 200
  }

  componentDidMount() {
    (async () => {
      const {
        iid, editable, id, canvasOption, width = 0, height = 0, responsive, targetURL, ...other
      } = this.props;
      const mergedCanvasOption = Object.assign({}, defaultCanvasOption, {
        width, height, selection: editable
      });
      this.canvas = new fabric.Canvas(`canvas_${id}`, defaultCanvasOption);
      this.canvas.setBackgroundColor(defaultCanvasOption.backgroundColor, this.canvas.renderAll.bind(this.canvas));
      // 引导线
      // initAligningGuidelines(this.canvas);
      this.canvas.renderAll();

      this.handler = new Handler({
        id,
        iid,
        width,
        height,
        editable,
        canvas: this.canvas,
        container: this.container.current,
        canvasOption: mergedCanvasOption,
        mode: `default`,
        ...other,
      });
      this.createObserver();

    })()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.width !== prevProps.width || this.props.height !== prevProps.height) {
      this.handler.eventHandler.resize(this.props.width, this.props.height);
    }
  }

  componentWillUnmount() {
    this.destroyObserver();
    this.handler.destroy();
  }

  createObserver = () => {
    this.resizeObserver = new ResizeObserver((entries = []) => {
      const {width = 0, height = 0} = (entries[0] && entries[0].contentRect) || {};
      this.handler.eventHandler.resize(width, height);
      if (!this.state.loaded) {
        this.handleLoad();
      }
    });
    this.resizeObserver.observe(this.container.current);
  };

  destroyObserver = () => {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  };

  handleLoad = () => {
    this.setState({loaded: true});
  };

  compHandler = {
    add: {
      image: async (url) => {
        // 去除旧的背景
        const prevLayers = this.handler.canvas.getObjects().filter(e => [`background`, `board`, `boardHeader`].includes(e.category));
        if (isValidArr(prevLayers)) {
          for (let i = 0; i < prevLayers.length; i++) {
            this.handler.remove(prevLayers[i]);
          }
          this.handler.canvas.bg = null;
          this.handler.canvas.lastHeight = 0;
        }

        const dataURL = typeof url !== `string` ? url.src : await imageURL2Base64(url);

        let {w, h} = await readImageDimensionOfURL(dataURL);

        this.handler.canvas.setBackgroundImage(dataURL, this.handler.canvas.renderAll.bind(this.handler.canvas), {
          erasable: false,
          crossOrigin: 'anonymous'
        });

        this.handler.canvas.bg = await imageURL2Base64(dataURL);
        this.handler.canvas.lastHeight = h;
        this.handler.canvas.lastWidth = w;

        const boardOptions = Object.assign({}, CanvasObject.board.options, {
          width: w + 5,
          height: h + 5,
        });

        const boardHeaderOptions = Object.assign({}, CanvasObject.boardHeader.options, {
          text: `画板`
        });

        const board = await this.handler.add(boardOptions);
        const boardHeader = await this.handler.add(boardHeaderOptions);
        this.handler.canvas.sendToBack(board);
        this.handler.canvas.sendToBack(boardHeader);
        this.handler.zoomHandler.zoomToFit();

      }
    },
    changeLanguage: val => {
      this.handler.translateHandler.changeLanguage(val)
    }
  }

  render() {
    const {id, outsideDisabled, interactionMode, zoomRatio, visibleEls, targetLanguage} = this.props;
    const {maxZoom, minZoom} = this.state;

    return (
      <div className={`cp-${klassName}`} ref={this.container}>
        <div className="inner">

          <ToolOperationNewComp handler={this.handler} visibleEls={visibleEls} interactionMode={interactionMode}/>

          <ZoomVerticalNewComp maxZoom={maxZoom} minZoom={minZoom} handler={this.handler} zoomRatio={zoomRatio}/>

          {
            !this.handler || (!isValidArr(this.handler?.canvas?._objects)) &&
            <div className="holder">
              <DisabledComp />
                {/*<ToolFileInputNewComp targetLanguage={targetLanguage} handler={this.compHandler} />*/}
              {/*<div className="txt">123</div>*/}
            </div>
          }

          <OutsideClickHandler
            disabled={outsideDisabled}
            onOutsideClick={() => {
              if (!this.handler) return;
              if (![`default`].includes(interactionMode)) setTimeout(() => {
                this.handler.interactionHandler.selection();
              }, 0);
            }}
          >
            <div id="canvas-wrapper">
              <canvas id={`canvas_${id}`}/>
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    );
  }
}
