import React from "react"
import { ToolPanelListItemNewComp } from "../../index";
import {
  mdiFountainPen, mdiGreasePencil,
} from "@mdi/js";

const keyName = "tool.new.image.paints";
const klassName = keyName.replace(/\./g, '-');

export default function ToolImagePaints({handler, editing, interactionMode}) {

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <ToolPanelListItemNewComp item={{
          icon: mdiGreasePencil,
          iconSize: 2,
          activeIcon: mdiGreasePencil,
          title: `涂抹笔`,
          click: e => handler.interactionHandler.patch()
        }} handler={handler} active={interactionMode === `patch`}/>

        <ToolPanelListItemNewComp item={{
          icon: mdiFountainPen,
          title: `画笔`,
          activeIcon: mdiFountainPen,
          iconSize: 2,
          click: e => handler.interactionHandler.draw()
        }} handler={handler} active={interactionMode === `draw`}/>

      </div>
    </div>
  )
}