import classnames from "classnames";
import Mcon from "@mdi/react";
import { Popover, Tag } from "antd";
import React from "react";
import { mdiCloseCircleOutline } from "@mdi/js";

const keyName = "tool.new.panel.item";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;

export default function ToolItem({handler, active, item, disabled, children, ...others}) {

  const {popover} = item || {};

  const content = <div className={classnames(`cp-${klassName}`, {active, disabled})}
                       onClick={disabled ? () => {
                       } : item.click}
                       {...others}
  >
    <div className="inner">

      <div className="tool-header">
        <Mcon
          color={disabled ? `#ddd` : item.iconColor || active ? item.iconColor || `#fff` : `#333`}
          path={active ? item.activeIcon || mdiCloseCircleOutline : item.icon}
          size={item.iconSize || iconSize}
        />
      </div>

      <div className="tool-content">
        <span className="text">{item.title}</span>
      </div>

      <div className="tool-footer ffNB">
        {item.shortcut && <Tag color={!disabled && `#333`} size={`small`}>{item.shortcut}</Tag>}
      </div>

      {children}
    </div>
  </div>

  return (
    popover ? <Popover {...popover}>{content}</Popover> : <>{content}</>
  )
}