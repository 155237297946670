import React, { useEffect } from "react";
import { getBrowser } from "../../../../../../../utils";


const keyName = "tool.new.browser.detection";
const klassName = keyName.replace(/\./g, '-');

export default function BrowserDetection(props) {

  const bz = getBrowser(window.navigator.userAgent);
  if ([`Chrome`, `Microsoft Edge`].includes(bz)) return null;

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        请您使用
        <a target="_blank" href="https://www.google.cn/intl/zh-CN/chrome/"><img width={18} height={18} src="https://img.miaotranslation.com/icons/chrome.png" alt=""/> 谷歌浏览器</a>
        或
        <a target="_blank" href="https://www.microsoft.com/zh-cn/edge"><img width={18} height={18} src="https://img.miaotranslation.com/icons/microsoft.png" alt=""/> 微软ME浏览器</a>
        访问本站
        <br/>
        以保证所有功能正常使用
        <br/>
        当前为{bz}浏览器
      </div>
    </div>
  )
}