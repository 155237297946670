import React from "react"
import { Space } from "antd";
import { ToolPanelListItemNewComp } from "../../index";
import { mdiDeleteVariant, mdiEraserVariant, mdiFormatText, mdiPanoramaHorizontalOutline } from "@mdi/js";

const keyName = "tool.new.textbox.progress";
const klassName = keyName.replace(/\./g, '-');

export default function ToolProgress({handler, editing, current}) {

  const hasBackground = editing.rel;
  const activeKey = editing?.progress;

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <ToolPanelListItemNewComp item={{
          icon: mdiFormatText,
          title: `文本编辑`,
          activeIcon: mdiFormatText,
          click: e => handler.progressModify()
        }} handler={handler} active={activeKey === `textbox`}/>

        {
          hasBackground &&
          <ToolPanelListItemNewComp item={{
            icon: mdiPanoramaHorizontalOutline,
            title: `背景编辑`,
            click: e => handler.progressModify()
          }} handler={handler} active={activeKey === `segment`}/>
        }


        <ToolPanelListItemNewComp item={{
          icon: mdiDeleteVariant,
          title: `删除图层`,
          click: e => handler.remove()
        }} handler={handler} active={false}/>
      </div>
    </div>
  )
}