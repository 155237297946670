import React, { useEffect } from "react";
import { Layout, Button, Divider, Tooltip } from "antd";
import { getOS } from "../../../../../../utils";
import {
  ToolImageNewComp,
  ToolDrawComp,
  ToolTextboxNewComp,
  ToolOcrComp,
  ToolPatchComp,
  ToolShapeNewComp, ToolCollectionNewComp, ToolLineNewComp
} from "../../index";
import Mcon from "@mdi/react";
import { mdiUndo, mdiRedo } from "@mdi/js";

const shortKeyName = getOS() === `MacOS` ? `⌘` : `Ctrl`;

const keyName = "tool.new.rside";
const klassName = keyName.replace(/\./g, '-');

export default function Rside(props) {
  const {
    editing,
    handler,
    interactionMode,
    targetLanguage,
    brush,
    translating,
    autoTranslate,
    fonts,
    imageDefaultActiveKey,
    textboxDefaultActiveKey,
    shapeDefaultActiveKey,
    collectionDefaultActiveKey
  } = props;
  const isCropping = [`ocr`, `cut`].includes(interactionMode) || false;

  const {width, color} = brush || {};
  const freeDrawingBrush = handler?.canvas?.freeDrawingBrush;
  // const {width: brushWidth = 5, color: brushFill = `rgba(0, 0, 0, 1)`} = freeDrawingBrush || {}

  const compHandler = {
    enable: () => handler?.onState({outsideDisabled: false}),
    disabled: () => handler?.onState({outsideDisabled: true}),
  }

  return (
    <div className={`cp-${klassName}`}
         onMouseEnter={compHandler?.disabled}
         onMouseLeave={compHandler?.enable}
    >
      <div className="inner">
        <Layout.Sider theme={`light`} width={220}>

          {
            interactionMode === `ocr` &&
            <ToolOcrComp tarLang={targetLanguage}
                         handler={handler}
                         editing={editing}
                         autoTranslate={autoTranslate}
            />
          }

          {/*画图模式 画笔对象*/}
          {/*{*/}
          {/*  interactionMode === `draw` && freeDrawingBrush &&*/}
          {/*  <ToolDrawComp handler={handler} editing={editing} brush={brush} />*/}
          {/*}*/}

          {/*{*/}
          {/*  interactionMode === `patch` && freeDrawingBrush &&*/}
          {/*  <ToolPatchComp handler={handler} editing={editing} brush={brush} />*/}
          {/*}*/}

          {/*图形对象*/}
          {
            editing && [`circle`, `rect`, `triangle`, `ellipse`].includes(editing.category) &&
            <ToolShapeNewComp interactionMode={interactionMode}
                              editing={editing}
                              handler={handler}
                              brush={brush}
                              defaultActiveKey={shapeDefaultActiveKey}
            />
          }

          {/*背景对象*/}
          {
            editing && [`image`, `segment`].includes(editing.category) &&
            <ToolImageNewComp interactionMode={interactionMode}
                              editing={editing}
                              handler={handler}
                              brush={brush}
                              defaultActiveKey={imageDefaultActiveKey}
            />
          }

          {/*文字框*/}
          {
            editing && [`FreeTextbox`, `textbox`].includes(editing.category) &&
            <ToolTextboxNewComp editing={editing}
                                fonts={fonts}
                                translating={translating}
                                handler={handler}
                                targetLanguage={targetLanguage}
                                interactionMode={interactionMode}
                                defaultActiveKey={textboxDefaultActiveKey}
            />
          }

          {
            // 多对象选择
            editing && [`line`].includes(editing.category) &&
            <ToolLineNewComp editing={editing}
                             handler={handler}
                             interactionMode={interactionMode}
                             defaultActiveKey={collectionDefaultActiveKey}/>
          }

          {
            // 多对象选择
            editing && [`collection`].includes(editing.category) &&
            <ToolCollectionNewComp editing={editing}
                                   handler={handler}
                                   interactionMode={interactionMode}
                                   defaultActiveKey={collectionDefaultActiveKey}/>
          }
        </Layout.Sider>
      </div>
    </div>
  )
}