import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';

class ContextmenuHandler {
  constructor(handle, options) {
    this.handler = handle;
    this.onContext = options.onContext;
    this.init();
  }

  /**
   * @description Init context menu
   */
  init = () => {
    this.contextmenuEl = document.createElement('div');
    this.contextmenuEl.id = `contextmenu_${this.handler.id}`;
    this.contextmenuEl.className = 'cp-tool-contextmenu contextmenu-hidden';
    document.body.appendChild(this.contextmenuEl);
  }

  /**
   * @description Show context menu
   * @memberof ContextmenuHandler
   */
  show = debounce(async (e, target) => {
    const {onContext} = this;
    while (this.contextmenuEl.hasChildNodes()) {
      this.contextmenuEl.removeChild(this.contextmenuEl.firstChild);
    }
    const contextmenu = document.createElement('div');
    contextmenu.className = 'rde-contextmenu-right';
    const element = await onContext(this.contextmenuEl, e, target);
    if (!element) {
      return;
    }
    contextmenu.innerHTML = element;
    this.contextmenuEl.appendChild(contextmenu);
    ReactDOM.render(element, contextmenu);
    this.contextmenuEl.classList.remove('contextmenu-hidden');
    const {clientX: left, clientY: top} = e;
    this.contextmenuEl.style.left = `${left}px`;
    this.contextmenuEl.style.top = `${top}px`;
  }, 200)

  /**
   * @description Hide context menu
   * @memberof ContextmenuHandler
   */
  hide = debounce(() => {
    if (this.contextmenuEl) {
      this.contextmenuEl.classList.add('contextmenu-hidden');
    }
  }, 150)

  destory = () => {
    if (this.contextmenuEl) {
      document.body.removeChild(this.contextmenuEl);
    }
  }
}

export default ContextmenuHandler;
