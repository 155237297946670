import {
  mdiCheckerboard, mdiEyedropperVariant,
  mdiFormatColorFill,
  mdiImageSizeSelectLarge, mdiOverscan,
  mdiSquare, mdiSquareOutline
} from "@mdi/js";
import Mcon from "@mdi/react";
import { InputNumber, Checkbox, Space, Button, Popover } from "antd";
import React, { useRef } from "react";
import { SketchPicker } from "react-color";
import useState from "react-usestateref";
import { HexToRGBA, RGBToHex } from "../../utils";

const keyName = "tool.tools.image";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;

export default function ToolImage({editing, handler}) {
  const {backgroundColor, uniSize} = editing || {};

  const widthEl = useRef(null);
  const heightEl = useRef(null);
  const scaleXEl = useRef(null);
  const scaleYEl = useRef(null);

  const [visible, setVisible] = useState(false);

  return (
    <div className={`cp-${klassName}`}>
      <Space direction={`vertical`} className="inner">
        <div>
          <div className="label">
            <Mcon size={iconSize} path={mdiOverscan} />
            <span>宽度 | 高度</span>
          </div>
          <Space>
            <InputNumber value={editing.width}
                         style={{width: `100%`, textAlign: 'center'}}
                         ref={widthEl}
                         onClick={e => editing.isEditing = true}
                         onPressEnter={e => {
                           if (editing?.isEditing) delete editing.isEditing;
                           widthEl.current.blur();
                         }}
                         onChange={val => {
                           handler.sets(`width`, val, editing)
                         }}
            />
            <InputNumber value={editing.height}
                         ref={heightEl}
                         onClick={e => editing.isEditing = true}
                         onPressEnter={e => {
                           if (editing?.isEditing) delete editing.isEditing;
                           heightEl.current.blur();
                         }}
                         onChange={val => {
                           handler.sets(`height`, val, editing)
                         }}

            />
          </Space>

        </div>

        <div>
          <div className="label">
            <Mcon size={iconSize} path={mdiImageSizeSelectLarge} />
            <span>横向缩放 | 纵向缩放</span>
          </div>
          <Space>
            <InputNumber value={parseFloat((editing.scaleX || 0).toFixed(2))}
                         step={0.1}
                         style={{width: `100%`, textAlign: 'center'}}
                         ref={scaleXEl}
                         onClick={e => editing.isEditing = true}
                         onPressEnter={e => {
                           if (editing?.isEditing) delete editing.isEditing;
                           scaleXEl.current.blur();
                         }}
                         onChange={val => {
                           // if (editing.category === `background`)
                           handler.sets(`scaleX`, val, editing)
                         }}
            />
            <InputNumber value={parseFloat((editing.scaleY || 0).toFixed(2))}
                         step={0.1}
                         ref={scaleYEl}
                         onClick={e => editing.isEditing = true}
                         onPressEnter={e => {
                           if (editing?.isEditing) delete editing.isEditing;
                           scaleYEl.current.blur();
                         }}
                         onChange={val => {
                           // if (editing.category === `background`)
                           handler.sets(`scaleY`, val, editing)
                         }}

            />
          </Space>

        </div>

        <div>
          <Checkbox checked={uniSize} onChange={e => {
            console.log(`e`, e.target.checked);
            editing.uniSize = e.target.checked
          }}>等比例缩放</Checkbox>
        </div>
      </Space>

      <div>
        <div className="label"><Mcon size={iconSize} path={mdiFormatColorFill} /> 背景色</div>
        <Button.Group>
          <Popover
            trigger="click"
            content={<SketchPicker
              color={backgroundColor}
              onChangeComplete={color => {
                let rgb = Object.values(color.rgb).slice(0, 3);
                handler.sets(`backgroundColor`, `rgb(${rgb})`)
              }}
            />}>
            <Button title={`当前背景颜色 ${backgroundColor}`}
                    onClick={e => setVisible(true)}>
              <Mcon size={iconSize} path={mdiSquare} color={backgroundColor} />
            </Button>
          </Popover>
          <Button
            title={`白色`}
            onClick={e => handler.sets(`backgroundColor`, `rgb(255, 255, 255)`)}>
            <Mcon size={iconSize} path={mdiSquareOutline} color={`rgb(0, 0, 0)`} />
          </Button>
          <Button title={`黑色`}
                  onClick={e => handler.sets(`backgroundColor`, `rgb(0, 0, 0)`)}>
            <Mcon size={iconSize} path={mdiSquare} color={`rgb(0, 0, 0)`} />
          </Button>
          <Button title={`透明`}
                  onClick={e => handler.sets(`backgroundColor`, `rgba(0, 0, 0, 0)`)}>
            <Mcon size={iconSize} path={mdiCheckerboard} color={`rgba(0, 0, 0, 0.35)`} />
          </Button>
          <label className="ant-btn" title={`拾色器`} onChange={e => {
            const {r, g, b} = HexToRGBA(e.target.value);
            handler.sets(`backgroundColor`, `rgb(${r}, ${g}, ${b})`)
          }}>
            <input defaultValue={backgroundColor && RGBToHex(backgroundColor)} type="color"
                   style={{visibility: `hidden`, position: `absolute`}} />
            <Mcon size={iconSize} path={mdiEyedropperVariant} color={`rgba(0, 0, 0, 1)`} />
          </label>
        </Button.Group>
      </div>

    </div>
  )
}