import {
  mdiCheckerboard, mdiContentCopy, mdiDeleteVariant,
  mdiEyedropperVariant, mdiFormatRotate90,
  mdiFormatTextbox, mdiGradientHorizontal, mdiLastpass, mdiSetMerge, mdiSetSplit,
  mdiSquare
} from "@mdi/js";
import Mcon from "@mdi/react";
import { Button, Divider, Input, InputNumber, Popover, Select, Space, Switch, Tooltip } from "antd";
import { capitalize, forEach, lowerCase, upperCase, debounce } from "lodash";
import React, { useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import useState from "react-usestateref";
import {
  defaultFontSize,
  defaultLineHeight,
  defaultPadding,
  font_202102,
  fontFree,
  fontSystem,
  languageMap
} from "../../config";
import { HexToRGBA, RGBToHex } from "../../utils";
import { ButtonGuideWidget } from "../widgets";
import { TextInputComp } from "../index";

const keyName = "tool.tools.textbox";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`;

export default function ToolTextbox({editing, handler, targetLanguage, interactionMode, fonts}) {

  const {fontSize, fontFamily, fontWeight, underline, linethrough, textAlign, fill, lineHeight, fontStyle, splitByGrapheme, backgroundColor, padding} = editing || {};

  const [visible, setVisible] = useState(false);
  const [fontsAvailable, setFontsAvailable] = useState([]);
  let container, monoWidth, sansWidth, serifWidth;
  const fontSizeEl = useRef(null);
  const lineHeightEl = useRef(null);
  const paddingEl = useRef(null);

  const compHandler = {
    enable: () => {
      handler.onState({outsideDisabled: false})
    },
    disabled: () => {
      handler.onState({outsideDisabled: true})
    },
  }

  useEffect(() => {
    let _fonts = Object.values(fontSystem).map(f => f.name);
    if (window.availableFonts) {
      setFontsAvailable(window.availableFonts)
    } else {
      container = document.createElement("span");
      container.innerHTML = Array(100).join("wi");
      container.style.cssText = [
        "position:absolute",
        "width:auto",
        "font-size:128px",
        "left:-99999px",
      ].join(" !important;");

      initialWidths();

      let detectedFonts = [];

      forEach(_fonts, (font) => {
        if (isFontAvailable(font)) {
          detectedFonts.push(font);
        }
      });

      detectedFonts = [...detectedFonts, ...font_202102];

      window.availableFonts = detectedFonts;

      setFontsAvailable(window.availableFonts)
    }
  }, [])

  const languages = Object.values(languageMap);
  const fonts_en = [
    ...Object.values(fontSystem).filter(el => el.nation === `en`).sort(),
    ...Object.values(fontFree).filter(el => el.nation === `en`).sort()
  ];
  const fonts_zh = [
    ...Object.values(fontSystem).filter(el => el.nation === `zh-CN`).sort(),
    ...Object.values(fontFree).filter(el => el.nation === `zh-CN`).sort()
  ]

  const video_guide_open = true;

  let isBold = fontWeight === `bold`;
  let isItalic = fontStyle === `italic`;
  let isUnderline = underline;
  let isLinethrough = linethrough;
  let alignment = textAlign;
  let isSplitByGrapheme = splitByGrapheme;

  const isGradient = typeof editing?.fill === `object`;
  const gradientStartColor = isGradient ? editing?.fill?.colorStops[0].color : `#f00`;
  const gradientEndColor = isGradient ? editing?.fill?.colorStops[1].color : `#00f`;
  const gradientAngle = isGradient ? editing?.fill?.angle : 0;

  const getWidth = (font) => {
    container.style.fontFamily = font;

    document.body.appendChild(container);
    const width = container.clientWidth;
    document.body.removeChild(container);

    return width;
  };

  const initialWidths = () => {
    monoWidth = getWidth("monospace");
    serifWidth = getWidth("serif");
    sansWidth = getWidth("sans-serif");
  };

  const isFontAvailable = (font) => {
    return (
      monoWidth !== getWidth(font + ",monospace") ||
      sansWidth !== getWidth(font + ",sans-serif") ||
      serifWidth !== getWidth(font + ",serif")
    );
  };

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <div className="label"><Mcon size={iconSize} path={mdiFormatTextbox} /> 内容</div>

        <TextInputComp editing={editing} handler={handler} tarLang={targetLanguage} />

        <Divider />

        {/*字体颜色*/}
        <Space>
          <div className="label">
            {/*<Mcon size={iconSize} path={mdiWaterOutline} />*/}
            字色
          </div>
          <Button.Group className={`color-group`}>
            <Popover
              trigger="click"
              content={<SketchPicker
                color={fill}
                onChangeComplete={color => {
                  let rgb = Object.values(color.rgb).slice(0, 3);
                  handler.sets(`fill`, `rgb(${rgb})`)
                }}
              />}>
              <Button title={`当前文本颜色 ${fill}`}
                      onClick={e => setVisible(true)}>
                <Mcon size={iconSize} path={mdiSquare}
                      color={fill && typeof fill !== `object` && fill || colorBlack} />
              </Button>
            </Popover>

            <Divider type={`vertical`} />

            <Button title={`黑色`}
                    onClick={e => handler.sets(`fill`, colorBlack)}>
              <Mcon size={iconSize} path={mdiSquare} color={colorBlack} />
            </Button>

            <Button title={`白色`}
                    onClick={e => handler.sets(`fill`, colorWhite)}>
              <Mcon size={iconSize} path={mdiSquare} color={colorWhite} />
            </Button>

            <label className="ant-btn" title={`拾色器`} onChange={e => {
              const {r, g, b} = HexToRGBA(e.target.value);
              handler.sets(`fill`, `rgb(${r}, ${g}, ${b})`)
            }}>
              <input defaultValue={fill && typeof fill !== `object` && RGBToHex(fill)} type="color"
                     style={{visibility: `hidden`, position: `absolute`}} />
              <Mcon size={iconSize} path={mdiEyedropperVariant} color={colorBlack} />
            </label>
          </Button.Group>
        </Space>

        <Divider />

        {/*背景颜色*/}
        <div>
          <div className="label">
            {/*<Mcon size={iconSize} path={mdiFormatColorFill} />*/}
            背景色
          </div>
          <Button.Group className={`color-group`}>
            <Popover
              trigger="click"
              content={<SketchPicker
                color={backgroundColor}
                onChangeComplete={color => {
                  let rgb = Object.values(color.rgb).slice(0, 3);
                  handler.sets(`backgroundColor`, `rgb(${rgb})`)
                }}
              />}>
              <Button title={`当前背景颜色 ${backgroundColor}`}
                      onClick={e => setVisible(true)}>
                <Mcon size={iconSize} path={mdiSquare} color={backgroundColor} />
              </Button>
            </Popover>
            <Divider type={`vertical`} />

            <Button title={`黑色`}
                    onClick={e => handler.sets(`backgroundColor`, colorBlack)}>
              <Mcon size={iconSize} path={mdiSquare} color={colorBlack} />
            </Button>

            <Button
              title={`白色`}
              onClick={e => handler.sets(`backgroundColor`, colorWhite)}>
              <Mcon size={iconSize} path={mdiSquare} color={colorWhite} />
            </Button>

            <Button title={`透明`}
                    onClick={e => handler.sets(`backgroundColor`, `rgba(0, 0, 0, 0)`)}>
              <Mcon size={iconSize} path={mdiCheckerboard} color={`rgba(0, 0, 0, 0.35)`} />
            </Button>
            <label className="ant-btn" title={`拾色器`} onChange={e => {
              const {r, g, b} = HexToRGBA(e.target.value);
              handler.sets(`backgroundColor`, `rgb(${r}, ${g}, ${b})`)
            }}>
              <input defaultValue={backgroundColor && RGBToHex(backgroundColor)} type="color"
                     style={{visibility: `hidden`, position: `absolute`}} />
              <Mcon size={iconSize} path={mdiEyedropperVariant} color={colorBlack} />
            </label>
          </Button.Group>
        </div>

        <Divider />

        <Space direction="vertical" style={{width: `100%`}}>
          <Button.Group>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={isBold}
                               type={`font.bold`}
                               onPress={e => handler.sets(`fontWeight`, isBold ? `normal` : `bold`)}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={isItalic}
                               type={`font.italic`}
                               onPress={e => handler.sets(`fontStyle`, isItalic ? `normal` : `italic`)}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={isUnderline}
                               type={`font.underline`}
                               onPress={e => handler.sets(`underline`, !isUnderline)}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={isLinethrough}
                               type={`font.linethrough`}
                               onPress={e => handler.sets(`linethrough`, !isLinethrough)}
            />
          </Button.Group>

          <Button.Group>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.capitalize`}
                               onPress={e => {
                                 let val = capitalize(editing.text);
                                 handler.sets(`text`, val)
                               }}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.upperCase`}
                               onPress={e => {
                                 let val = upperCase(editing.text);
                                 handler.sets(`text`, val)
                               }}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.lowerCase`}
                               onPress={e => {
                                 let val = lowerCase(editing.text);
                                 handler.sets(`text`, val)
                               }}
            />
          </Button.Group>

          <Button.Group>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={alignment === `left`}
                               type={`alignment.left`}
                               onPress={e => handler.sets(`textAlign`, `left`)}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={alignment === `center`}
                               type={`alignment.center`}
                               onPress={e => handler.sets(`textAlign`, `center`)}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={alignment === `right`}
                               type={`alignment.right`}
                               onPress={e => handler.sets(`textAlign`, `right`)}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={alignment === `justify`}
                               type={`alignment.justify`}
                               onPress={e => handler.sets(`textAlign`, `justify`)}
            />
          </Button.Group>

        </Space>

        <Divider />

        <Space>
          <div className="label">
            {/*<Mcon size={iconSize} path={mdiFormatFont} />*/}
            字体
          </div>
          <Button.Group title={`字体`}>
            <Select style={{width: 120}}
                    onChange={val => handler.sets(`fontFamily`, val)}
                    value={fontFamily || `SimHei`}>
              {/*<OptGroup label={`PDF内字体 可能缺失`}>*/}
              {/*  <Option value={editing.fontFamily}><span*/}
              {/*    style={{fontFamily: editing.fontFamily}}>{editing.fontFamily}</span></Option>*/}
              {/*</OptGroup>*/}
              <Select.OptGroup label={`中文字体`}>
                {
                  fonts_zh.map((font, fi) => {
                    if (!fontsAvailable.includes(font.name)) return null;
                    return <Select.Option key={fi} value={font.name}><span
                      style={{fontFamily: font.name}}>{font.title}</span></Select.Option>
                  })
                }
              </Select.OptGroup>
              <Select.OptGroup label={`英文字体`}>
                {
                  fonts_en.map((font, fi) => {
                    if (!fontsAvailable.includes(font.name)) return null;
                    return <Select.Option key={fi} value={font.name}><span
                      style={{fontFamily: font.name}}>{font.title}</span></Select.Option>
                  })
                }
              </Select.OptGroup>
            </Select>
          </Button.Group>
        </Space>

        <Divider />

        {/*文本大小*/}
        <Space>
          <div className="label">
            {/*<Mcon size={iconSize} path={mdiFormatSize} />*/}
            字号
          </div>
          <Button.Group>
            <InputNumber value={fontSize}
                         style={{width: 80, borderRadius: `0`, marginLeft: -1}}
                         min={1}
                         max={999}
                         step={1}
                         ref={fontSizeEl}
                         onClick={e => {
                           editing.enterEditing()
                           editing.setSelectionStart(editing.text.length)
                           editing.setSelectionEnd(editing.text.length)
                           setTimeout(() => {
                             fontSizeEl.current.focus();
                           }, 100)
                         }}
                         onPressEnter={e => {
                           if (editing?.isEditing) editing.exitEditing();
                           fontSizeEl.current.blur();
                         }}
                         onChange={val => handler.sets(`fontSize`, val)}
            />
            <Select className={`in-group-selector`}
                    dropdownMatchSelectWidth={false}
                    onChange={val => handler.sets(`fontSize`, val)}
                    value={fontSize}>
              {
                defaultFontSize.map((f, fi) => <Select.Option key={f} value={f}>{f}</Select.Option>)
              }
            </Select>
          </Button.Group>
        </Space>

        <Divider />

        <Space>
          <div className="label">
            {/*<Mcon size={iconSize} path={mdiFormatLineSpacing} />*/}
            行距
          </div>
          <Button.Group>

            <InputNumber value={lineHeight}
                         style={{width: 80, borderRadius: `0`, marginLeft: -1}}
                         step={0.5}
                         min={0}
                         max={99}
                         ref={lineHeightEl}
                         onPressEnter={e => {
                           if (editing?.isEditing) editing.exitEditing();
                           lineHeightEl.current.blur();
                         }}
                         onClick={e => {
                           e.preventDefault();
                           editing.enterEditing()
                           editing.setSelectionStart(editing.text.length)
                           editing.setSelectionEnd(editing.text.length)
                           setTimeout(() => {
                             lineHeightEl.current.focus();
                           }, 0)
                         }}
                         onChange={val => handler.sets(`lineHeight`, val)} />
            <Select className={`in-group-selector`}
                    dropdownMatchSelectWidth={false}
                    onChange={val => handler.sets(`lineHeight`, val)}
                    value={lineHeight}>
              {
                defaultLineHeight.map((f, fi) => <Select.Option key={f} value={f}>{f}</Select.Option>)
              }
            </Select>
          </Button.Group>
        </Space>

        <Divider />

        <Space>
          <div className="label">
            {/*<Mcon size={iconSize} path={mdiFormatLineSpacing} />*/}
            内边距
          </div>
          <Button.Group>

            <InputNumber value={padding}
                         style={{width: 80, borderRadius: `0`, marginLeft: -1}}
                         step={0.5}
                         min={0}
                         max={99}
                         ref={paddingEl}
                         onPressEnter={e => {
                           if (editing?.isEditing) editing.exitEditing();
                           paddingEl.current.blur();
                         }}
                         onClick={e => {
                           e.preventDefault();
                           editing.enterEditing()
                           editing.setSelectionStart(editing.text.length)
                           editing.setSelectionEnd(editing.text.length)
                           setTimeout(() => {
                             paddingEl.current.focus();
                           }, 0)
                         }}
                         onChange={val => handler.sets(`padding`, val)} />
            <Select className={`in-group-selector`}
                    dropdownMatchSelectWidth={false}
                    onChange={val => handler.sets(`padding`, val)}
                    value={padding}>
              {
                defaultPadding.map((f, fi) => <Select.Option key={f} value={f}>{f}</Select.Option>)
              }
            </Select>
          </Button.Group>
        </Space>

        <Divider />

        <div>
          <div className="label"><Mcon size={iconSize} path={mdiGradientHorizontal} /> 渐变色</div>

          <Space direction="vertical" style={{width: `100%`}}>
            <Switch
              style={{marginTop: 5}}
              checked={isGradient} onChange={e => {
              if (e) handler.gradientHandler.open();
              else handler.gradientHandler.close();
            }} />

            {
              isGradient &&
              <Button.Group>

                <Popover
                  trigger="click"
                  content={<SketchPicker
                    color={gradientStartColor}
                    onChangeComplete={color => handler.gradientHandler.change(`startColor`, color.hex)}
                  />}>
                  <Button title={`开始颜色`}>
                    <Mcon size={iconSize} path={mdiSquare} color={gradientStartColor} />
                  </Button>
                </Popover>
                <label className="ant-btn" title={`拾色器`}
                       onChange={e => {
                         handler.gradientHandler.change(`startColor`, e.target.value)
                       }}
                >
                  <input defaultValue={gradientStartColor} type="color"
                         style={{visibility: `hidden`, position: `absolute`}} />
                  <Mcon size={iconSize} path={mdiEyedropperVariant}
                        color={gradientStartColor || `#ff0000`}
                  />
                </label>

                <Popover
                  trigger="click"
                  content={<SketchPicker
                    color={gradientEndColor}
                    onChangeComplete={color => handler.gradientHandler.change(`endColor`, color.hex)}
                  />}>
                  <Button title={`结束颜色`}>
                    <Mcon size={iconSize} path={mdiSquare} color={gradientEndColor} />
                  </Button>
                </Popover>
                <label className="ant-btn" title={`拾色器`}
                       onChange={e => {
                         handler.gradientHandler.change(`endColor`, e.target.value)
                       }}
                >
                  <input defaultValue={gradientStartColor} type="color"
                         style={{visibility: `hidden`, position: `absolute`}} />
                  <Mcon size={iconSize} path={mdiEyedropperVariant}
                        color={gradientEndColor || `#ff0000`}
                  />
                </label>
                <Tooltip title={`${gradientAngle}°`}>
                  <Button onClick={e => {
                    let angle = gradientAngle + 45;
                    if (gradientAngle >= 315) angle = 0;
                    handler.gradientHandler.change(`angle`, angle)
                  }}>
                    <Mcon size={iconSize} path={mdiFormatRotate90} />
                  </Button>
                </Tooltip>

              </Button.Group>
            }
          </Space>

        </div>

        <Divider />

        <Space direction="vertical" style={{width: `100%`}}>

          <Button.Group>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.spaceClear`}
                               onPress={e => handler.contentHandler.clear()}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.dbc`}
                               onPress={e => handler.contentHandler.toCDB(`DBC`)}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.cdb`}
                               onPress={e => handler.contentHandler.toCDB(`CDB`)}
            />
          </Button.Group>

          <Button.Group>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.superScript`}
                               onPress={e => handler.contentHandler.superScript()}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.subScript`}
                               onPress={e => handler.contentHandler.subScript()}
            />
            <ButtonGuideWidget guideStatus={video_guide_open}
                               type={`str.removeScript`}
                               onPress={e => handler.contentHandler.removeScript()}
            />
          </Button.Group>

          <Divider />


          <Button.Group>
            <ButtonGuideWidget guideStatus={video_guide_open}
                               active={interactionMode === `brush`}
                               type={`mode.brush`}
                               onMouseEnter={compHandler.disabled}
                               onMouseLeave={compHandler.enable}
                               onPress={e => handler.interactionHandler.brush()}
            />

            <Divider type={`vertical`} />

            <Button shape={`square`} title={`复制`} onClick={e => handler.duplicate()}>
              <Mcon size={iconSize} path={mdiContentCopy} />
            </Button>

            <Divider type={`vertical`} />

            <Button shape={`square`} type={`danger`} title={`删除`} onClick={e => handler.remove(editing)}>
              <Mcon size={iconSize} path={mdiDeleteVariant} />
            </Button>
          </Button.Group>

          <Button.Group>
            <Button shape={`square`} title={`截断单词`} type={isSplitByGrapheme ? `primary` : `default`}
                    onClick={e => {
                      handler.sets(`splitByGrapheme`, !editing.splitByGrapheme);
                      setTimeout(() => handler.sets(`scale`, 1.2), 100);
                    }}>
              <Mcon size={iconSize} path={mdiLastpass} />
            </Button>

            {/*<Divider type={`vertical`} />*/}

            {/*<Button shape={`square`} title={`合并多个文本框`} onClick={e => handler.contentHandler.merge()}>*/}
            {/*  <Mcon size={iconSize} path={mdiSetMerge} />*/}
            {/*</Button>*/}
            {/*<Button shape={`square`} title={`拆分成多个文本框 一行为一个`} onClick={e => handler.contentHandler.split()}>*/}
            {/*  <Mcon size={iconSize} path={mdiSetSplit} />*/}
            {/*</Button>*/}
          </Button.Group>
        </Space>

        {/*<Button title={`复制`} onClick={e => handler.copy(editing.id)}>*/}
        {/*  <Mcon size={iconSize} path={mdiContentCopy} />*/}
        {/*</Button>*/}

        {/*<Button title={`粘贴`} onClick={e => handler.paste(editing.id)}>*/}
        {/*  <Mcon size={iconSize} path={mdiContentPaste} />*/}
        {/*</Button>*/}

      </div>
    </div>
  )
}