import { Typography, Input, Button, Select, Form } from "antd";
import React, { useEffect, useRef } from "react";
import useState from "react-usestateref"
import Languages from "../../../../assets/languages_map.json";
import { debounce } from "lodash";

const {Paragraph} = Typography

const compKey = `tool.new.text.input`;
const compKlass = compKey.replace(/\./g, '-');

export default function ToolTextInputNewComp({handler, editing, tarLang}) {
  const languages = Object.values(Languages);

  const [form] = Form.useForm();
  const [origin, setOrigin] = useState(editing.origin || editing.text);
  const [text, setText] = useState(editing.text);

  useEffect(() => {
    form.setFieldsValue({
      text: editing.text,
      origin: editing.origin || editing.text,
    })
  }, [editing.text])


  const _onChange = (e, key) => {
    // handler.sets(key, e.target.value, editing)
  }

  const useMap = {
    origin: {
      action: v => setOrigin(v)
    },
    text: {
      action: v => setText(v)
    }
  }

  const compHandler = {
    translate: async () => {
      let resolveText = await handler.translateHandler.translateText(origin);
      handler.sets(`text`, resolveText, editing)
    },
    change: debounce((e, key) => {
      useMap[key].action(e.target.value)
      handler.sets(key, e.target.value, editing);
    }, 500)
  }

  return (
    <div className={`cp-${compKlass}`}>
      <div className="inner">
        <Form form={form}
              layout="vertical"
              name="basic"
              initialValues={{
                origin: origin,
                text: text
              }}
        >
          <Form.Item name={`origin`} label={`原文`}>
            <Input.TextArea
              onChange={e => compHandler.change(e, `origin`)}
              autoSize={{minRows: 3, maxRows: 6}}
            />
          </Form.Item>

          <Button.Group>
            <Select
              showSearch
              value={tarLang || editing[`to`]}
              className="lang-select"
              placeholder="选择语言"
              optionFilterProp="children"
              onChange={val => handler.translateHandler.changeLanguage(val)}
              filterOption={(input, option) => {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            >
              {
                languages.map(lang => {
                  return (
                    <Select.Option key={lang[`code`]} value={lang[`code`]}>{lang[`name_cn`]}</Select.Option>
                  )
                })
              }
            </Select>
            <Button type={`primary`} onClick={e => compHandler.translate(origin)}>翻译</Button>
          </Button.Group>

          <Form.Item name={`text`} label={`展示文字`}>
            <Input.TextArea
              onChange={e => compHandler.change(e, `text`)}
              autoSize={{minRows: 3, maxRows: 6}}
            />
          </Form.Item>

        </Form>
      </div>
    </div>
  )
}