import {
  mdiCircleDouble,
  mdiEyedropperVariant,
  mdiFormatSize, mdiHexagon, mdiHexagonOutline,
  mdiSquare,
  mdiSquareOutline, mdiWaterOpacity,
  mdiWaterOutline
} from "@mdi/js";
import Mcon from "@mdi/react";
import { Button, InputNumber, Divider, Select, Slider, Popover, Row, Col } from "antd";
import React from "react";
import { defaultFontSize } from "../../../../config";
import { SketchPicker } from "react-color";
import { hexToRgb, HexToRGBA, RGBToHex } from "../../../../utils";
import { ToolPanelListItemNewComp } from "../../index";


const keyName = "tool.new.image.draw";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;

export default function ToolPatch({editing, handler, brush}) {
  const {width, color} = brush || {};

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        {/*画笔宽度*/}
        <div>
          <div className="label"><Mcon size={iconSize} path={mdiCircleDouble}/> 画笔宽度</div>
          <Row>
            <Col span={14}>
              <Slider min={1} max={200} value={width} onChange={val => handler.drawHandler.set(`width`, val)}/>
            </Col>
            <Col span={10}>
              <InputNumber value={width}
                           style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                           step={1}
                           onChange={val => handler.drawHandler.set(`width`, val)}
              />
            </Col>
          </Row>
        </div>

        {/*<Divider/>*/}

        {/*<div>*/}
        {/*  <div className="label"><Mcon size={iconSize} path={mdiWaterOpacity}/> 画笔透明度</div>*/}
        {/*  <Row>*/}
        {/*    <Col span={24}>*/}
        {/*      <Slider min={1} max={100} value={width} onChange={val => handler.drawHandler.set(`width`, val)}/>*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*</div>*/}

        <Divider/>

        <div>
          <div className="label"><Mcon size={iconSize} path={mdiWaterOutline}/> 画笔颜色</div>
          <Row>
            <Col>
              <ToolPanelListItemNewComp
                item={{
                  icon: mdiHexagon,
                  iconColor: color,
                  title: `选择颜色`
                }}
                active={false}
              >
                <label onChange={e => {
                  const {r, g, b} = hexToRgb(e.target.value);
                  handler.drawHandler.set(`color`, `rgb(${r}, ${g}, ${b})`)
                }}
                >
                  <input defaultValue={color} type="color"
                         style={{visibility: `hidden`, position: `absolute`}}/>
                </label>
              </ToolPanelListItemNewComp>
            </Col>

            <Col>
              <ToolPanelListItemNewComp
                item={{
                  icon: mdiHexagonOutline,
                  title: `白色`,
                  click: e => handler.drawHandler.set(`color`, colorWhite)
                }}
                handler={handler}
                active={false}
              />
            </Col>

            <Col>
              <ToolPanelListItemNewComp
                item={{
                  icon: mdiHexagon,
                  title: `黑色`,
                  click: e => handler.drawHandler.set(`color`, colorBlack)
                }}
                handler={handler}
                active={false}
              />
            </Col>


          </Row>
        </div>

        <Divider/>

        <Button block type="primary" onClick={e => handler.interactionHandler.selection()}>退出画笔</Button>
      </div>
    </div>
  )
}