import React from "react";

const compKey = `tool.pdf.widget.popover.guide`
const compKlass = compKey.replace(/\./g, '-');

export default function ({title, description, src}) {
  return (
    <div className={`cp-${compKlass}`}>
      <div className="inner">
        <div className="head">
          <video playsInline loop autoPlay controls={false}>
            <source src={src} type={`video/mp4`} />
          </video>
        </div>
        <div className="content">
          <div className="title">{title}</div>
          <div className="description" dangerouslySetInnerHTML={{__html: description}} />
        </div>
      </div>
    </div>
  )
}