import { formatOptions } from "../config";

export default class BrushHandler {
  constructor(handler) {
    this.handler = handler
  }

  start() {
    let ao = this.handler.canvas.getActiveObject();
    if (!ao) return this.handler.interactionHandler.selection();

    let formats = {}
    for (let k of formatOptions) {
      formats[k] = ao[k]
    }
    this.copyedFormats = formats;
    this.handler.isCopping = true;
  }

  end() {
    this.copyedFormats = null;
    this.handler.isCopping = false;
    this.handler.interactionHandler.selection();
  }

  paste(target) {
    // console.log(`target`, target);
    let formats = this.copyedFormats;
    for (let k in formats) {
      this.handler.sets(k, formats[k], target, 1);
    }
    this.handler.canvas.renderAll();
  }
}