import { fabric } from "fabric";


class ZoomHandler {
  constructor(handler, options) {
    this.handler = handler;
    this.onZoom = options.onZoom;
  }

  /**
   * @description Zoom to point
   * @param {fabric.Point} point
   * @param {number} zoom
   */
  zoomToPoint = (point, zoom) => {
    const {minZoom, maxZoom} = this.handler;
    let zoomRatio = zoom;
    if (zoom <= (minZoom / 100)) {
      zoomRatio = minZoom / 100;
    } else if (zoom >= (maxZoom / 100)) {
      zoomRatio = maxZoom / 100;
    }
    this.handler.canvas.zoomToPoint(point, zoomRatio);
    // Copy
    if (this.onZoom) this.onZoom(zoomRatio);
  };

  /**
   * @description Zoom one to one
   */
  zoomOneToOne = () => {
    const {widthArea = 0, heightArea = 0, widthBg = 0, heightBg = 0} = this.handler.getAreaAndBackgroundSize() || {};
    // console.log(`widthArea`, widthArea);
    // console.log(`widthBg`, widthBg);
    // console.log(`heightArea`, heightArea);
    // console.log(`heightBg`, heightBg);
    let x = 0, y = 0;
    const center = this.handler.canvas.getCenter();
    if (heightArea > widthArea) {
      if (heightBg >= widthBg) x = (widthArea - widthBg) / 2;
      y = (heightArea - heightBg) / 2;
    } else {
      if (heightBg >= widthBg) x = (widthArea - widthBg) / 2;
      y = (heightArea - heightBg) / 2;
    }
    let delta = new fabric.Point(x, y);

    this.zoomToPoint(new fabric.Point(center.left, center.top), 1);
    this.handler.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.handler.canvas.relativePan(delta);
    this.handler.canvas.requestRenderAll();
    return {x, y}
  };

  /**
   * @description Zoom to fit
   */
  zoomToFit = () => {
    let zoomRatio = this.handler.canvas.getZoom();
    const {widthArea = 0, heightArea = 0, widthBg = 0, heightBg = 0} = this.handler.getAreaAndBackgroundSize() || {};

    let x = 0, y = 0, delta;
    if (heightArea > widthArea) { // 如果工作区的高度大于宽度 那么缩放按照
      // console.log(`工作区的高度大于宽度`)
      if (heightBg > widthBg) {
        zoomRatio = heightArea / heightBg;
        delta = new fabric.Point((widthArea - widthBg * zoomRatio) / 2, 0);
        // console.log(`对象的高度大于宽度`, zoomRatio)
      } else {
        zoomRatio = widthArea / widthBg;
        delta = new fabric.Point(0, (heightArea - heightBg * zoomRatio) / 2);
        // console.log(`对象的高度小于宽度`, zoomRatio);
      }
    } else { // 工作区 宽度>高度
      // console.log(`工作区的高度小于宽度`)

      if (heightBg > widthBg) {
        zoomRatio = heightArea / heightBg;
        delta = new fabric.Point((widthArea - widthBg * zoomRatio) / 2, 0);
        // console.log(`对象的高度大于宽度`, zoomRatio)
      } else {
        zoomRatio = widthArea / widthBg;
        delta = new fabric.Point(0, (heightArea - heightBg * zoomRatio) / 2);
        // console.log(`对象的高度小于宽度`, zoomRatio)
      }
      // 适应的情况就是 目标高度适应工作区的高度
    }

    this.handler.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    this.zoomToPoint(new fabric.Point(x, y), zoomRatio);
    this.handler.canvas.relativePan(delta);
    if (this.onZoom) this.onZoom(zoomRatio);
  };

  /**
   * @description Zoom in
   */
  zoomIn = () => {
    let zoomRatio = this.handler.canvas.getZoom();
    zoomRatio += 0.1;
    const center = this.handler.canvas.getCenter();
    // 限制zoomRatio
    zoomRatio = this.handler.zoomHandler.restrictZoom(zoomRatio);
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    // if (this.onZoom) this.onZoom(zoomRatio);
  };

  /**
   * @description Zoom out
   */
  zoomOut = () => {
    let zoomRatio = this.handler.canvas.getZoom();
    zoomRatio -= 0.05;
    const center = this.handler.canvas.getCenter();
    // 限制zoomRatio
    zoomRatio = this.handler.zoomHandler.restrictZoom(zoomRatio);
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
    // if (this.onZoom) this.onZoom(zoomRatio);
  };

  /**
   * @description 限制缩放
   * @param zoomRatio
   * @return {number}
   */
  restrictZoom = (zoomRatio) => {
    const {minZoom, maxZoom} = this.handler.canvas;
    // 限制zoomRatio
    if (zoomRatio >= maxZoom / 100) {
      zoomRatio = maxZoom / 100
    } else if (zoomRatio <= minZoom / 100) {
      zoomRatio = minZoom / 100
    }
    return zoomRatio
  }

  /**
   * @description Zoom to point
   * @param {number} zoomRatio
   */
  zoomCenter = (zoomRatio) => {
    const center = this.handler.canvas.getCenter();
    this.zoomToPoint(new fabric.Point(center.left, center.top), zoomRatio);
  };
}

export default ZoomHandler;
