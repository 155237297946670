import { fabric } from 'fabric';
import CanvasObject from "./CanvasObjectCreate";

class InteractionHandler {

  constructor(handler) {
    this.handler = handler;
    if (this.handler.editable) {
      this.selection();
    }
  }

  /**
   * Change selection mode
   * @param [callback]
   */
  selection = (callback) => {
    const {onModeChange} = this.handler;
    // if (this.handler.interactionMode === 'selection') return;

    this.handler.interactionMode = 'default';
    this.handler.canvas.defaultCursor = 'default';
    this.handler.canvas.selection = true;
    // 关掉自由画笔
    this.drawingClear();
    this.handler.elementRangerHandler.hide();
    // 去掉选择框
    this.handler.ocrHandler.clear();
    this.handler.getObjects().forEach(obj => {
      if (callback) {
        this.interactionCallback(obj, callback);
      } else {
        // console.log(`selection.getObjects`, )
        // When typeof selection is ActiveSelection, ignoring selectable, because link position left: 0, top: 0
        if (!obj.excludeFromIntersection && obj.editable) {
          obj.hoverCursor = 'pointer';
          obj.selectable = true;
          obj.evented = true;
        }
      }
    });
    this.handler.canvas.renderAll();
    if (onModeChange) onModeChange(`default`)
  };

  drawingClear = () => {
    const cursor = this.handler.canvas.getObjects().find(e => e.category === `cursor`);
    if (cursor) this.handler.canvas.remove(cursor);
    this.handler.canvas.isDrawingMode = false;
    this.handler.canvas.requestRenderAll();
  }

  /**
   * Change grab mode
   * @param [callback]
   */
  grab = (callback) => {
    const {onModeChange} = this.handler;
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'grab') {
      return;
    }
    this.handler.canvas.isDrawingMode = false;
    this.handler.interactionMode = 'grab';
    this.handler.canvas.selection = false;
    this.handler.canvas.defaultCursor = 'grab';
    this.handler.getObjects().forEach(obj => {
      if (callback) {
        this.interactionCallback(obj, callback);
      } else {
        obj.selectable = false;
        obj.evented = !this.handler.editable;
      }
    });
    this.handler.canvas.renderAll();
    if (onModeChange) onModeChange(`grab`)
  };

  // 添加文字模式
  text() {
    const {onModeChange} = this.handler;
    this.handler.canvas.discardActiveObject();
    this.handler.drawHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'text') {
      this.selection();
      return;
    }

    this.handler.canvas.discardActiveObject();
    this.handler.interactionMode = `text`;
    this.handler.canvas.defaultCursor = 'text';
    this.handler.canvas.renderAll();
    this.handler.getObjects().forEach(obj => {
      obj.selectable = false;
      obj.evented = false;
    });
    if (onModeChange) onModeChange(`text`)
  }

  // 画线模式
  line() {
    console.log(`line`)
    const {onModeChange} = this.handler;
    this.handler.canvas.discardActiveObject();
    this.handler.drawHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'line') {
      this.selection();
      return;
    }

    this.handler.canvas.discardActiveObject();
    this.handler.canvas.selection = false;
    this.handler.interactionMode = `line`;
    this.handler.canvas.defaultCursor = 'crosshair';
    this.handler.canvas.renderAll();
    this.handler.getObjects().forEach(obj => {
      obj.selectable = false;
      obj.evented = false;
    });
    if (onModeChange) onModeChange(`line`)
  }

  // 格式刷模式
  brush() {
    const {onModeChange} = this.handler;
    this.handler.drawHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'brush') {
      this.selection();
      return;
    }
    this.handler.interactionMode = `brush`;
    this.handler.canvas.defaultCursor = 'crosshair';
    this.handler.canvas.hoverCursor = 'copy';
    this.handler.canvas.renderAll();
    this.handler.brushHandler.start();
    if (onModeChange) onModeChange(`brush`)
  }

  // ocr模式
  ocr() {
    const {onModeChange} = this.handler;
    this.handler.canvas.discardActiveObject();
    this.handler.drawHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'ocr') {
      this.selection();
      return;
    }
    this.handler.canvas.discardActiveObject();
    this.handler.interactionMode = `ocr`;
    this.handler.canvas.defaultCursor = 'crosshair';
    this.handler.canvas.renderAll();
    this.handler.getObjects().forEach(obj => {
      obj.selectable = false;
      obj.evented = false;
    });
    if (onModeChange) onModeChange(`ocr`)
  }

  // ocr模式
  cut() {
    const {onModeChange, interactionMode} = this.handler;
    this.handler.canvas.discardActiveObject();
    this.handler.drawHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'cut') {
      this.selection();
      return;
    }
    this.handler.canvas.discardActiveObject();
    this.handler.interactionMode = `cut`;
    this.handler.canvas.defaultCursor = 'crosshair';
    this.handler.canvas.renderAll();
    this.handler.getObjects().forEach(obj => {
      obj.selectable = false;
      obj.evented = false;
    });
    if (onModeChange) onModeChange(`cut`)
  }

  // fill模式
  fill() {
    const {onModeChange, interactionMode} = this.handler;
    this.handler.canvas.discardActiveObject();
    this.handler.drawHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'fill') {
      this.selection();
      return;
    }
    this.handler.interactionMode = `fill`;
    this.handler.canvas.renderAll();

    if (onModeChange) onModeChange(`fill`)
  }

  // 自由画笔
  draw() {
    const {onModeChange, brush} = this.handler;
    this.handler.drawHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'draw') {
      this.selection();
      return;
    }
    const {width, color} = brush || {};

    // 切换模式 通知全局
    this.handler.interactionMode = `draw`;
    // 添加cursor
    this.handler.drawHandler.pen();

    this.handler.canvas.freeDrawingCursor = `crosshair`;
    this.handler.canvas.freeDrawingBrush = new fabric.PencilBrush(this.handler.canvas);
    this.handler.canvas.freeDrawingBrush.color = color;
    this.handler.canvas.freeDrawingBrush.width = width;
    this.handler.canvas.isDrawingMode = true;
    this.handler.canvas.renderAll();

    if (onModeChange) onModeChange(`draw`)
  }

  // 自由画笔
  eraser() {
    const {onModeChange, brush} = this.handler;
    // this.handler.canvas.discardActiveObject();
    this.handler.drawHandler.end();
    this.handler.patchHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'eraser') {
      this.selection();
      return;
    }
    const {width, color} = brush || {};

    // 切换模式 通知全局
    this.handler.interactionMode = `eraser`;
    // 添加cursor
    this.handler.eraserHandler.pen();

    this.handler.canvas.freeDrawingCursor = `crosshair`;
    this.handler.canvas.freeDrawingBrush = new fabric.EraserBrush(this.handler.canvas);
    this.handler.canvas.freeDrawingBrush.width = width;
    this.handler.canvas.isDrawingMode = true;
    this.handler.canvas.renderAll();

    if (onModeChange) onModeChange(`eraser`)
  }

  // 补丁
  patch() {
    const {onModeChange, brush} = this.handler;
    // this.handler.canvas.discardActiveObject();
    this.handler.drawHandler.end();
    this.handler.elementRangerHandler.hide();
    if (this.handler.interactionMode === 'patch') {
      this.selection();
      return;
    }
    const {width} = brush || {};
    // 切换模式 通知全局
    this.handler.interactionMode = `patch`;
    // 添加cursor
    this.handler.patchHandler.pen();

    this.handler.canvas.freeDrawingCursor = `crosshair`;
    this.handler.canvas.freeDrawingBrush = new fabric.PencilBrush(this.handler.canvas);
    this.handler.canvas.freeDrawingBrush.color = `rgba(106, 117, 202, 0.5)`;
    this.handler.canvas.freeDrawingBrush.width = width;
    this.handler.canvas.isDrawingMode = true;
    this.handler.canvas.renderAll();

    if (onModeChange) onModeChange(`patch`)
  }

  /**
   * Moving objects in grap mode
   * @param {MouseEvent} e
   */
  moving = (e) => {
    const delta = new fabric.Point(e.movementX, e.movementY);
    this.handler.canvas.relativePan(delta);
    this.handler.canvas.requestRenderAll();
  };


  /**
   * Interaction callback
   *
   * @param obj
   * @param [callback]
   */
  interactionCallback = (obj, callback) => {
    callback(obj);
  };
}

export default InteractionHandler;
