import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';

class ElementRangerHandler {
    constructor(handle, options) {
        this.handler = handle;
        this.onElementRanger = options.onElementRanger;
        this.init();
    }

    /**
     * @description Init context menu
     */
    init = () => {
        this.rangerEl = document.createElement('div');
        this.rangerEl.id = `element-ranger-${this.handler.id}`;
        this.rangerEl.className = 'element-ranger element-ranger-hidden';
        document.body.appendChild(this.rangerEl);
    }

    /**
     * @description Show context menu
     * @memberof ContextmenuHandler
     */
    show = debounce(async (e, target) => {
        const { onElementRanger } = this;
        while (this.rangerEl.hasChildNodes()) {
            this.rangerEl.removeChild(this.rangerEl.firstChild);
        }
        const rangerContent = document.createElement('div');
        rangerContent.className = 'ranger-content';
        const element = await onElementRanger(this.rangerEl, e, target);
        if (!element) {
            return;
        }
        rangerContent.innerHTML = element;
        this.rangerEl.appendChild(rangerContent);
        ReactDOM.render(element, rangerContent);
        this.rangerEl.classList.remove('element-ranger-hidden');

        let { left, top, width, height } = this.handler.canvas.getAbsoluteCoords(target);
        const vtl = this.handler.canvas.viewportTransform[4], vtt = this.handler.canvas.viewportTransform[5];
        const sx = target.scaleX, sy = target.scaleY;

        const zoom = this.handler.canvas.getZoom();
        const widthZoom = target.width * zoom * sx;
        const heightZoom = target.height * zoom * sy;

        this.rangerEl.style.left = `${left + vtl}px`;
        this.rangerEl.style.top = `${top + vtt}px`;
        this.rangerEl.style.width = widthZoom + `px`;
        this.rangerEl.style.height = heightZoom + `px`;
    }, 10)

    /**
     * @description Hide context menu
     * @memberof ContextmenuHandler
     */
    hide = debounce(() => {
        if (this.rangerEl) {
            this.rangerEl.classList.add('element-ranger-hidden');
        }
    }, 150)

    destory = () => {
        if (this.rangerEl) {
            document.body.removeChild(this.rangerEl);
        }
    }
}

export default ElementRangerHandler;
