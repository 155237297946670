import React, { useEffect } from "react";
import { LayerOperationNewComp, LayerListNewComp } from "../index";
import { reverse } from "lodash"
import Mcon from "@mdi/react"
import useState from "react-usestateref"

const keyName = "tool.new.layer";
const klassName = keyName.replace(/\./g, '-');

export default function Layer({data, handler, editing}) {

  const [layers, setLayers] = useState([]);
  const [visible, setVisible] = useState(undefined);

  useEffect(() => {
    setLayers(reverse(data))
  }, [data])


  const compHandler = {
    select: item => {
      if (item.excludeFromIntersection) return;
      handler.select(item)
    }, title: {
      change: (e, item) => {
        item.display = e.target.value;
      }, visible: v => {
        setVisible(v)
      }
    },
  }

  // console.log(`Layer.layers`, layers);

  return (<div className={`cp-${klassName}`}>
    <div className="inner">

      <LayerOperationNewComp handler={handler} editing={editing}/>

      <LayerListNewComp layers={layers} editing={editing} handler={handler} compHandler={compHandler} visible={visible}/>
    </div>
  </div>);
}
