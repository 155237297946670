import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';

class WidgetContextmenuHandler {
  constructor(handle, options) {
    this.handler = handle;
    this.onWidgetContext = options.onWidgetContext;
    this.init();
  }

  /**
   * @description Init context menu
   */
  init = () => {
    this.contextmenuEl = document.createElement('div');
    this.contextmenuEl.id = `widget_contextmenu_${this.handler.id}`;
    this.contextmenuEl.className = 'cp-widget-contextmenu contextmenu-hidden';
    document.body.appendChild(this.contextmenuEl);
  }

  /**
   * @description Show context menu
   * @memberof ContextmenuHandler
   */
  show = debounce(async (e, target) => {
    const {onWidgetContext} = this;
    while (this.contextmenuEl.hasChildNodes()) {
      this.contextmenuEl.removeChild(this.contextmenuEl.firstChild);
    }
    const contextmenu = document.createElement('div');
    contextmenu.className = 'rde-contextmenu-right';
    const element = await onWidgetContext(this.contextmenuEl, e, target);
    if (!element) {
      return;
    }
    contextmenu.innerHTML = element;
    this.contextmenuEl.appendChild(contextmenu);
    ReactDOM.render(element, contextmenu);
    this.contextmenuEl.classList.remove('contextmenu-hidden');

    let {left, top, width, height} = this.handler.canvas.getAbsoluteCoords(target);
    const vtl = this.handler.canvas.viewportTransform[4], vtt = this.handler.canvas.viewportTransform[5];
    const subWidth = this.contextmenuEl.clientWidth > 200 ? this.contextmenuEl.clientWidth : 200;

    let l = `${(left + width) + vtl - subWidth}px`;
    let t = `${(top + height) + vtt + 5}px`;

    // 文字框的定位
    // const panelEl = document.getElementById('cp-tool-panel-textbox');
    // if ([`textbox`].includes(target?.category)) {
    //   panelEl.style.left = `${left + vtl + width + 5}px`;
    //   panelEl.style.top = `${top + vtt}px`;
    // }

    this.contextmenuEl.style.left = l;
    this.contextmenuEl.style.top = t;
  }, 10)

  /**
   * @description Hide context menu
   * @memberof ContextmenuHandler
   */0
  hide = debounce(() => {
    if (this.contextmenuEl) {
      this.contextmenuEl.classList.add('contextmenu-hidden');
    }
  }, 150)

  destory = () => {
    if (this.contextmenuEl) {
      document.body.removeChild(this.contextmenuEl);
    }
  }
}

export default WidgetContextmenuHandler;
