import {
  mdiCardTextOutline,
  mdiCheckerboard, mdiDramaMasks, mdiDrawing, mdiEraser, mdiEyedropperVariant,
  mdiFormatColorFill, mdiFormatTextbox, mdiGreasePencil, mdiImageEdit, mdiImageOutline,
  mdiImageSizeSelectLarge, mdiOverscan, mdiSquareCircle,
} from "@mdi/js";
import Mcon from "@mdi/react";
import { InputNumber, Checkbox, Space, Button, Popover, Divider, Collapse, List, Avatar } from "antd";
import React, { useRef } from "react";
import {
  ToolImageBackgroundNewComp,
  ToolProgressNewComp,
  ToolImagePaintsNewComp,
  ToolImagePatchNewComp,
  ToolImageDrawNewComp,
  ToolPanelListItemNewComp,
  ToolImageEraserNewComp,
  ToolImageEditsNewComp,
  ToolImageFillNewComp, ToolImageOperationNewComp
} from "../../index";

import ClipPathComp from "./clipPath"

const keyName = "tool.new.image";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;

export default function ToolImage({editing, handler, interactionMode, brush, defaultActiveKey}) {
  const {backgroundColor, uniSize, category, fill} = editing || {};

  return (
    <div className={`cp-${klassName}`}>
      {/*<Divider><Mcon size={1} path={mdiImageOutline}/> 图片编辑</Divider>*/}

      <ToolProgressNewComp handler={handler} editing={editing}/>

      <div className="inner">
        <Collapse expandIconPosition="right"
                  defaultActiveKey={defaultActiveKey}
                  onChange={v => {
                    handler.dbHandler.set(`ui`, `image_default_active_key`, v)
                  }}
                  ghost
        >
          {
            category === `segment` &&
            <>
              <Collapse.Panel
                key="paints"
                header={
                  <List.Item.Meta
                    title={`画笔组`}
                    avatar={
                      <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                              icon={<Mcon color={`#000`} path={mdiDrawing}/>}/>
                    }
                  />
                }
              >
                <ToolImagePaintsNewComp interactionMode={interactionMode} handler={handler} editing={editing}/>

                {
                  interactionMode === `patch` &&
                  <ToolImagePatchNewComp interactionMode={interactionMode} brush={brush} editing={editing}
                                         handler={handler}/>
                }

                {
                  interactionMode === `draw` &&
                  <ToolImageDrawNewComp interactionMode={interactionMode} brush={brush} editing={editing}
                                        handler={handler}/>
                }
              </Collapse.Panel>

              <Collapse.Panel
                key="fill"
                header={
                  <List.Item.Meta
                    title={`填充背景`}
                    avatar={
                      <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                              icon={<Mcon size={0.8} color={`#000`} path={mdiFormatColorFill}/>}/>
                    }
                  />
                }
              >

                <ToolImageFillNewComp handler={handler} fill={fill}/>
              </Collapse.Panel>

              <Collapse.Panel
                key="operation"
                header={
                  <List.Item.Meta
                    title={`操作`}
                    avatar={
                      <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                              icon={<Mcon size={0.8} color={`#000`} path={mdiFormatColorFill}/>}/>
                    }
                  />
                }
              >

                <ToolImageOperationNewComp handler={handler} backgroundColor={backgroundColor}/>
              </Collapse.Panel>
            </>
          }


          {
            category !== `segment` &&
            <>
              <Collapse.Panel
                key="edit"
                header={
                  <List.Item.Meta
                    title={`编辑`}
                    avatar={
                      <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                              icon={<Mcon size={0.8} color={`#000`} path={mdiImageEdit}/>}/>
                    }
                  />
                }
              >
                <ToolImageEditsNewComp interactionMode={interactionMode} handler={handler} editing={editing}/>

                {
                  interactionMode === `eraser` &&
                  <ToolImageEraserNewComp interactionMode={interactionMode} brush={brush} editing={editing}
                                          handler={handler}/>
                }
              </Collapse.Panel>

              <Collapse.Panel
                key="size"
                header={
                  <List.Item.Meta
                    title={`宽度 | 高度`}
                    avatar={
                      <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                              icon={<Mcon size={0.8} color={`#000`} path={mdiOverscan}/>}/>
                    }
                  />
                }
              >
                <Space>
                  <InputNumber value={editing.width}
                               style={{width: `100%`, textAlign: 'center'}}
                               onClick={e => editing.isEditing = true}
                               onChange={val => {
                                 handler.sets(`width`, val, editing)
                               }}
                  />
                  <InputNumber value={editing.height}
                               onClick={e => editing.isEditing = true}
                               onChange={val => {
                                 handler.sets(`height`, val, editing)
                               }}

                  />
                </Space>
              </Collapse.Panel>

              <Collapse.Panel
                key="scale"
                header={
                  <List.Item.Meta
                    title={`横向缩放 | 纵向缩放`}
                    avatar={
                      <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                              icon={<Mcon size={0.8} color={`#000`} path={mdiImageSizeSelectLarge}/>}/>
                    }
                  />
                }
              >
                <Space>
                  <InputNumber value={parseFloat((editing.scaleX || 0).toFixed(2))}
                               step={0.1}
                               style={{width: `100%`, textAlign: 'center'}}
                               onClick={e => editing.isEditing = true}
                               onChange={val => {
                                 // if (editing.category === `background`)
                                 handler.sets(`scaleX`, val, editing)
                               }}
                  />
                  <InputNumber value={parseFloat((editing.scaleY || 0).toFixed(2))}
                               step={0.1}
                               onClick={e => editing.isEditing = true}
                               onChange={val => {
                                 // if (editing.category === `background`)
                                 handler.sets(`scaleY`, val, editing)
                               }}

                  />
                </Space>
                <div>
                  <Checkbox checked={uniSize} onChange={e => {
                    editing.uniSize = e.target.checked
                  }}>等比例缩放</Checkbox>
                </div>
              </Collapse.Panel>

              <Collapse.Panel
                key="background"
                header={
                  <List.Item.Meta
                    title={`背景色`}
                    avatar={
                      <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                              icon={<Mcon size={0.8} color={`#000`} path={mdiFormatColorFill}/>}/>
                    }
                  />
                }
              >

                <ToolImageBackgroundNewComp handler={handler} backgroundColor={backgroundColor}/>
              </Collapse.Panel>

              {/*<Collapse.Panel*/}
              {/*  key="clip"*/}
              {/*  header={*/}
              {/*    <List.Item.Meta*/}
              {/*      title={`图形蒙版`}*/}
              {/*      avatar={*/}
              {/*        <Avatar size={`small`} style={{backgroundColor: `#fff`}}*/}
              {/*                icon={<Mcon size={0.8} color={`#000`} path={mdiSquareCircle}/>}/>*/}
              {/*      }*/}
              {/*    />*/}
              {/*  }*/}
              {/*>*/}

              {/*  <ClipPathComp handler={handler} editing={editing}/>*/}
              {/*</Collapse.Panel>*/}
            </>
          }

        </Collapse>

      </div>

    </div>
  )
}