import {
  mdiFormatColorFill, mdiFormatFont,
  mdiFormatTextVariantOutline,
  mdiPalette,
  mdiTextShadow, mdiTexture
} from "@mdi/js";
import Mcon from "@mdi/react";
import {
  Collapse,
  List,
  Avatar
} from "antd";
import React, { useEffect, useRef } from "react";
import {
  ToolTextboxBackgroundNewComp,
  ToolTextboxColorNewComp,
  ToolTextboxShadowNewComp,
  ToolProgressNewComp, ToolTextboxBorderNewComp
} from "../../index";
import ShapePatternComp from "./pattern";

const keyName = "tool.new.shape";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 1;
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`;

export default function ToolShape({editing, handler, targetLanguage, interactionMode, defaultActiveKey}) {

  const {
    fill,
    backgroundColor,
  } = editing || {};

  return (
    <div className={`cp-${klassName}`}>
      {/*<Divider><Mcon size={iconSize} path={mdiShapeOutline}/> 形状编辑</Divider>*/}

      <ToolProgressNewComp handler={handler} editing={editing}/>

      <div className="inner" style={{overflowY: `auto`}}>

        <Collapse expandIconPosition="right"
                  defaultActiveKey={defaultActiveKey}
                  onChange={v => {
                    handler.dbHandler.set(`ui`, `shape_default_active_key`, v)
                  }}
                  ghost
        >

          <Collapse.Panel
            key="color"
            header={
              <List.Item.Meta
                title={`颜色`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiPalette}/>}/>
                }
              />
            }
          >
            <ToolTextboxColorNewComp handler={handler} fill={fill} editing={editing}/>
          </Collapse.Panel>

          <Collapse.Panel
            key="backgroundColor"
            header={
              <List.Item.Meta
                title={`背景色`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiFormatColorFill}/>}/>
                }
              />
            }
          >
            <ToolTextboxBackgroundNewComp handler={handler} backgroundColor={backgroundColor}/>
          </Collapse.Panel>

          <Collapse.Panel
            key="shadow"
            header={
              <List.Item.Meta
                title={`阴影`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiTextShadow}/>}/>
                }
              />
            }
          >
            <ToolTextboxShadowNewComp handler={handler} editing={editing} />
          </Collapse.Panel>

          <Collapse.Panel
            key="border"
            header={
              <List.Item.Meta
                title={`描边`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiFormatTextVariantOutline}/>}/>
                }
              />
            }
          >
            <ToolTextboxBorderNewComp handler={handler} editing={editing} />
          </Collapse.Panel>

          <Collapse.Panel
            key="pattern"
            header={
              <List.Item.Meta
                title={`填充图片`}
                avatar={
                  <Avatar size={`small`} style={{backgroundColor: `#fff`}}
                          icon={<Mcon color={`#000`} path={mdiTexture}/>}/>
                }
              />
            }
          >
            <ShapePatternComp handler={handler} editing={editing} />
          </Collapse.Panel>
        </Collapse>

      </div>
    </div>
  )
}