import Cookies from "universal-cookie";

export function getHeaders(req) {
  let token, cookies;
  if (typeof window === "undefined") {
    // 不在Client
    cookies = new Cookies(req.headers.cookie);
    token = cookies.get(`token`);
  } else {
    // 在本地
    cookies = new Cookies(window.document.cookie);
    token = cookies.get(`token`);
  }
  let headers = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
}
