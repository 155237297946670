// Core
import axios from "axios";
import { message } from "antd";
// Plg
import queryString from "query-string";
import _, { forIn } from "lodash";
// Util
import { SERVER_BASE, CODE_MESSAGE } from "../config";
import { getHeaders } from "../config/header";

function checkStatus(response) {
  // console.log("response", response);

  if (response.status >= 200 && response.status < 300) {
    return response["data"];
  }
  const errortext = CODE_MESSAGE[response.status] || response.statusText;
  const error = new Error(errortext);
  error.statusCode = response.status;
  error.response = response;
  throw error;
}

export default function requewt(url, options) {
  const defaultOptions = {
    success: null,
    error: null,
    cacheErr: null,
    req: null
  };
  const mergedOptions = { ...defaultOptions, ...options };
  // console.log("options", options);
  // console.log("mergedOptions", mergedOptions);

  const { success, error, req, cacheErr } = mergedOptions;
  const headers = getHeaders(req);
  let reqOptions = { headers, ...mergedOptions.headers };
  if (mergedOptions.method === "POST") {
    reqOptions.method = "POST";
    if (
      mergedOptions &&
      mergedOptions.headers &&
      mergedOptions.headers[`Content-Type`] === "multipart/form-data"
    ) {
      let fm = new FormData();
      forIn(mergedOptions.body, function(value, key) {
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            fm.append(`${key}[]`, value[i]);
          }
        } else {
          fm.append(key, value);
        }
      });
      reqOptions.data = fm;
    } else reqOptions.data = mergedOptions.body;
  } else if (mergedOptions.method === "PUT") {
    reqOptions.method = "PUT";
    if (
      mergedOptions &&
      mergedOptions.headers &&
      mergedOptions.headers[`Content-Type`] === "multipart/form-data"
    ) {
      let fm = new FormData();
      forIn(mergedOptions.body, function(value, key) {
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            fm.append(`${key}[]`, value[i]);
          }
        } else {
          fm.append(key, value);
        }
      });
      reqOptions.data = fm;
    } else reqOptions.data = mergedOptions.body;
  } else if (mergedOptions.method === "DELETE") {
    reqOptions.method = "DELETE";
    if (mergedOptions.body)
      reqOptions.data = mergedOptions.body;
  } else {
    if (mergedOptions.body)
      url += "?" + queryString.stringify(mergedOptions.body);
  }
  const instance = axios.create({
    baseURL: SERVER_BASE,
    headers
  });

  reqOptions = { ...reqOptions, ...{ url } };
  // console.log("reqOptions", reqOptions);

  // _.extend(reqOptions, {url});
  //
  return instance(reqOptions)
    .then(checkStatus)
    .then(data => {
      // console.log("data", data);
      if (data.status === 200) {
        if (success) {
          success(data);
        }
      } else if (data.status === "ok") {
        if (success) {
          success(data);
        }
      } else {
        if (error) {
          error(data);
        }
      }
      return data;
    })
    .catch(e => {
      console.log("e1", JSON.stringify(e));
      if (e && e.response && e.response.data && e.response.data.code) {
        let msg = CODE_MESSAGE[e.response.data.code];
        msg && typeof window != undefined && message.error(msg);
      }
      if (cacheErr) {
        cacheErr();
      }
    });
}
