import React, { useState } from "react";
import Mcon from "@mdi/react"
import { Button, Row, Col, Tooltip, Popover, message } from "antd"
import {
  mdiImageFilterCenterFocusStrong,
  mdiContentSaveOutline,
  mdiEyeOutline,
  mdiFormatText,
  mdiFountainPen,
  mdiOcr,mdiRocketLaunchOutline,
  mdiImagePlus, mdiGreasePencil, mdiCloseCircleOutline
} from "@mdi/js"
import { isValidArr, readImageDimensionOfURL } from "../../../../../utils";

import CanvasObject from "../../handlers/CanvasObjectCreate";
import { imageURL2Base64 } from "../../utils";
import { LangSelectComp } from "../";

import FileInput from "./pop.file.input"

const keyName = "tool.tools";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.9;

export default function Tools({handler, pageHandler, targetLanguage, interactionMode}) {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleTrans, setVisibleTrans] = useState(false);
  const [translating, setTranslating] = useState(false);

  const compHandler = {
    enable: () => {
      handler.onState({outsideDisabled: false})
    },
    disabled: () => {
      handler.onState({outsideDisabled: true})
    },
    ocr: e => {
      e.stopPropagation();
      handler.interactionHandler.ocr()
    },
    cut: e => {
      e.stopPropagation();
      handler.interactionHandler.cut()
    },
    patch: e => {
      e.stopPropagation();
      handler.interactionHandler.patch()
    },
    draw: e => {
      e.stopPropagation();
      handler.interactionHandler.draw()
    },
    page: {
      translate: async () => {
        setTranslating(true)
        const res = await handler.pageTranslate();
        if (res) {
          message.success(`成功`)
          setVisibleTrans(false)
        }
        setTimeout(() => setTranslating(false), 300)
      }
    },
    add: {
      text: e => {
        e.stopPropagation();
        handler.interactionHandler.text()
      },
      image: async (url) => {
        // 去除旧的背景
        const prevLayers = handler.canvas.getObjects().filter(e => [`background`, `board`, `boardHeader`].includes(e.category));
        if (isValidArr(prevLayers)) {
          for (let i = 0; i < prevLayers.length; i++) {
            handler.remove(prevLayers[i]);
          }
          handler.canvas.bg = null;
          handler.canvas.lastHeight = 0;
        }

        const dataURL = typeof url !== `string` ? url.src : await imageURL2Base64(url);

        let {w, h} = await readImageDimensionOfURL(dataURL);

        handler.canvas.setBackgroundImage(dataURL, handler.canvas.renderAll.bind(handler.canvas), {
          crossOrigin: 'anonymous'
        });

        handler.canvas.bg = await imageURL2Base64(dataURL);
        handler.canvas.lastHeight = h;

        const boardOptions = Object.assign({}, CanvasObject.board.options, {
          width: w + 5,
          height: h + 5,
        });

        const boardHeaderOptions = Object.assign({}, CanvasObject.boardHeader.options, {
          text: `画板`
        });

        const board = await handler.add(boardOptions);
        const boardHeader = await handler.add(boardHeaderOptions);
        handler.canvas.sendToBack(board);
        handler.canvas.sendToBack(boardHeader);
        handler.zoomHandler.zoomToFit();

        setVisible(false);
      }
    }
  }

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <div className="ope-section">
          <Popover visible={visible}
                   content={<FileInput handler={compHandler} />}
                   trigger={[`click`]}
                   destroyTooltipOnHide
          >
            <Button id={`upload-btn`}
                    icon={
                      visible ?
                        <Mcon path={mdiCloseCircleOutline} size={iconSize} />
                        :
                        <Mcon path={mdiImagePlus} size={iconSize} />
                    }
                    block type={`primary`}
                    onClick={e => setVisible(!visible)}
            >
              {visible ? `关闭` : `插入图片`}
            </Button>
          </Popover>
        </div>

        <div className="ope-section">
          <Popover visible={visibleTrans}
                   content={<LangSelectComp loading={translating} handler={handler} compHandler={compHandler}
                                            tarLang={targetLanguage} style={{width: 200}}/>}
                   trigger={[`click`]}
                   destroyTooltipOnHide
          >
            <Button icon={
              visibleTrans ?
                <Mcon path={mdiCloseCircleOutline} size={iconSize}/>
                :
                <Mcon path={mdiRocketLaunchOutline} size={iconSize}/>
            }
                    block type={`primary`}
                    onClick={e => setVisibleTrans(!visibleTrans)}
            >
              {visibleTrans ? `关闭` : `一键处理`}
            </Button>
          </Popover>

        </div>

        <div className="ope-section">
          <Button loading={loading}
                  onClick={async e => {
                    if (!handler?.canvas?.bg) {
                      message.warn(`尚未创建背景图片 请点击左侧「插入图片」按钮上传图片`)
                      return
                    }
                    setLoading(true);
                    setTimeout(() => {
                      handler.exportPic();
                    }, 300)
                    setTimeout(() => setLoading(false), 800)
                  }}
                  icon={<Mcon path={mdiContentSaveOutline}
                              size={iconSize} />}
          >
            保存
          </Button>
          <Button onClick={e => pageHandler.visibleToggle(true)}
                  icon={<Mcon path={mdiEyeOutline} size={iconSize} />}>预览</Button>
        </div>

        <Row gutter={12} className="mb-2">
          <Col flex={1}>
            <Tooltip title={`快捷键X`} mouseEnterDelay={0.3}>
              <Button onClick={e => compHandler.cut(e)}
                      onMouseEnter={compHandler.disabled}
                      onMouseLeave={compHandler.enable}
                      block
                      type={interactionMode === `cut` ? `primary` : `default`}
                      shape="square" icon={<Mcon path={mdiImageFilterCenterFocusStrong} size={iconSize} />}
                      id={`cut-btn`}
              >

              </Button>
            </Tooltip>
            <div className="text-center">图像提取</div>
          </Col>
          <Col flex={1}>
            <Tooltip title={`快捷键C`} mouseEnterDelay={0.3}>
              <Button onClick={e => compHandler.ocr(e)}
                      onMouseEnter={compHandler.disabled}
                      onMouseLeave={compHandler.enable}
                      block
                      type={interactionMode === `ocr` ? `primary` : `default`}
                      shape="square" icon={<Mcon path={mdiOcr} size={iconSize} />}
                      id={`ocr-btn`}
              >

              </Button>
            </Tooltip>
            <div className="text-center">文字提取</div>

          </Col>
        </Row>

        <Row gutter={12}>
          <Col flex={1}>
            <Tooltip title={`快捷键T`} mouseEnterDelay={0.3}>
              <Button onClick={e => compHandler.add.text(e)}
                      onMouseEnter={compHandler.disabled}
                      onMouseLeave={compHandler.enable}
                      block
                      type={interactionMode === `text` ? `primary` : `default`}
                      shape="square" icon={<Mcon path={mdiFormatText} size={iconSize} />} />
            </Tooltip>
            <div className="text-center">文字</div>
          </Col>

          <Col flex={1}>
            <Tooltip title={`快捷键B`} mouseEnterDelay={0.3}>
              <Button onClick={e => compHandler.draw(e)}
                      onMouseEnter={compHandler.disabled}
                      onMouseLeave={compHandler.enable}
                      block
                      type={interactionMode === `draw` ? `primary` : `default`}
                      shape="square" icon={<Mcon path={mdiFountainPen} size={iconSize} />} />
            </Tooltip>
            <div className="text-center">画笔</div>
          </Col>

          <Col flex={1}>
            <Tooltip title={`快捷键J 只对背景图生效`} mouseEnterDelay={0.3}>
              <Button onClick={e => compHandler.patch(e)}
                      onMouseEnter={compHandler.disabled}
                      onMouseLeave={compHandler.enable}
                      block
                      type={interactionMode === `patch` ? `primary` : `default`}
                      shape="square" icon={<Mcon path={mdiGreasePencil} size={iconSize} />} />
            </Tooltip>
            <div className="text-center">涂抹</div>
          </Col>
        </Row>

        <div className="ope-section">


          {/*<ButtonGuideWidget guideStatus={video_guide_open}*/}
          {/*                   active={interactionMode === `ocr`}*/}
          {/*                   type={`mode.ocr`}*/}
          {/*                   onPress={e => {*/}
          {/*                     compHandler.ocr(e)*/}
          {/*                   }}*/}
          {/*                   onMouseEnter={compHandler.disabled}*/}
          {/*                   onMouseLeave={compHandler.enable}*/}
          {/*/>*/}


          {/*<ButtonGuideWidget guideStatus={video_guide_open}*/}
          {/*                   active={interactionMode === `text`}*/}
          {/*                   type={`mode.text`}*/}
          {/*                   onPress={e => compHandler.add.text(e)}*/}
          {/*                   onMouseEnter={compHandler.disabled}*/}
          {/*                   onMouseLeave={compHandler.enable}*/}
          {/*/>*/}

          {/*<Tooltip title={`添加文字`}>*/}

          {/*<Button onClick={e => compHandler.add.text(e)}*/}
          {/*        onMouseEnter={compHandler.disabled}*/}
          {/*        onMouseLeave={compHandler.enable}*/}
          {/*        type={interactionMode === `text` ? `primary` : `default`}*/}
          {/*        shape="square"*/}
          {/*        icon={<Mcon path={mdiFormatText} size={iconSize} />}*/}
          {/*/>*/}
          {/*</Tooltip>*/}

        </div>
      </div>
    </div>
  );
}
