import React from "react";
import classnames from "classnames";
import Mcon from "@mdi/react";
import {
  mdiDeleteVariant, mdiContentCopy, mdiAxisArrowLock, mdiAxisArrow, mdiArrowUp, mdiArrowCollapseUp,
  mdiArrowDown,
  mdiArrowCollapseDown
} from "@mdi/js";
import { Menu, Button, Tooltip } from "antd";

const compKey = `tool.context`
const compKlass = compKey.replace(/\./g, '-');

export default function contextComp({handler, target}) {
  if (!target) return null;
  if (target.type === 'activeSelection') {
    return (
      <div className={classnames(`cp-${compKlass}`)}>
        <Menu>
          <Menu.Item onClick={() => handler.remove()}>
            <span className="underlay"><Mcon size={0.75} path={mdiDeleteVariant} /></span><span
            className={`text`}>删除</span>
          </Menu.Item>
        </Menu>
      </div>
    )
  }
  return (
    <div className={classnames(`cp-${compKlass}`)}>
      <Menu>
        <Button.Group key={`floor`} style={{padding: `8px 18px 4px`}}>
          <Tooltip title={`上移一层`}>
            <Button disabled={!target} onClick={e => handler.bringForward()}
                    icon={<Mcon path={mdiArrowUp} size={0.75} />} />
          </Tooltip>

          <Tooltip title={`置于顶层`}>
            <Button disabled={!target} onClick={e => handler.bringToFront()}
                    icon={<Mcon path={mdiArrowCollapseUp} size={0.75} />} />
          </Tooltip>

          <Tooltip title={`下移一层`}>
            <Button disabled={!target} onClick={e => handler.sendBackwards()}
                    icon={<Mcon path={mdiArrowDown} size={0.75} />} />
          </Tooltip>

          <Tooltip title={`置于底层`}>
            <Button disabled={!target} onClick={e => handler.sendToBack()}
                    icon={<Mcon path={mdiArrowCollapseDown} size={0.75} />} />
          </Tooltip>
        </Button.Group>

        {
          [`FreeTextbox`, `textbox`].includes(target.type) &&
          <Menu.Item key={`duplicate`} onClick={() => handler.duplicate()}>
            <span className="underlay"><Mcon size={0.75} path={mdiContentCopy} /></span><span
            className={`text`}>复制文本框</span>
          </Menu.Item>
        }

        <Menu.Item key={`lock`} onClick={() => handler.toggleLock()}>
          <span className="underlay"><Mcon size={0.75}
                                           path={target?.lock ? mdiAxisArrowLock : mdiAxisArrow} /></span><span
          className={`text`}>位置锁定</span>
        </Menu.Item>

        <Menu.Item key={`delete`} onClick={() => handler.remove()}>
          <span className="underlay"><Mcon size={0.75} path={mdiDeleteVariant} /></span><span
          className={`text`}>删除</span>
        </Menu.Item>

      </Menu>
    </div>
  )
}