import React, { useState } from "react"
import { Button, Row, Col, Space, Select, InputNumber, Tooltip, Slider } from "antd";
import {
  mdiAlignHorizontalCenter,
  mdiAlignHorizontalDistribute,
  mdiAlignHorizontalLeft,
  mdiAlignHorizontalRight,
  mdiAlignVerticalBottom,
  mdiAlignVerticalCenter,
  mdiAlignVerticalDistribute,
  mdiAlignVerticalTop,
  mdiChevronDown,
  mdiChevronUp,
  mdiHexagon,
  mdiHexagonOutline,
  mdiLastpass,
} from "@mdi/js";
import Mcon from "@mdi/react";
import { ButtonGuideWidget } from "../../../widgets";
import { capitalize, lowerCase, upperCase } from "lodash";
import { isValidArr } from "../../../../../../../utils";
import { ToolPanelListItemNewComp } from "../../index";
import { hexToRgb } from "../../../../utils";

const keyName = "tool.new.line.stroke";
const klassName = keyName.replace(/\./g, '-');
const video_guide_open = true;
const iconSize = 0.75;
const iconColor = `#333`;
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;

export default function LineColor({handler, editing}) {

  const {stroke, strokeWidth} = editing;

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <Row>
          <Col span={8}>
            <ToolPanelListItemNewComp
              item={{
                icon: mdiHexagon,
                iconColor: stroke,
                title: `选择颜色`
              }}
              active={false}
            >
              <label onChange={e => {
                const {r, g, b} = hexToRgb(e.target.value);
                handler.sets(`stroke`, `rgb(${r}, ${g}, ${b})`)
              }}
              >
                <input defaultValue={stroke} type="color"
                       style={{visibility: `hidden`, position: `absolute`}}/>
              </label>
            </ToolPanelListItemNewComp>
          </Col>
          <Col span={8}>
            <ToolPanelListItemNewComp
              item={{
                icon: mdiHexagonOutline,
                title: `白色`,
                click: e => handler.sets(`stroke`, colorWhite)
              }}
              handler={handler}
              active={false}
            />
          </Col>
          <Col span={8}>

            <ToolPanelListItemNewComp
              item={{
                icon: mdiHexagon,
                title: `黑色`,
                click: e => handler.sets(`stroke`, colorBlack)
              }}
              handler={handler}
              active={false}
            />
          </Col>
        </Row>


      </div>
    </div>
  )
}