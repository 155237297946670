import classnames from "classnames";
import Mcon from "@mdi/react";
import { Button, Space, Slider, Card } from "antd";
import React from "react";
import { mdiFitToPageOutline, mdiMinus, mdiMonitorScreenshot, mdiPlus } from "@mdi/js";

const keyName = "tool.new.zoom.vertical";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;

export default function ZoomVertical({handler, zoomRatio}) {

  return (
    <div className={`cp-${klassName} ffNB`}>
      <Space direction="vertical" className="inner">

        <Card size={`small`} onClick={e => handler.zoomHandler.zoomOneToOne()}>
          <Button type={`link`}
                  icon={<Mcon size={iconSize} path={mdiFitToPageOutline}/>}/>
          <div className="text">原始尺寸</div>
        </Card>

        <Card size={`small`} onClick={e => handler.zoomHandler.zoomToFit()}>
          <Button type={`link`} icon={<Mcon size={iconSize} path={mdiMonitorScreenshot}/>}/>
          <div className="text">适应屏幕</div>
        </Card>


        <Card size={`small`}>
          <Button type={`link`} title={`放大`} onClick={e => handler.zoomHandler.zoomIn()}
                  icon={<Mcon size={iconSize} path={mdiPlus}/>}/>


          <div className="ratio">{(zoomRatio * 100).toFixed(0)} %</div>
          <Slider style={{height: 200}}
                  vertical
                  onChange={v => handler.zoomHandler.zoomCenter(+(v / 100).toFixed(2))}
                  max={handler?.canvas.maxZoom}
                  min={handler?.canvas.minZoom}
                  value={(zoomRatio * 100).toFixed(0)}
          />

          <Button type={`link`} className={`zoom-area`} title={`缩小`} onClick={e => handler.zoomHandler.zoomOut()}
                  icon={<Mcon size={iconSize} path={mdiMinus}/>}/>
        </Card>

      </Space>
    </div>
  )
}