import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Mcon from "@mdi/react";
import InputComp from "./input";
import DetectComp from "./detect";
import { fontFree, fontSystem } from "../../../../config";
import { mdiDotsHorizontal } from "@mdi/js";

const compKey = `tool.panel.textbox`
const compKlass = compKey.replace(/\./g, '-');

export default function PanelTextbox({handler, editing, className, targetLanguage, ...others}) {

  const panel = React.createRef();

  if(!editing) return null;

  return (
    <div className={classnames(`cp-${compKlass}`, {active: editing?.category === `textbox`})} ref={panel}
         id={`cp-${compKlass}`}>
      <div className="header">
        <span draggable onDrag={e => {
          e.preventDefault();
          e.stopPropagation();
          if (!e.pageX && !e.pageY) return;
          panel.current.style.position = `fixed`;
          panel.current.style.left = `${e.pageX - 100}px`;
          panel.current.style.top = `${e.pageY - 12}px`;
        }}>
          <Mcon path={mdiDotsHorizontal} size={1}/>
        </span>
      </div>
      <div className="inner">
        <InputComp handler={handler} editing={editing} tarLang={targetLanguage}/>
        {/*<PanelTextboxFormat handler={handler} editing={editing} fontsEN={fonts_en} fontsZH={fonts_zh}/>*/}
      </div>

    </div>
  )
}