import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';

class SelectorContextmenuHandler {
  constructor(handle, options) {
    this.handler = handle;
    this.onSelectorContext = options.onSelectorContext;
    this.init();
  }

  /**
   * @description Init context menu
   */
  init = () => {
    this.contextmenuEl = document.createElement('div');
    this.contextmenuEl.id = `ocr_contextmenu_${this.handler.id}`;
    this.contextmenuEl.className = 'cp-ocr-selector-contextmenu contextmenu-hidden';
    document.body.appendChild(this.contextmenuEl);
  }

  /**
   * @description Show context menu
   * @memberof ContextmenuHandler
   */
  show = debounce(async (e, target) => {
    const {onSelectorContext} = this;
    while (this.contextmenuEl.hasChildNodes()) {
      this.contextmenuEl.removeChild(this.contextmenuEl.firstChild);
    }
    const contextmenu = document.createElement('div');
    contextmenu.className = 'rde-contextmenu-right';
    const element = await onSelectorContext(this.contextmenuEl, e, target);
    if (!element) return;

    contextmenu.innerHTML = element;
    this.contextmenuEl.appendChild(contextmenu);
    ReactDOM.render(element, contextmenu);
    this.contextmenuEl.classList.remove('contextmenu-hidden');
    const btn = document.getElementById('rd-spin')

    let {left, top, width, height} = this.handler.canvas.getAbsoluteCoords(target);
    const vtl = this.handler.canvas.viewportTransform[4], vtt = this.handler.canvas.viewportTransform[5];

    this.contextmenuEl.style.left = `${(left + width) + vtl - 200}px`;
    this.contextmenuEl.style.top = `${(top + height) + vtt + 5}px`;
    btn.style.left = `${left + (width / 2) + vtl - 13}px`;
    btn.style.top = `${top + (height / 2) + vtt - 13}px`;
  }, 100)

  /**
   * @description Hide context menu
   * @memberof ContextmenuHandler
   */
  hide = debounce(() => {
    if (this.contextmenuEl) {
      this.contextmenuEl.classList.add('contextmenu-hidden');
    }
  }, 150)

  destory = () => {
    if (this.contextmenuEl) {
      document.body.removeChild(this.contextmenuEl);
    }
  }
}

export default SelectorContextmenuHandler;
