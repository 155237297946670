import React, { useEffect } from "react";
import { Layout } from "antd";

import { LayerNewComp } from "../index"

const keyName = "tool.new.aside";
const klassName = keyName.replace(/\./g, '-');

export default function Aside({handler, pageHandler, appHandler, interactionMode, editing, targetLanguage, loading}) {
  // 图层数据
  const layers = handler?.getObjects() || [];

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <Layout.Sider theme={`light`}>
          <LayerNewComp data={layers} handler={handler} editing={editing}/>
        </Layout.Sider>
      </div>
    </div>
  );
}
