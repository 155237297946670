import {
  mdiCircle,
  mdiCircleDouble, mdiEllipse,
  mdiEyedropperVariant,
  mdiFormatSize, mdiHexagon, mdiHexagonOutline,
  mdiSquare,
  mdiSquareOutline, mdiWaterOpacity,
  mdiWaterOutline
} from "@mdi/js";
import Mcon from "@mdi/react";
import { Button, InputNumber, Space, Select, Slider, Popover, Row, Col } from "antd";
import React from "react";


const keyName = "tool.clip.path";
const klassName = keyName.replace(/\./g, '-');
const iconSize = .75;
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;


export default function ToolClipPath({editing, handler}) {
  const {clipPath, width, height} = editing || {};

  const {type, radius, ex, ry, left, top, width: rw, height: rh} = clipPath || {};
  const max = width > height ? height : width;
  return (
    <div className={`cp-${klassName}`}>
      <Space direction="vertical" className="inner" style={{width: `100%`}}>
        <Row gutter={6}>
          <Col>
            <Button type={type === `rect` ? `primary` : `default`} onClick={e => handler.clipPathHandler.open(`rect`)}
                    icon={<Mcon path={mdiSquare} size={iconSize}/>}/>
          </Col>
          <Col>
            <Button type={type === `circle` ? `primary` : `default`} onClick={e => handler.clipPathHandler.open(`circle`)}
                    icon={<Mcon path={mdiCircle} size={iconSize}/>}/>
          </Col>
          <Col>
            <Button type={type === `ellipse` ? `primary` : `default`} onClick={e => handler.clipPathHandler.open(`ellipse`)}
                    icon={<Mcon path={mdiEllipse} size={iconSize}/>}/>
          </Col>
        </Row>

        {
          clipPath?.type === `circle` &&
          <>
            <Row gutter={6}>
              <Col span={14}>
                <Slider value={radius || 0}
                        max={Math.round(max / 2)}
                        min={1}
                        onChange={val => handler.clipPathHandler.change(`radius`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={radius || 0}
                             max={Math.round(max / 2)}
                             min={1}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={5}
                             onChange={val => handler.clipPathHandler.change(`radius`, val)}
                />
              </Col>
            </Row>

            <Row gutter={6}>
              <Col span={14}>
                <Slider value={left || 0}
                        max={width / 2 - radius}
                        min={-width / 2}
                        onChange={val => handler.clipPathHandler.change(`left`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={left || 0}
                             max={width / 2 - radius}
                             min={-width / 2}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={5}
                             onChange={val => handler.clipPathHandler.change(`left`, val)}
                />
              </Col>
            </Row>

            <Row gutter={6}>
              <Col span={14}>
                <Slider value={top || 0}
                        max={height / 2 - radius}
                        min={-height / 2}
                        onChange={val => handler.clipPathHandler.change(`top`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={top || 0}
                             max={height / 2 - radius}
                             min={-height / 2}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={1}
                             onChange={val => handler.clipPathHandler.change(`top`, val)}
                />
              </Col>
            </Row>
          </>
        }

        {
          clipPath?.type === `rect` &&
          <>
            <Row gutter={6}>
              <Col span={14}>
                <Slider min={1} max={max} value={rw || 0}
                        onChange={val => handler.clipPathHandler.change(`width`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={rw || 0}
                             max={max}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={1}
                             onChange={val => handler.clipPathHandler.change(`width`, val)}
                />
              </Col>
            </Row>

            <Row gutter={6}>
              <Col span={14}>
                <Slider min={1} max={max} value={rh || 0}
                        onChange={val => handler.clipPathHandler.change(`height`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={rh || 0}
                             max={max}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={1}
                             onChange={val => handler.clipPathHandler.change(`height`, val)}
                />
              </Col>
            </Row>

            <Row gutter={6}>
              <Col span={14}>
                <Slider value={left || 0}
                        max={width / 2 - rw}
                        min={-width / 2}
                        onChange={val => handler.clipPathHandler.change(`left`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={left || 0}
                             max={width / 2 - rw}
                             min={-width / 2}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={5}
                             onChange={val => handler.clipPathHandler.change(`left`, val)}
                />
              </Col>
            </Row>

            <Row gutter={6}>
              <Col span={14}>
                <Slider value={top || 0}
                        max={height / 2 - rh}
                        min={-height / 2}
                        onChange={val => handler.clipPathHandler.change(`top`, val)}/>
              </Col>
              <Col span={10}>
                <InputNumber value={top || 0}
                             max={height / 2 - rh}
                             min={-height / 2}
                             style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                             step={1}
                             onChange={val => handler.clipPathHandler.change(`top`, val)}
                />
              </Col>
            </Row>
          </>
        }

      </Space>
    </div>
  )
}