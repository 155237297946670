import React, { useEffect } from "react";
import classnames from "classnames";
import Mcon from "@mdi/react";
import { mdiDeleteVariant, mdiContentCopy, mdiCheck } from "@mdi/js";
import { Space, Button } from "antd";

const compKey = `tool.element.ranger`
const compKlass = compKey.replace(/\./g, '-');

export default function ElementRangerComp({ handler, target, loading, event }) {
  if (!target) return null;

  const compHandler = {
    enable: () => {
      handler.onState({ outsideDisabled: false })
    },
    disabled: () => {
      handler.onState({ outsideDisabled: true })
    },
  }

  const { interactionMode } = handler;
  return (
    <div className={classnames(`cp-${compKlass}`)}>
        {/*<span className="category">{target.title}</span>*/}
    </div>
  )
}