import React, { useEffect, useState } from "react";
import {Button, Popconfirm} from "antd"
import {CloseOutlined} from "@ant-design/icons"
import { toolbarConfig } from "../../config";

const keyName = "guide.tool";
const klassName = keyName.replace(/\./g, '-');

export default function GuideToolComp({interactionMode, handler}) {
  const [g, setG] = useState(null);

  const compHandler = {
    enable: () => {
      handler.onState({outsideDisabled: false})
    },
    disabled: () => {
      handler.onState({outsideDisabled: true})
    }
  }

  useEffect(() => {
    setG(toolbarConfig.mode[interactionMode])
  }, [interactionMode])

  if (![`text`, `cut`, `ocr`, `draw`, `patch`].includes(interactionMode)) return null;

  const {title, description, guide, key} = g || {};

  return (
    <div className={`cp-${klassName}`}
         onMouseEnter={compHandler.disabled}
         onMouseLeave={compHandler.enable}
    >
      <Popconfirm title={`是否永久关闭提示`}
                  onConfirm={e => handler.dbHandler.set(`setting`, `tool_mode_guide_open`, false)}
                  // onCancel={e => handler.onState({tool_mode_guide_open: false})}
      >
        <Button shape="circle" type="link" icon={<CloseOutlined />} />
      </Popconfirm>

      <div className="inner">
        <div className="head">
          {key === `text` && <video playsInline loop autoPlay controls={false}><source src={guide.video} type={`video/mp4`} /></video>}
          {key === `draw` && <video playsInline loop autoPlay controls={false}><source src={guide.video} type={`video/mp4`} /></video>}
          {key === `cut` && <video playsInline loop autoPlay controls={false}><source src={guide.video} type={`video/mp4`} /></video>}
          {key === `ocr` && <video playsInline loop autoPlay controls={false}><source src={guide.video} type={`video/mp4`} /></video>}
          {key === `patch` && <video playsInline loop autoPlay controls={false}><source src={guide.video} type={`video/mp4`} /></video>}
        </div>
        <div className="content">
          <div className="title">{title}</div>
          <div className="description" dangerouslySetInnerHTML={{__html: description}} />
        </div>
      </div>
    </div>
  )

}