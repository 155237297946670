class ModeHandler {
  constructor(handler) {
    this.handler = handler
  }

  // 注意 因为按钮都在区域外 所以点下去的一瞬间都会变成default 再点就是又打开了

  // 默认模式
  default() {
    const {onModeChange} = this.handler;
    this.handler.interactionMode = `default`;
    this.handler.canvas.defaultCursor = 'default';
    this.handler.canvas.hoverCursor = 'default';
    this.handler.drawHandler.end();
    this.handler.canvas.renderAll();
    this.handler.getObjects().filter(el => el.type === `FreeTextbox`).forEach(obj => {
      obj.selectable = true;
      obj.evented = true;
    });
    if (onModeChange) onModeChange(`default`)
  }

}

export default ModeHandler