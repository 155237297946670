import React, { useState } from "react"
import { Button, Row, Col, Space, Select, InputNumber, Tooltip } from "antd";
import {
  mdiAlignHorizontalCenter,
  mdiAlignHorizontalDistribute,
  mdiAlignHorizontalLeft,
  mdiAlignHorizontalRight,
  mdiAlignVerticalBottom,
  mdiAlignVerticalCenter,
  mdiAlignVerticalDistribute,
  mdiAlignVerticalTop,
  mdiChevronDown,
  mdiChevronUp,
  mdiHexagon,
  mdiHexagonOutline,
  mdiLastpass,
} from "@mdi/js";
import Mcon from "@mdi/react";
import { ButtonGuideWidget } from "../../../widgets";
import { capitalize, lowerCase, upperCase } from "lodash";
import { isValidArr } from "../../../../../../../utils";
import { ToolPanelListItemNewComp } from "../../index";

const keyName = "tool.new.collection.alignment";
const klassName = keyName.replace(/\./g, '-');
const video_guide_open = true;
const iconSize = 0.75;
const iconColor = `#333`;

export default function TextboxColor({handler, editing, fontsZH, fontsEN, fontsAvailable}) {

  return (
    <div className={`cp-${klassName}`}>
      <Space direction={`vertical`} className="inner">
        <Row justify="space-start">
          <Col >

            <ToolPanelListItemNewComp
              item={{
                icon: mdiAlignHorizontalLeft,
                title: `左侧对齐`,
                click: e => handler.alignmentHandler.horizontal.left()
              }}
              handler={handler}
              active={false}
            />
          </Col>
          <Col >

            <ToolPanelListItemNewComp
              item={{
                icon: mdiAlignHorizontalCenter,
                title: `水平居中`,
                click: e => handler.alignmentHandler.horizontal.center()
              }}
              handler={handler}
              active={false}
            />
          </Col>
          <Col >
            <ToolPanelListItemNewComp
              item={{
                icon: mdiAlignHorizontalRight,
                title: `右侧对齐`,
                click: e => handler.alignmentHandler.horizontal.right()
              }}
              handler={handler}
              active={false}
            />
          </Col>
          {/*<Col>*/}
          {/*  <Button icon={<Mcon color={iconColor} size={iconSize} path={mdiAlignHorizontalDistribute}/>}*/}
          {/*          onClick={e => handler.alignmentHandler.horizontal.distribute(`horizontal`)}*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>

        <Row justify="space-between">
          <Col  >
            <ToolPanelListItemNewComp
              item={{
                icon: mdiAlignVerticalTop,
                title: `顶部对齐`,
                click: e => handler.alignmentHandler.vertical.top()
              }}
              handler={handler}
              active={false}
            />
          </Col>
          <Col  >
            <ToolPanelListItemNewComp
              item={{
                icon: mdiAlignVerticalCenter,
                title: `垂直居中`,
                click: e => handler.alignmentHandler.vertical.middle()
              }}
              handler={handler}
              active={false}
            />
          </Col>
          <Col  >
            <ToolPanelListItemNewComp
              item={{
                icon: mdiAlignVerticalBottom,
                title: `底部对齐`,
                click: e => handler.alignmentHandler.vertical.bottom()
              }}
              handler={handler}
              active={false}
            />
          </Col>
          {/*<Col>*/}
          {/*  <Button icon={<Mcon color={iconColor} size={iconSize} path={mdiAlignVerticalDistribute}/>}*/}
          {/*          onClick={e => handler.alignmentHandler.vertical.distribute(`vertical`)}*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>

      </Space>
    </div>
  )
}