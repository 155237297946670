import { fabric } from "fabric";
import { API } from "../../../../config";
import REQUEST from "../../../../utils/requewt";
import {
  defaultTextboxOptions, triggerOptions, inheritOptions, fontSystem, lockOptions, propertiesToInclude
} from "../config";
import {
  EventHandler,
  ZoomHandler,
  ModeHandler,
  OcrHandler,
  CutHandler,
  TextHandler,
  ShortcutHandler,
  TransactionHandler,
  InteractionHandler,
  ContextmenuHandler,
  GradientHandler,
  ContentHandler,
  BrushHandler,
  TranslateHandler,
  DatabaseHandler,
  DrawHandler,
  SelectorContextmenuHandler,
  WidgetContextmenuHandler,
  PatchHandler,
  EraserHandler,
  ShapeHandler,
  FillHandler,
  ElementRangerHandler,
  ShadowHandler,
  SegmentHandler,
  AlignmentHandler,
  LineHandler,
  PatternHandler,
  ClipPathHandler
} from "./";
import CanvasObject from "./CanvasObjectCreate";
import { message } from "antd";
import { v4 } from "uuid";
import { saveAs } from "file-saver";
import FontFaceObserver from "fontfaceobserver";
import { filterSpace, isValidArr, readImageDimensionOfURL } from "../../../../utils";
import calc from "../../../../utils/calc.fontSize";
import { imageURL2Base64, isSplitByGrapheme } from "../utils";

class Handler {
  constructor(options) {
    // console.log(`options`, options);
    this.init(options);
    this.initCallback(options);
    this.initHandler(options);
  }

  init(options) {
    this.id = options.id;
    this.iid = options.iid;
    this.canvas = options.canvas;
    this.editable = options.editable;
    this.zoomRatio = options.zoomRatio;
    this.minZoom = options.minZoom;
    this.maxZoom = options.maxZoom;
    this.canvasOption = options.canvasOption;
    this.defaultOption = options.defaultOption;
    this.zoomEnabled = options.zoomEnabled;
    this.activeSelection = options.activeSelection;
    this.fabricObjects = Object.assign({}, CanvasObject, options.fabricObjects);
    this.width = options.width;
    this.height = options.height;
    this.keyEvent = options.keyEvent;

    this.interactionMode = options.interactionMode;

    this.mode = options.mode;
    this.saving = options.saving;
    this.project = options.project;
    this.paintOption = options.paintOption;
    this.drawOption = options.drawOption;
    this.clipboard = options.clipboard;

    this.objects = [];
    this.objectMap = {};
    this.steps = [];
    this.propertiesToInclude = propertiesToInclude;

  };

  initCallback = (options) => {
    this.onState = options.onState;
    this.onAdd = options.onAdd;
    this.onTooltip = options.onTooltip;
    this.onZoom = options.onZoom;
    this.onContext = options.onContext;
    this.onSelectorContext = options.onSelectorContext;
    this.onLink = options.onLink;
    this.onDblClick = options.onDblClick;
    this.onSelect = options.onSelect;
    this.onModified = options.onModified;
    this.onRemove = options.onRemove;
    this.onInitConfig = options.onInitConfig;
    this.onBrushChange = options.onBrushChange;
    this.onExport = options.onExport;
    this.onDBChange = options.onDBChange;
    this.onTranslated = options.onTranslated;
    this.onLocked = options.onLocked;
    this.onDrew = options.onDrew;
    this.onErased = options.onErased;
    this.onPatching = options.onPatching;
    this.onPatched = options.onPatched;
    this.onCut = options.onCut;
    this.onHideAll = options.onHideAll;
    this.onResized = options.onResized;
    this.onFilled = options.onFilled;
    this.onPageCut = options.onPageCut;
    this.onPatterned = options.onPatterned;
    this.onPageTranslated = options.onPageTranslated;

    this.onPaintCompete = options.onPaintCompete;
    this.onDrawComplete = options.onDrawComplete;
    this.onAddStep = options.onAddStep;
    this.onResetStep = options.onResetStep;
    this.onRemoveStep = options.onRemoveStep;
    this.onChangeFreeDrawing = options.onChangeFreeDrawing;
    this.onAddCursor = options.onAddCursor;
    this.onUpdateCursor = options.onUpdateCursor;
    this.onRemoveCursor = options.onRemoveCursor;
    this.onSwitchMode = options.onSwitchMode;
    this.onScroll = options.onScroll;
    this.onBlur = options.onBlur;
    this.onAction = options.onAction;
    this.onExportImage = options.onExportImage;
    this.onOcr = options.onOcr;
    this.onModeChange = options.onModeChange;
    this.onTransaction = options.onTransaction;
    this.onGradientModified = options.onGradientModified;
  };

  initHandler = (options) => {
    this.eventHandler = new EventHandler(this);
    this.modeHandler = new ModeHandler(this);
    this.ocrHandler = new OcrHandler(this);
    this.cutHandler = new CutHandler(this);
    this.drawHandler = new DrawHandler(this);
    this.eraserHandler = new EraserHandler(this);
    this.patchHandler = new PatchHandler(this);
    this.translateHandler = new TranslateHandler(this);
    this.textHandler = new TextHandler(this);
    this.brushHandler = new BrushHandler(this);
    this.zoomHandler = new ZoomHandler(this, {
      onZoom: options.onZoom
    });
    this.contextmenuHandler = new ContextmenuHandler(this, {
      onContext: options.onContext,
    });
    this.widgetContextmenuHandler = new WidgetContextmenuHandler(this, {
      onWidgetContext: options.onWidgetContext,
    });
    this.selectorContextmenuHandler = new SelectorContextmenuHandler(this, {
      onSelectorContext: options.onSelectorContext,
    });
    this.elementRangerHandler = new ElementRangerHandler(this, {
      onElementRanger: options.onElementRanger,
    });
    this.shortcutHandler = new ShortcutHandler(this);
    this.transactionHandler = new TransactionHandler(this);
    this.interactionHandler = new InteractionHandler(this);
    this.gradientHandler = new GradientHandler(this);
    this.shadowHandler = new ShadowHandler(this);
    this.contentHandler = new ContentHandler(this);
    this.fillHandler = new FillHandler(this);
    this.shapeHandler = new ShapeHandler(this);

    this.segmentHandler = new SegmentHandler(this);

    this.dbHandler = new DatabaseHandler(this);
    this.alignmentHandler = new AlignmentHandler(this);
    this.lineHandler = new LineHandler(this);
    this.patternHandler = new PatternHandler(this);
    this.clipPathHandler = new ClipPathHandler(this);
  };

  setKeyEvent = (keyEvent) => {
    this.keyEvent = Object.assign({}, this.keyEvent, keyEvent);
  };

  destroy = () => {
    this.eventHandler.detachEventListener();
    this.contextmenuHandler.destory();
    this.selectorContextmenuHandler.destory();
    this.clear(true);
  }

  /**
   * @description Get primary object
   * @returns {FabricObject[]}
   */
  getObjects = () => {
    const objects = this.canvas.getObjects()
      .filter((obj) => {
        return obj.id;
      }) || [];
    if (objects.length) {
      objects.forEach(obj => (this.objectMap[obj.id] = obj));
    } else {
      this.objectMap = {};
    }
    return objects;
  }

  /**
   * @description Add object
   * @param {Object} obj FabricObjectOption
   * @param {boolean} [centered=true]
   * @param {boolean} [loaded=false]
   * @param {boolean} [silent=false]
   * @param {number} [index=false]
   * @returns
   */
    // TODO 方法改为异步
  add = (obj, centered = true, loaded = false, silent, index) => {
    const existObj = this.findById(obj[`id`]);
    if (existObj) return existObj;

    const {editable, onAdd, defaultOption} = this;
    const option = {
      // hasControls: editable, hasBorders: editable,
      hoverCursor: !obj.editable ? "default" : "pointer"
    };

    const newOption = Object.assign({}, defaultOption, {
      editable,
    }, option, obj);
    // Individually create canvas object
    let createdObj;
    // Create canvas object
    if ([`image`, `background`].includes(obj.type)) {
      createdObj = this.addImage(newOption);
      createdObj.ratio = [parseInt(createdObj.width), parseInt(createdObj.height)];
    }
    // 处理路径
    else if (obj.type === "path") {
      new fabric.Path.fromObject(newOption, (path) => {
        createdObj = path;
      })
    }
    // 处理组
    else if (obj.type === 'group') {
      const objects = this.addGroup(newOption, centered, loaded);
      const groupOption = Object.assign({}, newOption, {objects, name: 'New Group'});
      createdObj = this.fabricObjects[obj.type].create(groupOption);
    }
    // 处理其他
    else {
      createdObj = this.fabricObjects[obj.type].create(newOption);
    }
    // 如果有监听
    if (CanvasObject[createdObj.type] && CanvasObject[createdObj.type].listener) {
      CanvasObject[createdObj.type].listener(createdObj, this.canvas)
    }
    this.canvas.add(createdObj);
    this.objects = this.getObjects();
    this.canvas.requestRenderAll();
    if (onAdd && !silent) onAdd(createdObj);
    return createdObj;
  };


  /**
   * @description Add object
   * @param {Object} obj FabricObjectOption
   * @param {boolean} [centered=true]
   * @param {boolean} [loaded=false]
   * @param {boolean} [silent=false]
   * @returns
   */
  addAsync = async (obj, centered = true, loaded = false, silent) => {

    // 校验唯一
    const existObj = this.findById(obj[`id`]);
    if (existObj) return existObj;

    const {editable, onAdd, defaultOption} = this;
    const option = {
      hasControls: editable,
      hasBorders: editable,
      // selectable: editable,
      // lockMovementX: !editable,
      // lockMovementY: !editable,
      hoverCursor: !obj.editable ? "default" : "pointer"
    };

    const newOption = Object.assign({}, defaultOption, {editable}, option, obj);
    // Individually create canvas object
    let createdObj;
    // Create canvas object
    if ([`image`, `background`].includes(obj.type)) {
      createdObj = await this.addImageSync(newOption);
      createdObj.ratio = [parseInt(createdObj.width), parseInt(createdObj.height)];
    }
    // 如果有监听
    if (CanvasObject[createdObj.type] && CanvasObject[createdObj.type].listener) {
      CanvasObject[createdObj.type].listener(createdObj, this.canvas)
    }
    this.canvas.add(createdObj);
    this.objects = this.getObjects();
    this.canvas.requestRenderAll();
    if (onAdd && !silent) onAdd(createdObj);
    return createdObj;
  };

  /**
   * Add group object
   *
   * @param {FabricGroup} obj
   * @param {boolean} [centered=true]
   * @param {boolean} [loaded=false]
   * @returns
   */
  addGroup = (obj, centered = true, loaded = false) => {
    return obj.objects.map(child => {
      return this.add(child, centered, loaded);
    });
  };

  /**
   * Add canvas objects and this.objects
   * @param Klass
   * @return {*}
   */
  addToQueue = Klass => {
    let exist = this.findById(Klass.id);
    if (exist) return Klass;
    // console.log(this.objects);
    this.canvas.add(Klass);
    this.objects = this.getObjects();
    return Klass;
  };

  addImage = (obj, cb) => {
    const {defaultOption} = this;
    const image = new Image();
    const {filters = [], ...otherOption} = obj;
    if (obj.src) {
      image.src = obj.src;
    }
    const createdObj = new fabric.Image(image, {
      ...defaultOption, ...otherOption,
    });
    // createdObj.set({
    //   filters: this.imageHandler.createFilters(filters),
    // });
    this.setImage(createdObj, obj.src || obj.file);
    return createdObj;
  };

  addImageSync = async (obj, cb) => {
    const {defaultOption} = this;
    const image = new Image();
    const {filters = [], ...otherOption} = obj;
    if (obj.src) {
      image.src = obj.src;
    }
    const createdObj = new fabric.Image(image, {
      ...defaultOption, ...otherOption,
    });
    await this.loadImageAsync(createdObj, obj.src || obj.file);
    return createdObj;
  };

  /**
   * @description Set the image
   * @param {Object} obj FabricImage
   * @param {*} source
   * @returns
   */
  setImage = (obj, source) => {
    if (!source) {
      this.loadImage(obj, null);
      obj.set("file", null);
      obj.set("src", null);
      return;
    }
    if (source instanceof File) {
      const reader = new FileReader();
      reader.onload = () => {
        this.loadImage(obj, reader.result);
        obj.set("file", source);
        obj.set("src", null);
      };
      reader.readAsDataURL(source);
    } else {
      this.loadImage(obj, source);
      obj.set("file", null);
      obj.set("src", source);
    }
  };

  setImageAsync = (obj, source) => {

    return new Promise(resolve => {
      // 判断File
      if (source instanceof File) {
        return new Promise(r => {
          const reader = new FileReader();
          reader.onload = async () => {
            obj = await this.loadImageAsync(obj, reader.result);
            obj.set("file", source);
            obj.set("src", null);

            r(obj)
          };
          reader.readAsDataURL(source);
        })
      } else {

      }
    })

  }

  /**
   * @description Load the image
   * @param {FabricImage} obj
   * @param {string} src
   */
  loadImage = (obj, src) => {
    let url = src;
    if (!url) {
      url = "/static/images/logo_s.png";
    }
    fabric.util.loadImage(url, source => {
      if (obj.type !== "image") {
        obj.setPatternFill({
          source, repeat: "repeat"
        }, null);
        obj.setCoords();
        this.canvas.renderAll();
        return;
      }
      obj.setElement(source);
      obj.setCoords();
      this.canvas.renderAll();
    }, null, {crossOrigin: 'anonymous'});
  };


  loadImageAsync = (obj, src) => {
    const that = this;
    return new Promise(resolve => {
      let url = src;
      if (!url) url = "/static/images/logo_s.png";
      fabric.util.loadImage(url, source => {
        if (obj.type !== "image") {
          obj.setPatternFill({
            source, repeat: "repeat"
          }, null);
          obj.setCoords();
          that.canvas.renderAll();
          return;
        }
        obj.setElement(source);
        obj.setCoords();
        resolve(obj)
        that.canvas.renderAll();
      }, null, {crossOrigin: 'anonymous'});
    })
  }

  // SET /////////////////////////////////
  /**
   * @description Set key pair
   * @param {keyof FabricObjectOption} key
   * @param {*} value
   * @returns
   */
  set = (key, value) => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    activeObject.set(key, value);
    activeObject.setCoords();
    this.canvas.requestRenderAll();
    const {onModified} = this;
    if (onModified) {
      onModified(activeObject, `set`);
    }
  };

  /**
   * @description set active object group fabric options
   * @param {FabricObjectOption} option
   */
  setObject = (option) => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    Object.keys(option)
      .forEach(key => {
        if (option[key] !== activeObject[key]) {
          activeObject.set(key, option[key]);
          activeObject.setCoords();
        }
      });
    this.canvas.requestRenderAll();
    const {onModified} = this;
    if (onModified) {
      onModified(activeObject);
    }
  };

  /**
   * @description handler single or multi object property
   * @param {keyof FabricObject} key
   * @param {*} value
   * @param {Object} [target]
   * @param silent
   */
  sets = (key, value, target, silent) => {
    let ao = target || this.canvas.getActiveObject();
    if (!ao) return;
    if (![`FreeTextbox`, `activeSelection`, `image`, `textbox`, `circle`, `triangle`, `rect`, `ellipse`, `line`,].includes(ao.type)) return;
    // 甄别类别
    if ([`textbox`].includes(ao.category)) {
      if (ao.isEditing) ao.exitEditing();
      // 甄别属性
      if (inheritOptions.includes(key) && !silent) {
        // 处理背景颜色
        if (key === `fill`) {
          if (typeof value === `string`) {
            this.gradientHandler.close(true);
            this.dbHandler.set(`format`, key, value);
          }
        } else this.dbHandler.set(`format`, key, value);
      }
    } else if ([`line`].includes(ao.category)) {
      if (inheritOptions.includes(key)) {
        this.dbHandler.set(`line`, key, value);
      }
    }

    // 处理尺寸问题
    if ([`width`, `height`, `scaleX`, `scaleY`].includes(key)) {
      const {uniSize = false, ratio = [1, 1]} = target || {};
      if (uniSize) {
        let keyRef = null, valueRef = null;
        switch (key) {
          case `width`:
            keyRef = `height`
            valueRef = parseFloat((value * ratio[1] / ratio[0]).toFixed(2));
            break
          case `height`:
            keyRef = `width`
            valueRef = parseFloat((value * ratio[0] / ratio[1]).toFixed(2));
            break
          case `scaleX`:
            keyRef = `scaleY`
            valueRef = value;
            break
          case `scaleY`:
            keyRef = `scaleX`
            valueRef = value;
            break
        }
        if (silent) this.setByObjectSilent(ao, keyRef, valueRef); else this.setByObject(ao, keyRef, valueRef);
      }
    }
    if (ao.type === `activeSelection`) {
      let modifies = [];
      ao.getObjects()
        .forEach(el => {
          let modified = null;
          if (silent) modified = this.setByObjectSilent(el, key, value); else modified = this.setByObject(el, key, value);
          modifies.push(modified)
        })
      return modifies
    }
    let modified;
    if (silent) modified = this.setByObjectSilent(ao, key, value);
    else modified = this.setByObject(ao, key, value);
    // 处理渐变

    return modified
  }

  /**
   * @description Set key pair by object
   * @param {FabricObject} obj
   * @param {keyof FabricObject} key
   * @param {*} value
   * @param  silent
   * @returns
   */
  setByObject = (obj, key, value, silent) => {
    if (!obj || !key) return;
    obj.set(key, value);
    obj.setCoords();
    this.canvas.requestRenderAll();
    const {onModified} = this;
    if (onModified && !silent) onModified(obj, `setByObject`);
    return obj
  };

  /**
   * @description 不会触发回调 不会触发高度更新（缩放更新） 同时立即更新试图
   * @param {FabricObject} obj
   * @param {keyof FabricObject} key
   * @param {*} value
   * @returns
   */
  setByObjectSilent = (obj, key, value) => {
    if (!obj) return;
    obj.set(key, value);
    if (triggerOptions.includes(key)) {
      if (obj[`_height`] && obj[`_height`] >= obj[`height`] || obj[`_width`] && obj[`_width`] >= obj[`width`]) {
        obj.set(`dirty`, false);
        obj.set(`height`, obj[`_height`] || obj[`height`]);
        obj.set(`width`, obj[`_width`] || obj[`width`]);
      } else {
        obj.set(`_height`, obj[`height`]);
        obj.set(`_width`, obj[`width`]);
      }
    }
    obj.setCoords();
    // this.canvas.bringToFront(obj);
    this.canvas.requestRenderAll();
    return obj
  };

  /**
   * @description Set key pair by id
   * @param {string} id
   * @param {keyof FabricObject} key
   * @param {*} value
   */
  setById = (id, key, value) => {
    const findObject = this.findById(id);
    this.setByObject(findObject, key, value);
  };

  /**
   * Set partial by object
   * @param {FabricObject} obj
   * @param {FabricObjectOption} option
   * @returns
   */
  setByPartial = (obj, option) => {
    if (!obj) {
      return;
    }
    obj.set(option);
    obj.setCoords();
    this.canvas.renderAll();
  };
  // SET END /////////////////////////////////


  /**
   * Set shadow
   * @param {fabric.Shadow} option
   * @returns
   */
  setShadow = (option) => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) {
      return;
    }
    activeObject.setShadow(option);
    this.canvas.requestRenderAll();
    const {onModified} = this;
    if (onModified) {
      onModified(activeObject);
    }
  };


  /**
   * Set image by id
   * @param {string} id
   * @param {*} source
   */
  setImageById = (id, source) => {
    const findObject = this.findById(id);
    this.setImage(findObject, source);
  };

  /**
   * Set the position on Object
   *
   * @param {FabricObject} obj
   * @param {boolean} [centered]
   */
  centerObject = (obj, centered) => {
    if (centered) {
      this.canvas.centerObject(obj);
      obj.setCoords();
    } else {
      this.setByPartial(obj, {
        left: obj.left / this.canvas.getZoom() - obj.width / 2 - this.canvas.viewportTransform[4] / this.canvas.getZoom(),
        top: obj.top / this.canvas.getZoom() - obj.height / 2 - this.canvas.viewportTransform[5] / this.canvas.getZoom(),
      });
    }
  };

  /**
   * 前移一层
   */
  bringForward = (ao) => {
    const activeObject = ao || this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.bringForward(activeObject);
      if (ao?.id && !ao?.ignoreTransaction && !this.transactionHandler.active) {
        this.transactionHandler.save('bringForward');
      }
      const {onModified} = this;
      if (onModified) {
        onModified(activeObject, `bringForward`);
      }
    }
  };

  /**
   * 最前
   */
  bringToFront = (ao) => {
    const activeObject = ao || this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.bringToFront(activeObject);
      if (ao?.id && !ao?.ignoreTransaction && !this.transactionHandler.active) {
        this.transactionHandler.save('bringToFront');
      }
      const {onModified} = this;
      if (onModified) {
        onModified(activeObject, `bringToFront`);
      }
    }
  };

  /**
   * 后移一层
   * @returns
   */
  sendBackwards = () => {
    const activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      const firstObject = this.canvas.getObjects()[0];
      if (firstObject.id === activeObject.id) {
        return;
      }
      if (activeObject?.id && !activeObject?.ignoreTransaction && !this.transactionHandler.active) {
        this.transactionHandler.save('sendBackwards');
      }
      this.canvas.sendBackwards(activeObject);
      const {onModified} = this;
      if (onModified) {
        onModified(activeObject, `sendBackwards`);
      }
    }
  };

  /**
   *  至底
   */
  sendToBack = (ao) => {
    const activeObject = ao || this.canvas.getActiveObject();
    if (activeObject) {
      this.canvas.sendToBack(activeObject);
      if (activeObject?.id && !activeObject?.ignoreTransaction && !this.transactionHandler.active) {
        this.transactionHandler.save('sendToBack');
      }
      const {onModified} = this;
      if (onModified) {
        onModified(activeObject, `sendToBack`);
      }
    }
  };

  /**
   * @description Select object
   * @param {FabricObject} obj
   * @param {boolean} [find]
   */
  select = (obj, find) => {
    let findObject = obj;
    if (find) {
      findObject = this.find(obj);
    }
    if (findObject) {
      this.canvas.discardActiveObject();
      this.canvas.setActiveObject(findObject);
      this.canvas.requestRenderAll();
    }
  };

  /**
   * @description Find object by object
   * @param {FabricObject} obj
   */
  find = (obj) => this.findById(obj.id);

  /**
   * @description Find object by id
   * @param {string} id
   * @returns {(FabricObject | null)}
   */
  findById = (id) => {
    let findObject;
    const exist = this.objects.some(obj => {
      // const exist = this.getObjects().some(obj => {
      if (obj.id === id) {
        findObject = obj;
        return true;
      }
      return false;
    });
    if (!exist) {
      // console.log(true, "Not found object by id.");
      return null;
    }
    return findObject;
  };

  remove = (target) => {
    const activeObject = target || this.canvas.getActiveObject();
    if (!activeObject) return;

    if (activeObject.type !== "activeSelection") {
      let arr = this.canvas.getObjects()
        .filter(el => el.id && el.id === activeObject.id);

      this.canvas.discardActiveObject();

      // 处理两次创建的问题
      if (arr.length < 2) {
        this.canvas.remove(activeObject);
        if (activeObject.id) this.removeOriginById(activeObject.id);
      } else {
        arr.forEach(el => {
          this.canvas.remove(el);
          if (activeObject.id) this.removeOriginById(el.id);
        });
      }

    } else {
      const {_objects: activeObjects} = activeObject;
      const existDeleted = activeObjects.some((obj) => typeof obj.deleted !== "undefined" && !obj.deleted);
      if (existDeleted) return;
      this.canvas.discardActiveObject();
      activeObjects.forEach((obj) => {
        this.canvas.remove(obj);
        this.removeOriginById(obj.id);
      });
      // 删除自己
      this.canvas.remove(activeObject);
    }
    // if (target?.id && !target?.ignoreTransaction && !this.transactionHandler.active) {
    //   this.transactionHandler.save('remove');
    // }
    this.objects = this.getObjects();
    this.canvas.requestRenderAll();
    const {onRemove} = this;
    if (onRemove) onRemove(activeObject);
  };

  /**
   * @description Remove element by id
   * @param {string} id
   */
  removeById = (id) => {
    const el = this.findById(id);
    if (el) {
      if (el.remove) {
        el.remove();
      } else {
        this.remove(el);
      }
    }

  }

  removeOriginById = (id) => {
    const object = this.findOriginByIdWithIndex(id);
    if (object.index > 0) {
      this.objects.splice(object.index, 1);
    }
  };

  findOriginByIdWithIndex = (id) => {
    let findObject = null;
    let index = -1;
    const exist = this.objects.some((obj, i) => {
      if (obj.id === id) {
        findObject = obj;
        index = i;
        return true;
      }
      return false;
    });

    if (!exist) {
      console.warn("findOriginByIdWithIndex Not found object by id.");
      return {};
    }

    return {
      object: findObject, index
    };
  };

  removeAll = () => {
    const {onRemove} = this;
    let objs = [...this.objects];
    this.canvas.clear();
    this.objects = [];

    if (onRemove) {
      onRemove(objs);
    }
  }

  copyText = () => {
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) return;
    let selectionText = activeObject.selectionText;
    // console.log(`selectionText`, selectionText);
    this.copyToClipboard(selectionText)
  }

  /**
   * @description Duplicate object
   * @returns
   */
  duplicate = () => {
    const {onAdd, propertiesToInclude, setByObject} = this;
    const grid = 10;
    const activeObject = this.canvas.getActiveObject();
    if (!activeObject) return;

    if (typeof activeObject.cloneable !== 'undefined' && !activeObject.cloneable) return;

    activeObject.clone((clonedObj) => {
      this.canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + grid, top: clonedObj.top + grid, evented: true,
      });
      if (clonedObj.type === 'activeSelection') {
        return message.warning(`暂不支持`);
        let activeSelection = {...clonedObj};
        activeSelection.canvas = this.canvas;
        activeSelection.forEachObject((obj) => {
          if (obj.type === `FreeTextbox`) {
            for (let k in defaultTextboxOptions) {
              setByObject(obj, k, defaultTextboxOptions[k], true);
            }
            obj.on('scaling', () => {
              let newHeight = obj.height * obj.scaleY;
              obj.set({
                width: obj.width * obj.scaleX, _width: obj.width * obj.scaleX, scaleX: 1,
              });
              obj.initDimensions();
              obj.set({height: newHeight, scaleY: 1, _height: newHeight});
            });
            obj.on(`changed`, (e) => {
              if (obj.height < obj._height) {
                let newHeight = obj._height;
                obj.set({height: newHeight, _height: newHeight})
              }
            })
          }
          obj.set('id', v4());
          this.canvas.add(obj);
          this.objects = this.getObjects();
          if (obj.dblclick) {
            obj.on('mousedblclick', this.eventHandler.object.mousedblclick);
          }
        });
        if (onAdd) onAdd(activeSelection);
        activeSelection.setCoords();
      }
      // 单个复制
      else {
        if (activeObject.id === clonedObj.id) {
          clonedObj.set('id', v4());
        }
        if ([`FreeTextbox`, `textbox`].includes(clonedObj.type)) {
          clonedObj.set('display', `${clonedObj.text.substr(0, 10)}的副本`);
          for (let k in defaultTextboxOptions) {
            setByObject(clonedObj, k, defaultTextboxOptions[k], true);
          }
          // clonedObj.on('scaling', () => {
          //   let newHeight = clonedObj.height * clonedObj.scaleY;
          //   clonedObj.set({
          //     width: clonedObj.width * clonedObj.scaleX, _width: clonedObj.width * clonedObj.scaleX, scaleX: 1,
          //   });
          //   clonedObj.initDimensions();
          //   clonedObj.set({height: newHeight, scaleY: 1, _height: newHeight});
          // });
          // clonedObj.on(`changed`, (e) => {
          //   if (clonedObj.height < clonedObj._height) {
          //     let newHeight = clonedObj._height;
          //     clonedObj.set({height: newHeight, _height: newHeight})
          //   }
          // })
        }
        // setByObject(clonedObj, `width`, clonedObj._width, true);
        // setByObject(clonedObj, `height`, clonedObj._height, true);
        // 处理复制的对象没办法编辑的情况
        // this.remove(clonedObj)
        this.canvas.add(clonedObj);
        this.objects = this.getObjects();
        // if (clonedObj.dblclick) {
        //   clonedObj.on('mousedblclick', this.eventHandler.object.mousedblclick);
        // }
        if (onAdd) onAdd(clonedObj);
      }
      this.canvas.setActiveObject(clonedObj);
      this.canvas.requestRenderAll();
    }, propertiesToInclude);
  }

  /**
   * @description Duplicate object by id
   * @param {string} id
   * @returns
   */
  duplicateById = (id) => {
    // console.log(`id`, id);
    const {onAdd, propertiesToInclude} = this;
    let grid = 10;
    const findObject = this.findById(id);
    // console.log(`findObject`, findObject);
    if (findObject) {
      if (typeof findObject.cloneable !== 'undefined' && !findObject.cloneable) {
        return false;
      }
      let options = {};
      if (findObject.type === `FreeTextbox`) options = Object.assign({}, options, defaultTextboxOptions);
      findObject.clone((cloned) => {
        cloned.set({
          ...options, left: cloned.left + grid, top: cloned.top + grid, id: v4(), evented: true,
        });

        if (CanvasObject[cloned.type] && CanvasObject[cloned.type].listener) {
          CanvasObject[cloned.type].listener(cloned, this.canvas)
        }
        // this.canvas.add(cloned);
        this.objects = this.getObjects();
        if (onAdd) onAdd(cloned);

        if (cloned.dblclick) {
          cloned.on('mousedblclick', this.eventHandler.object.mousedblclick);
        }
        this.canvas.setActiveObject(cloned);
        this.canvas.requestRenderAll();
      }, propertiesToInclude);
    }
    return true;
  }

  copy = () => {
    let {clipboard, propertiesToInclude, keyEvent} = this;
    let ao = this.canvas.getActiveObject();
    console.log(`ao`, ao, keyEvent);
    if (ao) {
      if (ao.type === 'activeSelection') return;

      ao.clone(function (cloned) {
        if (keyEvent.clipboard) {
          this.copyToClipboard(JSON.stringify(cloned.toObject(propertiesToInclude), null, '\t'));
        } else {
          clipboard = cloned;
        }
        console.log(`clipboard`, clipboard);
      }, propertiesToInclude);
    }

  }

  paste() {
    this.clipboard.clone(clonedObj => {
      console.log(`clonedObj`, clonedObj);
    })
  }

  cut() {
    this.copy();
    this.remove();
    this.isCut = true;
  };

  copyToClipboard = (value) => {
    // console.log(`value`, value)
    const textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    textarea.value = value;
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.canvas.wrapperEl.focus();
  };

  /**
   * @description 获取区域及当前背景的尺寸
   * @return {{widthBg: number, heightBg: number, widthArea: *, heightArea: *}}
   */
  getAreaAndBackgroundSize = () => {
    const {canvas} = this;
    // const object = canvas.getObjects().find(e => e.category === `background`);
    const object = canvas.backgroundImage;
    if (!object) return null;
    const {width: widthBg = 0, height: heightBg = 0} = object.getOriginalSize() || {};
    // const {width: widthBg = 0, height: heightBg = 0} = object.getBoundingRect() || {};
    const {width: widthScaleBg = 0, height: heightScaleBg = 0} = object.getBoundingRect() || {};
    const {width: widthArea, height: heightArea} = this.canvas;

    return {widthBg, heightBg, widthArea, heightArea, widthScaleBg, heightScaleBg};
  }

  /**
   * Clear canvas
   * @param {boolean} [includeWorkarea=false]
   */
  clear = (includeWorkarea = false) => {
    if (includeWorkarea) {
      this.canvas.clear();
      this.workarea = null;
    } else {
      this.canvas.discardActiveObject();
      this.canvas.getObjects()
        .forEach((obj) => {
          // 排除背景图
          if (!obj.eternal) this.canvas.remove(obj);
        });
    }
    this.objects = this.getObjects();
    this.canvas.renderAll();
  }

  /**
   * @description 导出图片
   * @param mine
   */
  async exportPic(mine) {

    const {onExport, canvas, onState} = this;
    this.zoomHandler.zoomOneToOne();
    let zoom = canvas.getZoom();
    let format = mine || `jpeg`
    // const bgKlass = this.getObjects().find(e => e[`category`] === `background`);
    const bgKlass = canvas.backgroundImage;

    let url = canvas.toDataURL({
      left: canvas.viewportTransform[4],
      top: canvas.viewportTransform[5],
      quality: 1,
      format,
      width: bgKlass.width * zoom,
      height: bgKlass.height * zoom,
      enableRetinaScaling: false,
      multiplier: 1
    })

    // let url = canvas.toDataURL({
    //   left: canvas.viewportTransform[4],
    //   top: canvas.viewportTransform[5],
    //   quality: 1,
    //   format,
    //   width: canvas.backgroundImage.width * zoom,
    //   height: canvas.backgroundImage.height * zoom,
    //   enableRetinaScaling: true,
    //   multiplier: 2
    // })
    // let url = this.canvas.toDataURL({
    //   format, quality: 1, multiplier: 2
    // });

    canvas.discardActiveObject();
    canvas.requestRenderAll();

    saveAs(url, `未知项目.${format}`);

    // sel = url = null;
    if (onExport) onExport(url);
    return url
  }

  /**
   * 字体加载检测
   * @param {Array} arr [fontFamily, fontFamily, ...]
   * @return {Promise<unknown[]>}
   */
  fontsLoader = async (arr) => {
    let font_system_arr = Object.values(fontSystem)
      .map(f => f.name);
    let observers = [];
    if (!arr || !Array.isArray(arr) || arr.length < 1) return;
    arr.forEach(function (family) {
      if (!font_system_arr.includes(family)) {
        let obs = new FontFaceObserver(family);
        observers.push(obs.load());
      }
    });

    return Promise.all(observers)
      .then((fonts) => {
        fonts.forEach((font) => console.log(`${font.family} 已加载`));
      })
      .catch((err) => {
        message.warning(`有字体未能成功加载`)
        console.warn('Some critical font are not available:', err);
      });
  }

  /**
   * 锁定
   * @param target
   * @return {Promise<void>}
   */
  toggleLock = async (target) => {
    const {onLocked} = this;
    const ao = target || this.canvas.getActiveObject();
    if (!ao) return
    let {lock} = ao;
    if ([`textbox`, `FreeTextbox`].includes(ao.type)) ao.exitEditing();
    if (lock) {
      for (let k in lockOptions) ao[k] = !lockOptions[k];
      ao.hoverCursor = `pointer`
    } else {
      for (let k in lockOptions) ao[k] = lockOptions[k];
      ao.hoverCursor = `default`
    }
    if (onLocked) onLocked(ao)
  }

  /**
   * 可视化
   * @param target
   * @return {Promise<void>}
   */
  toggleVisible = async (target) => {
    const ao = target || this.canvas.getActiveObject();
    if (!ao) return
    let {visible} = ao;
    if ([`textbox`, `FreeTextbox`].includes(ao.type)) ao.exitEditing();
    this.sets(`visible`, !visible, ao);
    this.canvas.requestRenderAll();
  }

  visibleToggle = (key, visible) => this.onState({[key]: visible});

  /**
   * 隐藏所有
   */
  hideAll = (visible) => {
    this.canvas.discardActiveObject();
    this.getObjects().filter(e => e.id).forEach(el => el.set(`visible`, visible));
    this.canvas.requestRenderAll();
    if (this.onHideAll) this.onHideAll(visible)
  }

  /**
   * 改变开屏引导
   * @param key
   * @param val
   */
  changeOpenGuide = (key, val) => {
    const {onDBChange} = this;
    this.dbHandler.set(`setting`, key, val);
    if (onDBChange) onDBChange(`setting`, key, val);
  }

  pageCut = async (values) => {
    const {canvas, onPageCut, onState} = this;

    if (!canvas.bg) {
      message.warn(`没有上传图片，请先点击左上方按钮上传图片`)
      return
    }

    onState({cutting: true});

    let {resultURL, segmentURL} = await this.cutHandler.cutPic(canvas.bg)
    if (!resultURL) {
      onPageCut(null)
      return
    }

    const imgID = v4(), segID = v4();

    // 建立涂抹层
    let segmentKlass = this.add(Object.assign({},
      CanvasObject.segment.options,
      {
        width: canvas.lastWidth,
        height: canvas.lastHeight,
        left: 0,
        top: 0,
        src: segmentURL,
        rel: imgID,
        id: segID,
        evented: true,
        selectable: true,
        ...values
      }
    ));

    let imageKlass = this.add(Object.assign({},
      CanvasObject.image.options,
      {
        width: canvas.lastWidth,
        height: canvas.lastHeight,
        left: 0,
        top: 0,
        id: imgID,
        rel: segID,
        src: resultURL,
      }
    ));

    canvas.sendToBack(imageKlass);
    canvas.sendToBack(segmentKlass);
    canvas.setActiveObject(imageKlass);

    // 如果需要翻译
    // if (auto_translate) klass = await eventer.translateEventer.transTextBox(textKlass)
    if (onPageCut) onPageCut(imageKlass);
  }

  /**
   * 一键翻译
   * @returns {Promise<boolean>}
   */
  pageTranslate = async () => {
    const {tar_lang, canvas, onPageTranslated, onState} = this;
    const that = this;

    if (!canvas.bg) {
      message.warn(`没有上传图片，请先点击左上方按钮上传图片`)
      return
    }

    onState({translating: true});
    let resp = await REQUEST(API.trial.image.translate, {
      method: `POST`, body: {
        // img_id: this.iid,
        img_base64: canvas.bg, tar_lang
      }
    });

    if (!resp || resp.error_code !== 0) {
      message.error(`失败! ${resp?.msg || ``}`);
      return false
    }

    if (!isValidArr(resp.data)) {
      message.warn(`未能识别到文字`)
      return false
    }

    for (let i = 0; i < resp.data.length; i++) {
      const group = resp.data[i];
      const txtID = v4(), segID = v4();
      // 配置文字
      let {inpaint_base64, layout, org_layout, text, trans_res, shield_result, color, textAlign} = group || {};

      const {width, height, left, top} = layout;
      const {width: w, height: h, left: l, top: t} = org_layout;

      if (trans_res) {
        const fontSize = trans_res ? calc(width, height, trans_res) : 30;

        let segmentKlass = that.add(Object.assign(
          {},
          CanvasObject.segment.options,
          {
            width,
            height,
            left,
            top,
            src: inpaint_base64,
            rel: txtID,
            id: segID,
            evented: true,
            selectable: true
          }));

        // console.log(`segmentKlass`, segmentKlass)
        let splitByGrapheme = await isSplitByGrapheme(trans_res);

        // 建立文字层
        let textKlass = that.add(Object.assign(
          {},
          CanvasObject.textbox.options,
          {
            width: w,
            height: h,
            left: l,
            top: t,
            fontSize,
            textAlign,
            fill: color,
            id: txtID, rel: segID, lineHeight: 1.16,
            text: trans_res,
            origin: text,
            splitByGrapheme
          }));
        // console.log(`textKlass`, textKlass)
        textKlass.setControlVisible(`mt`, false);
        textKlass.setControlVisible(`mb`, false);
        that.canvas.bringToFront(textKlass);
        that.canvas.sendToBack(segmentKlass);
      }
    }

    onPageTranslated();
    return true
  }

  /**
   * 切换关联图层
   * @param target
   */
  progressModify = (target) => {
    const ao = target || this.canvas.getActiveObject();
    if (!ao?.rel || ![`image`, `textbox`, `segment`].includes(ao.category)) return;

    const category = ao.category;
    const relID = ao.rel;
    // console.log(`category`, category);
    this.interactionHandler.selection();

    // 如果是背景层
    if (category === `segment`) {
      const relativeObj = this.getObjects().find(obj => obj.id === relID);
      if (!relativeObj) return;
      relativeObj.set(`visible`, true);
      this.canvas.setActiveObject(relativeObj);
      this.canvas.requestRenderAll();
    }
    // 文字层
    else {
      // 文字图片
      // 隐藏文字 - 查找图片层 - 激活图片层
      ao.set(`visible`, false);
      const segment = this.getObjects().find(obj => obj.id === relID);
      this.canvas.setActiveObject(segment);
      this.interactionHandler.patch();
      this.canvas.requestRenderAll();
    }
  }

  /**
   * 图片选择
   * @param url
   * @param key
   * @returns {Promise<void>}
   */
  imageSelect = async (url, key) => {
    if (![`replace`, `add`].includes(key) || !url) return;
    if (key === `replace`) await this._replaceBackground(url);
    else if (key === `add`) {
      const {[0]: w, [1]: h} = url?.dimension || {};
      const {width: bw, height: bh} = this.canvas.backgroundImage;
      const imgID = v4();
      const addKlass = await this.add({
        id: imgID,
        file: url,
        src: url.src,
        left: (bw - w) / 2,
        top: (bh - h) / 2,
        ...CanvasObject.image.options
      })
      this.canvas.setActiveObject(addKlass);
    }
  }

  /**
   * 替换背景
   * @param url
   * @returns {Promise<void>}
   * @private
   */
  _replaceBackground = async (url) => {
    const handler = this;

    // 去除旧的背景
    const prevLayers = handler.canvas.getObjects().filter(e => [`background`, `board`, `boardHeader`].includes(e.category));
    if (isValidArr(prevLayers)) {
      for (let i = 0; i < prevLayers.length; i++) {
        handler.canvas.remove(prevLayers[i]);
      }
      handler.canvas.bg = null;
      handler.canvas.lastHeight = 0;
    }

    const dataURL = typeof url !== `string` ? url.src : await imageURL2Base64(url);

    let {w, h} = await readImageDimensionOfURL(dataURL);

    handler.canvas.setBackgroundImage(dataURL, handler.canvas.renderAll.bind(handler.canvas), {
      erasable: false,
      crossOrigin: 'anonymous'
    });

    handler.canvas.bg = await imageURL2Base64(dataURL);
    handler.canvas.lastHeight = h;

    const boardOptions = Object.assign({}, CanvasObject.board.options, {
      width: w + 5,
      height: h + 5,
    });

    const boardKlass = new fabric.Rect(boardOptions);

    const boardHeaderOptions = Object.assign({}, CanvasObject.boardHeader.options, {
      text: `画板`
    });

    const boardHeaderKlass = new fabric.Rect(boardHeaderOptions);

    handler.canvas.add(boardKlass);
    handler.canvas.add(boardHeaderKlass);
    handler.canvas.sendToBack(boardKlass);
    handler.canvas.sendToBack(boardHeaderKlass);
    handler.zoomHandler.zoomToFit();
  }

}

export default Handler;