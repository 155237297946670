import CanvasObject from "./CanvasObjectCreate";
import { fabric } from "fabric";
import { lockOptions } from "../config";
import { v4 } from "uuid";

class SegmentHandler {
  constructor(handler) {
    this.handler = handler;
    this.segment = null;
  }

  goback = () => {
    const target = this.handler.canvas.getActiveObject();
    if (!target) return;
    console.log(`target`, target);
    target.setSrc(target.srcbk);
    target.srcbk = null;
    this.handler.canvas.requestRenderAll()
  }

  reset = () => {
    const {onResized} = this.handler;
    const target = this.handler.canvas.getActiveObject();
    if (!target) return;

    fabric.util.loadImage(this.handler.canvas.bg, async (imgElement) => {
      const {left: l, top: t, width: w, height: h, scaleX: sx, scaleY: sy} = target;
      let holderOptions = {
        // 重要 不然会出现白色边
        width: w * sx,
        height: h * sy,
        left: l,
        top: t,
        ...CanvasObject.selector.options,
        ...lockOptions
      }

      const fillOptions = {
        source: imgElement,
        repeat: 'no-repeat',
        offsetX: -l,
        offsetY: -t,
        // 重要 不然会出现白色边
        left: 0,
        top: 0,
        ignoreTransaction: true,
      }
      // let holderKlass = new fabric.Rect(holderOptions);
      const holderKlass = new fabric.Rect(holderOptions);
      holderKlass.fill = new fabric.Pattern(fillOptions);
      holderKlass.setCoords();
      const newURL = holderKlass.toDataURL({format: `jpeg`, quality: 1})

      let image = new Image();
      image.width = holderKlass.width;
      image.height = holderKlass.height;
      image.src = newURL;

      // 建立涂抹层
      let newSegment = new fabric.Image(image, {
        ...CanvasObject.segment.options,
        id: target.id,
        rel: target.rel,
        height: holderKlass.height,
        width: holderKlass.width,
        left: holderKlass.left,
        top: holderKlass.top,
        srcbk: target.src,
        evented: true,
        selectable: true
      })

      const targetIndex = this.handler.getObjects().findIndex(e => e.id === target.id);
      this.handler.canvas.insertAt(newSegment, targetIndex, false);
      this.handler.canvas.setActiveObject(newSegment);
      this.handler.canvas.remove(target);
      this.clear();
      if (onResized) onResized();

    });
  }

  resize = {
    start: e => {
      this.handler.interactionHandler.selection();
      const ao = this.handler.canvas.getActiveObject();
      if (!ao || ![`segment`].includes(ao?.category)) return;
      this.handler.isCorpping = true;
      this.segment = ao;
      // 隐藏片段
      ao.set(`visible`, false);

      let {width: w, height: h, left: l, top: t} = ao;

      if (w % 2 !== 0) w += 1;
      if (h % 2 !== 0) h += 1;

      let holderOptions = {
        width: w,
        height: h,
        left: l,
        top: t,
        ignoreTransaction: true,
        ...CanvasObject.selector.options
      }

      const holderKlass = new fabric.Rect(holderOptions);

      this.handler.canvas.add(holderKlass);
      holderKlass.setCoords();
      this.handler.canvas.setActiveObject(holderKlass);
      this.handler.canvas.bringToFront(holderKlass);
      this.handler.selectorContextmenuHandler.show(e, holderKlass);
    },
    confirm: e => {
      const {onResized} = this.handler;
      const target = this.handler.canvas.getActiveObject();
      if (!target) return;
      const that = this;

      fabric.util.loadImage(this.handler.canvas.bg, async (imgElement) => {
        const {left: l, top: t, width: w, height: h, scaleX: sx, scaleY: sy} = target;
        let holderOptions = {
          // 重要 不然会出现白色边
          width: w * sx,
          height: h * sy,
          left: l,
          top: t,
          ...CanvasObject.selector.options,
          ...lockOptions
        }

        const fillOptions = {
          source: imgElement,
          repeat: 'no-repeat',
          offsetX: -l,
          offsetY: -t,
          // 重要 不然会出现白色边
          left: 0,
          top: 0,
          ignoreTransaction: true,
        }
        // let holderKlass = new fabric.Rect(holderOptions);
        const holderKlass = new fabric.Rect(holderOptions);
        holderKlass.fill = new fabric.Pattern(fillOptions);
        holderKlass.setCoords();
        const newURL = holderKlass.toDataURL({format: `jpeg`, quality: 1})
        // console.log(`holderKlass`, holderKlass.width, holderKlass.height)
        //
        // this.segment.set(`width`, holderKlass.width);
        // this.segment.set(`height`, holderKlass.height);
        // this.segment.set(`top`, holderKlass.top);
        // this.segment.set(`left`, holderKlass.left);
        // this.segment.set(`dirty`, true);
        // this.segment.set(`src`, newURL);
        // that.segment.setCoords();
        // that.handler.canvas.setActiveObject(that.segment);
        // that.handler.canvas.renderAll();

        let image = new Image();
        image.width = holderKlass.width;
        image.height = holderKlass.height;
        image.src = newURL;

        // 建立涂抹层
        let newSegment = new fabric.Image(image, {
          ...CanvasObject.segment.options,
          id: this.segment.id,
          rel: this.segment.rel,
          height: holderKlass.height,
          width: holderKlass.width,
          left: holderKlass.left,
          top: holderKlass.top,
          evented: true,
          selectable: true
        })

        const targetIndex = this.handler.getObjects().findIndex(e => e.id === this.segment.id);
        this.handler.canvas.insertAt(newSegment, targetIndex, false);
        this.handler.canvas.setActiveObject(newSegment);
        this.handler.canvas.remove(this.segment);
        this.clear();

        if (onResized) onResized();
      });
    },
    cancel: e => {
      if (!this.segment) return;
      this.segment.set(`visible`, true);
      this.handler.canvas.setActiveObject(this.segment);
      this.clear()
    }
  }

  /**
   * 清除选择框
   */
  clear() {
    const selector = this.getSelector();
    if (selector) {
      this.handler.canvas.remove(selector);
      this.handler.selectorContextmenuHandler.hide();
    }
    this.segment = null;
    this.handler.canvas.requestRenderAll();
    this.handler.isCorpping = false;
  }

  /**
   * 获取选择框对象
   * @return {T}
   */
  getSelector = () => this.handler.canvas.getObjects().find(e => e.category === `selector`);
}

export default SegmentHandler