import React, { useState } from "react"
import { Button, Row, Col, Space, Select, InputNumber, Tooltip, Slider } from "antd";
import {
  mdiAlignHorizontalCenter,
  mdiAlignHorizontalDistribute,
  mdiAlignHorizontalLeft,
  mdiAlignHorizontalRight,
  mdiAlignVerticalBottom,
  mdiAlignVerticalCenter,
  mdiAlignVerticalDistribute,
  mdiAlignVerticalTop,
  mdiChevronDown,
  mdiChevronUp,
  mdiHexagon,
  mdiHexagonOutline,
  mdiLastpass, mdiVectorLine,
} from "@mdi/js";
import Mcon from "@mdi/react";
import { ButtonGuideWidget } from "../../../widgets";
import { capitalize, lowerCase, upperCase } from "lodash";
import { isValidArr } from "../../../../../../../utils";
import { ToolPanelListItemNewComp } from "../../index";
import { hexToRgb } from "../../../../utils";

const keyName = "tool.new.line.stroke";
const klassName = keyName.replace(/\./g, '-');
const video_guide_open = true;
const iconSize = 0.75;
const iconColor = `#333`;
const colorBlack = `rgb(0, 0, 0)`, colorWhite = `rgb(255, 255, 255)`, colorTransparent = `rgba(0, 0, 0, 0)`;

export default function LineStroke({handler, editing}) {

  const {stroke, strokeWidth} = editing;

  return (
    <div className={`cp-${klassName}`}>
      <div className="inner">
        <Row style={{width: `100%`}}>
          <Col span={14}>
            <Slider min={1} max={100} value={strokeWidth} onChange={val => handler.sets(`strokeWidth`, val)}/>
          </Col>
          <Col span={10}>
            <InputNumber value={strokeWidth}
                         style={{width: 75, borderRadius: `0`, marginLeft: -1}}
                         step={1}
                         onChange={val => handler.sets(`strokeWidth`, val)}
            />
          </Col>
        </Row>

      </div>
    </div>
  )
}