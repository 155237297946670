import React, { useState } from "react"
import { Button, Row, Col, Space, Select, InputNumber, Tooltip } from "antd";
import {
  mdiAlignHorizontalCenter,
  mdiAlignHorizontalDistribute,
  mdiAlignHorizontalLeft,
  mdiAlignHorizontalRight,
  mdiAlignVerticalBottom,
  mdiAlignVerticalCenter,
  mdiAlignVerticalDistribute,
  mdiAlignVerticalTop,
  mdiChevronDown,
  mdiChevronUp,
  mdiHexagon,
  mdiHexagonOutline,
  mdiLastpass,
} from "@mdi/js";
import Mcon from "@mdi/react";
import { ButtonGuideWidget } from "../../../widgets";
import { capitalize, lowerCase, upperCase } from "lodash";
import { isValidArr } from "../../../../../../../utils";
import { ToolPanelListItemNewComp } from "../../index";

const keyName = "tool.new.collection.distribution";
const klassName = keyName.replace(/\./g, '-');
const video_guide_open = true;
const iconSize = 0.75;
const iconColor = `#333`;

export default function TextboxColor({handler, editing, fontsZH, fontsEN, fontsAvailable}) {

  return (
    <div className={`cp-${klassName}`}>
      <Space direction={`vertical`} className="inner">
        <Row justify="space-start">
          <Col>

            <ToolPanelListItemNewComp
              disabled={editing?._objects?.length < 3}
              item={{
                icon: mdiAlignHorizontalDistribute,
                title: `水平分布`,
                click: e => handler.alignmentHandler.horizontal.distribute(`horizontal`)
              }}
              handler={handler}
              active={false}
            />
          </Col>
          <Col>
            <ToolPanelListItemNewComp
              disabled={editing?._objects?.length < 3}
              item={{
                icon: mdiAlignVerticalDistribute,
                title: `垂直分布`,
                click: e => handler.alignmentHandler.vertical.distribute(`vertical`)
              }}
              handler={handler}
              active={false}
            />
          </Col>

        </Row>

      </Space>
    </div>
  )
}