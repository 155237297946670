import {
  mdiImageFilterCenterFocusStrong, mdiImagePlus,
  mdiOcr,
} from "@mdi/js";
import Mcon from "@mdi/react";
import { Button, Tag } from "antd";
import React from "react";

export default function steps({handler}) {
  return [
    {
      target: '#upload-btn',
      content: <div className={`guide-step-content`}>
        <div className="inner">
          <div className="title">
            点击「插入图片」<Button type={`primary`} icon={<Mcon  path={mdiImagePlus} size={0.9} />} />
          </div>
          <div className="txt">选择您想要处理的本地图片 / 或者粘贴一个网络图片的地址</div>
          <video style={{width: `100%`}} loop autoPlay playsInline>
            <source type="video/mp4" src={`https://img.miaotranslation.com/bstool/guide/upload.mp4`} />
          </video>
        </div>
      </div>,
      disableBeacon: true,
      desc: `演示上传`,
    },
    {
      target: '#cut-btn',
      content: <div className={`guide-step-content`}>
        <div className="inner">
          <div className="title">
            打开「图像提取」<Button icon={<Mcon path={mdiImageFilterCenterFocusStrong} size={0.9} />} />
          </div>
          <div className="txt">按住鼠标左键并拖动鼠标，框选出你要识别的内容，即可进行图像的提取</div>
          <video style={{width: `100%`}} loop autoPlay playsInline>
            <source type="video/mp4" src={`https://img.miaotranslation.com/bstool/guide/cut.mp4`} />
          </video>
        </div>
      </div>,
      desc: `演示抠图`,
    },
    {
      target: '#ocr-btn',
      content: <div className={`guide-step-content`}>
        <div className="inner">
          <div className="title">
            打开「文字提取」<Button icon={<Mcon path={mdiOcr} size={0.9} />} />
          </div>
          <div className="txt">按住鼠标左键并拖动鼠标，框选出你要识别的内容，即可进行识别并进行文字翻译</div>
          <video style={{width: `100%`}} loop autoPlay playsInline>
            <source type="video/mp4" src={`https://img.miaotranslation.com/bstool/guide/ocr.mp4`} />
          </video>
        </div>
      </div>,
      desc: `演示OCR模式`,
    },
  ]
}