import React from "react";
import { ToolPage } from "./pages";
import "./App.less";

export default function App(options) {
  return (
    <div className="App">
      <ToolPage />
    </div>
  );
}
