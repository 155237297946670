import React from "react";
import { Layout, Button, Tooltip, Tag, Divider, Slider } from "antd";
import Mcon from "@mdi/react"
import {
  mdiPlus,
  mdiMinus,
  mdiFitToPageOutline,
  mdiHelpCircleOutline,
  mdiMonitorScreenshot,
  mdiHandHeart, mdiArrowAll
} from "@mdi/js";

import { getOS } from "../../../../../utils"

const keyName = "tool.header";
const klassName = keyName.replace(/\./g, '-');
const iconSize = 0.75;

export default function Header({handler, zoomRatio = 1, interactionMode, visibleEls}) {

  let isGrab = interactionMode === `grab`;

  const {minZoom = 10, maxZoom = 200} = handler?.canvas || {};

  const compHandler = {
    enable: () => {
      handler.onState({outsideDisabled: false})
    },
    disabled: () => {
      handler.onState({outsideDisabled: true})
    },
  }

  const shortKeyName = getOS() === `MacOS` ? `⌥` : `Alt`;

  return (
    <Layout.Header theme={`light`} className={`cp-${klassName}`}
                   onMouseEnter={compHandler.disabled}
                   onMouseLeave={compHandler.enable}
    >
      <div className="inner">

        <Button.Group id={`zoom-area`}>
          <Tooltip title={<span>拖拽画板 快捷键：按住 <Tag>{shortKeyName}</Tag></span>}>
            <Button shape="square"
                    type={isGrab && `primary`}
                    onClick={e => {
                      isGrab ?
                        handler.interactionHandler.selection()
                        :
                        handler.interactionHandler.grab()
                    }}
                    icon={<Mcon size={iconSize} path={mdiArrowAll}/>}
            />
          </Tooltip>


          <Divider type="vertical"/>

          <Button shape="square" className={`zoom-area`} title={`缩小`} onClick={e => handler.zoomHandler.zoomOut()}
                  icon={<Mcon size={iconSize} path={mdiMinus}/>}/>

          <Button style={{width: 80}} disabled>{(zoomRatio * 100).toFixed(0)} %</Button>
          <Button shape="square" title={`1：1`} onClick={e => handler.zoomHandler.zoomOneToOne()}
                  icon={<Mcon size={iconSize} path={mdiFitToPageOutline}/>}/>

          <Button shape="square" title={`适应屏幕`} onClick={e => handler.zoomHandler.zoomToFit()}
                  icon={<Mcon size={iconSize} path={mdiMonitorScreenshot}/>}/>

          <Button shape="square" title={`放大`} onClick={e => handler.zoomHandler.zoomIn()}
                  icon={<Mcon size={iconSize} path={mdiPlus}/>}/>

          <Divider type="vertical"/>

          <Slider onChange={v => handler.zoomHandler.zoomCenter(+(v / 100).toFixed(2))}
                  style={{width: 200, margin: `8px 0`}} max={maxZoom} min={minZoom}
                  value={(zoomRatio * 100).toFixed(0)}/>

          <Tooltip trigger={[`hover`]} title={<span>
                      <div>缩放
                        <br/>
                        快捷键：按住<Tag className="ml-1">{shortKeyName}</Tag><br/>
                        同时滚动鼠标滚轮</div>
                    </span>
          }>
            <Button type={`link`}>
              <Mcon size={iconSize} path={mdiHelpCircleOutline}/>
            </Button>
          </Tooltip>
        </Button.Group>

        <Button disabled>{interactionMode?.toUpperCase()}</Button>

        <Divider type="vertical"/>

        <Button type={visibleEls ? `default` : `primary`}
                onClick={e => handler.hideAll(!visibleEls)}>{visibleEls ? `查看原图` : `关闭原图`}</Button>
      </div>
    </Layout.Header>
  );
}
