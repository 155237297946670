import {
  mdiFormatAlignCenter,
  mdiFormatAlignJustify,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatAnnotationPlus,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatPaint,
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiOcr,
  mdiFormatLetterCase,
  mdiFormatLetterCaseUpper,
  mdiFormatLetterCaseLower,
  mdiFormatSubscript,
  mdiFormatSuperscript, mdiMoonWaxingCrescent, mdiMoonFull, mdiTrayRemove, mdiClose, mdiImageFilterCenterFocusStrong
} from "@mdi/js";
import { capitalize, lowerCase, upperCase } from "lodash";
import React from "react";
import { Tag } from "antd"


export default {
  mode: {
    text: {
      title: `添加文字`,
      key: `text`,
      icon: mdiFormatAnnotationPlus,
      description: `<div>
        <div>点击按钮后 在需要添加文字的区域点击</div>
        <div>点击空白处 或着 <Tag>Esc</Tag>可以退出</div>
        <div>快捷键「T」</div>
      </div>`,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/mode-text.mp4`
      }
    },
    ocr: {
      title: `文字提取`,
      icon: mdiOcr,
      key: `ocr`,
      description: `<div>
        <div>点击按钮后 框选需要OCR文字的区域 即可识别</div>
        <div>右侧可设置识别时自动翻译 加快效率</div>
        <div>点击空白处 或着 <Tag>Esc</Tag>可以退出</div>
        <div>快捷键「C」</div>
      </div>`,
      guide: {
        video: `https://img.miaotranslation.com/bstool/guide/ocr.mp4`
      }
    },
    cut: {
      title: `图像提取`,
      icon: mdiImageFilterCenterFocusStrong,
      key: `cut`,
      description: `<div>
        <div>点击按钮后 框选需要提取的图像的区域 即可生成</div>
        <div>点击空白处 或着 <Tag>Esc</Tag>可以退出</div>
        <div>快捷键「X」</div>
      </div>`,
      guide: {
        video: `https://img.miaotranslation.com/bstool/guide/cut.mp4`
      }
    },
    draw: {
      title: `画笔`,
      icon: mdiImageFilterCenterFocusStrong,
      key: `draw`,
      description: `<div>
        <div>点击按钮后 即可进行绘画</div>
        <div>点击<Tag>Esc</Tag>可以退出</div>
        <div>快捷键「B」</div>
      </div>`,
      guide: {
        video: `https://img.miaotranslation.com/bstool/guide/draw-fast.mp4`
      }
    },
    patch: {
      title: `涂抹`,
      icon: mdiImageFilterCenterFocusStrong,
      key: `patch`,
      description: `<div>
        <div>点击按钮后 可以处理「文字提取」后背景污点的问题</div>
        <div>点击<Tag>Esc</Tag>可以退出</div>
        <div>快捷键「J」</div>
      </div>`,
      guide: {
        video: `https://img.miaotranslation.com/bstool/guide/patch-fast.mp4`
      }
    },
    brush: {
      title: `格式刷`,
      icon: mdiFormatPaint,
      key: `brush`,
      description: `选中想要复制格式的对象，然后按下「格式刷」，点击区域内需要相同格式的文本框，即可粘贴样式 / 点击空白处可以退出`,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/mode-brush.mp4`
      }
    }
  },

  font: {
    bold: {
      title: `加粗`,
      icon: mdiFormatBold,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/font-bold.mp4`
      }
    },
    italic: {
      title: `倾斜`,
      icon: mdiFormatItalic,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/font-italic.mp4`
      }
    },
    underline: {
      title: `下划线`,
      icon: mdiFormatUnderline,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/font-underline.mp4`
      }
    },
    linethrough: {
      title: `删除线`,
      icon: mdiFormatStrikethrough,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/font-linethrough.mp4`
      }
    },
  },

  alignment: {
    left: {
      title: `左对齐`,
      icon: mdiFormatAlignLeft,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/alignment-left.mp4`
      }
    },
    center: {
      title: `居中对齐`,
      icon: mdiFormatAlignCenter,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/alignment-center.mp4`
      }
    },
    right: {
      title: `右对齐`,
      icon: mdiFormatAlignRight,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/alignment-right.mp4`
      }
    },
    justify: {
      title: `两端对齐`,
      icon: mdiFormatAlignJustify,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/alignment-justify.mp4`
      }
    },

  },

  str: {
    spaceClear: {
      title: `去除中文空格`,
      icon: mdiTrayRemove,
      description: `去除两个中文间单个空格`,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-spaceclear.mp4`
      }
    },
    dbc: {
      title: `半角转全角`,
      icon: mdiMoonFull,
      description: `将符号转换为全角符号`,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-cdb.mp4`
      }
    },
    cdb: {
      title: `全角转半角`,
      icon: mdiMoonWaxingCrescent,
      description: `将符号转换为半角符号`,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-cdb.mp4`
      }
    },
    superScript: {
      title: `上角标`,
      icon: mdiFormatSuperscript,
      description: `选中想要设置为上角标的文本部分，点击按钮`,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-superscript.mp4`
      }
    },
    subScript: {
      title: `下角标`,
      icon: mdiFormatSubscript,
      description: `选中想要设置为下角标的文本部分，点击按钮`,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-subscript.mp4`
      }
    },
    removeScript: {
      title: `移除上下角标`,
      icon: mdiClose,
      description: `选中想要去掉下角标的文本部分，点击按钮`,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-subscript.mp4`
      }
    },
    capitalize: {
      title: `首字母大写`,
      icon: mdiFormatLetterCase,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-capitalize.mp4`
      }
    },
    upperCase: {
      title: `全部大写`,
      icon: mdiFormatLetterCaseUpper,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-uppercase.mp4`
      }
    },
    lowerCase: {
      title: `全部小写`,
      icon: mdiFormatLetterCaseLower,
      description: ``,
      guide: {
        video: `https://img.miaotranslation.com/picpaw/tool/pdf/guide/str-lowercase.mp4`
      }
    }
  }
}